import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppThunk } from "../../app/store";

export interface ManagerAuthVerifySliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  isManagerVerified: boolean;
  error?: string;
}

const initialState: ManagerAuthVerifySliceState = {
  status: "IDLE",
  isManagerVerified: false,
};

export const SignInStep4AuthVerifySlice = createSlice({
  name: "authVerify",
  initialState,
  reducers: {
    resetToIdle: (state) => {
      state.status = "IDLE";
      state.isManagerVerified = false;
    },
    getManagerAuthVerifyStart: (state) => {
      state.status = "LOADING";
    },
    getManagerAuthVerifySuccess: (
      state,
      action: PayloadAction<{
        isManagerVerified: boolean;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.isManagerVerified = action.payload.isManagerVerified;
    },
    getManagerAuthVerifyFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
  },
});

export const {
  resetToIdle,
  getManagerAuthVerifyStart,
  getManagerAuthVerifySuccess,
  getManagerAuthVerifyFailure,
} = SignInStep4AuthVerifySlice.actions;

export const managerAuthVerifyReducer = SignInStep4AuthVerifySlice.reducer;

export const loadManagerAuthVerify = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getManagerAuthVerifyStart());
    // const isManagerVerified = (await managersApi.getManagerAuthVerify())
    //   .verified;
    dispatch(
      getManagerAuthVerifySuccess({
        isManagerVerified: false,
      }),
    );
  } catch (err: any) {
    dispatch(getManagerAuthVerifyFailure(err.toString()));
  }
};
