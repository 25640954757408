import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import useInterval from "@use-it/interval";
import React from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SubRouteProps } from "../../components/SubRouteProps";
import { resetToInitial } from "./FirebaseSlice";
import LoginLayout from "./LoginLayout";
import { SignInButton } from "./SignInButton";
import { loadManagerAuthStatus } from "./SignInStep5AuthStatusSlice";

export const SignInStep5AuthStatusView: React.FC<SubRouteProps> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { status, error, managerStatus } = useAppSelector(
    (state) => state.auth.managerAuthStatus,
  );
  const delayMs = 3000;
  const [delay, setDelay] = React.useState<number | null>(delayMs);
  const [failureCount, setFailureCount] = React.useState(0);

  useInterval(async () => {
    if (
      managerStatus === ManagersClient.ManagerGetStatusResDtoStatusEnum.Invalid
    ) {
      dispatch(loadManagerAuthStatus());
      setDelay(null);
      setFailureCount((x) => x + 1);
    }
  }, delay);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        break;
      case "SUCCESS":
        setDelay(delayMs);
        break;
      case "ERROR":
        break;
      default:
        break;
    }
  }, [history, status]);

  React.useEffect(() => {
    switch (managerStatus) {
      case ManagersClient.ManagerGetStatusResDtoStatusEnum.InvitedManager:
        history.push("/onboarding");
        break;
      case ManagersClient.ManagerGetStatusResDtoStatusEnum.ExistingManager:
        history.push("/organizations");
        break;
      case ManagersClient.ManagerGetStatusResDtoStatusEnum.Invalid:
        break;
      default:
        break;
    }
  }, [history, managerStatus]);

  if (error || failureCount >= 10) {
    return (
      <LoginLayout>
        <ErrorView
          message={"Oops something went wrong. Please try again later."}
        />
        <SignInButton
          btnText={"Sign-in using another email"}
          onClick={() => {
            dispatch(resetToInitial());
            history.push(`${basePath}/enter-email`);
          }}
        />
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <div className={"flex flex-col items-center justify-center"}>
        <div className={"mt-3 text-2xl font-medium text-default"}>
          Sign-in successful!
        </div>
        <div className={"w-24 h-24 my-4"}>
          <LoadingView />
        </div>
      </div>
    </LoginLayout>
  );
};
