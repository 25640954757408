import {
  ManagerPropertySetupStatus,
  ManagerPropertyStep,
} from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";

export interface SetupAccount {
  name: string;
  status: string;
}

export interface SetupAccountSliceState {
  setupAccountStatus:
    | "IDLE"
    | "SETUP_ACCOUNT_LOADING"
    | "SETUP_ACCOUNT_SUCCESS"
    | "SETUP_ACCOUNT_ERROR";
  setupAccountError?: string;
  setupAccount: Array<any>;
}

const initialState: SetupAccountSliceState = {
  setupAccountStatus: "IDLE",
  setupAccount: [],
};

export const SetupAccountSlice = createSlice({
  name: "setupAccount",
  initialState,

  reducers: {
    resetSetupAccountStatus: (state) => {
      state.setupAccountStatus = "IDLE";
      state.setupAccountError = undefined;
    },
    getSetupAccountStart: (state) => {
      state.setupAccountStatus = "SETUP_ACCOUNT_LOADING";
    },
    getSetupAccountSuccess: (
      state,
      action: PayloadAction<ManagerPropertyStep[]>,
    ) => {
      state.setupAccountStatus = "SETUP_ACCOUNT_SUCCESS";
      state.setupAccount = action.payload || [];
      state.setupAccountError = undefined;
    },
    getSetupAccountFailure: (state, action: PayloadAction<string>) => {
      state.setupAccountStatus = "SETUP_ACCOUNT_ERROR";
      state.setupAccountError = action.payload;
    },
  },
});

export const {
  resetSetupAccountStatus,
  getSetupAccountStart,
  getSetupAccountSuccess,
  getSetupAccountFailure,
} = SetupAccountSlice.actions;

export const setupAccReducer = SetupAccountSlice.reducer;

// FUNCTIONS TO CHECK EACH PROPERTY OF THE ARRAY

export const getAccountSetupStepsInfo =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getSetupAccountStart());

      const { orgId, propertyId } = getSelectionIds(getState());

      const setupAccount: ManagerPropertySetupStatus =
        await managersApi.getPropertySetupStatus({
          orgId,
          propertyId,
        });

      dispatch(getSetupAccountSuccess(setupAccount.results));

      // dispatch(
      //   getSetupAccountSuccess([
      //     {
      //       name: "CONFIGURE_PROPERTY",
      //       complete: true,
      //       steps: [
      //         {
      //           name: "ADD_UNIT_NUMBERS",
      //           complete: false,
      //         },
      //         {
      //           name: "ADD_PROPERTY_CONTACTS",
      //           complete: false,
      //         },
      //         {
      //           name: "ADD_DOCUMENTS",
      //           complete: true,
      //         },
      //       ],
      //     },
      //     {
      //       name: "TEST_DRIVE",
      //       complete: false,
      //       steps: [
      //         {
      //           name: "DOWNLOAD_APP",
      //           complete: true,
      //         },
      //         {
      //           name: "APPROVE_YOURSELF",
      //           complete: true,
      //         },
      //         {
      //           name: "TEST_ANNOUNCEMENT",
      //           complete: false,
      //         },
      //       ],
      //     },
      //     {
      //       name: "INVITE_RESIDENTS",
      //       complete: true,
      //       steps: [
      //         {
      //           name: "ADD_EMAILS",
      //           complete: true,
      //         },
      //         {
      //           name: "INVITE_QR",
      //           complete: true,
      //         },
      //       ],
      //     },
      //   ]),
      // );
    } catch (err: any) {
      console.error(err);
      dispatch(getSetupAccountFailure(prettyRawError(err)));
    }
  };
