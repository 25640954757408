import React, { FunctionComponent } from "react";

import { FabLogo } from "../../components/FabLogo";

type Props = unknown;

const LoginLayout: FunctionComponent<Props> = (props) => {
  const { children } = props;

  return (
    <div
      className={
        "min-h-screen flex flex-col items-center justify-center bg-indigo-50"
      }>
      <div
        className={
          "max-w-md w-full bg-white rounded-md border px-8 py-8 shadow-lg"
        }>
        <FabLogo styles={"mx-auto h-24 w-auto"} />

        {children}
      </div>

      <div className={"max-w-md w-full"}>
        <div className={"flex items-center justify-evenly text-sm mt-4"}>
          <a
            target={"_blank"}
            href={"/privacy-policy.pdf"}
            className={"font-medium text-indigo-600 hover:text-indigo-500"}
            rel={"noreferrer"}>
            Privacy
          </a>
          <a
            target={"_blank"}
            href={"/terms.pdf"}
            className={"font-medium text-indigo-600 hover:text-indigo-500"}
            rel={"noreferrer"}>
            Terms
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
