import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import { capitalCase } from "change-case";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { FormLabel } from "../../../components/forms/FormLabel";
import { SharedStyles } from "../../../themes/FabTheme";
import { prettyRawError } from "../../../utils/ErrorParseDisplay";
import {
  buildingsSelector,
  resetGetAllBuildingsStatus,
} from "../../Buildings/BuildingsListingSlice";
import {
  approvePendingResident,
  denyPendingResident,
  resetApprovePendingStatus,
  resetDenyPendingStatus,
} from "./PendingResidentsListingSlice";
import { getAccountSetupStepsInfo } from "../../SetupAccount/SetupAccountSlice";

type Props = {
  basePath: string;
  existingDto?: ManagersClient.ManagerGetPendingResidentDto;
};

const schemaNestedProfile =
  Joi.object<ManagersClient.ManagerGetPendingResidentDto>({
    firstName: SchemaValidation.FieldRequiredString({ label: "First name" }),
    lastName: SchemaValidation.FieldRequiredString({ label: "Last name" }),
    phoneNumber: SchemaValidation.FieldRequiredString({
      label: "Phone number",
    }),
    unitName: SchemaValidation.FieldRequiredString({
      label: "Unit name",
      min: 1,
      max: 10,
    }),
    role: SchemaValidation.FieldRequiredString({
      label: "Role",
    }),
    createdAt: SchemaValidation.FieldOptionalString({
      label: "Created at",
      max: 100,
    }),
  });

const schema = Joi.object<ManagersClient.ManagerPutPendingResidentApproveDto>({
  buildingId: SchemaValidation.FieldOptionalString({
    label: "Property name",
  }),
  profile: schemaNestedProfile,
});

export const PendingResidentComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath, existingDto } = props;

  // forms
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    trigger,
  } = useForm<ManagersClient.ManagerPutPendingResidentApproveDto>({
    defaultValues: { profile: existingDto },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  // rtk
  const dispatch = useAppDispatch();
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  const buildings = buildingsSelector.selectAll(store.getState());
  const {
    approvePendingStatus,
    approvePendingError,
    denyPendingStatus,
    denyPendingError,
  } = useAppSelector(
    (state) => state.residentDirectory.pendingResidentsListing,
  );
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(
    "ResidentComposer | ResidentComposer - errors",
    prettyRawError(errors),
  );

  // effects
  React.useEffect(() => {
    return () => {
      dispatch(resetApprovePendingStatus());
      dispatch(resetDenyPendingStatus());
      dispatch(resetGetAllBuildingsStatus());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (existingDto) {
      trigger();
    }
  }, [trigger, existingDto]);

  React.useEffect(() => {
    if (
      approvePendingStatus === "APPROVE_PENDING_LOADING" ||
      denyPendingStatus === "DENY_PENDING_LOADING"
    ) {
      setIsLoading(true);
      return;
    }

    if (
      approvePendingStatus === "APPROVE_PENDING_SUCCESS" ||
      denyPendingStatus === "DENY_PENDING_SUCCESS"
    ) {
      setIsLoading(false);
      history.push(basePath);
      if (history.location.pathname.includes("/setup-account")) {
        dispatch(getAccountSetupStepsInfo());
      }
      return;
    }

    if (
      approvePendingStatus === "APPROVE_PENDING_ERROR" ||
      denyPendingStatus === "DENY_PENDING_ERROR"
    ) {
      setIsLoading(false);
      if (history.location.pathname.includes("/setup-account")) {
        dispatch(getAccountSetupStepsInfo());
      }
    }
  }, [basePath, history, approvePendingStatus, denyPendingStatus]);

  async function onApprove(
    data: ManagersClient.ManagerPutPendingResidentApproveDto,
  ) {
    const dto = data;
    console.log(
      "ResidentComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty) {
      dispatch(
        approvePendingResident({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          pendingResidentId: dto.profile.id,
          managerPutPendingResidentApproveDto: dto,
        }),
      );
    }
  }

  async function onDeny(
    data: ManagersClient.ManagerPutPendingResidentApproveDto,
  ) {
    const dto = data;
    console.log(
      "ResidentComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty && existingDto) {
      dispatch(
        denyPendingResident({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          pendingResidentId: dto.profile.id,
        }),
      );
    }
  }

  const rolesOptions = Object.entries(
    ManagersClient.ManagerGetPendingResidentDtoRoleEnum,
  ).map((kv) => {
    const [, value] = kv;
    const displayValue = capitalCase(value);
    return (
      <option value={value} key={value}>
        {displayValue}
      </option>
    );
  });

  const buildingsOptions = buildings.map((kv) => {
    const { id: key, name: value } = kv;
    const displayValue = capitalCase(value);
    return (
      <option value={key} key={key}>
        {displayValue}
      </option>
    );
  });

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4"}>
        Review Pending Resident
      </div>

      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Role"} />
            <select
              {...register("profile.role")}
              className={
                "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }>
              {rolesOptions}
            </select>
            {errors.profile?.role && (
              <div className={"text-error"}>{errors.profile?.role.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"First name"} />
            <input
              type={"text"}
              {...register("profile.firstName")}
              className={SharedStyles.inputs.input()}
            />
            {errors.profile?.firstName && (
              <div className={"text-error"}>
                {errors.profile?.firstName.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Last name"} />
            <input
              type={"text"}
              {...register("profile.lastName")}
              className={SharedStyles.inputs.input()}
            />
            {errors.profile?.lastName && (
              <div className={"text-error"}>
                {errors.profile?.lastName.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Phone number"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("profile.phoneNumber")}
                className={SharedStyles.inputs.disabled()}
                readOnly
              />
            </div>
            {errors.profile?.phoneNumber && (
              <div className={"text-error"}>
                {errors.profile?.phoneNumber.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Unit number"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("profile.unitName")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.profile?.unitName && (
              <div className={"text-error"}>
                {errors.profile?.unitName.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Parking stall number(s)"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("profile.parkingStall")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.profile?.parkingStall && (
              <div className={"text-error"}>
                {errors.profile?.parkingStall.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Storage unit number(s)"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("profile.storageUnit")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.profile?.unitName && (
              <div className={"text-error"}>
                {errors.profile?.storageUnit?.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Property name"} />
            <select
              {...register("buildingId")}
              className={
                "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }>
              {buildingsOptions}
            </select>
            {errors.buildingId && (
              <div className={"text-error"}>{errors.buildingId.message}</div>
            )}
          </div>
        </div>

        <>
          <div className={"py-3 mt-2"}>
            <div className={"space-x-4"}>
              <ButtonSimple
                text={"Approve"}
                btnType={"submit"}
                btnStyle={"primary"}
                isDisabled={!isValid}
                isSpinning={isLoading}
                onClick={handleSubmit(onApprove)}
              />
              <ButtonSimple
                text={"Deny"}
                btnType={"submit"}
                btnStyle={"primary"}
                btnIntent={"error"}
                isDisabled={!isValid}
                isSpinning={isLoading}
                onClick={handleSubmit(onDeny)}
              />
            </div>
          </div>
          {approvePendingError && <ErrorView message={approvePendingError} />}
          {denyPendingError && <ErrorView message={denyPendingError} />}
        </>
      </form>
    </div>
  );
};
