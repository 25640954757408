import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { InvitedManagerComposer } from "./InvitedManagers/InvitedManagerComposer";
import { ManagerDirectoryTabs } from "./ManagerDirectoryTabs";

type ManagerDirectoryNavNewProps = {
  basePath: string;
};

export const ManagerDirectoryNav: React.FC<
  ManagerDirectoryNavNewProps
> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const addNewManager = `${path}/invite-manager`;

  trackEvent("Manager Directory Clicked!");

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Manager Directory</div>
        </Link>
      </div>
      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Invite other council members, your property manager, or building team
        to FabricApp Manager. Please note that anyone you invite will have the
        same permissions as you do.
      </h2>

      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Invite Manager"}
          btnType={"button"}
          btnStyle={"secondary"}
          btnIntent={"action"}
          onClick={() => {
            trackEvent("Invite Manager Clicked!");
            history.push(addNewManager);
          }}
        />
      </div>

      <Switch>
        <Route path={addNewManager}>
          <InvitedManagerComposer basePath={path} />
        </Route>
        <Route path={path}>
          <div className={"grid grid-cols-12 gap-6 mt-10"}>
            <div className={"col-span-12 lg:col-span-12"}>
              <ManagerDirectoryTabs basePath={path} />
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};
