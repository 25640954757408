import React from "react";

import { ConfigurePropertySteps } from "./ConfigureProperty/ConfigurePropertySteps";
import { InviteResidentsSteps } from "./InviteResidents/InviteResidentsSteps";
import { SetupAccountStepLabel } from "./SetupAccountStepLabel";
import { TestDriveSteps } from "./TestDrive/TestDriveSteps";

export const SetupAccountSteps = (props: any) => {
  const { stepsStatus } = props;

  const steps = [
    {
      name: "Configure your property",
      complete: stepsStatus[0].complete,
      component: (
        <ConfigurePropertySteps
          configurePropertyStepsList={stepsStatus[0].steps}
        />
      ),
    },
    {
      name: "Test drive",
      complete: stepsStatus[1].complete,
      component: <TestDriveSteps testDriveStepsList={stepsStatus[1].steps} />,
    },
    {
      name: "Invite residents",
      complete: stepsStatus[2].complete,
      component: (
        <InviteResidentsSteps inviteResidentsStepsList={stepsStatus[2].steps} />
      ),
    },
  ];

  // eslint-disable-next-line no-nested-ternary
  const selectedStep = !steps[0].complete ? 0 : !steps[1].complete ? 1 : 2;

  return (
    <div className={"flex flex-col justify-start items-start ml-4"}>
      {steps.map((step: any, stepIdx: any) => (
        <SetupAccountStepLabel
          selected={stepIdx === selectedStep}
          key={step.name}
          step={step}
          stepIdx={stepIdx}
        />
      ))}
    </div>
  );
};
