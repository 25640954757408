import { dayjsUtils } from "@fabricapp-ca/fabricapp-data-models";
import { ManagerGetAnnouncementResDto } from "@fabricapp-ca/fabricapp-openapi/generated/managers-ts-fetch/models/index";
import React from "react";
import { MdAttachFile } from "react-icons/md";
import Linkify from "react-linkify";
import { upperCaseFirst } from "upper-case-first";

import { LinkSimple } from "../../components/LinkSimple";
import { getAvatarOrPhotoUrl } from "../../utils/AvatarInitials";

type SentAnnouncementCardProps = {
  basePath: string;
  sentAnnouncement: ManagerGetAnnouncementResDto;
  showLink: boolean;
};

export const SentAnnouncementCard: React.FC<SentAnnouncementCardProps> = (
  props,
) => {
  const { sentAnnouncement, basePath, showLink } = props;
  const { id, author, messagePreviewText, title, createdAt } = sentAnnouncement;

  const avatarUrl = getAvatarOrPhotoUrl({
    firstName: author.firstName,
    lastName: author.lastName,
    photoPath: author.photoPath,
  });
  return (
    <>
      <div
        className={
          "flex lg:flex-row flex-col align-center justify-between my-2"
        }>
        <div
          className={
            "flex lg:flex-row md:flex-row flex-col items-center w-full my-2 px-2"
          }>
          <img
            alt={"User Avatar"}
            className={"px-2 py-2 w-24 h-auto object-contain rounded-full"}
            src={avatarUrl}
          />
          <div className={"flex-col ml-2 space-y-2"}>
            <div className={"lg:text-left text-center text-emph-high"}>
              {upperCaseFirst(author.firstName)}
            </div>
            <div
              className={"lg:text-left text-center text-default font-medium"}>
              {title}
            </div>
          </div>
        </div>

        <div
          className={"flex flex-col justify-center items-center lg:w-1/6 my-2"}>
          <div className={"text-emph-high"}>
            {upperCaseFirst(dayjsUtils.formatDate(createdAt))}
          </div>
          {showLink && (
            <div className={"flex justify-end"}>
              <LinkSimple
                text={"View"}
                destination={`${basePath}/${id}`}
                iconPosition={"right"}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={
          "flex flex-col align-center justify-between my-2 lg:px-10 px-2"
        }>
        {showLink && (
          <div className={"lg:text-left text-center text-default px-2"}>
            <Linkify>{messagePreviewText}</Linkify>
          </div>
        )}
        {sentAnnouncement.document && (
          <div
            className={
              "flex lg:justify-start justify-center items-center space-x-1 text-emph-high"
            }>
            <MdAttachFile />
            <span className={"text-emph-high text-center"}>
              contains attachment
            </span>
          </div>
        )}
      </div>
    </>
  );
};
