import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";

export interface ProfileInfoListingSliceState {
  getProfileStatus:
    | "IDLE"
    | "PROFILE_LOADING"
    | "PROFILE_SUCCESS"
    | "PROFILE_ERROR";
  getProfileError?: string;
  profile?: ManagersClient.ManagerGetProfileResDto;
}

const initialState: ProfileInfoListingSliceState = {
  getProfileStatus: "IDLE",
};

export const ProfileInfoListingSlice = createSlice({
  name: "profileInfo",
  initialState,
  reducers: {
    resetProfileInfoStatus: (state) => {
      state.getProfileStatus = "IDLE";
      state.getProfileError = undefined;
    },
    getProfileInfoStart: (state) => {
      state.getProfileStatus = "PROFILE_LOADING";
    },
    getProfileInfoSuccess: (
      state,
      action: PayloadAction<{
        profile: ManagersClient.ManagerGetProfileResDto;
      }>,
    ) => {
      state.getProfileStatus = "PROFILE_SUCCESS";
      state.profile = action.payload.profile;
      state.getProfileError = undefined;
    },
    getProfileInfoFailure: (state, action: PayloadAction<string>) => {
      state.getProfileStatus = "PROFILE_ERROR";
      state.getProfileError = action.payload;
    },
  },
});

export const {
  resetProfileInfoStatus,
  getProfileInfoStart,
  getProfileInfoSuccess,
  getProfileInfoFailure,
} = ProfileInfoListingSlice.actions;

export const profileInfoReducer = ProfileInfoListingSlice.reducer;

export const loadProfileInfoListing = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getProfileInfoStart());
    const profile = await managersApi.getManagerProfile();
    dispatch(getProfileInfoSuccess({ profile }));
  } catch (err: any) {
    dispatch(getProfileInfoFailure(prettyRawError(err)));
  }
};

export const loadProfileInfoListingSilently =
  (): AppThunk => async (dispatch) => {
    try {
      const profile = await managersApi.getManagerProfile();
      dispatch(getProfileInfoSuccess({ profile }));
    } catch (err: any) {
      dispatch(getProfileInfoFailure(prettyRawError(err)));
    }
  };
