import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { capitalCase } from "change-case";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getAccountSetupStepsInfo } from "../SetupAccount/SetupAccountSlice";
import {
  deleteOnePropertyContact,
  postOnePropertyContact,
  resetPostOnePropertyContactsStatus,
  resetUpdateDeleteOnePropertyContactsStatus,
  updateOnePropertyContact,
} from "./propertyContactsSlice";

type Props = {
  basePath: string;
  existingDto?: ManagersClient.ManagerGetPropertyContactDto;
};

const yupSchema = yup
  .object({
    name: yup.string().required().label("Name").min(1),
    hours: yup.string().label("Hours").max(100),
    notes: yup.string().label("Notes").max(200),
    contactType: yup.string().label("Contact type"),
  })
  .shape(
    {
      email: yup.string().when("phoneNumber", {
        is: (phoneNumber: any) => !phoneNumber || phoneNumber.length === 0,
        then: yup
          .string()
          .required("At least one of email or phone number is required"),
      }),
      phoneNumber: yup.string().when("email", {
        is: (email: any) => !email || email.length === 0,
        then: yup
          .string()
          .required("At least one of email or phone number is required"),
      }),
    },
    [["email", "phoneNumber"]],
  );

export const PropertyContactComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();

  const { basePath, existingDto } = props;
  const defaultValues = existingDto || {};

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ManagersClient.ManagerPostPropertyContactDto>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(yupSchema),
  });
  const dispatch = useAppDispatch();
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  const {
    postOneStatus,
    postOneError,
    updateDeleteOneStatus,
    updateDeleteOneError,
  } = useAppSelector((state) => state.propertyContacts.propertyContactsListing);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(
    "PropertyContactComposer | PropertyContactComposer - errors",
    prettyRawError(errors),
  );

  React.useEffect(() => {
    return () => {
      dispatch(resetPostOnePropertyContactsStatus());
      dispatch(resetUpdateDeleteOnePropertyContactsStatus());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (
      postOneStatus === "POST_ONE_LOADING" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_LOADING"
    ) {
      setIsLoading(true);
      return;
    }

    if (
      postOneStatus === "POST_ONE_SUCCESS" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_SUCCESS"
    ) {
      setIsLoading(false);
      history.push(basePath);
      if (history.location.pathname.includes("/setup-account")) {
        dispatch(getAccountSetupStepsInfo());
      }
      return;
    }

    if (
      postOneStatus === "POST_ONE_ERROR" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_ERROR"
    ) {
      setIsLoading(false);
    }
  }, [basePath, history, postOneStatus, updateDeleteOneStatus]);

  async function onCreate(data: ManagersClient.ManagerPostPropertyContactDto) {
    trackEvent("Add Button Clicked!");
    const dto = {
      name: data.name,
      email: data.email || undefined,
      hours: data.hours || undefined,
      notes: data.notes || undefined,
      phoneNumber: data.phoneNumber || undefined,
      contactType: data.contactType,
    };
    console.log(
      "PropertyContactComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty) {
      dispatch(
        postOnePropertyContact({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          dto,
        }),
      );
    }
  }

  async function onUpdate(data: ManagersClient.ManagerPostPropertyContactDto) {
    const dto = {
      name: data.name,
      email: data.email || undefined,
      hours: data.hours || undefined,
      notes: data.notes || undefined,
      phoneNumber: data.phoneNumber || undefined,
      contactType: data.contactType,
    };
    console.log(
      "PropertyContactComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty && existingDto) {
      dispatch(
        updateOnePropertyContact({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          propertyContactId: existingDto.id,
          dto,
        }),
      );
    }
  }

  async function onDelete() {
    if (selectedOrg && selectedProperty && existingDto) {
      dispatch(
        deleteOnePropertyContact({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          propertyContactId: existingDto.id,
        }),
      );
    }
  }

  const options = Object.entries(
    ManagersClient.ManagerPostPropertyContactDtoContactTypeEnum,
  ).map((kv) => {
    const [key, value] = kv;
    const displayValue = capitalCase(value);
    console.log(`PropertyContactComposer | ${key} | ${value}`);
    return (
      <option value={value} key={value}>
        {displayValue}
      </option>
    );
  });
  console.log("ERROR", postOneError);

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        Add New Contact
      </div>

      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Contact type"} />
            <select
              {...register("contactType")}
              className={
                "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }>
              {options}
            </select>
            {errors.contactType && (
              <div className={"text-error"}>{errors.contactType.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Name"} htmlForId={"name"} />
            <input
              type={"text"}
              {...register("name")}
              className={SharedStyles.inputs.input()}
            />
            {errors.name && (
              <div className={"text-error"}>{errors.name.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Phone number"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("phoneNumber")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.phoneNumber && (
              <div className={"text-error"}>{errors.phoneNumber.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"email"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("email")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.email && (
              <div className={"text-error"}>{errors.email.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"hours"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("hours")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.hours && (
              <div className={"text-error"}>{errors.hours.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"notes"} />
            <div className={"mt-1"}>
              <textarea
                rows={2}
                {...register("notes")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.notes && (
              <div className={"text-error"}>{errors.notes.message}</div>
            )}
          </div>
        </div>

        {!existingDto && (
          <>
            <div className={"py-3 mt-2"}>
              <ButtonSimple
                text={"Add"}
                btnType={"submit"}
                btnStyle={"primary"}
                isDisabled={!isValid}
                isSpinning={isLoading}
                onClick={handleSubmit(onCreate)}
              />
            </div>
            {postOneError && <ErrorView message={postOneError} />}
          </>
        )}
        {existingDto && (
          <>
            <div className={"py-3 mt-2"}>
              <div className={"space-x-4"}>
                <ButtonSimple
                  text={"Update"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onUpdate)}
                />
                <ButtonSimple
                  text={"Delete"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  btnIntent={"error"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onDelete)}
                />
              </div>
            </div>
            {updateDeleteOneError && (
              <ErrorView message={updateDeleteOneError} />
            )}
          </>
        )}
      </form>
    </div>
  );
};
