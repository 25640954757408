import React from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import { SharedStyles } from "../../themes/FabTheme";
import { getCurrentTabClassName } from "../../utils/TabUtils";
import { ApprovedManagersListing } from "./ApprovedManagers/ApprovedManagersListing";
import { InvitedManagersNav } from "./InvitedManagers/InvitedManagersNav";

type ManagerDirectoryTabsProps = {
  basePath: string;
};

export const ManagerDirectoryTabs: React.FC<ManagerDirectoryTabsProps> = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToApproved = `${path}/approved`;
  const pathToInvited = `${path}/invited`;
  const pathToDefault = path;

  const getTabClassName: (myPath: string, isDefault?: boolean) => string =
    React.useCallback(
      (currentPath, isDefault = false) => {
        return getCurrentTabClassName({
          currentPath,
          pathname,
          pathToDefault,
          isDefault,
        });
      },
      [pathToDefault, pathname],
    );

  return (
    <div className={"py-4"}>
      <div className={SharedStyles.tabs.tabListClassName()}>
        <div className={"flex flex-row items-start space-x-4"}>
          <Link
            to={pathToApproved}
            className={getTabClassName(pathToApproved, true)}>
            Managers
          </Link>
          <Link to={pathToInvited} className={getTabClassName(pathToInvited)}>
            Invited
          </Link>
        </div>
      </div>

      <div className={"min-h-screen"}>
        <Switch>
          <Route path={pathToApproved} component={ApprovedManagersListing} />
          <Route path={pathToInvited} component={InvitedManagersNav} />
          <Route path={path} component={ApprovedManagersListing} />
        </Switch>
      </div>
    </div>
  );
};
