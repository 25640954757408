import React from "react";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";

type PaginationProps = {
  totalCount?: number;
  initialPage: number;
  onPageChange: (selectedPage: number) => void;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { totalCount, initialPage, onPageChange } = props;
  const [selectedPage, setSelectedPage] = React.useState(initialPage);

  React.useEffect(() => {
    onPageChange(selectedPage);
  }, [onPageChange, selectedPage]);

  React.useEffect(() => {
    setSelectedPage(initialPage);
  }, [setSelectedPage, initialPage]);

  if (!totalCount) {
    return <></>;
  }

  console.log(selectedPage, totalCount);
  const previousDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === totalCount;
  const disabledClassNames = "opacity-50 cursor-not-allowed";
  const previousArrowClassNames = previousDisabled ? disabledClassNames : "";
  const nextArrowClassNames = nextDisabled ? disabledClassNames : "";

  return (
    <div
      className={
        "flex flex-row space-x-4 justify-center items-center select-none"
      }>
      <button
        className={previousArrowClassNames}
        disabled={previousDisabled}
        onClick={() => {
          setSelectedPage((val) => val - 1);
        }}>
        <HiOutlineArrowLeft />
      </button>
      <div
        className={
          "border border-dotted border-default px-2 py-4 text-primary"
        }>
        {selectedPage} / {totalCount}
      </div>
      <button
        className={nextArrowClassNames}
        disabled={nextDisabled}
        onClick={() => {
          setSelectedPage((val) => val + 1);
        }}>
        <HiOutlineArrowRight />
      </button>
    </div>
  );
};
