import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { UnitProfileEditor } from "./UnitProfileEditor";

export const UnitProfilesNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  const pathToAdd = `${path}/:unitId`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Unit Profiles</div>
        </Link>
      </div>

      <Switch>
        <Route path={pathToAdd}>
          <UnitProfileEditor basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
