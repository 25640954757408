import { MinutesStartEnd } from "../../utils/DateTimeUtils";

type AmenityAvailableType = { id: string; name: string };

export const availableAmenityTypes: Array<AmenityAvailableType> = [
  { id: "GYM", name: "Gym" },
  { id: "POOL", name: "Pool" },
  { id: "SAUNA", name: "Sauna" },
  { id: "EVENT_ROOM", name: "Event Room" },
  { id: "OTHER", name: "Other" },
];

export enum YesNoEnum {
  Yes = "Yes",
  No = "No",
}
type AddNewAmenityDaySchedule = {
  isOpen: YesNoEnum;
  openHours: MinutesStartEnd;
  isClosedForCleaning: YesNoEnum;
  cleaningHours: MinutesStartEnd;
};

export type AddNewAmenityState = {
  step1Type: {
    selectedType?: AmenityAvailableType;
    tower: string;
  };
  step2Info: {
    name: string;
    floor: string;
    photoUrl: string;
  };
  step3Schedule: {
    monday: AddNewAmenityDaySchedule;
    tuesday: AddNewAmenityDaySchedule;
    // wednesday: AddNewAmenityDaySchedule;
    // thursday: AddNewAmenityDaySchedule;
    // friday: AddNewAmenityDaySchedule;
    // saturday: AddNewAmenityDaySchedule;
    // sunday: AddNewAmenityDaySchedule;
  };
  step4Rules: {
    maxBookings?: number;
  };
};

export const addNewAmenityState: AddNewAmenityState = {
  step1Type: {
    tower: "",
  },
  step2Info: {
    name: "Gym",
    floor: "Floor P4",
    photoUrl: "https://i.postimg.cc/HL1v3513/Adobe-Stock-308417287.jpg",
  },
  step3Schedule: {
    monday: {
      isOpen: YesNoEnum.No,
      openHours: { start: 9 * 60, end: 21 * 60 },
      isClosedForCleaning: YesNoEnum.No,
      cleaningHours: { start: 11 * 60, end: 13 * 60 },
    },
    tuesday: {
      isOpen: YesNoEnum.No,
      openHours: { start: 9 * 60, end: 21 * 60 },
      isClosedForCleaning: YesNoEnum.No,
      cleaningHours: { start: 11 * 60, end: 12 * 60 },
    },
    // wednesday: {
    //   isOpen: true,
    //   openHours: { start: 9 * 60, end: 21 * 60 },
    //   isClosedForCleaning: true,
    //   cleaningHours: { start: 11 * 60, end: 12 * 60 },
    // },
    // thursday: {
    //   isOpen: true,
    //   openHours: { start: 9 * 60, end: 21 * 60 },
    //   isClosedForCleaning: true,
    //   cleaningHours: { start: 11 * 60, end: 12 * 60 },
    // },
    // friday: {
    //   isOpen: true,
    //   openHours: { start: 9 * 60, end: 21 * 60 },
    //   isClosedForCleaning: true,
    //   cleaningHours: { start: 11 * 60, end: 12 * 60 },
    // },
    // saturday: {
    //   isOpen: true,
    //   openHours: { start: 9 * 60, end: 21 * 60 },
    //   isClosedForCleaning: true,
    //   cleaningHours: { start: 11 * 60, end: 12 * 60 },
    // },
    // sunday: {
    //   isOpen: true,
    //   openHours: { start: 9 * 60, end: 21 * 60 },
    //   isClosedForCleaning: true,
    //   cleaningHours: { start: 11 * 60, end: 12 * 60 },
    // },
  },
  step4Rules: {},
};

export type AddNewAmenityAction = {
  stepValue:
    | { step: "step1Type"; value: AddNewAmenityState["step1Type"] }
    | { step: "step2Info"; value: AddNewAmenityState["step2Info"] }
    | { step: "step3Schedule"; value: AddNewAmenityState["step3Schedule"] }
    | { step: "step4Rules"; value: AddNewAmenityState["step4Rules"] };
};

export const addAmenityReducer = (
  prevState: AddNewAmenityState,
  action: AddNewAmenityAction,
): AddNewAmenityState => {
  switch (action.stepValue.step) {
    case "step1Type":
      return {
        ...prevState,
        ...action.stepValue.value,
      };
    case "step2Info":
      return {
        ...prevState,
        ...action.stepValue.value,
      };
    case "step3Schedule":
      return {
        ...prevState,
        ...action.stepValue.value,
      };
    case "step4Rules":
      return {
        ...prevState,
        ...action.stepValue.value,
      };
    default:
      throw Error("Unknown add amenity case");
  }
};
