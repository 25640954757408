import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../../api/managersApi";
import type { RootState } from "../../../app/rootReducer";
import type { AppThunk } from "../../../app/store";
import { prettyRawError } from "../../../utils/ErrorParseDisplay";
import { MaybeT } from "../../../utils/SimpleTypes";
import { sortDescByCreatedAt } from "../../../utils/SortUtils";

export interface InvitedManagersListingSliceState {
  getAllStatus:
    | "IDLE"
    | "ALL_INVITED_LOADING"
    | "ALL_INVITED_SUCCESS"
    | "ALL_INVITED_ERROR";
  getAllError?: string;
  postInvitedStatus:
    | "IDLE"
    | "POST_INVITED_LOADING"
    | "POST_INVITED_SUCCESS"
    | "POST_INVITED_ERROR";
  postInvitedError?: string;
  deleteInvitedStatus:
    | "IDLE"
    | "DELETE_INVITED_LOADING"
    | "DELETE_INVITED_SUCCESS"
    | "DELETE_INVITED_ERROR";
  deleteInvitedError?: string;
  invitedManagersAdapter: EntityState<ManagersClient.ManagerGetInvitedManagerResDto>;
}

const invitedManagersAdapter =
  createEntityAdapter<ManagersClient.ManagerGetInvitedManagerResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: InvitedManagersListingSliceState = {
  getAllStatus: "IDLE",
  postInvitedStatus: "IDLE",
  deleteInvitedStatus: "IDLE",
  invitedManagersAdapter: invitedManagersAdapter.getInitialState(),
};

export const InvitedManagersListingSlice = createSlice({
  name: "invitedManagersListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.postInvitedError = undefined;
      state.getAllError = undefined;
    },
    getAllPendingStart: (state) => {
      state.getAllStatus = "ALL_INVITED_LOADING";
      state.getAllError = undefined;
    },
    getAllPendingSuccess: (
      state,
      action: PayloadAction<{
        invitedManagers: Array<ManagersClient.ManagerGetInvitedManagerResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_INVITED_SUCCESS";
      invitedManagersAdapter.setAll(
        state.invitedManagersAdapter,
        action.payload.invitedManagers,
      );
      state.getAllError = undefined;
    },
    getAllPendingFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_INVITED_ERROR";
      state.getAllError = action.payload;
    },

    resetPostInvitedStatus: (state) => {
      state.postInvitedStatus = "IDLE";
      state.postInvitedError = undefined;
    },
    postInvitedStart: (state) => {
      state.postInvitedStatus = "POST_INVITED_LOADING";
    },
    postInvitedSuccess: (state) => {
      state.postInvitedStatus = "POST_INVITED_SUCCESS";
      state.postInvitedError = undefined;
    },
    postInvitedFailure: (state, action: PayloadAction<string>) => {
      state.postInvitedStatus = "POST_INVITED_ERROR";
      state.postInvitedError = action.payload;
    },

    resetDeleteInvitedStatus: (state) => {
      state.deleteInvitedStatus = "IDLE";
      state.deleteInvitedError = undefined;
    },
    deleteInvitedStart: (state) => {
      state.deleteInvitedStatus = "DELETE_INVITED_LOADING";
    },
    deleteInvitedSuccess: (state) => {
      state.deleteInvitedStatus = "DELETE_INVITED_SUCCESS";
      state.deleteInvitedError = undefined;
    },
    deleteInvitedFailure: (state, action: PayloadAction<string>) => {
      state.deleteInvitedStatus = "DELETE_INVITED_ERROR";
      state.deleteInvitedError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllPendingStart,
  getAllPendingSuccess,
  getAllPendingFailure,
  resetPostInvitedStatus,
  postInvitedSuccess,
  postInvitedStart,
  postInvitedFailure,
  resetDeleteInvitedStatus,
  deleteInvitedSuccess,
  deleteInvitedStart,
  deleteInvitedFailure,
} = InvitedManagersListingSlice.actions;

export const invitedManagersListingReducer =
  InvitedManagersListingSlice.reducer;

export const loadInvitedManagersListing =
  (input: { orgId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId } = input;
    try {
      dispatch(getAllPendingStart());
      const invitedManagers = (
        await managersApi.getInvitedManagers({
          orgId,
        })
      ).results;
      dispatch(getAllPendingSuccess({ invitedManagers }));
    } catch (err: any) {
      dispatch(getAllPendingFailure(prettyRawError(err)));
    }
  };

export const postInvitedManager =
  (input: {
    orgId: string;
    dto: ManagersClient.ManagerPostInvitedManagerReqDto;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, dto } = input;
    try {
      dispatch(postInvitedStart());
      await managersApi.postInvitedManager({
        orgId,
        managerPostInvitedManagerReqDto: dto,
      });
      dispatch(postInvitedSuccess());
    } catch (err: any) {
      const error = await err.json();
      dispatch(postInvitedFailure(error.message));
    }
  };

export const deleteInvitedManager =
  (input: { orgId: string; invitedManagerId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId, invitedManagerId } = input;
    try {
      dispatch(deleteInvitedStart());
      await managersApi.deleteInvitedManager({
        orgId,
        invitedManagerId,
      });
      dispatch(deleteInvitedSuccess());
    } catch (err: any) {
      dispatch(deleteInvitedFailure(prettyRawError(err)));
    }
  };

export const invitedManagersSelector =
  invitedManagersAdapter.getSelectors<RootState>(
    (state) =>
      state.managerDirectory.invitedManagersListing.invitedManagersAdapter,
  );

export const invitedManagerCountSelector = (state: RootState): number => {
  return invitedManagersSelector.selectTotal(state);
};

export const invitedManagerIsEmptySelector = (state: RootState): boolean => {
  return invitedManagersSelector.selectTotal(state) === 0;
};

export const invitedManagerByIdSelector = (
  state: RootState,
  invitedManagerId: string,
): MaybeT<ManagersClient.ManagerGetInvitedManagerResDto> => {
  const allItems = invitedManagersSelector.selectAll(state);
  return allItems.find((x) => x.id === invitedManagerId);
};
