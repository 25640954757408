import { CheckmarkSvg } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent } from "react";

type TotalUnitsBoxProps = {
  addedUnits: number | undefined;
  totalUnits: number | undefined;
};

export const TotalUnitsBox: FunctionComponent<TotalUnitsBoxProps> = (props) => {
  const { addedUnits, totalUnits } = props;

  return (
    <div className={"w-full flex flex-col my-16 p-4 border rounded-md"}>
      <div className={"flex flex-row items-center py-4"}>
        <div className={"px-4"}>
          <CheckmarkSvg styles={"text-green-400"} />
        </div>
        <div className={"flex flex-col"}>
          <div className={"text-emph-high"}>Units Entered</div>
          <div className={"text-2xl font-medium"}>
            {addedUnits}/{totalUnits}
          </div>
        </div>
      </div>
    </div>
  );
};
