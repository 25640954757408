/* eslint-disable */
import React, { FunctionComponent, useReducer } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AddAmenityWizardDone } from "./AddAmenityWizardDone";
import { AddAmenityWizardIntro } from "./AddAmenityWizardIntro";
import { AddAmenityWizardStep2 } from "./AddAmenityWizardStep2";
import { AddAmenityWizardStep3 } from "./AddAmenityWizardStep3";
import { AddAmenityWizardStep4 } from "./AddAmenityWizardStep4";
import { addAmenityReducer, addNewAmenityState } from "./AddNewAmenityState";

type Props = {
  basePath: string;
};

export const AddAmenityWizard: FunctionComponent<Props> = (props) => {
  const { basePath } = props;
  const { path } = useRouteMatch();
  const [state, dispatch] = useReducer(addAmenityReducer, addNewAmenityState);

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <AddAmenityWizardIntro
            dispatcher={dispatch}
            defaultValue={state.step1Type}
            basePath={path}
          />
        </Route>
        <Route path={`${path}/step2`}>
          <AddAmenityWizardStep2
            dispatcher={dispatch}
            defaultValue={state.step2Info}
            basePath={path}
          />
        </Route>
        <Route path={`${path}/step3`}>
          <AddAmenityWizardStep3
            basePath={path}
          />
        </Route>
        <Route path={`${path}/step4`}>
          <AddAmenityWizardStep4
            basePath={path}
          />
        </Route>
        <Route path={`${path}/done`}>
          <AddAmenityWizardDone redirectPath={basePath}  />
        </Route>
      </Switch>
    </div>
  );
};
