import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { UnitGroupRow } from "./UnitGroupRow";
import {
  getAllUnitGroupsListing,
  resetUnitGroupsStatus,
  unitGroupsSelector,
} from "./unitGroupsSlice";

type AllUnitGroupsListingProps = {
  basePath: string;
};

export const AllUnitGroupsListing: FunctionComponent<
  AllUnitGroupsListingProps
> = (props) => {
  const { basePath } = props;
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.unitGroups.unitGroupsListing,
  );
  const unitGroups = unitGroupsSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(
        getAllUnitGroupsListing({
          propertyId: selectedProperty.id,
          orgId: selectedOrg.id,
        }),
      );
    }
    return () => {
      dispatch(resetUnitGroupsStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (!selectedOrg || !selectedProperty) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!unitGroups || unitGroups.length < 1) {
    return (
      <EmptyStateImage
        text={"No unit groups yet!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  const unitGroupRows = unitGroups.map((item) => {
    return <UnitGroupRow basePath={basePath} key={item.id} dto={item} />;
  });

  return (
    <div className={"flex flex-col"}>
      <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
        <div
          className={
            "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          }>
          <div
            className={
              "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            }>
            <table className={"min-w-full divide-y divide-gray-200"}>
              <thead className={"bg-gray-50"}>
                <tr>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Name
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Created At
                  </th>
                  <th scope={"col"} className={"relative px-6 py-3"}>
                    <span className={"sr-only"}>View</span>
                  </th>
                </tr>
              </thead>
              <tbody className={"bg-white divide-y divide-gray-200"}>
                {unitGroupRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
