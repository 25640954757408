import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { DocumentGroupsSideNav } from "./DocumentGroupsSideNav";
import { loadDocumentsListing } from "./DocumentsListingSlice";
import { DocumentViewOrCreate } from "./DocumentViewOrCreate";

export const DocumentGroups: React.FC<unknown> = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { status, error, allDocuments } = useAppSelector(
    (state) => state.documents.documentsListing,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    console.log("inside document groups");
    dispatch(loadDocumentsListing());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, path, status]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  return (
    <div className={"flex flex-row"}>
      <DocumentGroupsSideNav url={url} documents={allDocuments} />
      <div className={"flex flex-col w-full px-6"}>
        <Switch>
          <Route path={`${path}/new`}>
            <DocumentViewOrCreate defaultTitle={""} />
          </Route>
          <Route path={`${path}/other/:documentId`}>
            <DocumentViewOrCreate defaultTitle={"New document name"} />
          </Route>
          <Route path={`${path}`}>
            <EmptyStateImage
              text={"Please select a document"}
              imageType={
                ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureDocuments
              }
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
