import { setUserId } from "@amplitude/analytics-browser";
import { Middleware } from "@reduxjs/toolkit";

import { LocalStorageAPI } from "../api/localStorageAPI";
import { getLsFbAuthTokenSignOut, signedInWithFirebase } from "../AppSlice";
import {
  CurrentUser,
  CurrentUserInfo,
  fbAuth,
} from "../configs/FirebaseConfig";
import {
  signOutSuccess,
  verifyEmailSuccess,
} from "../features/Auth/FirebaseSlice";
import { restoreSelectionContextFromLS } from "../features/SelectionContext/SelectionContextSlice";

const transformCurrentUserInfo = (user: CurrentUser): CurrentUserInfo => {
  if (!user) {
    return undefined;
  }
  const allUserInfos = user.providerData.flatMap((userInfo) =>
    userInfo ? [userInfo] : [],
  );

  if (allUserInfos.length < 1) {
    return undefined;
  }
  const firstUserInfo = allUserInfos[0];

  // This is required to prevent the non-serializable values
  // https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions
  return {
    photoURL: firstUserInfo.photoURL,
    phoneNumber: firstUserInfo.phoneNumber,
    email: firstUserInfo.email,
    displayName: firstUserInfo.displayName,
    uid: firstUserInfo.uid,
    providerId: firstUserInfo.providerId,
  };
};

const ONE_DAY_MILLISECONDS = 1 * 24 * 3600 * 1000;
const SEVEN_DAYS_MILLISECONDS = ONE_DAY_MILLISECONDS * 7;

export function createFirebaseAuthEnhancer() {
  const getFirebaseMiddleware: Middleware = (store) => {
    const onTokenChange = async (user: CurrentUser | null) => {
      // console.log("getFirebaseMiddleware.onTokenChange", user);

      const userInfo = user ? transformCurrentUserInfo(user) : ({} as any);
      const fbUserToken = await user?.getIdToken();

      const lastSignedIn = await LocalStorageAPI.getKv("lastSignedIn");
      if (
        lastSignedIn != null &&
        new Date().getTime() - Number(lastSignedIn) > SEVEN_DAYS_MILLISECONDS
      ) {
        await LocalStorageAPI.clearStorage();
        await fbAuth.signOut();
        store.dispatch(getLsFbAuthTokenSignOut());
        store.dispatch(signOutSuccess());
        return;
      }

      // note: we want to prefer fb user token if it is available
      const userToken = fbUserToken;
      if (userToken) {
        // console.log("sign-in with success", {
        //   userInfo,
        //   userToken,
        // });
        await LocalStorageAPI.setJson("currentUserInfo", userInfo);
        await LocalStorageAPI.setKv(
          "lastSignedIn",
          new Date().getTime().toString(),
        );
        store.dispatch(verifyEmailSuccess({ userInfo }));
        store.dispatch(restoreSelectionContextFromLS() as any);
        store.dispatch(signedInWithFirebase());
        setUserId(user?.uid);
      }
    };
    fbAuth.onIdTokenChanged(onTokenChange);

    return (next) => (action) => {
      // console.log("inside getFirebaseMiddleware");
      next(action);
    };
  };

  return getFirebaseMiddleware;
}
