import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { trackEvent } from "../../utils/Amplitude";
import { SentAnnouncementsNav } from "./SentAnnouncementsNav";

type Props = {
  basePath: string;
};

export const AnnouncementsTabs: React.FC<Props> = (props) => {
  const history = useHistory();
  const { basePath } = props;

  return (
    <div className={"py-4"}>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Create Announcement"}
          btnType={"button"}
          btnStyle={"secondary"}
          btnIntent={"action"}
          onClick={() => {
            trackEvent("Create Announcements Clicked!");
            history.push(`${basePath}/create`);
          }}
        />
      </div>

      <Tabs
        className={"flex flex-col items-start min-h-screen"}
        disabledTabClassName={"opacity-50 cursor-not-allowed"}
        selectedTabClassName={
          "text-primary border-b-2 border-primary text-center"
        }
        selectedTabPanelClassName={"w-11/12 py-4 mx-4"}>
        <TabList
          className={
            "flex flex-row border-b-2 border-border-low w-full text-emph-high"
          }>
          <Tab className={"w-50 px-4 py-4 text-center cursor-pointer"}>
            Sent
          </Tab>
        </TabList>
        <TabPanel>
          <SentAnnouncementsNav />
        </TabPanel>
      </Tabs>
    </div>
  );
};
