import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { SentRemindersListing } from "./SentRemindersListing";
import { SentReminderViewer } from "./SentReminderViewer";

export const SentRemindersView: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full px-6"}>
        <Switch>
          <Route path={`${path}/:reminderId`}>
            <SentReminderViewer basePath={path} />
          </Route>
          <Route path={`${path}`}>
            <SentRemindersListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
