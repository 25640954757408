import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../../utils/Amplitude";
import { InvitedManagerEditor } from "./InvitedManagerEditor";
import { InvitedManagersListing } from "./InvitedManagersListing";

export const InvitedManagersNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();
  trackEvent("Invited Tab Clicked!");
  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full px-6"}>
        <Switch>
          <Route path={`${path}/:invitedManagerId`}>
            <InvitedManagerEditor basePath={path} />
          </Route>
          <Route path={`${path}`}>
            <InvitedManagersListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
