import objectInspect from "object-inspect";

export const DEFAULT_ERROR_STRING =
  "Oops something went wrong. Sorry for the inconvenience. The FabricApp team has been notified about this problem.";

type Prop = string | number | symbol;

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends Prop>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(prop);
}

const STATUS_CODE_EXPECTATION_FAILED = 417;

export function parseErrorForDisplay(error: unknown): string {
  if (error instanceof Response) {
    if (error.status === STATUS_CODE_EXPECTATION_FAILED) {
      return `${error.status} - ${error.statusText}`;
    }

    return `${error.status} - ${error.statusText}. ${DEFAULT_ERROR_STRING}`;
  }

  if (
    typeof error === "object" &&
    error !== null &&
    hasOwnProperty(error, "error") &&
    typeof error.error === "string"
  ) {
    return error.error;
  }

  return DEFAULT_ERROR_STRING;
}

export function prettyRawError(error: unknown): string {
  return objectInspect({ error }, { indent: "\t", depth: 3 });
}
