import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { sortDescByCreatedAt } from "../../utils/SortUtils";

export interface UnitGroupsListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  postOneStatus:
    | "IDLE"
    | "POST_ONE_LOADING"
    | "POST_ONE_SUCCESS"
    | "POST_ONE_ERROR";
  postOneError?: string;
  updateDeleteOneStatus:
    | "IDLE"
    | "UPDATE_DELETE_ONE_LOADING"
    | "UPDATE_DELETE_ONE_SUCCESS"
    | "UPDATE_DELETE_ONE_ERROR";
  updateDeleteOneError?: string;
  unitGroupsAdapter: EntityState<ManagersClient.ManagerUnitGroupResDto>;
}

const unitGroupsAdapter =
  createEntityAdapter<ManagersClient.ManagerUnitGroupResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: UnitGroupsListingSliceState = {
  getAllStatus: "IDLE",
  postOneStatus: "IDLE",
  updateDeleteOneStatus: "IDLE",
  unitGroupsAdapter: unitGroupsAdapter.getInitialState(),
};

export const UnitGroupsListingSlice = createSlice({
  name: "unitGroupsListing",
  initialState,
  reducers: {
    resetUnitGroupsStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    getUnitGroupsStart: (state) => {
      state.getAllStatus = "GET_ALL_LOADING";
    },
    getUnitGroupsSuccess: (
      state,
      action: PayloadAction<{
        unitGroups: Array<ManagersClient.ManagerUnitGroupResDto>;
      }>,
    ) => {
      state.getAllStatus = "GET_ALL_SUCCESS";
      unitGroupsAdapter.setAll(
        state.unitGroupsAdapter,
        action.payload.unitGroups,
      );
      state.getAllError = undefined;
    },
    getUnitGroupsFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "GET_ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetPostOneUnitGroupStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOneUnitGroupStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOneUnitGroupStart: (state) => {
      state.postOneStatus = "POST_ONE_LOADING";
    },
    postOneUnitGroupSuccess: (state) => {
      state.postOneStatus = "POST_ONE_SUCCESS";
      state.postOneError = undefined;
    },
    postOneUnitGroupFailure: (state, action: PayloadAction<string>) => {
      state.postOneStatus = "POST_ONE_ERROR";
      state.postOneError = action.payload;
    },

    resetUpdateDeleteOneUnitGroupStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneUnitGroupStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneUnitGroupStart: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_LOADING";
    },
    updateDeleteOneUnitGroupSuccess: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_SUCCESS";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneUnitGroupFailure: (state, action: PayloadAction<string>) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_ERROR";
      state.updateDeleteOneError = action.payload;
    },
  },
});

export const {
  resetUnitGroupsStatus,
  getUnitGroupsStart,
  getUnitGroupsSuccess,
  getUnitGroupsFailure,
  resetPostOneUnitGroupStatus,
  postOneUnitGroupStatus,
  postOneUnitGroupSuccess,
  postOneUnitGroupStart,
  postOneUnitGroupFailure,
  resetUpdateDeleteOneUnitGroupStatus,
  updateDeleteOneUnitGroupSuccess,
  updateDeleteOneUnitGroupStart,
  updateDeleteOneUnitGroupFailure,
} = UnitGroupsListingSlice.actions;

export const unitGroupsListingReducer = UnitGroupsListingSlice.reducer;

export const getAllUnitGroupsListing =
  (input: { orgId: string; propertyId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId } = input;
    try {
      dispatch(getUnitGroupsStart());
      const unitGroups = (
        await managersApi.getUnitGroupsForProperty({
          orgId,
          propertyId,
        })
      ).results;
      dispatch(
        getUnitGroupsSuccess({
          unitGroups,
        }),
      );
    } catch (err: any) {
      dispatch(getUnitGroupsFailure(prettyRawError(err)));
    }
  };

export const postOneUnitGroup =
  (input: {
    orgId: string;
    propertyId: string;
    dto: ManagersClient.ManagerCreateOrUpdateUnitGroupReqDto;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, dto } = input;
    try {
      dispatch(postOneUnitGroupStart());
      await managersApi.createUnitGroupForProperty({
        orgId,
        propertyId,
        managerCreateOrUpdateUnitGroupReqDto: dto,
      });
      dispatch(postOneUnitGroupSuccess());
    } catch (err: any) {
      const error = await err.json();
      dispatch(postOneUnitGroupFailure(prettyRawError(error.message)));
    }
  };

export const deleteOneUnitGroup =
  (input: {
    orgId: string;
    propertyId: string;
    unitGroupId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, unitGroupId } = input;
    try {
      dispatch(updateDeleteOneUnitGroupStart());
      await managersApi.deleteUnitGroupById({ orgId, propertyId, unitGroupId });
      dispatch(updateDeleteOneUnitGroupSuccess());
    } catch (err: any) {
      dispatch(updateDeleteOneUnitGroupFailure(prettyRawError(err)));
    }
  };

export const unitGroupsSelector = unitGroupsAdapter.getSelectors<RootState>(
  (state) => state.unitGroups.unitGroupsListing.unitGroupsAdapter,
);

export const unitGroupCountSelector = (state: RootState): number => {
  return unitGroupsSelector.selectTotal(state);
};

export const unitGroupIsEmptySelector = (state: RootState): boolean => {
  return unitGroupsSelector.selectTotal(state) === 0;
};

export const unitGroupByIdSelector = (
  state: RootState,
  unitGroupId: string,
): ManagersClient.ManagerUnitGroupResDto | undefined => {
  const allItems = unitGroupsSelector.selectAll(state);
  return allItems.find((x) => x.id === unitGroupId);
};
