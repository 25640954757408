import { ManagerGetBuildingStatsDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { capitalCase } from "change-case";
import React, { FunctionComponent, useEffect } from "react";
import { BsBuilding } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { MdAddToQueue } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { StatsCardProps } from "../../components/stats/StatsCard";
import { StatsRow } from "../../components/stats/StatsRow";
import { getSelectionIds } from "../../utils/Helpers";
import { PendingResidentsListing } from "../ResidentDirectory/PendingResidents/PendingResidentsListing";
import { LaunchApp } from "../SetupAccount/LaunchApp";
import {getAllBuildingsListing} from "../Buildings/BuildingsListingSlice";

const initStats: Array<StatsCardProps> = [
  {
    label: "Total # residents signed up",
    value: "loading",
    iconType: FiUsers,
  },
  {
    label: "Suites Signed-up/Total",
    value: "loading",
    iconType: BsBuilding,
  },
  {
    label: "Pending residents",
    value: "loading",
    iconType: MdAddToQueue,
  },
];
type Props = unknown;
const DashboardLanding: FunctionComponent<Props> = () => {
  const { profile } = useAppSelector((state) => state.profileInfo);

  const [stats, setStats] = React.useState(initStats);
  const { orgId, propertyId, buildingId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllBuildingsListing({orgId, propertyId}));
  }, [orgId, propertyId]);

  useEffect(() => {
    if (buildingId != null) {
      managersApi
        .getBuildingStats({
          orgId,
          propertyId,
          buildingId,
        })
        .then((data: ManagerGetBuildingStatsDto) => {
          setStats([
            {
              label: "Total # residents signed up",
              value: `${data.residentsSignedUp}`,
              iconType: FiUsers,
            },
            {
              label: "Suites Signed-up/Total",
              value: `${data.unitsSignedUp}/${data.numOfUnits}`,
              iconType: BsBuilding,
            },
            {
              label: "Pending residents",
              value: `${data.pendingResidents}`,
              iconType: MdAddToQueue,
            },
          ]);
        });
    }
  }, [buildingId, orgId, propertyId]);

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"text-3xl font-medium text-heading pb-2 mb-2"}>
        Hi {capitalCase(profile?.firstName ?? "")} 👋, {"Let's"} get started
        with FabricApp!
      </div>

      <LaunchApp />

      {/* info cards */}
      <StatsRow stats={stats} />

      {/* pending and activity */}
      <div className={"grid grid-cols-12 gap-6 mt-10"}>
        <div className={"border shadow-sm col-span-12 lg:col-span-12"}>
          <div
            className={
              "flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 bg-gray-100"
            }>
            <h2 className={"text-default mr-auto"}>Pending Approval</h2>
            <Link
              to={"/dashboard/resident-directory"}
              className={"text-primary underline"}>
              View All
            </Link>
          </div>
          <PendingResidentsListing
            basePath={"/dashboard/resident-directory/pending"}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardLanding;
