import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { EmptyStateImage } from "../../DefaultImages/EmptyStateImage";
import { InvitedManagerRow } from "./InvitedManagerRow";
import { invitedManagersSelector, loadInvitedManagersListing } from "./InvitedManagersListingSlice";

type InvitedManagersProps = {
  basePath: string;
};

export const InvitedManagersListing: React.FC<InvitedManagersProps> = (
  props,
) => {
  const { basePath } = props;
  const { selectedOrg } = useAppSelector((state) => state.selectionContext);

  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.managerDirectory.invitedManagersListing,
  );
  const invitedManagers = invitedManagersSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (selectedOrg) {
      dispatch(
        loadInvitedManagersListing({
          orgId: selectedOrg.id,
        }),
      );
    }
  }, [dispatch, selectedOrg]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "ALL_INVITED_LOADING":
        setIsLoading(true);
        break;
      case "ALL_INVITED_SUCCESS":
        setIsLoading(false);
        break;
      case "ALL_INVITED_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (!selectedOrg) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!invitedManagers || invitedManagers.length < 1) {
    return (
      <EmptyStateImage
        text={"No invited managers yet!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  const invitedManagerRows = invitedManagers.map((item) => {
    return <InvitedManagerRow basePath={basePath} key={item.id} dto={item} />;
  });

  return (
    <div className={"flex flex-col"}>
      <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
        <div
          className={
            "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          }>
          <div
            className={
              "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            }>
            <table className={"min-w-full divide-y divide-gray-200"}>
              <thead className={"bg-gray-50"}>
                <tr>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Name
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Invited at
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Title
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Email
                  </th>
                  <th scope={"col"} className={"relative px-6 py-3"}>
                    <span className={"sr-only"}>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className={"bg-white divide-y divide-gray-200"}>
                {invitedManagerRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
