import React from "react";

import { FabTheme } from "../../themes/FabTheme";

export type LabelPillProps = {
  label: string;
};

export const LabelPill: React.FC<LabelPillProps> = (props) => {
  const { label } = props;

  return (
    <div
      style={{ backgroundColor: FabTheme.colors.primaryLight }}
      className={
        "text-xs text-center px-2 py-2 max-w-sm mx-1 h-auto rounded-md"
      }>
      {label}
    </div>
  );
};
