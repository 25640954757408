import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { SentReminderCard } from "./SentReminderCard";
import { loadSentRemindersListing } from "./SentRemindersListingSlice";

type SentRemindersListingProps = {
  basePath: string;
};

export const SentRemindersListing: React.FC<SentRemindersListingProps> = (
  props,
) => {
  const { basePath } = props;
  const history = useHistory();
  const { status, error, sentReminders } = useAppSelector(
    (state) => state.reminders.sentReminders,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    dispatch(loadSentRemindersListing());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, status]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  if (!sentReminders || sentReminders.length < 1) {
    return (
      <EmptyStateImage
        text={"Compose your first reminder now!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureReminders
        }
      />
    );
  }

  const sentReminderCards = sentReminders.map((item) => {
    return (
      <div
        key={item.id}
        className={"border rounded-md shadow-sm flex flex-col my-2"}>
        <SentReminderCard basePath={basePath} sentReminder={item} showLink />
      </div>
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      {sentReminderCards}
    </div>
  );
};
