import { ButtonSimple, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";

import { useAppSelector } from "../../../app/hooks";

type ResidentDeleteModalProps = {
  showModal: boolean;
  residentId: string;
  setShowModal: (a: boolean) => void;
  deleteFn: () => void;
};
export function ResidentDeleteModal(props: ResidentDeleteModalProps) {
  const { showModal, setShowModal, deleteFn, residentId } = props;

  const { deletingResident } = useAppSelector(
    (state) => state.residentDirectory.directoryListing,
  );

  return (
    <>
      {showModal ? (
        <>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            }>
            <div className={"relative w-14 mx-auto max-w-3xl w-2/3"}>
              {deletingResident === residentId ? (
                <LoadingView />
              ) : (
                <div
                  className={
                    "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10"
                  }>
                  {/*header*/}

                  <div
                    className={
                      "mx-auto flex h-16 w-16 rounded-full bg-red-100"
                    }>
                    <svg
                      xmlns={"http://www.w3.org/2000/svg"}
                      fill={"none"}
                      viewBox={"0 0 24 24"}
                      strokeWidth={"1.5"}
                      stroke={"currentColor"}
                      className={"text-red-600"}>
                      <path
                        strokeLinecap={"round"}
                        strokeLinejoin={"round"}
                        d={
                          "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        }
                      />
                    </svg>
                  </div>
                  <div
                    className={
                      "flex items-center text-center justify-center p-5 rounded-t"
                    }>
                    <h3 className={"text-3xl font-semibold w-full"}>
                      Are you sure you want to remove this resident?
                    </h3>
                  </div>
                  {/*body*/}
                  <div className={"mb-5"}>
                    <span
                      className={
                        "text-emph-high text-center mb-1 text-default block"
                      }>
                      This action cannot be undone, and the resident will lose
                      access to their FabricApp Resident App immediately.
                    </span>
                  </div>

                  {/*footer*/}
                  <div
                    className={
                      "flex flex-col lg:flex-row md:flex-row items-center justify-center rounded-b sm:space-x-4"
                    }>
                    <ButtonSimple
                      text={"I’m sure!"}
                      btnType={"submit"}
                      btnStyle={"primary"}
                      btnIntent={"error"}
                      onClick={deleteFn}
                    />
                    <ButtonSimple
                      text={"Cancel"}
                      btnType={"button"}
                      classes={"my-5"}
                      btnStyle={"secondary"}
                      onClick={() => setShowModal(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={"opacity-25 fixed inset-0 z-40 bg-black"} />
        </>
      ) : null}
    </>
  );
}
