import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { sortDescByCreatedAt } from "../../utils/SortUtils";

export interface PropertyContactsListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  postOneStatus:
    | "IDLE"
    | "POST_ONE_LOADING"
    | "POST_ONE_SUCCESS"
    | "POST_ONE_ERROR";
  postOneError?: string;
  updateDeleteOneStatus:
    | "IDLE"
    | "UPDATE_DELETE_ONE_LOADING"
    | "UPDATE_DELETE_ONE_SUCCESS"
    | "UPDATE_DELETE_ONE_ERROR";
  updateDeleteOneError?: string;
  propertyContactsAdapter: EntityState<ManagersClient.ManagerGetPropertyContactDto>;
}

const propertyContactsAdapter =
  createEntityAdapter<ManagersClient.ManagerGetPropertyContactDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: PropertyContactsListingSliceState = {
  getAllStatus: "IDLE",
  postOneStatus: "IDLE",
  updateDeleteOneStatus: "IDLE",
  propertyContactsAdapter: propertyContactsAdapter.getInitialState(),
};

export const PropertyContactsListingSlice = createSlice({
  name: "propertyContactsListing",
  initialState,
  reducers: {
    resetAllPropertyContactsStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    getAllPropertyContactsStart: (state) => {
      state.getAllStatus = "GET_ALL_LOADING";
    },
    getAllPropertyContactsSuccess: (
      state,
      action: PayloadAction<{
        propertyContacts: Array<ManagersClient.ManagerGetPropertyContactDto>;
      }>,
    ) => {
      state.getAllStatus = "GET_ALL_SUCCESS";
      propertyContactsAdapter.setAll(
        state.propertyContactsAdapter,
        action.payload.propertyContacts,
      );
      state.getAllError = undefined;
    },
    getAllPropertyContactsFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "GET_ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetPostOnePropertyContactsStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOnePropertyContactStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOnePropertyContactStart: (state) => {
      state.postOneStatus = "POST_ONE_LOADING";
    },
    postOnePropertyContactSuccess: (state) => {
      state.postOneStatus = "POST_ONE_SUCCESS";
      state.postOneError = undefined;
    },
    postOnePropertyContactFailure: (state, action: PayloadAction<string>) => {
      state.postOneStatus = "POST_ONE_ERROR";
      state.postOneError = action.payload;
    },

    resetUpdateDeleteOnePropertyContactsStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOnePropertyContactStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOnePropertyContactStart: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_LOADING";
    },
    updateDeleteOnePropertyContactSuccess: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_SUCCESS";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOnePropertyContactFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_ERROR";
      state.updateDeleteOneError = action.payload;
    },
  },
});

export const {
  resetAllPropertyContactsStatus,
  getAllPropertyContactsStart,
  getAllPropertyContactsSuccess,
  getAllPropertyContactsFailure,
  resetPostOnePropertyContactsStatus,
  postOnePropertyContactStatus,
  postOnePropertyContactSuccess,
  postOnePropertyContactStart,
  postOnePropertyContactFailure,
  resetUpdateDeleteOnePropertyContactsStatus,
  updateDeleteOnePropertyContactSuccess,
  updateDeleteOnePropertyContactStart,
  updateDeleteOnePropertyContactFailure,
} = PropertyContactsListingSlice.actions;

export const propertyContactsListingReducer =
  PropertyContactsListingSlice.reducer;

export const getAllPropertyContactsListing =
  (input: { orgId: string; propertyId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId } = input;
    try {
      dispatch(getAllPropertyContactsStart());
      const propertyContacts = (
        await managersApi.getPropertyContacts({
          orgId,
          propertyId,
        })
      ).results;
      dispatch(
        getAllPropertyContactsSuccess({
          propertyContacts,
        }),
      );
    } catch (err: any) {
      dispatch(getAllPropertyContactsFailure(prettyRawError(err)));
    }
  };

export const postOnePropertyContact =
  (input: {
    orgId: string;
    propertyId: string;
    dto: ManagersClient.ManagerPostPropertyContactDto;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, dto } = input;
    try {
      dispatch(postOnePropertyContactStart());
      await managersApi.postPropertyContact({
        orgId,
        propertyId,
        managerPostPropertyContactDto: dto,
      });
      dispatch(postOnePropertyContactSuccess());
    } catch (err: any) {
      const error = await err.json();
      dispatch(postOnePropertyContactFailure(prettyRawError(error.message)));
    }
  };

export const updateOnePropertyContact =
  (input: {
    orgId: string;
    propertyId: string;
    propertyContactId: string;
    dto: ManagersClient.ManagerPostPropertyContactDto;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, propertyContactId, dto } = input;
    try {
      dispatch(updateDeleteOnePropertyContactStart());
      await managersApi.putPropertyContact({
        orgId,
        propertyId,
        propertyContactId,
        managerPostPropertyContactDto: dto,
      });
      dispatch(updateDeleteOnePropertyContactSuccess());
    } catch (err: any) {
      dispatch(updateDeleteOnePropertyContactFailure(prettyRawError(err)));
    }
  };

export const deleteOnePropertyContact =
  (input: {
    orgId: string;
    propertyId: string;
    propertyContactId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, propertyContactId } = input;
    try {
      dispatch(updateDeleteOnePropertyContactStart());
      await managersApi.deletePropertyContact({
        orgId,
        propertyId,
        propertyContactId,
      });
      dispatch(updateDeleteOnePropertyContactSuccess());
    } catch (err: any) {
      dispatch(updateDeleteOnePropertyContactFailure(prettyRawError(err)));
    }
  };

export const propertyContactsSelector =
  propertyContactsAdapter.getSelectors<RootState>(
    (state) =>
      state.propertyContacts.propertyContactsListing.propertyContactsAdapter,
  );

export const propertyContactCountSelector = (state: RootState): number => {
  return propertyContactsSelector.selectTotal(state);
};

export const propertyContactIsEmptySelector = (state: RootState): boolean => {
  return propertyContactsSelector.selectTotal(state) === 0;
};

export const propertyContactByIdSelector = (
  state: RootState,
  propertyContactId: string,
): ManagersClient.ManagerGetPropertyContactDto | undefined => {
  const allItems = propertyContactsSelector.selectAll(state);
  return allItems.find((x) => x.id === propertyContactId);
};
