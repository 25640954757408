import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { HiOutlineCloudUpload } from "react-icons/hi";
import { upperCaseFirst } from "upper-case-first";

import { managersApi } from "../../api/managersApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { getSelectionIds } from "../../utils/Helpers";
import { getAccountSetupStepsInfo } from "../SetupAccount/SetupAccountSlice";
import { loadDocumentsListing } from "./DocumentsListingSlice";
import {useHistory} from "react-router-dom";
import {trackEvent} from "../../utils/Amplitude";

type DocumentCreateViewProps = {
  defaultTitle: string;
};

type DocumentUploadForm = {
  title: string;
  document: FileList;
};

export const DocumentCreateView: React.FC<DocumentCreateViewProps> = (
  props,
) => {
  const { defaultTitle } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<DocumentUploadForm>({
    mode: "onChange",
  });
  const [selectedFileName, setSelectedFileName] = React.useState("Select File");
  const [isLoading, setIsLoading] = React.useState(false);
  const watchDocument: FileList = watch("document");
  const { orgId, propertyId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  React.useEffect(() => {
    console.log("DocumentGroups | useEffect.watchDocument", { watchDocument });
    if (watchDocument && watchDocument.length > 0) {
      setSelectedFileName(watchDocument[0].name);
    }
  }, [watchDocument]);

  React.useEffect(() => {
    setValue("title", defaultTitle, {
      shouldValidate: true,
    });
  }, [defaultTitle, setValue]);

  async function onSubmit(data: DocumentUploadForm) {
    console.log("DocumentGroups | handleSubmit", { data });
    setIsLoading(true);

    try {
      const maybeFiles = data.document;
      if (maybeFiles.length > 0) {
        // todo: restrict file size
        console.log("DocumentGroups | onSubmit | uploading");
        const fileToUpload = maybeFiles[0];

        const uploadedDoc = await managersApi.uploadDocument({
          orgId,
          propertyId,
          title: data.title,
          type: ManagersClient.UploadDocumentTypeEnum.Other,
          documents: [fileToUpload],
        });
        console.log("DocumentGroups | onSubmit ", uploadedDoc);

        if (history.location.pathname.includes("/setup-account")) {
          dispatch(getAccountSetupStepsInfo());
        }
        dispatch(loadDocumentsListing());
      }
    } finally {
      setIsLoading(false);
    }
  }

  const documentTitle = "Document Title";

  console.log("errors document", errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={"mb-2"}>
        <FormLabel htmlForId={"title"} text={documentTitle} />
        <input
          type={"text"}
          {...register("title", { required: true, minLength: 5 })}
          className={
            "w-full px-4 py-3 rounded-lg focus:outline-none focus:shadow-outline text-gray-600 font-medium"
          }
        />
      </div>
      {errors.title && (
        <div className={"text-error mb-2"}>
          Please enter a title of more than 5 characters.
        </div>
      )}

      <div
        className={
          "flex w-full items-center justify-center bg-grey-lighter mb-5"
        }
        onClick={() => {
          trackEvent("Select File Button Clicked!");
        }}>
        <label
          className={
            "w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-primary hover:text-white"
          }>
          <HiOutlineCloudUpload size={40} />
          <span className={"mt-2 text-base leading-normal"}>
            {selectedFileName}
          </span>
          <input
            className={"hidden"}
            type={"file"}
            {...register("document", { required: true })}
            multiple={false}
            accept={".pdf"}
          />
        </label>
      </div>
      {errors.document && (
        <div className={"text-error"}>{errors.document.message}</div>
      )}

      <div className={"flex flex-row justify-center py-3 space-x-4 mt-2"}>
        <ButtonSimple
          text={upperCaseFirst("upload")}
          btnType={"submit"}
          isDisabled={isLoading}
          isSpinning={isLoading}
          onClick={() => {
            trackEvent("Upload Button Clicked!");
          }}
        />
      </div>
    </form>
  );
};
