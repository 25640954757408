import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { SharedStyles } from "../../themes/FabTheme";
import { getSelectionIds } from "../../utils/Helpers";

type Props = {
  basePath: string;
};

type FormData = ManagersClient.ManagerPostPropertyReqDto;

const schema = Joi.object<FormData>({
  name: SchemaValidation.FieldRequiredString({ label: "Name" }),
  address: SchemaValidation.FieldRequiredString({ label: "Address", min: 10 }),
  identifier: SchemaValidation.FieldRequiredString({ label: "Identifier" }),
});

export const PropertyComposer: React.FC<Props> = (props) => {
  const history = useHistory();
  const { basePath } = props;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<FormData>({
    // defaultValues: { photoPath: props.data.photoPath },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { orgId } = useAppSelector((state) => getSelectionIds(state));

  async function onSubmit(data: FormData) {
    console.log("PropertyComposer: data", {
      data,
    });

    setIsLoading(true);
    try {
      await managersApi.postPropertyByManagerOrg({
        orgId,
        managerPostPropertyReqDto: data,
      });
      history.push(basePath);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        Add New Property
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"name"} />
            <input
              type={"text"}
              {...register("name")}
              className={SharedStyles.inputs.input()}
            />
            {errors.name && (
              <div className={"text-error"}>{errors.name.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"address"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("address")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.address && (
              <div className={"text-error"}>{errors.address.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"identifier"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("identifier")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            <div className={SharedStyles.inputs.inputDesc()}>
              This could be a LTSA number, LMS number or an internal building
              identifier.
            </div>
            {errors.identifier && (
              <div className={"text-error"}>{errors.identifier.message}</div>
            )}
          </div>
        </div>

        <div className={"py-3 mt-2"}>
          <ButtonSimple
            text={"Add Property"}
            btnType={"submit"}
            btnStyle={"primary"}
            isDisabled={!isValid}
            isSpinning={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
