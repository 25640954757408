import React from "react";

export const SupportDetails = () => {
  return (
    <div className={"flex flex-col items-center justify-end py-4"}>
      <div className={"divide-y divide-gray-400 md:divide-y-5 lg:w-1/2 mb-5"}>
        <hr className={"solid"} />
      </div>
      <div className={"mb-3"}>
        Need some help? The FabricApp Team is here for you!
      </div>
      <div>
        Email us at{" "}
        <a
          href={"mailto:support@fabricapp.ca"}
          className={"text-primary underline"}>
          support@fabricapp.ca
        </a>{" "}
      </div>
    </div>
  );
};
