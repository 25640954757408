import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { PendingResidentsListing } from "./PendingResidentsListing";

export const ApproveYourselfPage: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"container mx-auto px-6 py-4"}>
        <div className={"flex flex-row text-2xl font-medium pb-4 mb-1"}>
          <Link to={path}>
            <div className={"text-primary"}>Approve yourself</div>
          </Link>
        </div>
        <h2 className={"text-left text-xl font-sans text-emph-high mb-4"}>
          💡After you approve yourself, go back to the resident app and refresh.
          You should have access now!
        </h2>
        <div className={"grid grid-cols-12 gap-6 mt-10"}>
          <div className={"shadow-sm col-span-12 lg:col-span-12"}>
            <PendingResidentsListing
              basePath={"/dashboard/resident-directory/pending"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
