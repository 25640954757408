import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

type MultiSelectOption = { label: string; value: string; __isNew__?: boolean };
type MultiSelectProps = {
  name: string;
  options: Array<MultiSelectOption>;
  onChange: (values: Array<MultiSelectOption>) => void;
  defaultValues: MultiSelectOption[];
  creatable?: boolean;
};

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const { name, options, onChange, defaultValues, creatable } = props;

  return (
    <div>
      {creatable ? (
        <CreatableSelect
          id={name.toLowerCase()}
          defaultValue={defaultValues}
          isMulti
          name={"colors"}
          isClearable={false}
          onChange={(selectedOptions) => {
            const rwSelectedOptions = selectedOptions.map((val) => val); // convert read-only to read-write
            onChange(rwSelectedOptions);
          }}
          options={options}
          className={"basic-multi-select mt-1"}
          classNamePrefix={"select"}
        />
      ) : (
        <Select
          id={name.toLowerCase()}
          defaultValue={defaultValues}
          isMulti
          name={"colors"}
          isClearable={false}
          onChange={(selectedOptions) => {
            const rwSelectedOptions = selectedOptions.map((val) => val); // convert read-only to read-write
            onChange(rwSelectedOptions);
          }}
          options={options}
          className={"basic-multi-select mt-1"}
          classNamePrefix={"select"}
        />
      )}
    </div>
  );
};
