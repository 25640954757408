import React from "react";
import { AiOutlineClose } from "react-icons/all";

import { SetupAccountSteps } from "./SetupAccountSteps";

export const SetupAccountProgressSidebar = (props: any) => {
  const { stepsStatus, setIsOpen } = props;

  return (
    <>
      <div className={"w-64 h-12"}>
        <div className={"lg:invisible md:invisible sm:visible w-full justify-end"}>
          <AiOutlineClose
            className={"block h-10 w-6 mr-6"}
            aria-hidden={"true"}
            onClick={() => setIsOpen(false)}
          />
        </div>

        <nav className={"mt-10 divide-y-4 divide-dashed"}>
          <SetupAccountSteps stepsStatus={stepsStatus} />
        </nav>
      </div>
    </>
  );
};
