import { PaperPlaneSvg } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";

import { LocalStorageAPI } from "../../api/localStorageAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SubRouteProps } from "../../components/SubRouteProps";
import { resetToInitial } from "./FirebaseSlice";
import LoginLayout from "./LoginLayout";
import { SignInButton } from "./SignInButton";

export const SignInStep2EmailSentView: React.FC<SubRouteProps> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUserInfo, authStatus } = useAppSelector(
    (state) => state.auth.firebaseAuth,
  );

  const [emailAddress, setEmailAddress] = React.useState<string | undefined>();

  React.useEffect(() => {
    async function restoreEmailFromLs() {
      const emailFromLs = await LocalStorageAPI.getKv("signInEmail");
      setEmailAddress(emailFromLs);
    }
    // noinspection JSIgnoredPromiseFromCall
    restoreEmailFromLs();
  });

  // If user is already logged-in, re-direct them to the dashboard
  React.useEffect(() => {
    if (currentUserInfo) {
      console.log("SignInStep2EmailSentView | history.push /dashboard");
      history.push("/dashboard");
    }
  }, [authStatus, currentUserInfo, history]);

  if (authStatus !== "SIGN_IN_DONE") {
    return <Redirect to={`${basePath}`} />;
  }

  return (
    <LoginLayout>
      <div className={"flex flex-col items-center justify-center"}>
        <div className={"mt-3 text-2xl font-medium text-default"}>
          Check your email
        </div>
        <div className={"w-24 h-24 my-4"}>
          <PaperPlaneSvg classNames={"text-primary"} />
        </div>
        <div className={"text-default text-center"}>
          We have sent an email to{" "}
          <span className={"font-medium"}>{emailAddress}</span>. If this email
          address has an account on FabricApp, you will find a magic link that
          will sign you into your account.
          <br />
          <br />
          The link will expire in few hours, so be sure to use it soon.
        </div>

        <SignInButton
          btnText={"Sign-in using another email"}
          onClick={() => {
            dispatch(resetToInitial());
            console.log("SignInStep2EmailSentView | history.push /enter-email");
            history.push(`${basePath}/enter-email`);
          }}
        />
      </div>
    </LoginLayout>
  );
};
