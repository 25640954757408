import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { AllContactsNav } from "./AllContactsNav";

type PropertyContactsTabsProps = {
  basePath: string;
  addNewPath: string;
};

export const PropertyContactsTabs: React.FC<PropertyContactsTabsProps> = (
  props,
) => {
  const { addNewPath } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToAllContacts = `${path}/all-contacts`;
  const pathToDefault = path;

  const getTabClassName: (myPath: string, isDefault?: boolean) => string =
    React.useCallback(
      (currentPath, isDefault = false) => {
        const isMyPath = pathname.endsWith(currentPath);
        const isCurrentPath = pathname.endsWith(pathToDefault) && isDefault;
        const isActive = isMyPath || isCurrentPath;
        return isActive
          ? SharedStyles.tabs.selectedTabClassName()
          : SharedStyles.tabs.unselectedTabClassName();
      },
      [pathToDefault, pathname],
    );

  return (
    <div className={"py-4"}>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add Property Contact"}
          btnType={"button"}
          btnStyle={"secondary"}
          onClick={() => {
            trackEvent("Add Property Contact Button Clicked!");
            history.push(addNewPath);
          }}
        />
      </div>

      <div className={SharedStyles.tabs.tabListClassName()}>
        <div className={"flex flex-row items-start space-x-4"}>
          <Link
            to={pathToAllContacts}
            className={getTabClassName(pathToAllContacts, true)}>
            All Contacts
          </Link>
        </div>
      </div>

      <div className={"min-h-screen"}>
        <Switch>
          <div className={"grid grid-cols-12 gap-6 mt-10"}>
            <div className={"shadow-sm col-span-12 lg:col-span-12"}>
              <Route path={pathToAllContacts} component={AllContactsNav} />
              <Route path={path} component={AllContactsNav} />
            </div>
          </div>
        </Switch>
      </div>
    </div>
  );
};
