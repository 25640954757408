import { combineReducers } from "@reduxjs/toolkit";

import { appLaunchReducer } from "../AppSlice";
import { amenitiesReducer } from "../features/Amenities/AmenitiesReducer";
import { announcementsReducer } from "../features/Announcements/AnnouncementsReducer";
import { managerAuthReducer } from "../features/Auth/AuthReducer";
import { buildingsReducer } from "../features/Buildings/BuildingsReducer";
import { defaultImagesReducer } from "../features/DefaultImages/DefaultImageReducer";
import { documentsReducer } from "../features/Documents/DocumentsReducer";
import { managerDirectoryReducer } from "../features/ManagerDirectory/ManagerDirectoryReducer";
import { onboardingReducer } from "../features/Onboarding/OnboardingSlice";
import { organizationsReducer } from "../features/Organizations/OrganizationReducer";
import { perksReducer } from "../features/Perks/PerksReducer";
import { profileInfoReducer } from "../features/Profile/ProfileInfoSlice";
import { propertiesReducer } from "../features/Property/PropertyReducer";
import { propertyContactsReducer } from "../features/PropertyContacts/propertyContactReducer";
import { remindersReducer } from "../features/Reminders/RemindersReducer";
import { importEmailsReducer } from "../features/ResidentDirectory/ImportEmails/importEmailsReducer";
import { residentDirectoryReducer } from "../features/ResidentDirectory/ResidentDirectoryReducer";
import { selectionContextReducer } from "../features/SelectionContext/SelectionContextSlice";
import { setupAccountReducer } from "../features/SetupAccount/setupAccountReducer";
import { setupStepsReducer } from "../features/Signup/SetupStepsSlice";
import { signupReducer } from "../features/Signup/SignupSlice";
import { unitGroupsReducer } from "../features/UnitGroups/unitGroupsReducer";
import { unitNumbersReducer } from "../features/UnitNumbers/unitNumbersReducer";
import { unitProfilesReducer } from "../features/UnitProfiles/unitProfilesReducer";

const rootReducer = combineReducers({
  appLaunch: appLaunchReducer,
  selectionContext: selectionContextReducer,
  auth: managerAuthReducer,
  profileInfo: profileInfoReducer,
  onboarding: onboardingReducer,
  organizations: organizationsReducer,
  properties: propertiesReducer,
  buildings: buildingsReducer,
  residentDirectory: residentDirectoryReducer,
  documents: documentsReducer,
  announcements: announcementsReducer,
  amenities: amenitiesReducer,
  reminders: remindersReducer,
  perks: perksReducer,
  defaultImages: defaultImagesReducer,
  propertyContacts: propertyContactsReducer,
  managerDirectory: managerDirectoryReducer,
  unitGroups: unitGroupsReducer,
  unitNumbers: unitNumbersReducer,
  unitProfiles: unitProfilesReducer,
  signup: signupReducer,
  setupSteps: setupStepsReducer,
  importEmails: importEmailsReducer,
  setupAccountReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
