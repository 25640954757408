import React from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { DocumentCreateView } from "./DocumentCreateView";
import { DocumentPdfViewer } from "./DocumentPdfViewer";

type DocumentViewOrCreateProps = {
  defaultTitle: string;
};

export const DocumentViewOrCreate: React.FC<DocumentViewOrCreateProps> = (
  props,
) => {
  const { defaultTitle } = props;
  const { documentId } = useParams<{ documentId: string }>();
  console.log("DocumentGroups | DocumentView", documentId);
  const { allDocuments } = useAppSelector(
    (state) => state.documents.documentsListing,
  );

  // docType -> doc | undefined
  const maybeDocument = allDocuments?.find((doc) => doc.id === documentId);

  const derivedView = maybeDocument ? (
    <DocumentPdfViewer
      downloadPath={maybeDocument.downloadPath}
      title={maybeDocument.title}
      id={maybeDocument.id}
      deletable
    />
  ) : (
    <DocumentCreateView defaultTitle={defaultTitle} />
  );

  return <div className={"flex flex-col w-full"}>{derivedView}</div>;
};
