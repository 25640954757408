import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";

export interface DefaultImagesListingSliceState {
  getAllStatus: "IDLE" | "ALL_LOADING" | "ALL_SUCCESS" | "ALL_ERROR";
  getAllError?: string;
  defaultImagesAdapter: EntityState<ManagersClient.SharedDefaultImageResDto>;
}

const defaultImagesAdapter =
  createEntityAdapter<ManagersClient.SharedDefaultImageResDto>({
    selectId: (item) => item.id,
    // sortComparer: (a, b) => a.content.t.title.localeCompare(b.title),
  });

const initialState: DefaultImagesListingSliceState = {
  getAllStatus: "IDLE",
  defaultImagesAdapter: defaultImagesAdapter.getInitialState(),
};

export const DefaultImagesListingSlice = createSlice({
  name: "defaultImagesListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
    },
    getAllImagesStart: (state) => {
      state.getAllStatus = "ALL_LOADING";
    },
    getAllImagesSuccess: (
      state,
      action: PayloadAction<{
        defaultImages: Array<ManagersClient.SharedDefaultImageResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_SUCCESS";
      defaultImagesAdapter.setAll(
        state.defaultImagesAdapter,
        action.payload.defaultImages,
      );
    },
    getAllImagesFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_ERROR";
      state.getAllError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllImagesStart,
  getAllImagesSuccess,
  getAllImagesFailure,
} = DefaultImagesListingSlice.actions;

export const defaultImagesListingReducer = DefaultImagesListingSlice.reducer;

export const loadDefaultImagesListing = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getAllImagesStart());
    const defaultImages = (await managersApi.getAllDefaultImages()).results;
    dispatch(getAllImagesSuccess({ defaultImages }));
  } catch (err: any) {
    dispatch(getAllImagesFailure(err.toString()));
  }
};

export const defaultImagesSelector =
  defaultImagesAdapter.getSelectors<RootState>(
    (state) => state.defaultImages.defaultImagesListing.defaultImagesAdapter,
  );

export const defaultImageCountSelector = (state: RootState): number => {
  return defaultImagesSelector.selectTotal(state);
};

export const defaultImageIsEmptySelector = (state: RootState): boolean => {
  return defaultImagesSelector.selectTotal(state) === 0;
};

export const defaultImageByTypeSelector = (
  state: RootState,
  type: ManagersClient.SharedDefaultImageResDtoTypeEnum,
): ManagersClient.SharedDefaultImageResDto | undefined => {
  const allDefaultImage = defaultImagesSelector.selectAll(state);
  return allDefaultImage.find((x) => x.type === type);
};
