import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { SetupAccountPage } from "./SetupAccountPage";

export const SetupAccountNav = (props: any) => {
  const { path } = useRouteMatch();

  return (
    <div className={"container bg-white"}>
      <Switch>
        <Route path={`${path}`}>
          <SetupAccountPage basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
