import React from "react";
import { useHistory } from "react-router-dom";

export const LaunchTab = () => {
  const history = useHistory();
  return (
    <div className={"flex flex-row self-center mt-5 mb-5"}>
      <button
        className={"relative w-10"}
        onClick={() => history.push("/dashboard")}>
        <svg
          xmlns={"http://www.w3.org/2000/svg"}
          fill={"none"}
          viewBox={"0 0 24 24"}
          strokeWidth={1.5}
          stroke={"currentColor"}
          className={"w-4 h-4"}>
          <path
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            d={"M6 18L18 6M6 6l12 12"}
          />
        </svg>
      </button>

      <p>Launch resident app</p>
    </div>
  );
};
