import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { capitalCase } from "change-case";

export const SubStepLabels = (props: any) => {
  const { step, stepIdx } = props;

  return (
    <div className={"py-4"}>
      <nav className={"flex justify-center"} aria-label={"Progress"}>
        <ol role={"list"} className={"space-y-6"}>
          <li>
            {step.complete === true ? (
              <div className={"group"}>
                <span className={"flex items-start"}>
                  <span
                    className={
                      "relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    }>
                    <CheckCircleIcon
                      className={
                        "h-full w-full text-indigo-600 group-hover:text-indigo-800"
                      }
                      aria-hidden={"true"}
                    />
                  </span>
                  <span
                    className={
                      "ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900"
                    }>
                    {capitalCase(step.name)}
                  </span>
                </span>
              </div>
            ) : (
              <div className={"group"}>
                <div className={"flex items-start"}>
                  <div
                    className={
                      "relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    }
                    aria-hidden={"true"}>
                    <div
                      className={
                        "h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400"
                      }
                    />
                  </div>
                  <p
                    className={
                      "ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900"
                    }>
                    {capitalCase(step.name)}
                  </p>
                </div>
              </div>
            )}
          </li>
        </ol>
      </nav>
    </div>
  );
};
