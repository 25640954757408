import "quill/dist/quill.snow.css";

import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { PostAnnouncementRecipientTypeEnum } from "@fabricapp-ca/fabricapp-openapi/generated/managers-ts-fetch/apis/ManagersApi";
import {
  ButtonSimple,
  ErrorView,
  LoadingView,
} from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { truncate } from "lodash"; // Add css for snow theme
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineCloudUpload } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { upperCaseFirst } from "upper-case-first";

import { managersApi } from "../../api/managersApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ContentEditor } from "../../components/forms/ContentEditor";
import { FormLabel } from "../../components/forms/FormLabel";
import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { getSelectionIds } from "../../utils/Helpers";
import {
  getAllImportEmailsListing,
  resetImportEmailsStatus,
} from "../ResidentDirectory/ImportEmails/importEmailsSlice";
import { getAccountSetupStepsInfo } from "../SetupAccount/SetupAccountSlice";
import { ImportContactsModal } from "./ImportContactsModal";
import { UnitSelect } from "./UnitSelect";

type AnnouncementComposerProps = {
  basePath: string;
};

type AnnouncementComposerFormData = {
  recipients: Array<string>;
  unitGroups: Array<string>;
  subject: string;
  messagePreviewText: string;
  documents?: FileList;
  allowResponse: boolean;
};

const schema = Joi.object<AnnouncementComposerFormData>({
  recipients: SchemaValidation.FieldOptionalArray({
    label: "First name",
    min: 0,
    items: SchemaValidation.FieldOptionalString({
      label: "Recipient",
      min: 10,
    }),
  }),
  unitGroups: SchemaValidation.FieldOptionalArray({
    label: "Unit Group",
    min: 0,
    items: SchemaValidation.FieldOptionalString({
      label: "Unit Group ID",
      min: 10,
    }),
  }),

  subject: SchemaValidation.FieldRequiredString({
    label: "Subject",
    min: 5,
    max: 75,
  }),
  messagePreviewText: SchemaValidation.FieldRequiredString({
    label: "Message",
    min: 10,
    max: 5000,
  }),
  allowResponse: Joi.boolean().label("Allow Response"),
});

export const AnnouncementComposer: React.FC<AnnouncementComposerProps> = (
  props,
) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { basePath } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingResidentDirectory, setIsLoadingResidentDirectory] =
    React.useState(true);
  const [residentDirectory, setResidentDirectory] = React.useState<any>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [unitGroupDirectory, setUnitGroupDirectory] = React.useState<any>([]);

  const { orgId, propertyId, buildingId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  const { getAllStatus, getAllError, importEmails } = useAppSelector(
    (state) => state.importEmails.importEmailsListing,
  );

  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const [selectedFileName, setSelectedFileName] = React.useState("Select File");

  const [messageJson, setMessageJson] = React.useState("");
  const [toLength, setToLength] = React.useState<boolean>(false);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(getAllImportEmailsListing());
    }
    return () => {
      dispatch(resetImportEmailsStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus, importEmails]);

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  useEffect(() => {
    async function getUnits() {
      const res = await managersApi.getUnitsByBuilding({
        orgId,
        propertyId,
        buildingId,
      });
      const units = [];
      for (const result of res.results) {
        units.push({ value: result.id, label: result.name, type: "UNIT" });
      }
      setResidentDirectory(units);
      setIsLoadingResidentDirectory(false);
    }

    async function getUnitGroups() {
      const unitGroups = await managersApi.getUnitGroupsForProperty({
        orgId,
        propertyId,
      });

      let unitGroupsFormatted: any = [];

      if (unitGroups.results && unitGroups.results.length > 0) {
        unitGroupsFormatted = unitGroups.results.map((ug) => {
          return {
            label: ug.name,
            value: ug.id,
            type: "UNIT_GROUPS",
          };
        });
      }

      setUnitGroupDirectory([
        {
          value: "ALL_RESIDENTS",
          label: "All Residents",
          type: "UNIT_GROUPS",
        },
        ...unitGroupsFormatted,
      ]);
    }

    getUnits();
    getUnitGroups();
  }, []);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<AnnouncementComposerFormData>({
    defaultValues: { allowResponse: false, recipients: [], unitGroups: [] },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const watchDocument: FileList | undefined = watch("documents");

  React.useEffect(() => {
    console.log("DocumentGroups | useEffect.watchDocument", { watchDocument });
    if (watchDocument && watchDocument.length > 0) {
      setSelectedFileName(watchDocument[0].name);
    }
  }, [watchDocument]);

  const watchUnitGroups = watch("unitGroups");
  const watchRecipients = watch("recipients");

  React.useEffect(() => {
    if (watchUnitGroups.length === 0 && watchRecipients.length === 0) {
      setToLength(true);
    } else {
      setToLength(false);
    }
  }, [watchUnitGroups, watchRecipients]);

  async function onSendNow(data: AnnouncementComposerFormData) {
    trackEvent("Send Announcements Clicked!");
    console.log("data", data);

    trackEvent("Announcements Send Button Pressed");

    const { subject, messagePreviewText, allowResponse } = data;
    setIsLoading(true);
    console.log("AnnouncementComposer | onSendNow", { data });
    const truncatedMessagePreview = truncate(messagePreviewText, {
      length: 200,
    });
    let recipientType: PostAnnouncementRecipientTypeEnum =
      ManagersClient.PostAnnouncementRecipientTypeEnum.Units;
    const selectedUnits: string[] = [];
    const selectedUnitGroups: string[] = [];
    if (data.recipients) {
      for (const recipient of data.recipients) {
        selectedUnits.push(recipient);
      }
      if (data.recipients.indexOf("ALL_RESIDENTS") > -1) {
        recipientType =
          ManagersClient.PostAnnouncementRecipientTypeEnum.Property;
      }
    }

    if (data.unitGroups) {
      for (const unitGroup of data.unitGroups) {
        selectedUnitGroups.push(unitGroup);
      }
    }

    try {
      const announcementParams: ManagersClient.PostAnnouncementRequest = {
        status: ManagersClient.PostAnnouncementStatusEnum.Sent,
        propertyId,
        orgId,
        subject,
        recipientType,
        message: messageJson,
        messagePreviewText: truncatedMessagePreview,
        selectedBuildings: [],
        selectedUnits,
        selectedUnitGroups,
        allowResponse,
        documents:
          data.documents && data.documents.length > 0
            ? [data.documents[0]]
            : [],
      };
      console.log("documents", announcementParams.documents);
      await managersApi.postAnnouncement(announcementParams);
      if (history.location.pathname.includes("/setup-account")) {
        dispatch(getAccountSetupStepsInfo());
      }
      setIsLoading(false);
      history.push(basePath);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }

  console.log("ERRORS ", errors.recipients, errors.unitGroups);

  if (isLoadingResidentDirectory) return <LoadingView />;

  return (
    <div
      onClick={() => {
        if (importEmails.length === 0 && residentDirectory.length === 0) {
          setOpenModal(true);
        }
      }}>
      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full "}>
            <UnitSelect
              unitGroupDirectory={unitGroupDirectory}
              residentDirectory={residentDirectory}
              schema={schema}
              getValues={getValues}
              setValue={setValue}
            />

            {toLength && (
              <div className={"text-error"}>
                Please select someone to send this message to
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <div className={"flex flex-row space-x-2 items-center"}>
              <input
                type={"checkbox"}
                {...register("allowResponse")}
                className={
                  "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                }
              />
              <FormLabel text={"Allow Responses"} />
            </div>
            <div className={"text-sm text-emph-high mt-2"}>
              Residents will be able to respond to announcements via email if
              this is checked.
            </div>
            {errors.subject && (
              <div className={"text-error"}>
                {errors?.allowResponse?.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"subject"} />
            <input
              type={"text"}
              {...register("subject")}
              className={SharedStyles.inputs.input()}
            />
            <div className={"text-sm text-emph-high mt-2"}>
              Enter a title for this announcement.
            </div>
            {errors.subject && (
              <div className={"text-error"}>{errors.subject.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Attachment"} extraClassNames={"mb-1"} />
            <label
              className={
                "w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-primary hover:text-white"
              }>
              <HiOutlineCloudUpload size={40} />
              <span className={"mt-2 text-base leading-normal"}>
                {selectedFileName}
              </span>
              <input
                className={"hidden"}
                type={"file"}
                {...register("documents", { required: false })}
                multiple={false}
                accept={".pdf"}
              />
            </label>
          </div>
          {errors.documents && (
            <div className={"text-error"}>{errors.documents.message}</div>
          )}

          <div
            className={"col-span-full mb-20"}
            style={{ height: 400, width: "100%" }}>
            <FormLabel text={"message"} extraClassNames={"mb-1"} />
            <Controller
              control={control}
              name={"messagePreviewText"}
              render={({ field }) => (
                <ContentEditor
                  name={"announcement"}
                  onChange={(quillDeltaJson: string, previewText: string) => {
                    setMessageJson(quillDeltaJson);
                    field.onChange(previewText);
                  }}
                />
              )}
            />
            {errors.messagePreviewText && (
              <div className={"text-error"}>
                {errors.messagePreviewText.message}
              </div>
            )}
          </div>
        </div>

        <div className={"py-3 space-x-4 mt-2"}>
          <ButtonSimple
            text={upperCaseFirst("send now")}
            btnType={"button"}
            isDisabled={!isValid || toLength}
            isSpinning={isLoading}
            onClick={handleSubmit(onSendNow)}
          />
        </div>
      </form>
      <ImportContactsModal showModal={openModal} setShowModal={setOpenModal} />
    </div>
  );
};
