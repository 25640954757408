import { ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";

export const ProgressBreadcrumbs = (props: any) => {
  const { breadcrumbStep } = props;
  return (
    <div className={"my-4"}>
      <nav className={"flex bg-white"} aria-label={"Breadcrumb"}>
        <ol
          role={"list"}
          className={
            "mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6"
          }>
          {breadcrumbStep.map((step: any, index: number) => (
            <li key={step.name} className={"flex"}>
              <div className={"flex items-center"}>
                <a
                  className={
                    "mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  }
                  aria-current={step.current ? "step" : undefined}>
                  {step.name}
                </a>

                {breadcrumbStep.length - 1 !== index ? (
                  <ChevronRightIcon
                    className={"h-5 w-5 flex-shrink-0 text-gray-400"}
                    aria-hidden={"true"}
                  />
                ) : (
                  <></>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
