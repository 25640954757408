import React, { FunctionComponent } from "react";

export const AddAmenityWizardLayout: FunctionComponent<unknown> = (props) => {
  const { children } = props;
  return (
    <div>
      <div
        className={
          "flex justify-center items-center w-full min-h-full py-4 overflow-visible rounded"
        }>
        <div
          className={
            "mx-auto md:w-11/12 w-full bg-white rounded-sm px-20 py-2"
          }>
          {children}
        </div>
      </div>
    </div>
  );
};
