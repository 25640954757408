import { LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SupportDetails } from "../UnitNumbers/SupportDetails";
import SignupLayout from "./SignupLayout";
import { saveSignupWithEmail } from "./SignupSlice";
import { SignupSteps } from "./SignupSteps";
import { trackEvent } from "../../utils/Amplitude";

export const CheckEmailPage = () => {
  const location: any = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useAppDispatch();

  const { managerEntryPointState } = useAppSelector((state) => state.signup);
  const [isLoading, setIsLoading] = React.useState(false);
  const wizardStepper = wizardStepperWrapper(SignupSteps)(0);
  const resendEmail = async () => {
    trackEvent("Verify my email button Clicked!");
    setIsLoading(true);
    if (executeRecaptcha && managerEntryPointState) {
      const recaptchaCode = await executeRecaptcha();
      const newState = {
        ...managerEntryPointState,
        recaptchaCode,
      };
      dispatch(saveSignupWithEmail(newState));
    }
    setIsLoading(false);
  };
  return (
    <SignupLayout>
      {wizardStepper}
      <div className={"mt-12"}>
        <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Check your email!
        </h2>
        <h2 className={"mt-2 text-center text-lg font-medium text-emph-high"}>
          To activate your account, please follow the instructions we just sent
          to{" "}
          <span className={"font-medium text-indigo-600 hover:text-indigo-500"}>
            {location?.state?.email}
          </span>
          . If you are having trouble finding your email, make sure the email
          you provided is correct and check your spam folder.
        </h2>
        <div
          className={"flex mt-6 my-12"}
          style={{
            // border: "1px solid red",
            justifyContent: "space-evenly",
          }}>
          {isLoading ? (
            <LoadingView />
          ) : (
            <button
              onClick={() => {
                resendEmail();
              }}
              className={
                "inline-flex justify-center w-full px-4 py-1 mt-1 font-sans text-sm leading-none text-center text-indigo-600 no-underline cursor-pointer sm:text-base md:text-lg"
              }>
              Resend email
            </button>
          )}
        </div>
        <SupportDetails />
      </div>
    </SignupLayout>
  );
};
