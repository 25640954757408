import React from "react";
import { useQuill } from "react-quilljs";

type ContentEditorProps = {
  name: string;
  onChange: (quillDeltaJson: string, messagePreviewText: string) => void;
  defaultValue?: string;
};

export const ContentEditor: React.FC<ContentEditorProps> = (props) => {
  const { name, onChange, defaultValue } = props;
  const theme = "snow";
  const placeholder = `Enter new ${name.toLowerCase()} here...`;
  const formats = [
    "bold",
    "italic",
    "underline",
    "background",
    "color",
    "header",
    "indent",
    "list",
    "link",
  ];
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "link"], // toggled buttons
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"], // remove formatting button
    ],
  };
  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  React.useEffect(() => {
    if (!quill || !defaultValue) {
      return;
    }

    quill.setContents(JSON.parse(defaultValue));
  }, [defaultValue, quill]);

  React.useEffect(() => {
    if (!quill) {
      return () => {};
    }

    const textChangeHandler = () => {
      console.log("Text change!", { content: quill.getContents() });
      const deltaString = JSON.stringify(quill.getContents());
      const messagePreviewText = quill.getText();
      onChange(deltaString, messagePreviewText);
    };

    console.log("ContentEditor | setting up quill.on(text-change)");
    quill.on("text-change", textChangeHandler);

    return () => {
      console.log("ContentEditor | cleanup useEffect");
      quill.off("text-change", textChangeHandler);
    };
  }, [onChange, quill]);

  return <div id={name.toLowerCase()} ref={quillRef} />;
};
