import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import AmenitiesNav from "../Amenities/AmenitiesNav";
import { AnnouncementsNav } from "../Announcements/AnnouncementsNav";
import { BuildingNav } from "../Buildings/BuildingNav";
import { DocumentsNav } from "../Documents/DocumentsNav";
import { InviteResidentsNav } from "../InviteResidents/InviteResidentsNav";
import { ManagerDirectoryNav } from "../ManagerDirectory/ManagerDirectoryNav";
import { OrganizationNav } from "../Organizations/OrganizationNav";
import { PerksNav } from "../Perks/PerksNav";
import { PropertyNav } from "../Property/PropertyNav";
import { PropertyContactsNav } from "../PropertyContacts/PropertyContactsNav";
import { RemindersNav } from "../Reminders/RemindersNav";
import ResidentDirectoryNav from "../ResidentDirectory/ResidentDirectoryNav";
import { UnitGroupsNav } from "../UnitGroups/UnitGroupsNav";
import { UnitNumbersNav } from "../UnitNumbers/UnitNumbersNav";
import { UnitProfilesNav } from "../UnitProfiles/UnitProfilesNav";
import DashboardHeaderNav from "./DashboardHeaderNav";
import DashboardLanding from "./DashboardLanding";
import DashboardSideNav from "./DashboardSideNav";

type Props = unknown;

const DashboardNav: FunctionComponent<Props> = () => {
  const { path } = useRouteMatch();
  const { currentUserInfo } = useAppSelector(
    (state) => state.auth.firebaseAuth,
  );
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  if (!currentUserInfo) {
    console.log("history.redirect /signin");
    return <Redirect to={"/signin"} />;
  }
  if (!selectedOrg) {
    console.log("history.redirect /select-organization");
    return <Redirect to={"/select-organization"} />;
  }
  if (selectedOrg && !selectedProperty) {
    console.log("history.redirect /select-property");
    return <Redirect to={"/select-property"} />;
  }

  return (
    <div className={"flex flex-col min-h-screen bg-white"}>
      <DashboardHeaderNav />

      <div className={"flex-1 flex flex-row"}>
        <DashboardSideNav />

        <main className={"flex-1 overflow-y-auto container mx-auto px-6 py-10"}>
          <Switch>
            <Route path={`${path}/organizations`}>
              <OrganizationNav basePath={path} />
            </Route>
            <Route path={`${path}/properties`}>
              <PropertyNav basePath={path} />
            </Route>
            <Route path={`${path}/amenities`}>
              <AmenitiesNav basePath={path} />
            </Route>
            <Route path={`${path}/resident-directory`}>
              <ResidentDirectoryNav />
            </Route>
            <Route path={`${path}/unit-directory`}>
              <UnitProfilesNav />
            </Route>
            <Route path={`${path}/manager-directory`}>
              <ManagerDirectoryNav basePath={path} />
            </Route>
            <Route path={`${path}/buildings`}>
              <BuildingNav basePath={path} />
            </Route>
            <Route path={`${path}/announcements`}>
              <AnnouncementsNav />
            </Route>
            <Route path={`${path}/reminders`}>
              <RemindersNav />
            </Route>
            <Route path={`${path}/documents`}>
              <DocumentsNav />
            </Route>
            <Route path={`${path}/perks`}>
              <PerksNav />
            </Route>
            <Route path={`${path}/contacts`}>
              <PropertyContactsNav />
            </Route>
            <Route path={`${path}/unit-groups`}>
              <UnitGroupsNav />
            </Route>
            <Route path={`${path}/unit-numbers`}>
              <UnitNumbersNav />
            </Route>
            <Route path={`${path}/invite-residents`}>
              <InviteResidentsNav />
            </Route>
            <Route path={`${path}`}>
              <DashboardLanding />
            </Route>
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default DashboardNav;
