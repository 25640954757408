import React from "react";

import fabLogo from "../assets/images/fab-logo.png";
import fabLogoPoweredBy from "../assets/images/fab-logo-powered-by.png";

type Props = {
  styles?: string;
  poweredBy?: boolean;
};

export const FabLogo: React.FC<Props> = (props) => {
  const { styles = "", poweredBy = false } = props;
  return <img className={styles} src={poweredBy ? fabLogoPoweredBy : fabLogo} alt={"FabricApp Logo"} />;
};
