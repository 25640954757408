import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { SignupComposer } from "./SignupComposer";
import SignupLayout from "./SignupLayout";

export const SignupPage = (props: any) => {
  return (
    <SignupLayout>
      <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Let's create your FabricApp account in 3 easy steps
      </h2>
      <h2 className={"mt-2 text-center text-lg font-medium text-emph-high"}>
        This will take you about 3 minutes to complete
      </h2>

      <GoogleReCaptchaProvider
        reCaptchaKey={"6LfB79keAAAAAJDOPaBvi6BC6q32SBnzxCXYYIOY"}>
        <SignupComposer />
      </GoogleReCaptchaProvider>
    </SignupLayout>
  );
};
