import React from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type StepLabelProps = {
  step: any;
  stepIdx: number;
  selected: boolean;
};

export const SetupAccountStepLabel = (props: StepLabelProps) => {
  const { step, stepIdx, selected } = props;

  return (
    <nav aria-label={"Progress"}>
      <ol role={"list"} className={"overflow-hidden"}>
        <li
          className={classNames(
            stepIdx !== step.length - 1 ? "pb-10" : "",
            "relative",
          )}>
          {step.complete === true ? (
            <>
              {stepIdx !== step.length - 1 ? (
                <div
                  className={
                    "absolute top-4 left-4 -ml-px mt-0.5 w-0.5 bg-indigo-600"
                  }
                  aria-hidden={"true"}
                />
              ) : null}
              <div className={"group relative flex items-start"}>
                <span className={"flex h-9 items-center"}>
                  <span
                    className={
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800 text-white"
                    }>
                    {stepIdx + 1}
                  </span>
                </span>
                <span className={"mt-2 ml-4 flex min-w-0 flex-col"}>
                  <span className={"text-sm font-medium"}>{step.name}</span>
                  <span className={"text-sm text-gray-500"}>
                    {step.component}
                  </span>
                </span>
              </div>
            </>
          ) : (
            <>
              {stepIdx !== step.length - 1 ? (
                <div
                  className={
                    "absolute top-4 left-4 -ml-px mt-0.5 w-0.5 bg-gray-00"
                  }
                  aria-hidden={"true"}
                />
              ) : null}
              <div className={"group relative flex items-start"}>
                <span className={"flex h-9 items-center"} aria-hidden={"true"}>
                  <span
                    className={
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400 text-primary"
                    }>
                    {stepIdx + 1}
                  </span>
                </span>
                <span className={"ml-4 mt-2 flex min-w-0 flex-col"}>
                  <span
                    className={`text-sm font-medium text-gray-500 ${
                      selected ? "text-indigo-500" : ""
                    }`}>
                    {step.name}
                  </span>
                  <span className={"text-sm text-gray-500"}>
                    {step.component}
                  </span>
                </span>
              </div>
            </>
          )}
        </li>
      </ol>
    </nav>
  );
};
