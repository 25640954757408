import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectInspect from "object-inspect";

import { LocalStorageAPI } from "../../api/localStorageAPI";
import { managersApi } from "../../api/managersApi";
import { AppThunk } from "../../app/store";

export interface SignupState {
  wizardStepStatus:
    | "IDLE"
    | "SIGN_UP_WITH_EMAIL_LOADING"
    | "SIGN_UP_WITH_EMAIL_SUCCESS"
    | "LOADING_POST_ENTRYPOINT"
    | "SUCCESS_POST_ENTRYPOINT"
    | "ERROR_POST_ENTRYPOINT";

  managerEntryPointState?: ManagersClient.ManagerSignUpWithEmailReqDto;
  signupWithEmailData?: ManagersClient.ManagerSignUpWithEmailReqDto;
  postEntrypointError?: string;
}

const initialState: SignupState = {
  wizardStepStatus: "IDLE",
};

export const SignupSlice = createSlice({
  name: "signup",
  initialState,

  reducers: {
    signupWithEmailLoading: (state) => {
      state.wizardStepStatus = "SIGN_UP_WITH_EMAIL_LOADING";
    },

    signupWithEmailSuccess: (state) => {
      state.wizardStepStatus = "SIGN_UP_WITH_EMAIL_SUCCESS";
    },

    signupWithEmailFailure: (
      state,
      action: PayloadAction<{ error: string }>,
    ) => {
      state.wizardStepStatus = "ERROR_POST_ENTRYPOINT";
      state.postEntrypointError = action.payload.error;
    },

    setSignupWithEmailState: (
      state,
      action: PayloadAction<ManagersClient.ManagerSignUpWithEmailReqDto>,
    ) => {
      state.managerEntryPointState = action.payload;
    },
  },
});

export const {
  signupWithEmailLoading,
  signupWithEmailSuccess,
  signupWithEmailFailure,
  setSignupWithEmailState,
} = SignupSlice.actions;

export const signupReducer = SignupSlice.reducer;

export const saveSignupWithEmail =
  (
    signupWithEmailData: ManagersClient.ManagerSignUpWithEmailReqDto,
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(signupWithEmailLoading());

      // const { signupWithEmailData } = getState().signup;

      if (!signupWithEmailData) {
        dispatch(
          signupWithEmailFailure({
            error: "Missing required step data",
          }),
        );
        return;
      }
      await LocalStorageAPI.setKv("signInEmail", signupWithEmailData.email);
      const state = {
        email: signupWithEmailData.email,
        country: signupWithEmailData.country,
        acceptedAgreements: signupWithEmailData.acceptedAgreements,
        recaptchaCode: signupWithEmailData.recaptchaCode,
      };
      await managersApi.signUpWithEmail({
        managerSignUpWithEmailReqDto: state,
      });
      dispatch(signupWithEmailSuccess());
      dispatch(setSignupWithEmailState(state));
    } catch (error: any) {
      const errorMessage = await error.json();
      console.log(
        "signupWithEmailSlice | signupWithEmail error",
        objectInspect({ error }),
      );
      dispatch(
        signupWithEmailFailure({
          error: errorMessage.message,
        }),
      );
    }
  };

// export const saveSignupWithEmail =
// (signupData: ManagersClient.ManagerSignUpWithEmailReqDto): AppThunk =>
// async (dispatch) => {
//   dispatch(signupWithEmailLoading());
//   dispatch(signupWithEmailSuccess(signupData));
// };
