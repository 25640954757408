import React, { FunctionComponent } from "react";

type TemporaryResidentUnitUserCardInput = {
  email: string;
};

export const TemporaryResidentUnitUserCard: FunctionComponent<
  TemporaryResidentUnitUserCardInput
> = (props) => {
  const { email } = props;

  return (
    <div
      className={
        "flex flex-1 flex-col lg:flex-row items-center max-w-xs w-full py-2 my-2 px-5"
      }>
      <span
        className={
          "w-12 h-12 lg:w-12 lg:h-12 lg:mr-1 lg:mt-2 rounded-full text-center flex justify-center items-center bg-primary px-5"
        }>
        <span className={"text-lg font-medium leading-none text-white"}>?</span>
      </span>

      <div className={"lg:ml-2 text-center lg:text-left mt-3 lg:mt-0"}>
        <div className={"inline-block text-sm font-medium w-full lg:mt-5"}>
          {"This person hasn’t signed up for FabricApp yet!"}
        </div>

        <div className={"text-gray-600 text-sm mt-0.5 underline"}>{email}</div>
      </div>
      <div className={"flex mt-4 lg:mt-0"} />
    </div>
  );
};
