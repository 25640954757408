import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { PropertyContactRow } from "./PropertyContactRow";
import {
  getAllPropertyContactsListing,
  propertyContactsSelector,
  resetAllPropertyContactsStatus,
} from "./propertyContactsSlice";

type AllContactsListingProps = {
  basePath: string;
};

export const AllContactsListing: FunctionComponent<AllContactsListingProps> = (
  props,
) => {
  const { basePath } = props;
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.propertyContacts.propertyContactsListing,
  );
  const propertyContacts = propertyContactsSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(
        getAllPropertyContactsListing({
          propertyId: selectedProperty.id,
          orgId: selectedOrg.id,
        }),
      );
    }
    return () => {
      dispatch(resetAllPropertyContactsStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (!selectedOrg || !selectedProperty) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!propertyContacts || propertyContacts.length < 1) {
    return (
      <EmptyStateImage
        text={"No property contacts yet!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  const propertyContactRows = propertyContacts.map((item) => {
    return <PropertyContactRow basePath={basePath} key={item.id} dto={item} />;
  });

  return (
    <div className={"flex flex-col"}>
      <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
        <div
          className={
            "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          }>
          <div
            className={
              "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            }>
            <table className={"min-w-full divide-y divide-gray-200"}>
              <thead className={"bg-gray-50"}>
                <tr>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Name
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Contact
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Contact type
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Hours
                  </th>
                  <th scope={"col"} className={"relative px-6 py-3"}>
                    <span className={"sr-only"}>View</span>
                  </th>
                </tr>
              </thead>
              <tbody className={"bg-white divide-y divide-gray-200"}>
                {propertyContactRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
