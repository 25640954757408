import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import Interweave from "interweave";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { FormLabel } from "../../components/forms/FormLabel";
import { FabPerkCard } from "./FabPerkCard";
import {
  fabPerksSelector,
  loadFabricAppPerkById,
  resetGetOneStatus,
} from "./FabricAppPerksListingSlice";

export type SentAnnouncementViewerProps = {
  basePath: string;
};

export const FabricAppPerkDetails: React.FC<SentAnnouncementViewerProps> = (
  props,
) => {
  const { basePath } = props;
  const { perkId } = useParams<{ perkId: string }>();
  const { getOneStatus, getOneError } = useAppSelector(
    (state) => state.perks.fabricAppPerksListing,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    dispatch(loadFabricAppPerkById(perkId));
  }, [dispatch, perkId]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(resetGetOneStatus());
    }
  }, [dispatch, isLoading]);

  React.useEffect(() => {
    switch (getOneStatus) {
      case "ONE_LOADING":
        setIsLoading(true);
        break;
      case "ONE_SUCCESS":
        setIsLoading(false);
        break;
      case "ONE_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getOneStatus]);

  const fabPerk = fabPerksSelector.selectById(store.getState(), perkId);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!fabPerk || getOneError) {
    return <ErrorView message={`Missing FabricApp perk ${perkId}`} />;
  }

  const { description, brand } = fabPerk;

  return (
    <div className={"flex flex-col px-6 py-6 border rounded-md shadow-sm"}>
      <FabPerkCard basePath={basePath} fabPerk={fabPerk} showLink={false} />

      <div className={"py-4 px-4"}>
        <FormLabel text={"Perk description"} />
        <Interweave content={description} />
      </div>

      <div className={"py-4 px-4"}>
        <FormLabel text={"Phone number"} />
        {brand.phoneNumber}
      </div>

      <div className={"py-4 px-4"}>
        <FormLabel text={"Email"} />
        {brand.email}
      </div>
    </div>
  );
};
