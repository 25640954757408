import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { AllUnitGroupsNav } from "./AllUnitGroupsNav";

type UnitGroupsTabsProps = {
  basePath: string;
  addNewPath: string;
};

export const UnitGroupsTabs: React.FC<UnitGroupsTabsProps> = (props) => {
  const { addNewPath } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToAllUnitGroups = `${path}/all-unit-groups`;
  const pathToDefault = path;

  const getTabClassName: (myPath: string, isDefault?: boolean) => string =
    React.useCallback(
      (currentPath, isDefault = false) => {
        const isMyPath = pathname.endsWith(currentPath);
        const isCurrentPath = pathname.endsWith(pathToDefault) && isDefault;
        const isActive = isMyPath || isCurrentPath;
        return isActive
          ? SharedStyles.tabs.selectedTabClassName()
          : SharedStyles.tabs.unselectedTabClassName();
      },
      [pathToDefault, pathname],
    );

  return (
    <div className={"py-4"}>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add Unit Group"}
          btnType={"button"}
          btnStyle={"secondary"}
          onClick={() => {
            trackEvent("Add Unit Groups Button Clicked!");
            history.push(addNewPath);
          }}
        />
      </div>

      <div className={SharedStyles.tabs.tabListClassName()}>
        <div className={"flex flex-row items-start space-x-4"}>
          <Link
            to={pathToAllUnitGroups}
            className={getTabClassName(pathToAllUnitGroups, true)}>
            All Unit Groups
          </Link>
        </div>
      </div>

      <div className={"min-h-screen"}>
        <Switch>
          <div className={"grid grid-cols-12 gap-6 mt-10"}>
            <div className={"shadow-sm col-span-12 lg:col-span-12"}>
              <Route path={pathToAllUnitGroups} component={AllUnitGroupsNav} />
              <Route path={path} component={AllUnitGroupsNav} />
            </div>
          </div>
        </Switch>
      </div>
    </div>
  );
};
