import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocalStorageAPI } from "../../api/localStorageAPI";
import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";

export interface SelectionContextSliceState {
  getRoleStatus: "IDLE" | "ROLE_LOADING" | "ROLE_SUCCESS" | "ROLE_ERROR";
  getRoleError?: string;
  selectedOrg?: ManagersClient.SharedGetOrgResDto;
  selectedProperty?: ManagersClient.ManagerGetPropertyResDto;
  selectedOrgRole?: ManagersClient.ManagerGetRoleResDto;
  selectedBuildings?: ManagersClient.ManagerGetBuildingResDto[];
  isMultiOrgUser?: boolean;
}

const initialState: SelectionContextSliceState = {
  getRoleStatus: "IDLE",
};

export const SelectionContextSlice = createSlice({
  name: "selectionContext",
  initialState,
  reducers: {
    resetGetRoleStatus: (state) => {
      state.getRoleStatus = "IDLE";
      state.getRoleError = undefined;
    },
    setSelectedOrg: (
      state,
      action: PayloadAction<{
        selectedOrg: ManagersClient.SharedGetOrgResDto;
        selectedOrgRole: ManagersClient.ManagerGetRoleResDto;
        totalOrgs: number;
      }>,
    ) => {
      state.selectedOrg = action.payload.selectedOrg;
      state.selectedOrgRole = action.payload.selectedOrgRole;
      state.selectedProperty = undefined;
      state.getRoleStatus = "ROLE_SUCCESS";
      state.isMultiOrgUser = action.payload.totalOrgs > 1;
    },
    setSelectedProperty: (
      state,
      action: PayloadAction<{
        selectedProperty: ManagersClient.ManagerGetPropertyResDto;
      }>,
    ) => {
      state.selectedProperty = action.payload.selectedProperty;
    },
    setSelectedBuildings: (
      state,
      action: PayloadAction<{
        selectedBuildings: ManagersClient.ManagerGetBuildingResDto[];
      }>,
    ) => {
      state.selectedBuildings = action.payload.selectedBuildings;
    },

    getRoleStart: (state) => {
      state.getRoleStatus = "ROLE_LOADING";
    },
    getRoleFailure: (state, action: PayloadAction<string>) => {
      state.getRoleStatus = "ROLE_ERROR";
      state.getRoleError = action.payload;
    },
  },
});

export const {
  resetGetRoleStatus,
  setSelectedOrg,
  setSelectedProperty,
  setSelectedBuildings,
  getRoleStart,
  getRoleFailure,
} = SelectionContextSlice.actions;

export const selectionContextReducer = SelectionContextSlice.reducer;

export const updateSelectedOrg =
  (input: {
    selectedOrg: ManagersClient.SharedGetOrgResDto;
    totalOrgs: number;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRoleStart());
      const selectedOrgRole = await managersApi.getManagerRoleByOrg({
        orgId: input.selectedOrg.id,
      });
      const selectedOrgObj = {
        selectedOrg: input.selectedOrg,
        selectedOrgRole,
        totalOrgs: input.totalOrgs,
      };
      dispatch(setSelectedOrg(selectedOrgObj));
      await LocalStorageAPI.setJson("selectedOrg", selectedOrgObj);
    } catch (err: any) {
      console.log("SelectionContextSlice | getRoleErr", { err });
      dispatch(getRoleFailure(prettyRawError(err)));
    }
  };

export const updateSelectedProperty =
  (input: {
    selectedProperty: ManagersClient.ManagerGetPropertyResDto;
  }): AppThunk =>
  async (dispatch) => {
    const selectedPropertyObj = { selectedProperty: input.selectedProperty };
    dispatch(setSelectedProperty(selectedPropertyObj));
    await LocalStorageAPI.setJson("selectedProperty", selectedPropertyObj);
  };

export const restoreSelectionContextFromLS =
  (): AppThunk => async (dispatch) => {
    const selectedOrgObj: any = await LocalStorageAPI.getJson("selectedOrg");
    const selectedPropertyObj: any = await LocalStorageAPI.getJson(
      "selectedProperty",
    );
    const selectedBuildingsObj: any = await LocalStorageAPI.getJson(
      "selectedBuildings",
    );
    if (selectedOrgObj) {
      dispatch(setSelectedOrg(selectedOrgObj));
    }
    if (selectedPropertyObj) {
      dispatch(setSelectedProperty(selectedPropertyObj));
    }
    if (selectedBuildingsObj) {
      dispatch(updateSelectedBuildings(selectedBuildingsObj));
    }
  };

export const updateSelectedBuildings =
  (input: {
    selectedBuildings: ManagersClient.ManagerGetBuildingResDto[];
  }): AppThunk =>
  async (dispatch) => {
    dispatch(
      setSelectedBuildings({ selectedBuildings: input.selectedBuildings }),
    );
  };
