import { ManagerPostUnitReqDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import {
  ButtonSimple,
  ErrorView,
  LoadingView,
} from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAccountSetupStepsInfo } from "../../SetupAccount/SetupAccountSlice";
import Pagination from "../../UnitNumbers/Paginator";
import { SupportDetails } from "../../UnitNumbers/SupportDetails";
import { getAllUnitNumbersListing } from "../../UnitNumbers/unitNumbersSlice";
import { GoToUnitNumbersModal } from "./GoToUnitNumbersModal";
import {
  addImportEmails,
  deleteEmail,
  resetAddImportEmailsStatus,
  resetDeleteEmailStatus,
  UnitEmail,
} from "./importEmailsSlice";
import { ImportEmailsTable } from "./ImportEmailsTable";

type AllImportEmailsListingProps = {
  basePath: string;
};

export const ImportEmailsListing: FunctionComponent<
  AllImportEmailsListingProps
> = (props) => {
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const {
    addImportEmailsStatus,
    addImportEmailsError,
    deleteEmailStatus,
    deleteEmailError,
  } = useAppSelector((state) => state.importEmails.importEmailsListing);

  const { getAllStatus, getAllError, unitNumbers } = useAppSelector(
    (state) => state.unitNumbers.unitNumbersListing,
  );

  // console.log(unitNumbers, "unitNumbers");

  const [emailList, setEmailList] = useState<Array<UnitEmail>>([]);
  const [deletedEmailList, setDeletedEmailList] = useState<Array<string>>([]);
  const [allRows, setAllRows] = useState<Array<ManagerPostUnitReqDto>>([
    ...(unitNumbers || []),
  ]);

  const [openImportEmailModal, setOpenImportEmailModal] =
    useState<boolean>(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [clickAddEmail, setClickAddEmail] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord: number = currentPage * recordsPerPage;
  const indexOfFirstRecord: number = indexOfLastRecord - recordsPerPage;
  const currentRecords = allRows.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages: number = Math.ceil(allRows.length / recordsPerPage);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(getAllUnitNumbersListing());
    }
    return () => {
      dispatch(resetAddImportEmailsStatus());
      dispatch(resetDeleteEmailStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setAllRows(unitNumbers || []);
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus, unitNumbers]);

  React.useEffect(() => {
    switch (addImportEmailsStatus) {
      case "ADD_IMPORT_EMAILS_LOADING":
        setIsLoading(true);
        break;
      case "ADD_IMPORT_EMAILS_SUCCESS":
        // setIsLoading(false);
        dispatch(getAllUnitNumbersListing());
        if (history.location.pathname.includes("/setup-account")) {
          dispatch(getAccountSetupStepsInfo());
        }
        break;
      case "ADD_IMPORT_EMAILS_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [addImportEmailsStatus]);

  React.useEffect(() => {
    switch (deleteEmailStatus) {
      case "DELETE_EMAIL_LOADING":
        setIsLoading(true);
        break;
      case "DELETE_EMAIL_SUCCESS":
        // setIsLoading(false);
        dispatch(getAllUnitNumbersListing());
        break;
      case "DELETE_EMAIL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [deleteEmailStatus]);

  if (!selectedOrg || !selectedProperty) {
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (addImportEmailsError) {
    return <ErrorView message={addImportEmailsError} />;
  }

  if (deleteEmailError) {
    return <ErrorView message={deleteEmailError} />;
  }

  const addTableRows = () => {
    if (unitNumbers.length >= 1) {
      setClickAddEmail(true);
    } else {
      setOpenImportEmailModal(true);
    }
  };

  const handleChange = (index: number, emailTag: any) => {
    const { text } = emailTag;
    const rowsInput: any = [...unitNumbers];

    setEmailList([
      ...emailList,
      {
        unitId: rowsInput[index].id,
        email: text,
      },
    ]);
  };

  const deleteEmailFromRow = (
    rowIndex: number,
    temporaryResidentEmailId: string,
  ) => {
    setDeletedEmailList([...deletedEmailList, temporaryResidentEmailId]);

    const rowsInput: any = [...unitNumbers];
    const rowData: any = rowsInput[rowIndex];

    rowsInput[rowIndex] = {
      ...rowData,
      temporaryResidents: rowData.temporaryResidents.filter(
        (resident: any, index: any) => resident.id !== temporaryResidentEmailId,
      ),
    };

    setAllRows(rowsInput);
  };

  const deleteEmailFromDB = (temporaryResidentEmailId: string) => {
    dispatch(deleteEmail(temporaryResidentEmailId));
  };

  const onSaveRows = () => {
    if (deletedEmailList) {
      for (let i = 0; i < deletedEmailList.length; i++) {
        deleteEmailFromDB(deletedEmailList[i]);
      }
    }
    if (emailList.length > 0) {
      setClickAddEmail(false);
      dispatch(addImportEmails(emailList));

    } else {
      setClickAddEmail(false);
    }
  };

  return (
    <>
      <div className={"flex flex-col"}>
        <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
          <div
            className={
              "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            }>
            <div className={"flex flex-row items-center justify-end py-4"}>
              {unitNumbers.length > 0 && (
                <ButtonSimple
                  text={"Save"}
                  btnType={"button"}
                  btnStyle={"primary"}
                  classes={"mx-2"}
                  onClick={onSaveRows}
                />
              )}
              <ButtonSimple
                text={"+ Email"}
                btnType={"button"}
                btnStyle={"secondary"}
                onClick={addTableRows}
              />
            </div>

            <ImportEmailsTable
              clickAddEmail={clickAddEmail}
              handleChange={handleChange}
              currentRecords={currentRecords}
              deleteEmailFromRow={deleteEmailFromRow}
            />

            {unitNumbers.length > 0 && (
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}

            <SupportDetails />
          </div>
        </div>

        <GoToUnitNumbersModal
          showModal={openImportEmailModal}
          setShowModal={setOpenImportEmailModal}
        />
      </div>
    </>
  );
};
