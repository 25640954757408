import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { AnnouncementComposer } from "./AnnouncementComposer";
import { AnnouncementsTabs } from "./AnnouncementsTabs";

export const AnnouncementsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Announcements Clicked!");
  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Announcements</div>
        </Link>
      </div>

      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Designed for longer form communications, such as memos and notices.
        Send to All Residents, individual units, or Unit Groups. Announcements
        will be instantly viewable by residents in the resident app after you
        hit send.
      </h2>

      <Switch>
        <Route exact path={`${path}/create`}>
          <AnnouncementComposer basePath={path} />
        </Route>
        <Route path={path}>
          <div className={"grid grid-cols-12 gap-6 mt-10"}>
            <div className={"col-span-12 "}>
              <AnnouncementsTabs basePath={path} />
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};
