import { sentenceCase } from "change-case";
import React, { useEffect, useRef, useState } from "react";

import { SharedStyles } from "../../themes/FabTheme";

enum Titles {
  PROPERTY_MANAGER = "PROPERTY_MANAGER",
  COUNCIL_MEMBER = "COUNCIL_MEMBER",
  BUILDING_TEAM = "BUILDING_TEAM",
}

function useOutsideAlerter(ref: any, setExpanded: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setExpanded(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setExpanded]);
}

type ManagerTitleCheckBoxSelectProps = {
  roleValue: any;
  setValue: (...args: any[]) => void;
  valueName: string;
};

export const ManagerTitleCheckBoxSelect = (
  props: ManagerTitleCheckBoxSelectProps,
) => {
  const wrapperRef = useRef(null);

  const { roleValue, setValue, valueName } = props;

  const [expanded, setExpanded] = useState(false);
  const [selection, setSelection] = useState(roleValue);

  useOutsideAlerter(wrapperRef, setExpanded);

  const toggleExpanded = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const handleChange = (event: any) => {
    setSelection(event.target.value);
    setValue(valueName, event.target.value, {
      shouldValidate: true,
    });
  };

  return (
    <div ref={wrapperRef}>
      <button
        id={"dropdownRadioHelperButton"}
        data-dropdown-toggle={"dropdownRadioHelper"}
        className={
          "flex flex-row justify-between mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        }
        type={"button"}
        onClick={toggleExpanded}>
        <span>{sentenceCase(selection)}</span>

        <svg
          className={"w-4 h-4 self-end"}
          aria-hidden={"true"}
          fill={"none"}
          stroke={"currentColor"}
          viewBox={"0 0 24 24"}
          xmlns={"http://www.w3.org/2000/svg"}>
          <path
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            strokeWidth={"2"}
            d={"M19 9l-7 7-7-7"}
          />
        </svg>
      </button>

      {expanded && (
        <div
          id={"dropdownRadioHelper"}
          className={
            "w-full z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
          }>
          <ul
            className={"p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"}
            aria-labelledby={"dropdownRadioHelperButton"}>
            <li>
              <div
                className={
                  "flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                }>
                <div className={"flex items-center h-5"}>
                  <input
                    id={"helper-radio-4"}
                    name={"helper-radio"}
                    type={"radio"}
                    value={Titles.COUNCIL_MEMBER}
                    checked={roleValue === Titles.COUNCIL_MEMBER}
                    className={
                      "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className={"ml-2 text-lg"}>
                  <label
                    htmlFor={"helper-radio-4"}
                    className={"font-medium text-gray-900 dark:text-gray-300"}>
                    <div>Council member</div>
                    <p
                      id={"helper-radio-text-4"}
                      className={
                        "text-sm font-normal text-gray-500 dark:text-gray-300"
                      }>
                      On the home owner&apos;s council / board for this
                      property.
                    </p>
                  </label>
                </div>
              </div>
            </li>
            <hr />
            <li>
              <div
                className={
                  "flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                }>
                <div className={"flex items-center h-5"}>
                  <input
                    id={"helper-radio-5"}
                    name={"helper-radio"}
                    type={"radio"}
                    value={Titles.PROPERTY_MANAGER}
                    checked={roleValue === Titles.PROPERTY_MANAGER}
                    className={
                      "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className={"ml-2 text-lg"}>
                  <label
                    htmlFor={"helper-radio-5"}
                    className={"font-medium text-gray-900 dark:text-gray-300"}>
                    <div>Property manager</div>
                    <p
                      id={"helper-radio-text-5"}
                      className={
                        "text-sm font-normal text-gray-500 dark:text-gray-300"
                      }>
                      Works for the property management company that manages
                      this property.
                    </p>
                  </label>
                </div>
              </div>
            </li>
            <hr />
            <li>
              <div
                className={
                  "flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                }>
                <div className={"flex items-center h-5"}>
                  <input
                    id={"helper-radio-6"}
                    name={"helper-radio"}
                    type={"radio"}
                    value={Titles.BUILDING_TEAM}
                    checked={roleValue === Titles.BUILDING_TEAM}
                    className={
                      "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className={"ml-2 text-lg"}>
                  <label
                    htmlFor={"helper-radio-6"}
                    className={"font-medium text-gray-900 dark:text-gray-300"}>
                    <div>Building team</div>
                    <p
                      id={"helper-radio-text-6"}
                      className={
                        "text-sm font-normal text-gray-500 dark:text-gray-300"
                      }>
                      Works onsite at this property (e.g. building manager,
                      concierge, caretaker)
                    </p>
                  </label>
                </div>
              </div>
            </li>
            <hr />
            <li>
              <div
                className={
                  "flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                }>
                <div className={"flex items-center h-5"}>
                  <input
                    id={"helper-radio-6"}
                    name={"helper-radio"}
                    type={"radio"}
                    checked={
                      roleValue !== Titles.BUILDING_TEAM &&
                      roleValue !== Titles.PROPERTY_MANAGER &&
                      roleValue !== Titles.COUNCIL_MEMBER &&
                      roleValue !== ""
                    }
                    className={
                      "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    }
                    onChange={() => {}}
                  />
                </div>
                <div className={"ml-2 text-lg w-full"}>
                  <label
                    htmlFor={"helper-radio-6"}
                    className={"font-medium text-gray-900 dark:text-gray-300"}>
                    <div>Other</div>
                    <input
                      type={"text"}
                      value={
                        roleValue !== Titles.BUILDING_TEAM &&
                        roleValue !== Titles.PROPERTY_MANAGER &&
                        roleValue !== Titles.COUNCIL_MEMBER
                          ? roleValue
                          : ""
                      }
                      placeholder={"What’s your role?"}
                      className={SharedStyles.inputs.input()}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
