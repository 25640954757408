import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import { AppThunk } from "../../app/store";

export type Step2ProfileGetData = {
  profile: ManagersClient.ManagerSignUpDetailsProfileDto;
};

export type Step2ProfilePostData = {
  profile: ManagersClient.ManagerSignUpDetailsProfileDto;
};

export type Step3PropertyGetData = {
  property: ManagersClient.ManagerSignUpDetailsPropertyDetailsDto;
};

export type Step3PropertyPostData = {
  property: ManagersClient.ManagerSignUpDetailsPropertyDetailsDto;
};

export interface SetupStepsState {
  wizardStepStatus:
    | "IDLE"
    | "LOADING_GET_ENTRYPOINT"
    | "SUCCESS_GET_ENTRYPOINT"
    | "ERROR_GET_ENTRYPOINT"
    | "STEP2_LOADING"
    | "STEP2_SUCCESS"
    | "STEP3_LOADING"
    | "STEP3_SUCCESS"
    | "LOADING_POST_ENTRYPOINT"
    | "SUCCESS_POST_ENTRYPOINT"
    | "ERROR_POST_ENTRYPOINT";

  managerEntryPointState?: ManagersClient.ManagerSignUpDetailsDto;
  step2ProfileData?: Step2ProfilePostData;
  step3PropertyData?: Step3PropertyPostData;
  getEntrypointError?: string;
  postEntrypointError?: string;
}

const initialState: SetupStepsState = {
  wizardStepStatus: "IDLE",
};

export const SetupStepsSlice = createSlice({
  name: "SetupSteps",
  initialState,
  reducers: {

    step2Loading: (state) => {
      state.wizardStepStatus = "STEP2_LOADING";
    },
    step2Success: (state, action: PayloadAction<Step2ProfilePostData>) => {
      state.wizardStepStatus = "STEP2_SUCCESS";
      state.step2ProfileData = action.payload;
      state.postEntrypointError = undefined;
    },
    step3Loading: (state) => {
      state.wizardStepStatus = "STEP3_LOADING";
    },
    step3Success: (state, action: PayloadAction<Step3PropertyPostData>) => {
      state.wizardStepStatus = "STEP3_SUCCESS";
      state.step3PropertyData = action.payload;
      state.postEntrypointError = undefined;
    },

    completeSetupStepsLoading: (state) => {
      state.wizardStepStatus = "LOADING_POST_ENTRYPOINT";
    },
    completeSetupStepsSuccess: (state) => {
      state.wizardStepStatus = "SUCCESS_POST_ENTRYPOINT";
    },
    completeSetupStepsFailure: (
      state,
      action: PayloadAction<{ error: string }>,
    ) => {
      state.wizardStepStatus = "ERROR_POST_ENTRYPOINT";
      state.postEntrypointError = action.payload.error;
    },
  },
});

export const {
  step2Loading,
  step2Success,
  step3Loading,
  step3Success,
  completeSetupStepsLoading,
  completeSetupStepsFailure,
  completeSetupStepsSuccess,
} = SetupStepsSlice.actions;

export const setupStepsReducer = SetupStepsSlice.reducer;

export const saveStep2 =
  (step2Data: Step2ProfilePostData): AppThunk =>
  async (dispatch) => {
    dispatch(step2Loading());
    dispatch(step2Success(step2Data));
  };

export const saveStep3 =
  (step3Data: Step3PropertyPostData): AppThunk =>
  async (dispatch) => {
    dispatch(step3Loading());
    dispatch(step3Success(step3Data));
  };

export const completeSetupSteps =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(completeSetupStepsLoading());

      const { step3PropertyData, step2ProfileData } = getState().setupSteps;

      console.log("step2ProfileData", step2ProfileData);
      console.log("step3PropertyData", step3PropertyData);

      if (!step3PropertyData || !step2ProfileData) {
        dispatch(
          completeSetupStepsFailure({
            error: "Missing required step data",
          }),
        );
        return;
      }

      await managersApi.newUserOnboarding({
        managerSignUpDetailsDto: {
          profile: step2ProfileData.profile,
          property: step3PropertyData.property,
        },
      });

      dispatch(completeSetupStepsSuccess());
    } catch (error: any) {
      const errorMessage = await error.json();

      dispatch(
        completeSetupStepsFailure({
          error: errorMessage.message,
        }),
      );
    }
  };
