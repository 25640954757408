import React from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import DashboardHeaderNav from "../Dashboard/DashboardHeaderNav";
import { OrganizationListing } from "./OrganizationListing";

export type Props = {
  basePath: string;
};

export const OrganizationSignInNav: React.FC<Props> = () => {
  const { path } = useRouteMatch();
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const { currentUserInfo } = useAppSelector(
    (state) => state.auth.firebaseAuth,
  );

  if (!currentUserInfo) {
    console.log("history.redirect /signin");
    return <Redirect to={"/signin"} />;
  }

  if (selectedOrg && !selectedProperty) {
    console.log("history.redirect /select-property");
    return <Redirect to={"/select-property"} />;
  }

  return (
    <div className={"flex flex-col min-h-screen bg-white"}>
      <DashboardHeaderNav />

      <div className={"container mx-auto px-6 py-4"}>
        <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
          <Link to={path}>
            <div className={"text-primary"}>Select An Organization</div>
          </Link>
        </div>

        <Switch>
          <Route path={path}>
            <OrganizationListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
