import { dayjsUtils } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { capitalCase } from "change-case";
import React, { FunctionComponent } from "react";
import { upperCaseFirst } from "upper-case-first";

import { LinkSimple } from "../../components/LinkSimple";
import { getAvatarInitialsUrl } from "../../utils/AvatarInitials";

type PendingResidentRowProps = {
  basePath: string;
  dto: ManagersClient.ManagerGetPropertyContactDto;
};

export const PropertyContactRow: FunctionComponent<PendingResidentRowProps> = (
  props,
) => {
  const { basePath, dto } = props;
  const { id, name, email, phoneNumber, createdAt, hours, contactType } = dto;

  const avatarUrl = getAvatarInitialsUrl({ firstName: name, lastName: "" });

  return (
    <tr>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <div className={"flex items-center"}>
          <div className={"w-12 h-12 flex-none image-fit"}>
            <img
              alt={"View Profile"}
              className={"rounded-full"}
              src={avatarUrl}
            />
          </div>
          <div className={"flex flex-col ml-4"}>
            <div className={"text-sm font-medium text-gray-900"}>{name}</div>
            <div className={"text-sm text-gray-500"}>
              {upperCaseFirst(dayjsUtils.formatDate(createdAt))}
            </div>
          </div>
        </div>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <div className={"text-sm text-gray-900"}>{phoneNumber || "--"}</div>
        <div className={"text-sm text-gray-900"}>{email || "--"}</div>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <span
          className={
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          }>
          {capitalCase(contactType)}
        </span>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap text-sm text-gray-500"}>
        {hours || "--"}
      </td>
      <td
        className={
          "px-6 py-4 space-x-4 whitespace-nowrap text-right text-sm font-medium"
        }>
        <div className={"flex justify-end"}>
          <LinkSimple
            text={"View"}
            destination={`${basePath}/${id}`}
            iconPosition={"right"}
          />
        </div>
      </td>
    </tr>
  );
};
