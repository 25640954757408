import "quill/dist/quill.snow.css";

import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi"; // Add css for snow theme
import { truncate } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { upperCaseFirst } from "upper-case-first";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { ContentEditor } from "../../components/forms/ContentEditor";
import { FormLabel } from "../../components/forms/FormLabel";
import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { getSelectionIds } from "../../utils/Helpers";

type ReminderComposerProps = {
  basePath: string;
};

type ReminderComposerFormData = {
  subject: string;
  messagePreviewText: string;
};

const schema = Joi.object<ReminderComposerFormData>({
  subject: SchemaValidation.FieldRequiredString({
    label: "Subject",
    min: 5,
    max: 75,
  }),
  messagePreviewText: SchemaValidation.FieldRequiredString({
    label: "MessagePreview",
    min: 10,
    max: 5000,
  }),
});

export const ReminderComposer: React.FC<ReminderComposerProps> = (props) => {
  const history = useHistory();
  const { basePath } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [messageJson, setMessageJson] = React.useState("");

  const { orgId, propertyId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm<ReminderComposerFormData>({
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  async function onSendNow(data: ReminderComposerFormData) {
    trackEvent("Send Now Button Clicked!");
    const { subject, messagePreviewText } = data;
    setIsLoading(true);
    console.log("ReminderComposer | onSendNow", { data });
    const truncatedMessagePreview = truncate(messagePreviewText, {
      length: 200,
    });
    try {
      await managersApi.postReminder({
        status: ManagersClient.PostReminderStatusEnum.Sent,
        propertyId,
        orgId,
        managerPostReminderReqDto: {
          subject,
          message: messageJson,
          recipientType:
            ManagersClient.ManagerPostReminderReqDtoRecipientTypeEnum.Property,
          messagePreviewText: truncatedMessagePreview,
        },
      });
      setIsLoading(false);
      history.push(basePath);
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"to"} />
            <div className={"mt-3 text-default font-light"}>All Residents</div>
            <div className={"text-sm text-emph-high mt-2"}>
              Reminders are always sent to all residents.
            </div>
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"subject"} />
            <input
              type={"text"}
              {...register("subject")}
              className={SharedStyles.inputs.input()}
            />
            <div className={"text-sm text-emph-high mt-2"}>
              Enter a title for this reminder.
            </div>
            {errors.subject && (
              <div className={"text-error"}>{errors.subject.message}</div>
            )}
          </div>

          <div
            className={"col-span-full mb-20"}
            style={{ height: 200, width: "100%" }}>
            <FormLabel text={"message"} extraClassNames={"mb-1"} />
            <Controller
              control={control}
              name={"messagePreviewText"}
              render={({ field }) => (
                <ContentEditor
                  name={"reminder"}
                  onChange={(quillDeltaJson: string, previewText: string) => {
                    console.log(
                      "ReminderComposer | onChange",
                      JSON.parse(quillDeltaJson),
                      { len: previewText.length },
                    );
                    setMessageJson(quillDeltaJson);
                    field.onChange(previewText);
                  }}
                />
              )}
            />
            {errors.messagePreviewText && (
              <div className={"text-error"}>
                {errors.messagePreviewText.message}
              </div>
            )}
          </div>
        </div>

        <div className={"py-3 space-x-4 mt-2"}>
          <ButtonSimple
            text={upperCaseFirst("send now")}
            btnType={"button"}
            isDisabled={!isValid}
            isSpinning={isLoading}
            onClick={handleSubmit(onSendNow)}
          />
        </div>
      </form>
    </div>
  );
};
