import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { SentAnnouncementCard } from "./SentAnnouncementCard";
import { loadSentAnnouncementsListing } from "./SentAnnouncementsListingSlice";

type SentAnnouncementsListingProps = {
  basePath: string;
};

export const SentAnnouncementsListing: React.FC<
  SentAnnouncementsListingProps
> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const { status, error, sentAnnouncements } = useAppSelector(
    (state) => state.announcements.sentAnnouncements,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    dispatch(loadSentAnnouncementsListing());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, status]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  if (!sentAnnouncements || sentAnnouncements.length < 1) {
    return (
      <EmptyStateImage
        text={"Compose your first announcement now!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  const sentAnnouncementCards = sentAnnouncements.map((item) => {
    return (
      <div
        key={item.id}
        className={"border rounded-md shadow-sm flex flex-col my-2"}>
        <SentAnnouncementCard
          basePath={basePath}
          sentAnnouncement={item}
          showLink
        />
      </div>
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      {sentAnnouncementCards}
    </div>
  );
};
