import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";

export interface SentRemindersListingSliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  error?: string;
  sentReminders?: Array<ManagersClient.ManagerGetReminderResDto>;
  sentRemindersAdapter: EntityState<ManagersClient.ManagerGetReminderResDto>;
}

const sentRemindersAdapter =
  createEntityAdapter<ManagersClient.ManagerGetReminderResDto>({
    selectId: (sentReminder) => sentReminder.id,
    // sortComparer: (a, b) => a.content.t.title.localeCompare(b.title),
  });

const initialState: SentRemindersListingSliceState = {
  status: "IDLE",
  sentRemindersAdapter: sentRemindersAdapter.getInitialState(),
};

export const SentRemindersListingSlice = createSlice({
  name: "sentRemindersListing",
  initialState,
  reducers: {
    getSentRemindersListingStart: (state) => {
      state.status = "LOADING";
    },
    getSentRemindersListingSuccess: (
      state,
      action: PayloadAction<{
        sentReminders: Array<ManagersClient.ManagerGetReminderResDto>;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.sentReminders = action.payload.sentReminders;
      sentRemindersAdapter.setAll(
        state.sentRemindersAdapter,
        action.payload.sentReminders,
      );
    },
    getSentRemindersListingFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
  },
});

export const {
  getSentRemindersListingStart,
  getSentRemindersListingSuccess,
  getSentRemindersListingFailure,
} = SentRemindersListingSlice.actions;

export const sentRemindersListingReducer = SentRemindersListingSlice.reducer;

export const loadSentRemindersListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getSentRemindersListingStart());

      const { orgId, propertyId } = getSelectionIds(getState());

      const foo: ManagersClient.ManagerPageOfGetRemindersResDto =
        await managersApi.getReminders({
          status: ManagersClient.GetRemindersStatusEnum.Sent,
          propertyId,
          orgId,
        });

      dispatch(
        getSentRemindersListingSuccess({
          sentReminders: foo.results,
        }),
      );
    } catch (err: any) {
      dispatch(getSentRemindersListingFailure(prettyRawError(err)));
    }
  };

export const sentRemindersSelector =
  sentRemindersAdapter.getSelectors<RootState>(
    (state) => state.reminders.sentReminders.sentRemindersAdapter,
  );
