import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";

import { getDownloadUrl } from "../../api/managersApi";
import { ImageSimple } from "../../components/image/ImageSimple";

type OrganizationCardProps = {
  organization: ManagersClient.SharedGetOrgResDto;
  onClick: () => void;
};

export const OrganizationCard: React.FC<OrganizationCardProps> = (props) => {
  const { organization, onClick } = props;
  const { name, address, photoPath } = organization;

  return (
    <div>
      <button type={"button"} className={"mx-4 w-64"} onClick={onClick}>
        <div
          className={
            "flex flex-col justify-center items-center border border-border-low rounded-lg py-8 focus:outline-none focus:ring-1 focus:bg-indigo-700 group hover:bg-primary hover:shadow-lg hover:border-transparent"
          }>
          <div className={"w-full h-40 mb-4"}>
            <ImageSimple
              imageUrl={getDownloadUrl(photoPath)}
              altName={"organization image"}
              contain
            />
          </div>
          <div className={"text-lg text-default group-hover:text-white"}>
            {name}
          </div>
        </div>
      </button>
    </div>
  );
};
