import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ManagerPutTemporaryUnitResidentEmailContainer } from "@fabricapp-ca/fabricapp-openapi/generated/managers-ts-fetch/models/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../../api/managersApi";
import type { AppThunk } from "../../../app/store";
import { prettyRawError } from "../../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../../utils/Helpers";
import { getAllUnitNumbersListing } from "../../UnitNumbers/unitNumbersSlice";

export interface ImportEmailsListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  deleteEmailStatus:
    | "IDLE"
    | "DELETE_EMAIL_LOADING"
    | "DELETE_EMAIL_SUCCESS"
    | "DELETE_EMAIL_ERROR";
  deleteEmailError?: string;
  addImportEmailsStatus:
    | "IDLE"
    | "ADD_IMPORT_EMAILS_LOADING"
    | "ADD_IMPORT_EMAILS_SUCCESS"
    | "ADD_IMPORT_EMAILS_ERROR";
  addImportEmailsError?: string;
  importEmails: Array<ManagersClient.ManagerPutTemporaryUnitResidentEmailContainer>;
}

export interface UnitEmail {
  email: string;
  unitId: string;
}

const initialState: ImportEmailsListingSliceState = {
  getAllStatus: "IDLE",
  deleteEmailStatus: "IDLE",
  addImportEmailsStatus: "IDLE",
  importEmails: [],
};

export const ImportEmailsListingSlice = createSlice({
  name: "importEmailsListing",
  initialState,
  reducers: {
    resetImportEmailsStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    getImportEmailsStart: (state) => {
      state.getAllStatus = "GET_ALL_LOADING";
    },
    getImportEmailsSuccess: (
      state,
      action: PayloadAction<{
        importEmails: Array<ManagersClient.ManagerPutTemporaryUnitResidentEmailContainer>;
      }>,
    ) => {
      state.getAllStatus = "GET_ALL_SUCCESS";
      state.importEmails = action.payload.importEmails || [];
      state.getAllError = undefined;
    },
    getImportEmailsFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "GET_ALL_ERROR";
      state.getAllError = action.payload;
    },
    resetDeleteEmailStatus: (state) => {
      state.deleteEmailStatus = "IDLE";
      state.deleteEmailError = undefined;
    },
    deleteEmailStart: (state) => {
      state.deleteEmailStatus = "DELETE_EMAIL_LOADING";
    },
    deleteEmailSuccess: (state) => {
      state.deleteEmailStatus = "DELETE_EMAIL_SUCCESS";
      state.deleteEmailError = undefined;
    },
    deleteEmailFailure: (state, action: PayloadAction<string>) => {
      state.deleteEmailStatus = "DELETE_EMAIL_ERROR";
      state.deleteEmailError = action.payload;
    },

    resetAddImportEmailsStatus: (state) => {
      state.addImportEmailsStatus = "IDLE";
      state.addImportEmailsError = undefined;
    },
    addImportEmailsStatus: (state) => {
      state.addImportEmailsStatus = "IDLE";
      state.addImportEmailsError = undefined;
    },
    addImportEmailsStart: (state) => {
      state.addImportEmailsStatus = "ADD_IMPORT_EMAILS_LOADING";
    },
    addImportEmailsSuccess: (state) => {
      state.addImportEmailsStatus = "ADD_IMPORT_EMAILS_SUCCESS";
      state.addImportEmailsError = undefined;
    },
    addImportEmailsFailure: (state, action: PayloadAction<string>) => {
      state.addImportEmailsStatus = "ADD_IMPORT_EMAILS_ERROR";
      state.addImportEmailsError = action.payload;
    },
  },
});

export const {
  resetImportEmailsStatus,
  getImportEmailsStart,
  getImportEmailsSuccess,
  getImportEmailsFailure,
  resetDeleteEmailStatus,
  deleteEmailStart,
  deleteEmailSuccess,
  deleteEmailFailure,
  resetAddImportEmailsStatus,
  addImportEmailsStatus,
  addImportEmailsSuccess,
  addImportEmailsStart,
  addImportEmailsFailure,
} = ImportEmailsListingSlice.actions;

export const importEmailsListingReducer = ImportEmailsListingSlice.reducer;

export const deleteEmail =
  (email: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteEmailStart());
      const { orgId, propertyId } = getSelectionIds(getState());

      await managersApi.deleteTemporaryResidentEmail({
        orgId,
        propertyId,
        temporaryUnitResidentEmailId: email,
      });
      dispatch(deleteEmailSuccess());
      dispatch(getAllUnitNumbersListing());
    } catch (err: any) {
      const error = await err.json();
      dispatch(deleteEmailFailure(prettyRawError(error.message)));
    }
  };

export const addImportEmails =
  (importEmailsList: Array<UnitEmail>): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(addImportEmailsStart());
      const { orgId, propertyId } = getSelectionIds(getState());

      const importEmailsObject: Array<ManagerPutTemporaryUnitResidentEmailContainer> =
        [];
      importEmailsList.forEach((email) => {
        let unitObj = importEmailsObject.find((o) => o.unitId === email.unitId);
        if (unitObj == null) {
          unitObj = {
            residents: [],
            unitId: email.unitId,
          };
          importEmailsObject.push(unitObj);
        }
        unitObj.residents.push({ ...email, id: null });
      });

      await managersApi.putTemporaryResidentEmails({
        orgId,
        propertyId,
        managerPutTemporaryUnitResidentEmailDto: {
          units: importEmailsObject,
        },
      });
      dispatch(addImportEmailsSuccess());
    } catch (err: any) {
      const error = await err.json();
      dispatch(addImportEmailsFailure(prettyRawError(error.message)));
    }
  };

export const getAllImportEmailsListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getImportEmailsStart());
      const { orgId, propertyId } = getSelectionIds(getState());

      const importEmails = (
        await managersApi.getTemporaryResidentEmails({
          orgId,
          propertyId,
        })
      ).units;
      console.log(importEmails, "importEmails in slice");
      dispatch(
        getImportEmailsSuccess({
          importEmails,
        }),
      );
    } catch (err: any) {
      console.log(err, "err in slice");
      dispatch(getImportEmailsFailure(prettyRawError(err)));
    }
  };
