import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AllUnitGroupsListing } from "./AllUnitGroupsListing";
import { UnitGroupEditor } from "./UnitGroupEditor";

export const AllUnitGroupsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full"}>
        <Switch>
          <Route path={`${path}/:unitGroupId`}>
            <UnitGroupEditor basePath={path} />
          </Route>
          <Route path={`${path}`}>
            <AllUnitGroupsListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
