import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useState } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const UnitSelect = (props: any) => {
  const { unitGroupDirectory, residentDirectory, setValue, getValues } = props;

  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);

  function deleteElement(arr: Array<any>, item: any) {
    const newArr = arr.filter((val: any) => {
      return val !== item.value;
    });

    return newArr;
  }

  function removeItem(item: any) {
    const removedSelection = selectedItems.filter(
      (selected: any) => selected.value !== item.value,
    );

    setSelectedItems(removedSelection);

    const values = getValues();

    item.type === "UNIT"
      ? setValue("recipients", deleteElement(values.recipients, item))
      : setValue("unitGroups", deleteElement(values.unitGroups, item));
  }

  const handleSelection = (e: any) => {
    const values = getValues();

    const currSelectedItems = [...selectedItems];

    const isFound = currSelectedItems.some((element: any) => {
      if (element.value === e.value) {
        return true;
      }
      return false;
    });

    if (isFound === false) {
      currSelectedItems.push(e);

      e.type === "UNIT"
        ? setValue("recipients", [...values.recipients, e.value])
        : setValue("unitGroups", [...values.unitGroups, e.value]);
    } else {
      currSelectedItems.splice(currSelectedItems.indexOf(e), 1);

      e.type === "UNIT"
        ? setValue("recipients", deleteElement(values.recipients, e))
        : setValue("unitGroups", deleteElement(values.unitGroups, e));
    }
    setSelectedItems(currSelectedItems);
  };

  return (
    <div className={"flex items-center justify-start w-full "}>
      <div className={"w-full"}>
        <Listbox value={selectedItems} onChange={handleSelection}>
          {({ open }) => (
            <>
              <Listbox.Label
                className={"block text-sm font-medium text-gray-700"}>
                To
              </Listbox.Label>
              <div className={"relative mt-1"}>
                <span className={"inline-block w-full rounded-md shadow-sm"}>
                  <Listbox.Button
                    className={
                      "cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue " +
                      "focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    }>
                    {!selectedItems.length &&
                      "Select one or more unit group and units"}

                    <div>
                      {selectedItems.map((item: any) => (
                        <div
                          key={item.value}
                          className={
                            "inline-flex items-center px-1 mr-1 mt-1 rounded text-white bg-primary"
                          }>
                          {item.label}
                          <div
                            className={
                              "ml-1 bg-gray-100 rounded-full cursor-pointer"
                            }
                            onClick={() => removeItem(item)}>
                            <svg
                              width={"15"}
                              height={"15"}
                              viewBox={"0 0 20 20"}
                              fill={"none"}
                              xmlns={"http://www.w3.org/2000/svg"}>
                              <path
                                fillRule={"evenodd"}
                                clipRule={"evenodd"}
                                d={
                                  "M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                                }
                                fill={"#4A5568"}
                              />
                            </svg>
                          </div>
                        </div>
                      ))}
                      <span
                        className={
                          "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                        }>
                        <svg
                          className={"h-5 w-5 text-gray-400"}
                          viewBox={"0 0 20 20"}
                          fill={"none"}
                          stroke={"currentColor"}>
                          <path
                            d={"M7 7l3-3 3 3m0 6l-3 3-3-3"}
                            strokeWidth={"1.5"}
                            strokeLinecap={"round"}
                            strokeLinejoin={"round"}
                          />
                        </svg>
                      </span>
                    </div>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  as={Fragment}
                  leave={"transition ease-in duration-100"}
                  leaveFrom={"opacity-100"}
                  leaveTo={"opacity-0"}>
                  <Listbox.Options
                    className={
                      "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    }>
                    <div className={"py-2 font-semibold pl-6"}>UNIT GROUPS</div>
                    <hr />
                    {unitGroupDirectory.map((group: any) => (
                      <Listbox.Option
                        key={group.value}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9",
                          )
                        }
                        value={group}>
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate",
                              )}>
                              {group.label}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                )}>
                                <CheckIcon
                                  className={"h-5 w-5"}
                                  aria-hidden={"true"}
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}

                    <div className={"py-2 font-semibold pl-6"}>UNITS</div>
                    <hr />
                    {residentDirectory.map((unit: any) => (
                      <Listbox.Option
                        key={unit.value}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9",
                          )
                        }
                        value={unit}>
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate",
                              )}>
                              {unit.label}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                )}>
                                <CheckIcon
                                  className={"h-5 w-5"}
                                  aria-hidden={"true"}
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>{" "}
    </div>
  );
};
