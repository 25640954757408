import {
  ManagerGetUnitAttachmentsForProperty, ManagerGetUnitOnlyResDto,
  ManagerGetUnitResDto,
  ParkingDto,
  StorageUnitDto,
} from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useParams } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { UnitProfileComposer } from "./UnitProfileComposer";

type UnitGroupEditorProps = {
  basePath: string;
};

export const UnitProfileEditor: React.FC<UnitGroupEditorProps> = (props) => {
  const { unitId } = useParams<{ unitId: string }>();
  const { basePath } = props;


  const [existingStorageUnits, setExistingStorageUnits] = React.useState<
    StorageUnitDto[]
  >([]);
  const [existingParkingStalls, setExistingParkingStalls] = React.useState<
    ParkingDto[]
  >([]);
  const [unitProfile, setUnitProfile] =
    React.useState<ManagerGetUnitOnlyResDto | null>(null);

  const { selectedOrg, selectedProperty, selectedBuildings } = useAppSelector(
    (state) => state.selectionContext,
  );

  React.useEffect(() => {
    async function getUnitAttachments() {
      const unitAttachments: ManagerGetUnitAttachmentsForProperty =
        await managersApi.getUnitAttachmentsForProperty({
          propertyId: selectedProperty?.id || "",
          orgId: selectedOrg?.id || "",
        });
      setExistingStorageUnits(unitAttachments.storageUnits);
      setExistingParkingStalls(unitAttachments.parkingStalls);
    }
    async function getUnitProfile() {
      const unitProfileRes = await managersApi.getUnitProfile({
        propertyId: selectedProperty?.id || "",
        orgId: selectedOrg?.id || "",
        buildingId: selectedBuildings?.[0].id || "",
        unitId: unitId || "",
      });
      setUnitProfile(unitProfileRes);
    }
    getUnitAttachments();
    getUnitProfile();
  }, []);

  if (!unitProfile) {
    return <LoadingView />;
  }

  return (
    <UnitProfileComposer
      basePath={basePath}
      unitProfile={unitProfile}
      existingStorageUnits={existingStorageUnits}
      existingParkingStalls={existingParkingStalls}
    />
  );
};
