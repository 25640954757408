import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AllUnitNumbersListing } from "./AllUnitNumbersListing";

export const AllUnitNumbersNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full"}>
        <Switch>
          <Route path={`${path}`}>
            <div className={"grid grid-cols-12 gap-6 mt-10"}>
              <div className={"shadow-sm col-span-12 lg:col-span-12"}>
                <AllUnitNumbersListing basePath={path} />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
};
