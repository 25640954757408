import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";

import { getDownloadUrl } from "../../api/managersApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { FabLogo } from "../../components/FabLogo";
import {
  defaultImageByTypeSelector,
  defaultImageIsEmptySelector,
  loadDefaultImagesListing,
} from "./DefaultImageSlice";

type DefaultImageProps = {
  imageType: ManagersClient.SharedDefaultImageResDtoTypeEnum;
};

export const DefaultImage: React.FC<DefaultImageProps> = (props) => {
  const { imageType } = props;
  const dispatch = useAppDispatch();
  const { getAllError } = useAppSelector(
    (state) => state.defaultImages.defaultImagesListing,
  );
  const isDefaultImagesEmpty = defaultImageIsEmptySelector(store.getState());
  const defaultImageByType = defaultImageByTypeSelector(
    store.getState(),
    imageType,
  );

  React.useEffect(() => {
    if (isDefaultImagesEmpty) {
      dispatch(loadDefaultImagesListing());
    }
  }, [dispatch, isDefaultImagesEmpty]);

  const fallbackImage = (
    <div>
      <FabLogo />
    </div>
  );

  if (getAllError || !defaultImageByType) {
    return fallbackImage;
  }

  const downloadPathUrl = getDownloadUrl(defaultImageByType.downloadPath);

  return (
    <img
      // className={"flex object-contain"}
      className={"flex object-cover w-full h-full"}
      src={downloadPathUrl}
      alt={imageType}
    />
  );
};
