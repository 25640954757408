import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import Interweave from "interweave";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import React from "react";
import { BiLike } from "react-icons/bi";
import { BsBuilding } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { getDownloadUrl, managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { LabelPillContainer } from "../../components/pills/LabelPillContainer";
import { StatsCardProps } from "../../components/stats/StatsCard";
import { StatsRow } from "../../components/stats/StatsRow";
import { getSelectionIds } from "../../utils/Helpers";
import { DocumentPdfViewer } from "../Documents/DocumentPdfViewer";
import { SentAnnouncementCard } from "./SentAnnouncementCard";
import { sentAnnouncementsSelector } from "./SentAnnouncementsListingSlice";

export type SentAnnouncementViewerProps = {
  basePath: string;
};

type DeltaOps = Array<Record<string, unknown>>;
type QuillContent = { ops: DeltaOps };

type AnnouncementStats = {
  deliveredCount: number;
  viewedCount: number;
  likedCount: number;
};

export const SentAnnouncementViewer: React.FC<SentAnnouncementViewerProps> = (
  props,
) => {
  const { basePath } = props;
  const { announcementId } = useParams<{ announcementId: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [content, setContent] = React.useState<DeltaOps>([]);
  const [announcementStats, setAnnouncementStats] =
    React.useState<AnnouncementStats>({
      deliveredCount: 0,
      viewedCount: 0,
      likedCount: 0,
    });
  const { orgId, propertyId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  const stats: Array<StatsCardProps> = [
    {
      label: "Delivered to units",
      value: `${announcementStats.deliveredCount}`,
      iconType: BsBuilding,
    },
    {
      label: "Viewed by units",
      value: `${announcementStats.viewedCount}`,
      iconType: FiUsers,
    },
    {
      label: "Liked by residents",
      value: `${announcementStats.likedCount}`,
      iconType: BiLike,
    },
  ];

  React.useEffect(() => {
    async function getAnnouncementById() {
      try {
        const announcement = await managersApi.getAnnouncementById({
          orgId,
          propertyId,
          status: ManagersClient.GetAnnouncementByIdStatusEnum.Sent,
          announcementId,
        });
        const msg = JSON.parse(announcement.message) as QuillContent;
        setContent(msg.ops);
        setAnnouncementStats({
          deliveredCount: announcement.deliveredCount,
          viewedCount: announcement.viewedCount,
          likedCount: announcement.likedCount,
        });
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    }
    getAnnouncementById();
  }, [announcementId]);

  const sentAnnouncement = sentAnnouncementsSelector.selectById(
    store.getState(),
    announcementId,
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (!sentAnnouncement) {
    return (
      <ErrorView message={`Missing sent announcement ${announcementId}`} />
    );
  }

  const cfg = {};
  const converter = new QuillDeltaToHtmlConverter(content, cfg);
  const source = { html: converter.convert() };
  console.log("SentAnnouncementsView | SentAnnouncementViewer", {
    foo: JSON.stringify(content),
  });

  return (
    <>
      <StatsRow stats={stats} />
      <div
        className={"flex flex-col px-6 py-6 my-2 border rounded-md shadow-sm"}>
        <SentAnnouncementCard
          basePath={basePath}
          sentAnnouncement={sentAnnouncement}
          showLink={false}
        />
        <div className={"py-4 px-4"}>
          <Interweave content={source.html} />
        </div>

        <div className={"text-sm  py-4 px-2 border-t-2"}>
          <LabelPillContainer
            title={"Sent to"}
            labels={[
              ...sentAnnouncement.selectedUnits,
              ...sentAnnouncement.selectedUnitGroups,
            ]}
          />
        </div>
        {sentAnnouncement.document && (
          <div className={"border-t-2 py-4"}>
            <DocumentPdfViewer
              downloadPath={getDownloadUrl(sentAnnouncement.document)}
              title={"Attachment"}
              id={sentAnnouncement.document}
              deletable={false}
            />
          </div>
        )}
      </div>
    </>
  );
};
