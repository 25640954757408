import { LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import { Menu, Transition } from "@headlessui/react";
import { capitalCase } from "change-case";
import React, { Fragment } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAvatarOrPhotoUrl } from "../../utils/AvatarInitials";
import { firebaseEmailSignOut } from "../Auth/FirebaseSlice";

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

export const ProfileDropDown = (props: any) => {
  const { selectedOrgRole } = props;

  const dispatch = useAppDispatch();

  let { profile, getProfileStatus } = useAppSelector(
    (state) => state.profileInfo,
  );

  if (getProfileStatus === "PROFILE_LOADING") {
    return <LoadingView />;
  }

  if (!profile) {
    profile = {
      createdAt: "",
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      photoPath: "",
    };
  }

  const dropDowns = [
    {
      label: `${profile.firstName} ${profile.lastName}`,
      disabled: true,
    },
    {
      label: `${profile.email}`,
      disabled: true,
    },
    {
      label: selectedOrgRole?.managerTitle
        ? `Title: ${capitalCase(selectedOrgRole?.managerTitle)}`
        : null,
      disabled: true,
    },
    {
      label: "Sign out",
      onClick: () => {
        dispatch(firebaseEmailSignOut());
      },
    },
  ];

  const avatarUrl = getAvatarOrPhotoUrl({
    firstName: profile.firstName,
    lastName: profile.lastName,
    photoPath: profile.photoPath,
  });

  return (
    <Menu as={"div"} className={"ml-3 relative"}>
      {({ open: profileOpen }) => (
        <>
          <div>
            <Menu.Button
              className={
                "max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none " +
                "focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary focus:ring-white"
              }>
              <span className={"sr-only"}>Open user menu</span>
              <img
                className={"h-8 w-8 rounded-full"}
                src={avatarUrl}
                alt={""}
              />
            </Menu.Button>
          </div>
          <Transition
            show={profileOpen}
            as={Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={"transform opacity-0 scale-95"}
            enterTo={"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={"transform opacity-100 scale-100"}
            leaveTo={"transform opacity-0 scale-95"}>
            <Menu.Items
              static
              className={
                "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              }>
              {dropDowns.map(
                (item) =>
                  item.label && (
                    <Menu.Item key={item.label} disabled={item.disabled}>
                      {({ active }) => (
                        <button
                          type={"button"}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm w-full text-left",
                            item.disabled ? "text-gray-300" : "text-gray-700",
                          )}
                          onClick={item.onClick}>
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  ),
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
