import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { getAuthHeaders, getDownloadUrl } from "../../api/managersApi";
import { useAppDispatch } from "../../app/hooks";
import { Pagination } from "../../components/navigation/Pagination";
import { MaybeT } from "../../utils/SimpleTypes";
import { deleteDocumentById } from "./DocumentsListingSlice";

type DocumentPdfViewerProps = {
  downloadPath: string;
  title: string;
  id: string;
  deletable: boolean;
};

export const DocumentPdfViewer: React.FC<DocumentPdfViewerProps> = (props) => {
  const { title, downloadPath, id, deletable } = props;
  const downloadUrl = getDownloadUrl(downloadPath);
  const [totalNumPages, setTotalNumPages] = React.useState<MaybeT<number>>();
  const [PDFBlob, setPDFBlob] = React.useState(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [httpHeaders, setHttpHeaders] = React.useState<any>(null);
  const dispatch = useAppDispatch();

  console.log("loading doc");

  React.useEffect(() => {
    if (downloadUrl && httpHeaders) {
      const controller = new AbortController();

      fetch(downloadUrl, { signal: controller.signal, headers: httpHeaders })
        .then((res) => res.blob())
        .then((file) => setPDFBlob(file as any))
        .catch((e) => {
          if (e.name !== "AbortError") {
            /* handle error*/
          }
        });

      return () => controller.abort();
    }
    return () => {};
  }, [downloadUrl, setPDFBlob, httpHeaders]);

  React.useEffect(() => {
    if (downloadUrl && httpHeaders == null) {
      console.log(
        "DocumentGroups | useEffect - setting current page to 1",
        downloadUrl,
      );
      setCurrentPageNumber(1);
      getHeaders();
    }
  }, [downloadUrl, httpHeaders]);

  function onDocumentLoadSuccess({
    numPages: numOfPages,
  }: {
    numPages: number;
  }) {
    setTotalNumPages(numOfPages);
    console.log("DocumentGroups | onDocumentLoadSuccess", { numOfPages });
  }

  const getHeaders = async () => {
    const headers = await getAuthHeaders();
    console.log("DocumentGroups | setting headers", { headers });
    setHttpHeaders(headers);
  };

  const deleteDocument = () => {
    dispatch(deleteDocumentById(id));
  };

  return downloadUrl != null && httpHeaders != null ? (
    <div className={"flex flex-col text-center w-full"}>
      <div className={"w-full flex lg:flex-row flex-col justify-center"}>
        <div
          className={
            "text-heading text-2xl col-span-4 text-center font-semibold self-center"
          }>
          {title}
        </div>
        {deletable && (
          <div
            className={"flex flex-row justify-center items-center ml-5 py-4"}>
            <ButtonSimple
              text={"Delete"}
              btnType={"button"}
              btnStyle={"secondary"}
              btnIntent={"error"}
              onClick={deleteDocument}
            />
          </div>
        )}
      </div>
      <Document
        className={
          "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 flex justify-center"
        }
        file={PDFBlob}
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={currentPageNumber} />
      </Document>

      <Pagination
        totalCount={totalNumPages}
        initialPage={currentPageNumber}
        onPageChange={setCurrentPageNumber}
      />
    </div>
  ) : null;
};
