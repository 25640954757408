import React, { FunctionComponent } from "react";

import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";

type UnitGroupRowProps = {
  index: number;
  name: string;
  floor: string;
  deleteEditRow(index: number): void;
  handleChange(index: number, evnt: any): void;
};

export const UnitNumberInputRow: FunctionComponent<UnitGroupRowProps> = (
  props,
) => {
  const { index, name, floor, deleteEditRow, handleChange } = props;

  return (
    <tr key={index as any}>
      <td className={"p-3"}>
        <input
          type={"text"}
          value={name}
          onChange={(evnt) => handleChange(index, evnt)}
          name={"name"}
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}
          onClick={() => {
            trackEvent("Unit Number Field Clicked!");
          }}
        />
      </td>
      <td className={"p-3"}>
        <input
          type={"number"}
          value={floor}
          onChange={(evnt) => handleChange(index, evnt)}
          name={"floor"}
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}
          onClick={() => {
            trackEvent("Floor Number Field Clicked!");
          }}
        />
      </td>
      <td>
        <button
          onClick={() => {
            deleteEditRow(index);
          }}>
          <svg
            xmlns={"http://www.w3.org/2000/svg"}
            fill={"none"}
            viewBox={"0 0 24 24"}
            strokeWidth={1.5}
            stroke={"currentColor"}
            className={"w-6 h-6"}>
            <path
              strokeLinecap={"round"}
              strokeLinejoin={"round"}
              d={"M6 18L18 6M6 6l12 12"}
            />
          </svg>
        </button>
      </td>
    </tr>
  );
};
