import React from "react";

type Props = { imageUrl?: string; altName: string; contain?: boolean };

export const ImageSimple: React.FC<Props> = (props) => {
  const defaultBuildingImageUrl =
    process.env.REACT_APP_DEFAULT_BUILDING_IMAGE_URL;
  // typeValidations.assertIsString(defaultBuildingImageUrl);
  const { imageUrl = defaultBuildingImageUrl, altName, contain } = props;

  let classes = "inline-block w-full h-full rounded-md ";
  if (contain) {
    classes += "object-contain";
  } else {
    classes += "object-cover";
  }
  return <img className={classes} src={imageUrl} alt={altName} />;
};
