import React from "react";
import { useParams } from "react-router-dom";

import { store } from "../../app/store";
import { PropertyContactComposer } from "./PropertyContactComposer";
import { propertyContactByIdSelector } from "./propertyContactsSlice";

type PropertyContactEditorProps = {
  basePath: string;
};

export const PropertyContactEditor: React.FC<PropertyContactEditorProps> = (
  props,
) => {
  const { contactId } = useParams<{ contactId: string }>();
  const { basePath } = props;

  const existingDto = propertyContactByIdSelector(store.getState(), contactId);

  return (
    <PropertyContactComposer basePath={basePath} existingDto={existingDto} />
  );
};
