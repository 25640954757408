import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { PostCreatePerkTypeEnum } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { ImageUploadSimple } from "../../components/image/ImageUploadSimple";
import { SharedStyles } from "../../themes/FabTheme";
import { getSelectionIds } from "../../utils/Helpers";

type Props = {
  basePath: string;
};

type FormData = {
  brandName: string;
  phoneNumber: string;
  title: string;
  description: string;
  email?: string;
  photoPath?: string;
  // attachmentId?: string;
};

const schema = Joi.object<FormData>({
  brandName: SchemaValidation.FieldRequiredString({ label: "Brand name" }),
  title: SchemaValidation.FieldRequiredString({ label: "Perk Title", min: 10 }),
  description: SchemaValidation.FieldRequiredString({
    label: "Perk Description",
    min: 20,
    max: 1000,
  }),
  phoneNumber: SchemaValidation.FieldRequiredString({
    label: "Contact phone number",
  }),
  email: SchemaValidation.FieldOptionalString({
    label: "Email",
  }),
  photoPath: SchemaValidation.FieldOptionalString({ label: "Photo", max: 200 }),
});

export const FabricAppPerkComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath } = props;
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
  } = useForm<FormData>({
    // defaultValues: { photoPath: props.data.photoPath },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const { orgId, propertyId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  async function onSubmit(data: FormData) {
    console.log("FabAddPerk: data", {
      data,
    });

    setIsLoading(true);
    try {
      await managersApi.postCreatePerk({
        type: PostCreatePerkTypeEnum.Fabricapp,
        orgId,
        propertyId,
        managerPostPerkReqDto: {
          title: data.title,
          description: data.description,
          brand: {
            name: data.brandName,
            phoneNumber: data.phoneNumber,
            email: data.email || undefined,
            photoPath: data.photoPath,
          },
        },
      });
      history.push(basePath);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        Add New FabricApp Perk
      </div>
      <ErrorView
        message={
          "Note: This is temporary for testing. Fab perks should only be added from the fab admin console."
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Brand Name"} htmlForId={"brandName"} />
            <input
              type={"text"}
              {...register("brandName")}
              className={SharedStyles.inputs.input()}
            />
            {errors.brandName && (
              <div className={"text-error"}>{errors.brandName.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"title"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("title")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.title && (
              <div className={"text-error"}>{errors.title.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"description"} />
            <div className={"mt-1"}>
              <textarea
                rows={5}
                {...register("description")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.description && (
              <div className={"text-error"}>{errors.description.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Phone Number"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("phoneNumber")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.phoneNumber && (
              <div className={"text-error"}>{errors.phoneNumber.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Email (optional)"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("email")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.email && (
              <div className={"text-error"}>{errors.email.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Perk Photo"} />
            <div className={"mt-1 flex justify-start"}>
              <Controller
                control={control}
                name={"photoPath"}
                render={({ field }) => (
                  <ImageUploadSimple
                    onChange={field.onChange}
                    labelText={"Upload perk photo"}
                    uploadDirName={"amenity"}
                    existingImagePath={field.value}
                    defaultImageType={
                      ManagersClient.SharedDefaultImageResDtoTypeEnum
                        .FeaturePerks
                    }
                    uploadType={
                      ManagersClient.UploadPhotoUploadTypeEnum.PerkPhoto
                    }
                  />
                )}
              />
            </div>
            {errors.photoPath && (
              <div className={"text-error"}>{errors.photoPath.message}</div>
            )}
          </div>
        </div>

        <div className={"py-3 mt-2"}>
          <ButtonSimple
            text={"Add Perk"}
            btnType={"submit"}
            btnStyle={"primary"}
            isDisabled={!isValid}
            isSpinning={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
