import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { PropertyComposer } from "./PropertyComposer";
import { PropertyListing } from "./PropertyListing";

export type Props = {
  basePath: string;
};

export const PropertyNav: React.FC<Props> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const addPropertyPath = `${path}/add`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>My Properties</div>
        </Link>
      </div>

      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add New Property"}
          btnType={"button"}
          btnStyle={"secondary"}
          onClick={() => history.push(addPropertyPath)}
        />
      </div>

      <Switch>
        <Route path={addPropertyPath}>
          <PropertyComposer basePath={path} />
        </Route>
        <Route path={path}>
          <PropertyListing basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
