import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineContacts, AiOutlineGroup } from "react-icons/ai";
import { FiMonitor, MdOutlinePersonAdd } from "react-icons/all";
import { FiHash, FiUsers } from "react-icons/fi";
import { GiSpeaker } from "react-icons/gi";
import { GrUserAdmin } from "react-icons/gr";
import {
  HiMenu,
  HiOutlineClock,
  HiOutlineDocumentText,
  HiOutlineSwitchHorizontal,
} from "react-icons/hi";
import { useHistory } from "react-router-dom";

export default function DashboardTopNav(props: any) {
  const { isMultiOrgUser } = props;

  const history = useHistory();

  const dropDowns = [
    {
      label: "Switch Organizations",
      onClick: () => {
        history.push("/dashboard/organizations");
      },
      icon: <HiOutlineSwitchHorizontal size={15} />,
      disabled: !isMultiOrgUser,
    },
    {
      label: "Switch Properties",
      onClick: () => {
        history.push("/dashboard/properties");
      },
      icon: <HiOutlineSwitchHorizontal size={15} />,
    },
    {
      label: "Dashboard",
      onClick: () => {
        history.push("/dashboard");
      },
      icon: <FiMonitor size={15} />,
    },
    {
      label: "Manager Directory",
      onClick: () => {
        history.push("/dashboard/manager-directory");
      },
      icon: <GrUserAdmin size={15} />,
    },
    {
      label: "Resident Directory",
      onClick: () => {
        history.push("/dashboard/resident-directory");
      },
      icon: <FiUsers size={15} />,
    },
    {
      label: "Reminders",
      onClick: () => {
        history.push("/dashboard/reminders");
      },
      icon: <HiOutlineClock size={15} />,
    },
    {
      label: "Announcements",
      onClick: () => {
        history.push("/dashboard/announcements");
      },
      icon: <GiSpeaker size={15} />,
    },
    {
      label: "Documents",
      onClick: () => {
        history.push("/dashboard/documents");
      },
      icon: <HiOutlineDocumentText size={15} />,
    },
    {
      label: "Contacts",
      onClick: () => {
        history.push("/dashboard/contacts");
      },
      icon: <AiOutlineContacts size={15} />,
    },
    {
      label: "Unit Groups",
      onClick: () => {
        history.push("/dashboard/unit-groups");
      },
      icon: <AiOutlineGroup size={15} />,
    },
    {
      label: "Unit Numbers",
      onClick: () => {
        history.push("/dashboard/unit-numbers");
      },
      icon: <FiHash size={15} />,
    },
    {
      label: "Invite Residents",
      onClick: () => {
        history.push("/dashboard/invite-residents");
      },
      icon: <MdOutlinePersonAdd size={15} />,
    },
  ];

  return (
    <Menu as={"div"} className={"ml-3 relative"}>
      {({ open: profileOpen }) => (
        <>
          <div>
            <Menu.Button>
              <HiMenu className={"block h-10 w-6"} aria-hidden={"true"} />
            </Menu.Button>
          </div>
          <Transition
            show={profileOpen}
            as={Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={"transform opacity-0 scale-95"}
            enterTo={"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={"transform opacity-100 scale-100"}
            leaveTo={"transform opacity-0 scale-95"}>
            <Menu.Items
              static
              className={
                "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              }>
              {dropDowns.map(
                (item) =>
                  item.label && (
                    <Menu.Item key={item.label} disabled={item.disabled}>
                      {({ active }) => (
                        <button
                          type={"button"}
                          className={
                            item.disabled
                              ? "flex flex-row justify-start text-gray-300 block px-4 py-2 text-sm w-full text-left"
                              : "flex flex-row justify-start text-gray-700 block px-4 py-2 text-sm w-full text-left"
                          }
                          onClick={item.onClick}>
                          <span>{item.icon}</span>
                          <span>&nbsp;{item.label}</span>
                        </button>
                      )}
                    </Menu.Item>
                  ),
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
