import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { updateSelectedOrg } from "../SelectionContext/SelectionContextSlice";
import { OrganizationCard } from "./OrganizationCard";
import {
  loadOrganizationListing,
  organizationsSelector,
  resetGetAllStatus,
} from "./OrganizationListingSlice";
import {loadProfileInfoListingSilently} from "../Profile/ProfileInfoSlice";
import {trackEvent} from "../../utils/Amplitude";

export type Props = {
  basePath: string;
};

export const OrganizationListing: React.FC<Props> = (props) => {
  const { basePath } = props;
  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.organizations.organizationListing,
  );
  const organizations = organizationsSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    dispatch(loadProfileInfoListingSilently());
  }, []);

  useEffect(() => {
    dispatch(loadOrganizationListing());
  }, [dispatch]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(resetGetAllStatus());
    }
  }, [dispatch, isLoading]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "ALL_LOADING":
        setIsLoading(true);
        break;
      case "ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!organizations || organizations.length < 1) {
    return (
      <EmptyStateImage
        text={
          "No organizations yet! Contact FabricApp team to create one for you."
        }
        imageType={ManagersClient.SharedDefaultImageResDtoTypeEnum.Organization}
      />
    );
  }

  const organizationCards = organizations.map((item) => {
    return (
      <OrganizationCard
        key={item.id}
        organization={item}
        onClick={() => {
          trackEvent("Organization Card Clicked!");
          console.log("OrganizationListing | selected organization", {
            basePath,
            id: item.id,
          });
          dispatch(
            updateSelectedOrg({
              selectedOrg: item,
              totalOrgs: organizations.length,
            }),
          );
        }}
      />
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      <div className={"flex flex-row"}>{organizationCards}</div>
    </div>
  );
};
