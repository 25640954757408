import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { updateSelectedBuildings } from "../SelectionContext/SelectionContextSlice";
import { BuildingCard } from "./BuildingCard";
import {
  buildingsSelector,
  getAllBuildingsListing,
  resetGetAllBuildingsStatus,
} from "./BuildingsListingSlice";
import {trackEvent} from "../../utils/Amplitude";

export type Props = {
  basePath: string;
};

export const BuildingListing: React.FC<Props> = (props) => {
  const { basePath } = props;
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.buildings.buildingsListing,
  );
  const buildings = buildingsSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (selectedProperty && selectedOrg) {
      dispatch(
        getAllBuildingsListing({
          propertyId: selectedProperty.id,
          orgId: selectedOrg.id,
        }),
      );
    }
    return () => {
      dispatch(resetGetAllBuildingsStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!buildings || buildings.length < 1) {
    return (
      <EmptyStateImage
        text={
          "No buildings yet! If you are an admin, you can create a new building."
        }
        imageType={ManagersClient.SharedDefaultImageResDtoTypeEnum.Property}
      />
    );
  }

  const buildingCards = buildings.map((item) => {
    return (
      <BuildingCard
        key={item.id}
        building={item}
        onClick={() => {
          trackEvent("Property Card Clicked!");
          console.log("BuildingListing | selected building", {
            basePath,
            id: item.id,
          });
          dispatch(updateSelectedBuildings({ selectedBuildings: [item] }));
        }}
      />
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      <div className={"flex flex-row"}>{buildingCards}</div>
    </div>
  );
};
