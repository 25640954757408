import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import Congrats from "../../assets/images/Congrats.png";

export const FinishSetup = (props: any) => {
  const { basePath } = props;

  const history = useHistory();
  const location: any = useLocation();
  console.log(location, "location");

  return (
    <div className={"flex flex-col"}>
      <div className={"mt-8 w-1/2 h-1/2 self-center"}>
        <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Nice work{" "}
          {/*<span className={"font-medium text-indigo-600 hover:text-indigo-500"}>*/}
          {/*  {location?.state?.username}*/}
          {/*</span>*/}
          {/*!*/}
        </h2>

        <div className={"flex justify-center"}>
          <img
            alt={"User Congrats"}
            className={"px-2 py-2 w-1/2 h-1/2"}
            src={Congrats}
          />
        </div>

        <h2 className={"mt-8 text-center text-lg font-medium text-emph-high"}>
          You’ve just launched an awesome resident app for your property. What a
          productive day! Now, let’s invite residents to the app.
        </h2>
      </div>

      <div className={"flex justify-center mt-5"}>
        <ButtonSimple
          text={"Invite Residents"}
          btnType={"submit"}
          btnStyle={"primary"}
          onClick={() => {
            history.push("/dashboard/invite-residents");
          }}
        />
      </div>
    </div>
  );
};
