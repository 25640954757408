import { dayjsUtils } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React, { FunctionComponent } from "react";
import { upperCaseFirst } from "upper-case-first";

import { LinkSimple } from "../../components/LinkSimple";

type UnitGroupRowProps = {
  basePath: string;
  dto: ManagersClient.ManagerUnitGroupResDto;
};

export const UnitGroupRow: FunctionComponent<UnitGroupRowProps> = (props) => {
  const { basePath, dto } = props;
  const { id, name, createdAt } = dto;

  return (
    <tr>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <div className={"flex items-center"}>
          <div className={"flex flex-col ml-4"}>
            <div className={"text-sm font-medium text-gray-900"}>{name}</div>
          </div>
        </div>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <div className={"text-sm text-gray-500"}>
          {upperCaseFirst(dayjsUtils.formatDate(createdAt))}
        </div>
      </td>
      <td
        className={
          "px-6 py-4 space-x-4 whitespace-nowrap text-right text-sm font-medium"
        }>
        <div className={"flex justify-end"}>
          <LinkSimple
            text={"View"}
            destination={`${basePath}/${id}`}
            iconPosition={"right"}
          />
        </div>
      </td>
    </tr>
  );
};
