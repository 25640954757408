import {
  BookingSvg,
  ButtonSimple,
  ErrorView,
  LoadingView,
} from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddAmenityEditor } from "./AddAmenityEditor";
import { AddAmenityWizard } from "./AddAmenityWizard";
import {
  addAmenitySetExistingAmenityToEdit,
  loadAmenitiesListing, resetAddAmenityState,
} from "./AmenitiesSlice";

type Props = { basePath: string };

const AmenitiesListing: React.FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { status, error, amenities } = useAppSelector(
    (state) => state.amenities.amenities,
  );
  const { basePath } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    dispatch(loadAmenitiesListing());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, status]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"text-3xl font-medium text-heading pb-4 mb-4"}>
        Amenities
      </div>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add amenity"}
          btnType={"button"}
          btnStyle={"secondary"}
          btnIntent={"action"}
          onClick={() => {
            dispatch(resetAddAmenityState());
            history.push(`${basePath}/add`);
          }}
        />
      </div>

      <div className={"flex flex-row flex-wrap space-y-2"}>
        {amenities &&
          amenities.map((item) => (
            <button
              type={"button"}
              className={"mx-4 w-64"}
              onClick={() => {
                dispatch(addAmenitySetExistingAmenityToEdit(item));
                history.push(`${basePath}/edit`);
              }}>
              <div
                className={
                  "flex flex-col h-40 justify-center items-center border border-border-low rounded-lg px-8 py-8 focus:outline-none focus:ring-1 focus:bg-indigo-700 group hover:bg-primary hover:shadow-lg hover:border-transparent"
                }>
                <div className={"w-6 h-6 mb-4"}>
                  <BookingSvg />
                </div>
                <div className={"text-lg text-default group-hover:text-white"}>
                  {item.name}
                </div>
                <div
                  className={"text-sm text-emph-low group-hover:text-gray-100"}>
                  {item.description}
                </div>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

const AmenitiesNav: React.FunctionComponent<Props> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AmenitiesListing basePath={path} />
      </Route>
      <Route path={`${path}/add`}>
        <AddAmenityWizard basePath={path} />
      </Route>
      <Route path={`${path}/edit`}>
        <AddAmenityEditor basePath={path} />
      </Route>
    </Switch>
  );
};

export default AmenitiesNav;
