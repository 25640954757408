import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";

import QR from "../../assets/images/QR.png";
import { trackEvent } from "../../utils/Amplitude";

const PrintQRCode = () => {
  const printContents: any =
    document.getElementById("printable-div")?.innerHTML;
  const originalContents: any = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
};

type InviteResidentQrProps = {
  propertyCode: string;
};

export const InviteResidentsSteps: React.FC<InviteResidentQrProps> = (
  props,
) => {
  const { propertyCode } = props;
  return (
    <>
      <ol className={"list-decimal px-6 w-full lg:w-2/5"}>
        <li
          className={
            "text-left text-md font-sans text-primary mb-4 mt-4 pb-6 "
          }>
          <span className={"text-emph-high"}>
            Copy the invite link into an email to all residents. You can also
            send this link as a text message. Remember to include the property
            code in your email or text.
          </span>

          <div className={"flex flex-row items-center justify-start py-4"}>
            <ButtonSimple
              text={"Copy invite link"}
              btnType={"button"}
              btnStyle={"secondary"}
              onClick={() => {
                trackEvent("Copy Invite Link Clicked!");
                navigator.clipboard.writeText(
                  "https://fabricappca.page.link/app",
                );
              }}
            />
          </div>
        </li>
        <li className={"text-left text-md font-sans text-primary mb-4 mt-4"}>
          <span className={"text-emph-high"}>
            Print the QR code below and post it in the elevator(s) and other
            high traffic areas on your property{" "}
          </span>
          <div className={"mt-10"}>
            <div className={"w-64 h-64 border"}>
              <img alt={"App QR code"} src={QR} />
            </div>

            <ButtonSimple
              text={"Print"}
              btnType={"button"}
              btnStyle={"primary"}
              classes={"mt-4"}
              onClick={() => {
                trackEvent("Print Button Clicked!");
                PrintQRCode();
              }}
            />
          </div>
        </li>
      </ol>

      <div
        id={"printable-div"}
        className={"invisible flex flex-col items-center"}>
        <div className={"w-full flex justify-center "}>
          <img alt={"App QR code"} className={"w-64 h-64 border"} src={QR} />
        </div>

        <p className={"py-4 text-center"}>
          Your building is using FabricApp for resident communications.
          <br />
          Scan and download FabricApp here.
        </p>
        <p className={"text-center py-4 text-2xl"}>
          Use the property code <b>{propertyCode}</b> while signing up.
        </p>
      </div>
    </>
  );
};
