import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory } from "react-router-dom";

export function GoToUnitNumbersModal(props: any) {
  const { showModal, setShowModal } = props;

  const history = useHistory();

  return (
    <>
      {showModal ? (
        <>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            }>
            <div className={"relative w-1/4 my-6 mx-auto max-w-3xl w-2/3"}>
              {/*content*/}
              <div
                className={
                  "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10"
                }>
                <div
                  className={
                    "flex items-center text-center justify-center p-5 rounded-t"
                  }>
                  <h3 className={"text-3xl font-semibold w-full"}>
                    Not so fast!
                  </h3>
                  <button
                    className={
                      "p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    }
                    onClick={() => setShowModal(false)}>
                    <span
                      className={
                        "bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                      }>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className={"mb-5"}>
                  <span
                    className={
                      "text-emph-high text-center mb-1 text-default block"
                    }>
                    You need to enter the Unit Numbers for your property before
                    you can add email addresses.
                  </span>
                </div>

                {/*footer*/}
                <div
                  className={
                    "flex items-center justify-center  rounded-b space-x-4"
                  }>
                  <ButtonSimple
                    text={"Go to Unit Numbers"}
                    btnType={"submit"}
                    btnStyle={"primary"}
                    onClick={() => {
                      setShowModal(false);
                      history.push("/dashboard/unit-numbers");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"opacity-25 fixed inset-0 z-40 bg-black"} />
        </>
      ) : null}
    </>
  );
}
