import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ManagerPostUnitReqDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import {
  ButtonSimple,
  ErrorView,
  LoadingView,
} from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect, useState } from "react";
import {Redirect, useHistory} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import Pagination from "./Paginator";
import { SupportDetails } from "./SupportDetails";
import { TotalUnitsBox } from "./TotalUnitsBox";
import { UnitNumberDeleteModal } from "./UnitNumberDeleteModal";
import {
  addUnitNumbers,
  getAllUnitNumbersListing,
  resetUnitNumbersStatus,
} from "./unitNumbersSlice";
import { UnitNumbersTable } from "./UnitNumbersTable";
import {getAccountSetupStepsInfo} from "../SetupAccount/SetupAccountSlice";
import {trackEvent} from "../../utils/Amplitude";

type AllUnitNumbersListingProps = {
  basePath: string;
};

export const AllUnitNumbersListing: FunctionComponent<
  AllUnitNumbersListingProps
> = (props) => {
  const { selectedOrg, selectedProperty, selectedBuildings } = useAppSelector(
    (state) => state.selectionContext,
  );

  const {
    getAllStatus,
    getAllError,
    addUnitNumbersStatus,
    addUnitNumbersError,
    unitNumbers,
  } = useAppSelector((state) => state.unitNumbers.unitNumbersListing);

  const [rowsData, setRowsData] = useState<Array<ManagerPostUnitReqDto>>([]);
  const [allRows, setAllRows] = useState<Array<ManagerPostUnitReqDto>>([
    ...rowsData,
    ...(unitNumbers || []),
  ]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState(50);

  const indexOfLastRecord: number = currentPage * recordsPerPage;
  const indexOfFirstRecord: number = indexOfLastRecord - recordsPerPage;
  const currentRecords = allRows.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages: number = Math.ceil(allRows.length / recordsPerPage);

  const totalNumOfUnits: number = selectedBuildings
    ? selectedBuildings[0].numOfUnits
    : 0;

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(getAllUnitNumbersListing());
    }
    return () => {
      dispatch(resetUnitNumbersStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setAllRows(unitNumbers || []);
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  React.useEffect(() => {
    switch (addUnitNumbersStatus) {
      case "ADD_UNIT_NUMBERS_LOADING":
        setIsLoading(true);
        break;
      case "ADD_UNIT_NUMBERS_SUCCESS":
        // setIsLoading(false);
        dispatch(getAllUnitNumbersListing());
        if (history.location.pathname.includes("/setup-account")) {
          dispatch(getAccountSetupStepsInfo());
        }
        break;
      case "ADD_UNIT_NUMBERS_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [addUnitNumbersStatus]);

  if (!selectedOrg || !selectedProperty) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (addUnitNumbersError) {
    return <ErrorView message={addUnitNumbersError} />;
  }

  const addTableRows = () => {
    trackEvent("+ Unit Button Clicked!");
    const rowsInput = {
      name: "",
      floor: 0,
    } as unknown as ManagerPostUnitReqDto;
    const newRowData = [rowsInput, ...rowsData];
    setRowsData(newRowData);
  };

  if ((!unitNumbers || unitNumbers.length < 1) && rowsData.length === 0) {
    return (
      <>
        <div className={"flex flex-row items-center justify-end py-4"}>
          <ButtonSimple
            text={"+ Unit"}
            btnType={"button"}
            btnStyle={"secondary"}
            onClick={addTableRows}
          />
        </div>
        <EmptyStateImage
          text={"No units yet!"}
          imageType={
            ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
          }
        />
      </>
    );
  }

  const deleteEditRow = (index: number) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index: number, evnt: any) => {
    const { name, value } = evnt.target;
    const rowsInput: any = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const onSaveRows = () => {
    console.log(rowsData, "rowsData");

    dispatch(addUnitNumbers(rowsData));

    // setAllRows([...allRows, ...rowsData]);
    setRowsData([]);
  };

  return (
    <>
      <TotalUnitsBox
        addedUnits={unitNumbers?.length}
        totalUnits={totalNumOfUnits}
      />

      <div className={"flex flex-col"}>
        <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
          <div
            className={
              "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            }>
            <div className={"flex flex-row items-center justify-end py-4"}>
              <ButtonSimple
                text={"Save"}
                btnType={"button"}
                btnStyle={"primary"}
                classes={"mx-2"}
                onClick={() => {
                  trackEvent("Save Button Clicked!");
                  setOpenModal(true);
                }}
                isDisabled={
                  rowsData.length <= 0 || unitNumbers?.length >= totalNumOfUnits
                }
              />
              <ButtonSimple
                text={"+ Unit"}
                btnType={"button"}
                btnStyle={"secondary"}
                onClick={addTableRows}
              />
            </div>

            <UnitNumbersTable
              rowsData={rowsData}
              deleteEditRow={deleteEditRow}
              handleChange={handleChange}
              currentRecords={currentRecords}
            />

            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />

            <SupportDetails />
          </div>
        </div>
        <UnitNumberDeleteModal
          showModal={openModal}
          setShowModal={setOpenModal}
          onSaveRows={onSaveRows}
        />
      </div>
    </>
  );
};
