import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { SharedStyles } from "../../themes/FabTheme";
import { getCurrentTabClassName } from "../../utils/TabUtils";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { FabricAppPerksNav } from "./FabricAppPerksNav";

const MyPropertyPerks = () => (
  <div>
    <EmptyStateImage
      text={
        "To add a custom perk that only applies to your property, please contact the FabricApp team."
      }
      imageType={ManagersClient.SharedDefaultImageResDtoTypeEnum.FeaturePerks}
    />
  </div>
);

type PerksTabsProps = {
  basePath: string;
};

export const PerksTabs: React.FC<PerksTabsProps> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToFabricApp = `${path}/fabricapp`;
  const pathToCustom = `${path}/custom`;
  const pathToDefault = path;

  const getTabClassName: (myPath: string, isDefault?: boolean) => string =
    React.useCallback(
      (currentPath, isDefault = false) => {
        return getCurrentTabClassName({
          currentPath,
          pathname,
          pathToDefault,
          isDefault,
        });
      },
      [pathToDefault, pathname],
    );

  return (
    <div className={"py-4"}>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Create New Fab Perk"}
          btnType={"button"}
          btnStyle={"secondary"}
          btnIntent={"action"}
          onClick={() => history.push(`${basePath}/add-fabperk`)}
        />
      </div>

      <div className={SharedStyles.tabs.tabListClassName()}>
        <div className={"flex flex-row items-start space-x-4"}>
          <Link
            to={pathToFabricApp}
            className={getTabClassName(pathToFabricApp, true)}>
            FabricApp Perks
          </Link>
          <Link to={pathToCustom} className={getTabClassName(pathToCustom)}>
            My Property Perks
          </Link>
        </div>
      </div>

      <div className={"min-h-screen"}>
        <Switch>
          <Route path={pathToFabricApp} component={FabricAppPerksNav} />
          <Route path={pathToCustom} component={MyPropertyPerks} />
          <Route path={path} component={FabricAppPerksNav} />
        </Switch>
      </div>
    </div>
  );
};
