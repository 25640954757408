import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { trackEvent } from "../../utils/Amplitude";
import { InviteResidentsSteps } from "./InviteResidentsSteps";

export const InviteResidentsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Invite Residents Clicked!");

  const { selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  return (
    <div className={"container py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-1"}>
        <Link to={path}>
          <div className={"text-primary"}>Invite Residents</div>
        </Link>
      </div>
      <h2 className={"text-left text-xl font-sans text-emph-high mb-5"}>
        The best way to invite residents to FabricApp is via a two step
        approach. If you need help with this, please contact the FabricApp Team
        via email at support@fabricapp.ca.
      </h2>

      <h4 className={"text-left text-md font-sans text-emph-high mb-16 mt-16"}>
        Your property code is: <b>{selectedProperty?.propertyCode}</b>
      </h4>

      <InviteResidentsSteps
        propertyCode={selectedProperty?.propertyCode || ""}
      />
    </div>
  );
};
