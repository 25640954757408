import React from "react";

export default function LaunchAppProgressBar(props: any) {
  const { stepsStatus } = props;

  const steps = [
    {
      id: "Step 1",
      name: "Configure Property",
      complete: stepsStatus[0].complete,
    },
    { id: "Step 2", name: "Test Drive", complete: stepsStatus[1].complete },
    {
      id: "Step 3",
      name: "Invite Residents",
      complete: stepsStatus[2].complete,
    },
  ];

  return (
    <nav aria-label={"Progress"}>
      <ol
        role={"list"}
        className={"space-y-4 md:flex md:space-y-0 md:space-x-8"}>
        {steps.map((step: any) => (
          <li key={step.name} className={"md:flex-1"}>
            {step.complete === true ? (
              <a
                className={
                  "group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                }>
                <span
                  className={
                    "text-sm font-medium text-indigo-600 group-hover:text-indigo-800"
                  }>
                  {step.id}
                </span>
                <span className={"text-sm font-medium"}>{step.name}</span>
              </a>
            ) : (
              <a
                className={
                  "group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                }>
                <span
                  className={
                    "text-sm font-medium text-gray-500 group-hover:text-gray-700"
                  }>
                  {step.id}
                </span>
                <span className={"text-sm font-medium"}>{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
