import { Prisma } from "@fabricapp-ca/fabricapp-api-shared";
import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import { sentenceCase } from "change-case";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddAmenityWizardLayout } from "./AddAmenityWizardLayout";
import { AddNewAmenityAction, AddNewAmenityState } from "./AddNewAmenityState";
import {AddAmenityStep1, addAmenityStep1Submit, resetAddAmenityStatus} from "./AmenitiesSlice";

type IntroProps = {
  basePath: string;
  dispatcher: React.Dispatch<AddNewAmenityAction>;
  defaultValue: AddNewAmenityState["step1Type"];
};

const schema = Joi.object<AddAmenityStep1>({
  amenityType: SchemaValidation.FieldOptionalString({
    label: "Amenity Type",
    min: 0,
    max: 100,
  }),
  amenityChargeType: SchemaValidation.FieldOptionalString({
    label: "Amenity Charge Type",
    min: 0,
    max: 100,
  }),
  buildingId: SchemaValidation.FieldOptionalString({
    label: "Building Id",
    min: 0,
    max: 100,
  }),
});

export const AddAmenityWizardIntro: FunctionComponent<IntroProps> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const { selectedBuildings } = useAppSelector(
    (state) => state.selectionContext,
  );
  const { status } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );

  const { step1 } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    switch (status) {
      case "STEP1_LOADING":
        setIsLoading(true);
        break;
      case "STEP1_SUCCESS":
        setIsLoading(false);
        history.push(`${basePath}/step2`);
        break;
      default:
        break;
    }
  }, [basePath, history, status]);

  React.useEffect(() => {
    dispatch(resetAddAmenityStatus());
  }, [dispatch]);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<AddAmenityStep1>({
    defaultValues: { ...step1 },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const amenityTypes = Object.keys(Prisma.AmenityType).map((t) => {
    return { id: t, name: sentenceCase(t) };
  });

  const amenityChargeTypes = Object.keys(Prisma.AmenityChargeType).map((t) => {
    return { id: t, name: sentenceCase(t) };
  });

  console.log(errors);

  const onSubmit = (data: AddAmenityStep1) => {
    dispatch(addAmenityStep1Submit(data));
  };

  return (
    <AddAmenityWizardLayout>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        Basic Information
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
        <div className={"container flex flex-col mx-auto px-6 py-10"}>
          <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
            Which Tower would this amenity be in?
          </div>

          <fieldset>
            <div className={"mb-4 space-y-4"}>
              {selectedBuildings?.map((building) => {
                return (
                  <div key={building.id} className={"flex items-center"}>
                    <input
                      id={building.id}
                      type={"radio"}
                      className={
                        "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      }
                      value={building.id}
                      {...register("buildingId")}
                    />
                    <label
                      htmlFor={building.id}
                      className={"ml-3 block font-light text-gray-700"}>
                      {building.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </fieldset>

          <div className={"text-2xl font-medium text-heading py-4 my-4"}>
            Which amenity would your like to create?
          </div>

          <div className={"mb-4 space-y-4"}>
            {amenityTypes.map((item) => {
              return (
                <div key={item.id} className={"flex items-center"}>
                  <input
                    id={item.id}
                    type={"radio"}
                    className={
                      "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    }
                    value={item.id}
                    {...register("amenityType")}
                  />
                  <label
                    htmlFor={item.id}
                    className={"ml-3 block font-light text-gray-700"}>
                    {item.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className={"text-2xl font-medium text-heading py-4 my-4"}>
            This amenity will be:
          </div>

          <div className={"mb-4 space-y-4"}>
            {amenityChargeTypes.map((item) => {
              return (
                <div key={item.id} className={"flex items-center"}>
                  <input
                    id={item.id}
                    type={"radio"}
                    className={
                      "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    }
                    value={item.id}
                    {...register("amenityChargeType")}
                  />
                  <label
                    htmlFor={item.id}
                    className={"ml-3 block font-light text-gray-700"}>
                    {item.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className={"py-4"}>
          <ButtonSimple text={"Next"} btnType={"submit"} btnStyle={"primary"} />
        </div>
      </form>
    </AddAmenityWizardLayout>
  );
};
