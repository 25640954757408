import React from "react";

import { SubStepLabels } from "../SubStepLabels";

export const ConfigurePropertySteps = (props: any) => {
  const { configurePropertyStepsList, selectedIdx } = props;

  return (
    <div className={"flex flex-col justify-start items-start"}>
      {configurePropertyStepsList.map((step: any) => (
        <SubStepLabels key={step.name} step={step} />
      ))}
    </div>
  );
};
