import { ManagerGetUnitGroupByIdResDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useParams } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { UnitGroupComposer } from "./UnitGroupComposer";
import { unitGroupByIdSelector } from "./unitGroupsSlice";

type UnitGroupEditorProps = {
  basePath: string;
};

export const UnitGroupEditor: React.FC<UnitGroupEditorProps> = (props) => {
  const { unitGroupId } = useParams<{ unitGroupId: string }>();
  const { basePath } = props;

  const existingDto = unitGroupByIdSelector(store.getState(), unitGroupId);
  const [currentUnitGroup, setCurrentUnitGroup] = React.useState<
    ManagerGetUnitGroupByIdResDto | undefined
  >(undefined);
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  React.useEffect(() => {
    async function getUnitGroupDetails() {
      if (existingDto) {
        const res = await managersApi.getUnitGroupById({
          orgId: selectedOrg?.id || "",
          propertyId: selectedProperty?.id || "",
          unitGroupId: existingDto?.id,
        });
        setCurrentUnitGroup(res);
      }
    }
    getUnitGroupDetails();
  }, []);

  if (existingDto && !currentUnitGroup) {
    return <LoadingView />;
  }

  return (
    <UnitGroupComposer basePath={basePath} existingDto={currentUnitGroup} />
  );
};
