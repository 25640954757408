import { dayjsUtils } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";
import { upperCaseFirst } from "upper-case-first";

import { getDownloadUrl } from "../../api/managersApi";
import { LinkSimple } from "../../components/LinkSimple";

type FabPerkCardProps = {
  basePath: string;
  fabPerk: ManagersClient.ManagerGetPerkResDto;
  showLink: boolean;
};

export const FabPerkCard: React.FC<FabPerkCardProps> = (props) => {
  const { fabPerk, basePath, showLink } = props;
  const { id, createdAt, title, description, brand } = fabPerk;
  const { name, photoPath } = brand;
  const photoPathUrl = getDownloadUrl(photoPath);

  return (
    <div className={"flex flex-row items-center justify-between w-full"}>
      <div className={"flex flex-row items-center"}>
        <img
          alt={"Perk"}
          className={"w-48 h-auto object-contain rounded"}
          src={photoPathUrl}
        />
        <div className={"flex-col ml-2 space-y-2"}>
          <div className={"text-lg font-semibold"}>{upperCaseFirst(title)}</div>
          <div className={"text-emph-high"}>{upperCaseFirst(name)}</div>
          {showLink && (
            <div className={"text-default line-clamp-1"}>
              {upperCaseFirst(description)}
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "flex flex-col justify-end items-end text-right flex-shrink-0"
        }>
        <div className={"text-emph-high"}>
          {upperCaseFirst(dayjsUtils.formatDate(createdAt))}
        </div>
        {showLink && (
          <div className={"flex justify-end"}>
            <LinkSimple
              text={"View"}
              destination={`${basePath}/${id}`}
              iconPosition={"right"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
