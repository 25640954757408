/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from "react";
import { v4 as uuidV4 } from "uuid";

export type WizardSteps = Array<{ name: string }>;
type Props = {
  steps: WizardSteps;
  currentStepIndex: number;
};

const WizardStepper: FunctionComponent<Props> = (props) => {
  const { steps, currentStepIndex } = props;
  const [stepperSteps, setStepperSteps] = React.useState<Array<JSX.Element>>(
    [],
  );
  const [textSteps, setTextSteps] = React.useState<Array<JSX.Element>>([]);

  React.useEffect(() => {
    const computedStepperSteps = steps.map((step, index) => {
      const isOverCurrentStep = index > currentStepIndex;
      const isUnderCurrentStep = index < currentStepIndex;
      const isLastStep = index === steps.length - 1;
      const cssProps: React.CSSProperties = {
        width: isUnderCurrentStep ? "100%" : "0%",
      };
      const track: JSX.Element = (
        <div
          className={
            "flex w-1/6 align-center items-center px-1 align-middle content-center"
          }>
          <div
            className={
              "flex-1 w-full bg-gray-200 rounded items-center align-middle align-center"
            }>
            <div
              className={
                "bg-indigo-200 text-xs leading-none h-1 text-center text-gray-700 rounded"
              }
              style={cssProps}
            />
          </div>
        </div>
      );
      const derivedTrack: JSX.Element = isLastStep ? <></> : track;

      const inactiveThumbClass =
        "w-10 h-10 mx-auto rounded-full text-lg flex items-center border border-gray-200 text-indigo-500 bg-white";
      const activeThumbClass =
        "w-10 h-10 mx-auto rounded-full text-lg flex items-center border border-gray-200 text-white bg-indigo-500";
      const derivedThumbClass = isOverCurrentStep
        ? inactiveThumbClass
        : activeThumbClass;

      return (
        <React.Fragment key={`stepper-fragment-${index}-${uuidV4()}`}>
          <div className={"flex-1"} key={`stepperSteps-${index}-${uuidV4()}`}>
            <div className={derivedThumbClass}>
              <span className={"text-center w-full"}>{index + 1}</span>
            </div>
          </div>
          {derivedTrack}
        </React.Fragment>
      );
    });
    setStepperSteps(computedStepperSteps);

    const computedTextSteps = steps.map((step, index) => {
      const width = `w-1/${steps.length}`;
      return (
        <div className={width} key={`stepper-text-${index}-${uuidV4()}`}>
          {step.name}
        </div>
      );
    });
    setTextSteps(computedTextSteps);
  }, [currentStepIndex, steps]);

  return (
    <div>
      <div>
        <div className={"max-w-full mx-auto my-4 border-b-2 pb-4"}>
          <div className={"flex pb-3"}>
            <div className={"flex-1"} key={`stepperSteps-${uuidV4()}`} />
            {stepperSteps}
            <div className={"flex-1"} key={`stepperSteps-${uuidV4()}`} />
          </div>
          <div className={"flex text-xs content-center text-center"}>
            {textSteps}
          </div>
        </div>
      </div>
    </div>
  );
};

export function wizardStepperWrapper(
  steps: WizardSteps,
): (currentStepIndex: number) => JSX.Element {
  return (currentStepIndex: number) => {
    return <WizardStepper currentStepIndex={currentStepIndex} steps={steps} />;
  };
}

export default WizardStepper;
