export const getSelectionIds: any = (state: any) => {
  return {
    orgId: state.selectionContext?.selectedOrg?.id,
    propertyId: state.selectionContext?.selectedProperty?.id,
    buildingId:
      state.selectionContext?.selectedBuildings != null
        ? state.selectionContext?.selectedBuildings[0]?.id
        : null,
  };
};
