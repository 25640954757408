import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import Interweave from "interweave";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import React from "react";
import { BiLike } from "react-icons/bi";
import { BsBuilding } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { StatsCardProps } from "../../components/stats/StatsCard";
import { StatsRow } from "../../components/stats/StatsRow";
import { getSelectionIds } from "../../utils/Helpers";
import { SentReminderCard } from "./SentReminderCard";
import { sentRemindersSelector } from "./SentRemindersListingSlice";

export type SentReminderViewerProps = {
  basePath: string;
};

type ReminderStats = {
  deliveredCount: number;
  viewedCount: number;
  likedCount: number;
};

type DeltaOps = Array<Record<string, unknown>>;
type QuillContent = { ops: DeltaOps };

export const SentReminderViewer: React.FC<SentReminderViewerProps> = (
  props,
) => {
  const { basePath } = props;
  const { reminderId } = useParams<{ reminderId: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [content, setContent] = React.useState<DeltaOps>([]);
  const [reminder, setReminder] = React.useState<
    ManagersClient.ManagerGetReminderDetailsResDto | undefined
  >();
  const [reminderStats, setReminderStats] = React.useState<ReminderStats>({
    deliveredCount: 0,
    viewedCount: 0,
    likedCount: 0,
  });

  const { orgId, propertyId } = useAppSelector((state) =>
    getSelectionIds(state),
  );

  const stats: Array<StatsCardProps> = [
    {
      label: "Delivered to units",
      value: `${reminderStats.deliveredCount}`,
      iconType: BsBuilding,
    },
    {
      label: "Viewed by units",
      value: `${reminderStats.viewedCount}`,
      iconType: FiUsers,
    },
    {
      label: "Liked by residents",
      value: `${reminderStats.likedCount}`,
      iconType: BiLike,
    },
  ];

  React.useEffect(() => {
    async function foo() {
      try {
        const output = await managersApi.getReminderById({
          orgId,
          propertyId,
          status: ManagersClient.GetReminderByIdStatusEnum.Sent,
          reminderId,
        });
        setReminder(output);
      } finally {
        setIsLoading(false);
      }
    }

    foo();
  }, [reminderId]);

  React.useEffect(() => {
    if (!reminder) return;
    const msg = JSON.parse(reminder.message) as QuillContent;
    setContent(msg.ops);

    setReminderStats({
      deliveredCount: reminder.deliveredCount,
      viewedCount: reminder.viewedCount,
      likedCount: reminder.likedCount,
    });

    setIsLoading(false);
  }, [reminder]);

  const sentReminder = sentRemindersSelector.selectById(
    store.getState(),
    reminderId,
  );

  const cfg = {};
  const converter = new QuillDeltaToHtmlConverter(content, cfg);
  const source = { html: converter.convert() };

  if (isLoading) {
    return <LoadingView />;
  }

  if (!sentReminder) {
    return <ErrorView message={`Missing sent reminder ${reminderId}`} />;
  }

  return (
    <>
      <StatsRow stats={stats} />
      <div
        className={"flex flex-col px-6 py-6 my-6 border rounded-md shadow-sm"}>
        {/* info cards */}

        <SentReminderCard
          basePath={basePath}
          sentReminder={sentReminder}
          showLink={false}
        />
        <div className={"py-4 px-4"}>
          <Interweave content={source.html} />
        </div>
      </div>
    </>
  );
};
