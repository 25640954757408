import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ManagerUnitGroupUnitResDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { managersApi } from "../../api/managersApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { MultiSelect } from "../../components/forms/MultiSelect";
import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import {
  deleteOneUnitGroup,
  postOneUnitGroup,
  resetPostOneUnitGroupStatus,
  resetUpdateDeleteOneUnitGroupStatus,
} from "./unitGroupsSlice";

type Props = {
  basePath: string;
  existingDto?: ManagersClient.ManagerGetUnitGroupByIdResDto;
};

const schema = Joi.object<ManagersClient.ManagerGetUnitGroupByIdResDto>({
  name: SchemaValidation.FieldRequiredString({ label: "Name" }),
  units: SchemaValidation.FieldRequiredArray({
    label: "Units",
    min: 1,
    max: 10000,
    items: Joi.object({
      name: SchemaValidation.FieldRequiredString({
        label: "Name",
        min: 1,
      }),
      id: SchemaValidation.FieldRequiredString({
        label: "ID",
        min: 1,
      }),
    }),
  }),
});

export const UnitGroupComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath, existingDto } = props;
  const [residentDirectory, setResidentDirectory] = React.useState<any>([]);

  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  React.useEffect(() => {
    async function getUnits() {
      const res = await managersApi.getUnitsByProperty({
        orgId: selectedOrg?.id || "",
        propertyId: selectedProperty?.id || "",
      });
      const units = [];
      for (const result of res.results) {
        units.push({ value: result.id, label: result.name });
      }
      setResidentDirectory([...units]);
    }

    getUnits();
  }, []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm<ManagersClient.ManagerGetUnitGroupByIdResDto>({
    defaultValues: existingDto || {},
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });
  const dispatch = useAppDispatch();

  const {
    postOneStatus,
    postOneError,
    updateDeleteOneStatus,
    updateDeleteOneError,
  } = useAppSelector((state) => state.unitGroups.unitGroupsListing);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(
    "UnitGroupComposer | UnitGroupComposer - errors",
    errors,
    prettyRawError(errors),
  );

  React.useEffect(() => {
    return () => {
      dispatch(resetPostOneUnitGroupStatus());
      dispatch(resetUpdateDeleteOneUnitGroupStatus());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (
      postOneStatus === "POST_ONE_LOADING" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_LOADING"
    ) {
      setIsLoading(true);
      return;
    }

    if (
      postOneStatus === "POST_ONE_SUCCESS" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_SUCCESS"
    ) {
      setIsLoading(false);
      history.push(basePath);
      return;
    }

    if (
      postOneStatus === "POST_ONE_ERROR" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_ERROR"
    ) {
      setIsLoading(false);
    }
  }, [basePath, history, postOneStatus, updateDeleteOneStatus]);

  async function onCreate(data: ManagersClient.ManagerGetUnitGroupByIdResDto) {
    trackEvent("Add Button Clicked!");
    const dto: ManagersClient.ManagerCreateOrUpdateUnitGroupReqDto = {
      name: data.name,
      id: data.id,
      units: data.units.map((u) => u.id),
    };
    console.log(
      "UnitGroupComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );

    if (selectedOrg && selectedProperty) {
      dispatch(
        postOneUnitGroup({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          dto,
        }),
      );
    }
  }

  async function onDelete() {
    if (selectedOrg && selectedProperty && existingDto) {
      dispatch(
        deleteOneUnitGroup({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          unitGroupId: existingDto.id,
        }),
      );
    }
  }

  async function onUpdate(data: ManagersClient.ManagerGetUnitGroupByIdResDto) {
    const dto: ManagersClient.ManagerCreateOrUpdateUnitGroupReqDto = {
      name: data.name,
      id: data.id,
      units: data.units.map((u) => u.id),
    };
    console.log(
      "UnitGroupComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty && existingDto) {
      dispatch(
        postOneUnitGroup({
          orgId: selectedOrg.id,
          propertyId: selectedProperty.id,
          dto,
        }),
      );
    }
  }

  const getLabelsForUnits = (
    units: ManagerUnitGroupUnitResDto[] | undefined,
  ) => {
    if (!units) {
      return [];
    }
    return units.map((ugu) => {
      return {
        label: ugu.name,
        value: ugu.id,
      };
    });
  };

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        {existingDto ? "Edit Unit Group" : "Add New Unit Group"}
      </div>
      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Name"} htmlForId={"name"} />
            <input
              type={"text"}
              {...register("name")}
              className={SharedStyles.inputs.input()}
            />
            {errors.name && (
              <div className={"text-error"}>{errors.name.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"Units"} />
            <Controller
              control={control}
              name={"units"}
              render={({ field }) => (
                <MultiSelect
                  name={"to"}
                  options={residentDirectory}
                  defaultValues={getLabelsForUnits(existingDto?.units)}
                  onChange={(allSelectedOptions) => {
                    console.log("multiselect | allSelectedOptions", {
                      allSelectedOptions,
                    });
                    const selectedRecipients = allSelectedOptions.map((val) => {
                      return {
                        id: val.value,
                        name: val.label,
                      };
                    });
                    field.onChange(selectedRecipients);
                  }}
                />
              )}
            />
            <div className={"text-sm text-emph-high mt-2"}>
              Enter specific units.
            </div>
            {errors.units?.length === 0 && (
              <div className={"text-error"}>
                At least one recipient is required.
              </div>
            )}
          </div>
        </div>
        {!existingDto && (
          <>
            <div className={"py-3 mt-2"}>
              <ButtonSimple
                text={"Add"}
                btnType={"submit"}
                btnStyle={"primary"}
                isDisabled={!isValid}
                isSpinning={isLoading}
                onClick={handleSubmit(onCreate)}
              />
            </div>
            {postOneError && <ErrorView message={postOneError} />}
          </>
        )}
        {existingDto && (
          <>
            <div className={"py-3 mt-2"}>
              <div className={"space-x-4"}>
                <ButtonSimple
                  text={"Update"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onUpdate)}
                />
                <ButtonSimple
                  text={"Delete"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  btnIntent={"error"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onDelete)}
                />
              </div>
            </div>
            {updateDeleteOneError && (
              <ErrorView message={updateDeleteOneError} />
            )}
          </>
        )}
      </form>
    </div>
  );
};
