import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ManagerPostUnitReqDto } from "@fabricapp-ca/fabricapp-openapi/generated/managers-ts-fetch/models/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";

export interface UnitNumbersListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  addUnitNumbersStatus:
    | "IDLE"
    | "ADD_UNIT_NUMBERS_LOADING"
    | "ADD_UNIT_NUMBERS_SUCCESS"
    | "ADD_UNIT_NUMBERS_ERROR";
  addUnitNumbersError?: string;
  unitNumbers: Array<ManagersClient.ManagerPostUnitReqDto>;
}

const initialState: UnitNumbersListingSliceState = {
  getAllStatus: "IDLE",
  addUnitNumbersStatus: "IDLE",
  unitNumbers: [],
};

export const UnitNumbersListingSlice = createSlice({
  name: "unitNumbersListing",
  initialState,
  reducers: {
    resetUnitNumbersStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    getUnitNumbersStart: (state) => {
      state.getAllStatus = "GET_ALL_LOADING";
    },
    getUnitNumbersSuccess: (
      state,
      action: PayloadAction<{
        unitNumbers: Array<ManagersClient.ManagerPostUnitReqDto>;
      }>,
    ) => {
      state.getAllStatus = "GET_ALL_SUCCESS";
      state.unitNumbers = action.payload.unitNumbers || [];
      state.getAllError = undefined;
    },
    getUnitNumbersFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "GET_ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetAddUnitNumbersUnitNumberStatus: (state) => {
      state.addUnitNumbersStatus = "IDLE";
      state.addUnitNumbersError = undefined;
    },
    addUnitNumbersStatus: (state) => {
      state.addUnitNumbersStatus = "IDLE";
      state.addUnitNumbersError = undefined;
    },
    addUnitNumbersStart: (state) => {
      state.addUnitNumbersStatus = "ADD_UNIT_NUMBERS_LOADING";
    },
    addUnitNumbersSuccess: (state) => {
      state.addUnitNumbersStatus = "ADD_UNIT_NUMBERS_SUCCESS";
      state.addUnitNumbersError = undefined;
    },
    addUnitNumbersFailure: (state, action: PayloadAction<string>) => {
      state.addUnitNumbersStatus = "ADD_UNIT_NUMBERS_ERROR";
      state.addUnitNumbersError = action.payload;
    },
  },
});

export const {
  resetUnitNumbersStatus,
  getUnitNumbersStart,
  getUnitNumbersSuccess,
  getUnitNumbersFailure,
  resetAddUnitNumbersUnitNumberStatus,
  addUnitNumbersStatus,
  addUnitNumbersSuccess,
  addUnitNumbersStart,
  addUnitNumbersFailure,
} = UnitNumbersListingSlice.actions;

export const unitNumbersListingReducer = UnitNumbersListingSlice.reducer;

export const getAllUnitNumbersListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getUnitNumbersStart());
      const { orgId, propertyId, buildingId } = getSelectionIds(getState());

      const unitNumbers = (
        await managersApi.getUnitsByBuilding({
          orgId,
          propertyId,
          buildingId,
        })
      ).results;
      dispatch(
        getUnitNumbersSuccess({
          unitNumbers,
        }),
      );
    } catch (err: any) {
      dispatch(getUnitNumbersFailure(prettyRawError(err)));
    }
  };

export const addUnitNumbers =
  (unitNumbersList: Array<ManagerPostUnitReqDto>): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(addUnitNumbersStart());
      const { orgId, propertyId, buildingId } = getSelectionIds(getState());

      await managersApi.postUnitsInBuilding({
        orgId,
        propertyId,
        buildingId,
        managerPostUnitReqDto: unitNumbersList,
      });
      dispatch(addUnitNumbersSuccess());
    } catch (err: any) {
      const error = await err.json();
      dispatch(addUnitNumbersFailure(prettyRawError(error.message)));
    }
  };
