import { getManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { trim } from "lodash";

import { APP_ENV, fbAuth } from "../configs/FirebaseConfig";

const getTokenFn = async (): Promise<string> => {
  const token = await fbAuth.currentUser?.getIdToken();
  return token || "";
};
export const getAuthHeaders = async (): Promise<{ [k: string]: string }> => {
  const token = await getTokenFn();
  return { Authorization: `Bearer ${token}` };
};

function urlPathTrim(path: string): string {
  return trim(path, " /");
}

// eslint-disable-next-line no-underscore-dangle
const BASE_URL_ENV = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
const BASE_URL = urlPathTrim(BASE_URL_ENV);
const BASE_PATH = urlPathTrim("/managers");
const BASE_PREFIX = `${BASE_URL}/${BASE_PATH}`;

export const managersApi = getManagersClient(BASE_URL, getTokenFn);

export function getDownloadUrl(downloadPath: string): string {
  const trimmedDlPath = urlPathTrim(downloadPath);
  if (trimmedDlPath.startsWith(BASE_PREFIX)) {
    return trimmedDlPath;
  }
  return `${BASE_PREFIX}/${trimmedDlPath}`;
}
