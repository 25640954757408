import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SharedStyles } from "../../themes/FabTheme";
import OnboardingLayout from "./OnboardingLayout";
import {
  completeOnboarding,
  saveOnboardingStep2,
  Step2ProfilePostData,
} from "./OnboardingSlice";
import { OnboardingSteps } from "./OnboardingSteps";
import { OnboardingText } from "./OnboardingText";
import {trackEvent} from "../../utils/Amplitude";

type Props = {
  basePath: string;
};

const schema = Joi.object<Step2ProfilePostData>({
  profile: Joi.object({
    firstName: SchemaValidation.FieldRequiredString({ label: "First name" }),
    lastName: SchemaValidation.FieldRequiredString({ label: "Last name" }),
    photoPath: SchemaValidation.FieldOptionalString({
      label: "Profile photo",
    }),
  }),
  authentication: Joi.object({
    phoneNumber: SchemaValidation.FieldRequiredString({
      label: "Phone Number",
    }),
  }),
});
const COUNTRY_CODE: CountryCode = "CA";
const Step2Profile: FunctionComponent<Props> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { wizardStepStatus, managerEntryPointState, postEntrypointError } =
    useAppSelector((state) => state.onboarding);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<Step2ProfilePostData>({
    defaultValues: { profile: managerEntryPointState?.profile },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const phoneNumberInputRef = React.createRef<HTMLInputElement>();

  const onSubmit = (step2Data: Step2ProfilePostData) => {
    trackEvent("Add Button Clicked!");
    dispatch(saveOnboardingStep2({ ...step2Data }));
  };
  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "STEP2_LOADING":
        setIsLoading(true);
        break;
      case "STEP2_SUCCESS":
        setIsLoading(true);
        dispatch(completeOnboarding());
        break;
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [basePath, history, wizardStepStatus]);

  const [isPhoneValid, setIsPhoneValid] = React.useState(false);
  const [formattedPhone, setFormattedPhone] = React.useState("");
  const [intlFormattedPhone, setIntlFormattedPhone] =
    React.useState(formattedPhone);
  const currentStepIndex = 1;
  const wizardStepper = wizardStepperWrapper(OnboardingSteps)(currentStepIndex);

  const setPhoneNumber = () => {
    const phoneNumber = phoneNumberInputRef.current?.value ?? "";
    const asYouType = new AsYouType(COUNTRY_CODE);
    const asYouTypeFormatted = asYouType.input(phoneNumber);
    if (asYouType.isValid()) {
      const parsedPhoneNumber = parsePhoneNumber(
        asYouTypeFormatted,
        COUNTRY_CODE,
      );
      const formattedNational = parsedPhoneNumber.formatNational();
      setFormattedPhone(formattedNational);
      setIntlFormattedPhone(parsedPhoneNumber.formatInternational());
      setIsPhoneValid(true);
      setValue("authentication.phoneNumber", formattedNational, {
        shouldValidate: true,
      });
    } else {
      setFormattedPhone(asYouTypeFormatted);
      setIntlFormattedPhone("");
      setIsPhoneValid(false);
    }
    console.log("Step3Authentication | setPhoneNumber", {
      isValid,
      isPhoneValid,
      errors,
    });
  };

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      case "SUCCESS_POST_ENTRYPOINT":
        setIsLoading(false);
        history.push("/select-organization");
        break;
      default:
        break;
    }
  }, [dispatch, history, wizardStepStatus]);

  return (
    <OnboardingLayout>
      {wizardStepper}
      <OnboardingText title={OnboardingSteps[currentStepIndex].name}>
        This helps others in your building to identify you quickly.
        <br />
        <br />
        FabricApp does not share your personal information without your consent.
        Please refer to our detailed privacy policy for more information.
      </OnboardingText>

      <div
        className={"max-w-lg mx-auto flex flex-col my-2 px-6 py-5 space-y-6"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"mb-5"}>
            <FormLabel text={"First name"} htmlForId={"firstname"} />
            <input
              type={"text"}
              {...register("profile.firstName")}
              className={SharedStyles.inputs.input()}
            />
            {errors?.profile?.firstName && (
              <div className={"text-error"}>
                {errors.profile.firstName.message}
              </div>
            )}
          </div>

          <div className={"mb-5"}>
            <FormLabel text={"Last name"} htmlForId={"lastname"} />
            <input
              type={"text"}
              {...register("profile.lastName")}
              className={SharedStyles.inputs.input()}
            />
            {errors?.profile?.lastName && (
              <div className={"text-error"}>
                {errors.profile.lastName.message}
              </div>
            )}
          </div>

          <div className={"mb-5"}>
            <FormLabel text={"Phone Number"} htmlForId={"phonenumber"} />
            <Controller
              control={control}
              name={"authentication.phoneNumber"}
              render={() => (
                <input
                  type={"tel"}
                  ref={phoneNumberInputRef}
                  onChange={() => {
                    setPhoneNumber();
                  }}
                  className={SharedStyles.inputs.input()}
                />
              )}
            />
            {!isPhoneValid && (
              <div className={"text-error"}>
                Please enter a valid phone number.
              </div>
            )}
          </div>

          <div className={"flex justify-center mt-5"}>
            <ButtonSimple
              text={"Next"}
              btnType={"submit"}
              btnStyle={"primary"}
              isDisabled={!isValid}
              isSpinning={isLoading}
            />
          </div>
          {postEntrypointError && (
            <div className={"text-error"}>{postEntrypointError}</div>
          )}
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Step2Profile;
