import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import OnboardingLayout from "./OnboardingLayout";
import { OnboardingText } from "./OnboardingText";

type Props = {
  basePath: string;
};

const OnboardingIntro: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath } = props;

  function handleNext() {
    console.log("clicked next");
    history.push(`${basePath}/step1-agreements`);
  }

  const listItem = (index: number, text: string) => (
    <div key={index} className={"flex md:contents"}>
      <div className={"col-start-2 col-end-4 mx-auto relative"}>
        <div
          className={
            "w-12 h-12 absolute top-1/2 -mt-6 rounded-full border border-primary flex items-center text-center justify-center text-primary"
          }>
          {index}
        </div>
      </div>
      <div className={"col-start-4 col-end-9 py-4 my-4 ml-4"}>
        <p className={"align-middle leading-tight text-justify text-default"}>
          {text}
        </p>
      </div>
    </div>
  );

  const listItems = [
    "Review terms and privacy policy",
    "Setup your profile",
    // "Enable additional security",
  ].map((x, idx) => {
    return listItem(idx + 1, x);
  });

  return (
    <OnboardingLayout>
      <OnboardingText title={"Let's get started!"}>
        FabricApp is a platform to manage the resident experience in your
        building. <br /> <br />
        To ensure FabricApp works best for your environment, we are going to
        guide you through the onboarding steps. These steps have to be only
        completed once.
      </OnboardingText>

      <div
        className={
          "max-w-lg mx-auto flex flex-col md:grid grid-cols-9 mx-auto my-2"
        }>
        {listItems}
      </div>

      <div className={"flex justify-center"}>
        <ButtonSimple
          text={"Next"}
          btnType={"button"}
          btnStyle={"primary"}
          onClick={handleNext}
        />
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingIntro;
