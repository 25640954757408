import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function AddressDropdown(props: any) {
  const { placeValue, setValue, trigger } = props;

  const [selectedPlace, setSelectedPlace] = useState<any>(null);

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_PLACES_KEY,
    debounce: 500,
  });

  const selectPlace = (place: any) => {
    if (placesService) {
      placesService.getDetails(
        { placeId: place.place_id, fields: ["formatted_address"] },
        (res: any) => {
          setValue("property.address", res?.formatted_address);
          setSelectedPlace(res?.formatted_address);
          trigger();
        },
      );
    }
  };

  return (
    <Combobox as={"div"} value={selectedPlace} onChange={selectPlace}>
      <div className={"relative mt-1"}>
        <Combobox.Input
          className={
            "w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          }
          onChange={(evt: any) => {
            setValue("property.address", null);
            setSelectedPlace(null);
            getPlacePredictions({ input: evt.target.value });
          }}
          displayValue={(place: any) => {
            return place;
          }}
        />
        <Combobox.Button
          className={
            "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          }>
          <ChevronUpDownIcon
            className={"h-5 w-5 text-gray-400"}
            aria-hidden={"true"}
          />
        </Combobox.Button>

        {!isPlacePredictionsLoading && (
          <Combobox.Options
            className={
              "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            }>
            {placePredictions.map((place: any) => (
              <Combobox.Option
                key={place.place_id}
                value={place}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900",
                  )
                }>
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold",
                      )}>
                      {place.description}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600",
                        )}>
                        <CheckIcon className={"h-5 w-5"} aria-hidden={"true"} />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
