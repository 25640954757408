import { ManagerPostUnitReqDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import React from "react";

import { UnitNumberInputRow } from "./UnitNumberInputRow";
import { UnitNumberRow } from "./UnitNumberRow";

type UnitNumbersTableProps = {
  rowsData: Array<ManagerPostUnitReqDto>;
  currentRecords: Array<ManagerPostUnitReqDto>;
  deleteEditRow(index: number): void;
  handleChange(index: number, evnt: any): void;
};

export const UnitNumbersTable = (props: any) => {
  const { rowsData, deleteEditRow, handleChange, currentRecords } = props;

  const unitNumberRows = rowsData.map((data: any, index: number) => {
    const { name, floor } = data;
    return (
      <UnitNumberInputRow
        key={index as any}
        index={index}
        name={name}
        floor={floor}
        deleteEditRow={deleteEditRow}
        handleChange={handleChange}
      />
    );
  });

  const unitNumberDisplayRows = currentRecords.map(
    (data: any, index: number) => {
      const { name, floor } = data;
      return (
        <UnitNumberRow
          key={index as any}
          index={index}
          name={name}
          floor={floor}
        />
      );
    },
  );

  return (
    <div
      className={
        "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      }>
      <table className={"min-w-full divide-y divide-gray-200"}>
        <thead className={"bg-gray-50"}>
          <tr>
            <th
              scope={"col"}
              className={
                "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              }>
              UNIT NUMBER
            </th>
            <th
              scope={"col"}
              className={
                "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              }>
              FLOOR NUMBER
            </th>
          </tr>
        </thead>
        <tbody className={"bg-white divide-y divide-gray-200"}>
          {unitNumberRows}
          {unitNumberDisplayRows}
        </tbody>
      </table>
    </div>
  );
};
