import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";
import { upperCaseFirst } from "upper-case-first";

import { DefaultImage } from "./DefaultImage";

type EmptyStateImageProps = {
  text: string;
  imageType: ManagersClient.SharedDefaultImageResDtoTypeEnum;
};

export const EmptyStateImage: React.FC<EmptyStateImageProps> = (props) => {
  const { imageType, text } = props;
  return (
    <div className={"flex flex-col w-1/2 justify-center mx-auto"}>
      <DefaultImage imageType={imageType} />
      <div className={"py-24 text-center text-emph-high"}>
        {upperCaseFirst(text)}
      </div>
    </div>
  );
};
