import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";
import { sortDescByCreatedAt } from "../../utils/SortUtils";

export interface PropertyListingSliceState {
  getAllStatus: "IDLE" | "ALL_LOADING" | "ALL_SUCCESS" | "ALL_ERROR";
  getAllError?: string;
  propertiesAdapter: EntityState<ManagersClient.ManagerGetPropertyResDto>;
}

const propertiesAdapter =
  createEntityAdapter<ManagersClient.ManagerGetPropertyResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: PropertyListingSliceState = {
  getAllStatus: "IDLE",
  propertiesAdapter: propertiesAdapter.getInitialState(),
};

export const PropertyListingSlice = createSlice({
  name: "propertyListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
    },
    getAllPropertiesStart: (state) => {
      state.getAllStatus = "ALL_LOADING";
    },
    getAllPropertiesSuccess: (
      state,
      action: PayloadAction<{
        properties: Array<ManagersClient.ManagerGetPropertyResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_SUCCESS";
      state.getAllError = undefined;
      propertiesAdapter.setAll(
        state.propertiesAdapter,
        action.payload.properties,
      );
    },
    getAllPropertiesFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_ERROR";
      state.getAllError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllPropertiesStart,
  getAllPropertiesSuccess,
  getAllPropertiesFailure,
} = PropertyListingSlice.actions;

export const propertyListingReducer = PropertyListingSlice.reducer;

export const loadPropertyListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getAllPropertiesStart());
      const { orgId } = getSelectionIds(getState());
      const properties = (
        await managersApi.getPropertiesByManagerId({
          orgId,
        })
      ).results;
      dispatch(getAllPropertiesSuccess({ properties }));
    } catch (err: any) {
      dispatch(getAllPropertiesFailure(prettyRawError(err)));
    }
  };

export const propertiesSelector = propertiesAdapter.getSelectors<RootState>(
  (state) => state.properties.propertyListing.propertiesAdapter,
);

export const propertiesCountSelector = (state: RootState): number => {
  return propertiesSelector.selectTotal(state);
};

export const propertiesIsEmptySelector = (state: RootState): boolean => {
  return propertiesSelector.selectTotal(state) === 0;
};

export const propertiesByIdSelector = (
  state: RootState,
  propertyId: string,
): ManagersClient.ManagerGetPropertyResDto | undefined => {
  const allItems = propertiesSelector.selectAll(state);
  return allItems.find((x) => x.id === propertyId);
};
