import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { SharedStyles } from "../../themes/FabTheme";
import { trackEvent } from "../../utils/Amplitude";
import { SignupDtoCountryEnum } from "./SignupDtos";
import { saveSignupWithEmail } from "./SignupSlice";

const schema = Joi.object<ManagersClient.ManagerSignUpWithEmailReqDto>({
  email: SchemaValidation.FieldRequiredString({
    label: "Email",
  }).email({ tlds: { allow: false } }),
  country: SchemaValidation.FieldRequiredString({
    label: "Country",
  }),
  acceptedAgreements: SchemaValidation.FieldRequiredBoolean({
    label: "acceptedAgreements",
    option: "mustBeTrue",
  }),
});

export const SignupComposer = (props: any) => {
  const { basePath, existingDto } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { wizardStepStatus, managerEntryPointState, postEntrypointError } =
    useAppSelector((state) => state.signup);

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<ManagersClient.ManagerSignUpWithEmailReqDto>({
    defaultValues: {
      email: managerEntryPointState?.email,
      country: managerEntryPointState?.country,
      acceptedAgreements: managerEntryPointState?.acceptedAgreements,
    },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const options = Object.entries(SignupDtoCountryEnum).map((kv) => {
    const [key, value] = kv;
    return (
      <option value={value} key={key}>
        {value}
      </option>
    );
  });

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "SIGN_UP_WITH_EMAIL_LOADING":
        setIsLoading(true);
        break;
      case "SIGN_UP_WITH_EMAIL_SUCCESS":
        setIsLoading(false);
        history.push({
          pathname: "signup/email-sent",
          state: { email: getValues().email },
        });
        break;
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [basePath, dispatch, getValues, history, wizardStepStatus]);

  const onSubmit = async (
    signupWithEmailData: ManagersClient.ManagerSignUpWithEmailReqDto,
  ) => {
    trackEvent("Next Clicked!");
    const data = { ...signupWithEmailData };
    if (executeRecaptcha) {
      data.recaptchaCode = await executeRecaptcha();
    }
    dispatch(saveSignupWithEmail(data));
  };

  return (
    <div
      className={
        "md: max-w-lg mx-auto flex flex-col lg:my-2 lg:px-8 lg:py-5 lg:space-y-8"
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"email"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("email")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors.email && (
              <div className={"text-error"}>{errors.email.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Country"} />
            <select
              {...register("country")}
              className={
                "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }>
              {options}
            </select>
            {errors.country && (
              <div className={"text-error"}>{errors.country.message}</div>
            )}
          </div>

          <div className={"col-span-full text-center"}>
            {postEntrypointError && <ErrorView message={postEntrypointError} />}
          </div>

          <div className={"col-span-full text-center"}>
            <input
              type={"checkbox"}
              {...register("acceptedAgreements")}
              className={
                "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              }
            />
            <label className={"font-medium text-gray-700 ml-3"}>
              I accept the{" "}
              <a
                target={"_blank"}
                onClick={() => trackEvent("Privacy Policy Clicked!")}
                href={"/privacy-policy.pdf"}
                className={"font-medium text-indigo-600 hover:text-indigo-500"}
                rel={"noreferrer"}>
                privacy policy
              </a>{" "}
              and{" "}
              <a
                target={"_blank"}
                onClick={() => trackEvent("Tnc Clicked!")}
                href={"/terms.pdf"}
                className={"font-medium text-indigo-600 hover:text-indigo-500"}
                rel={"noreferrer"}>
                terms of services.
              </a>
            </label>
          </div>
        </div>
        {!existingDto && (
          <>
            <div className={"flex justify-center mt-5"}>
              <ButtonSimple
                text={"Next"}
                btnType={"submit"}
                btnStyle={"primary"}
                isDisabled={!isValid}
                isSpinning={isLoading}
              />
            </div>
          </>
        )}{" "}
      </form>
    </div>
  );
};
