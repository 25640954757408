import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { OrganizationListing } from "./OrganizationListing";

export type Props = {
  basePath: string;
};

export const OrganizationNav: React.FC<Props> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>My Organizations</div>
        </Link>
      </div>

      <Switch>
        <Route path={path}>
          <OrganizationListing basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
