import React from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { SharedStyles } from "../../themes/FabTheme";
import { getCurrentTabClassName } from "../../utils/TabUtils";
import { ResidentDirectoryListing } from "./DirectoryListing/ResidentDirectoryListing";
import { ImportEmailInfoBox } from "./ImportEmails/ImportEmailInfoBox";
import { ImportEmailsNav } from "./ImportEmails/ImportEmailsNav";
import { PendingResidentsNav } from "./PendingResidents/PendingResidentsNav";

type ResidentDirectoryTabsProps = { basePath: string };

export const ResidentDirectoryTabs: React.FC<
  ResidentDirectoryTabsProps
> = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToFabricApp = `${path}/approved`;
  const pathToCustom = `${path}/pending`;
  const pathToImportEmails = `${path}/import-emails`;
  const pathToDefault = path;

  const getTabClassName: (myPath: string, isDefault?: boolean) => string =
    React.useCallback(
      (currentPath, isDefault = false) => {
        return getCurrentTabClassName({
          currentPath,
          pathname,
          pathToDefault,
          isDefault,
        });
      },
      [pathToDefault, pathname],
    );

  return (
    <div className={"py-4"}>
      <div className={SharedStyles.tabs.tabListClassName()}>
        <div className={"flex flex-row items-start space-x-4"}>
          <Link
            to={pathToFabricApp}
            className={getTabClassName(pathToFabricApp, true)}>
            Resident Profiles
          </Link>
          <Link to={pathToCustom} className={getTabClassName(pathToCustom)}>
            Pending Residents
          </Link>
          <div>
            <ImportEmailInfoBox />
            <Link
              to={pathToImportEmails}
              className={getTabClassName(pathToImportEmails)}>
              Import Emails
            </Link>
          </div>
        </div>
      </div>

      <div className={"min-h-screen"}>
        <Switch>
          <Route path={pathToFabricApp} component={ResidentDirectoryListing} />
          <Route path={pathToCustom} component={PendingResidentsNav} />
          <Route path={pathToImportEmails} component={ImportEmailsNav} />
          <Route path={path} component={ResidentDirectoryListing} />
        </Switch>
      </div>
    </div>
  );
};
