import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";

import { useAppSelector } from "../../app/hooks";
import QR from "../../assets/images/QR.png";

const PrintQRCode = () => {
  const printContents: any =
    document.getElementById("printable-div")?.innerHTML;
  const originalContents: any = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
};

export const DownloadAppPage = () => {
  const { selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  return (
    <>
      <div className={"container mx-auto px-6 py-4"}>
        <div className={"flex flex-row text-2xl font-medium pb-4 mb-1"}>
          <div className={"text-primary"}>Download resident app</div>
        </div>
        <h2 className={"text-left text-xl font-sans text-emph-high mb-4"}>
          Scan this QR code on your mobile phone to download the resident app.
          Please make sure to enable push notifications!
        </h2>

        <h4 className={"text-left text-md font-sans text-emph-high mb-4"}>
          Once you have the app, on-board yourself using your property&apos;s
          code.{" "}
          <p>
            Your property code is: <b>{selectedProperty?.propertyCode}</b>
          </p>
        </h4>

        <h4 className={"text-left text-md font-sans text-emph-high mb-4"}>
          After you are done, refresh this page and go to the next step.
        </h4>

        <div className={"w-64 h-64 border"}>
          <img alt={"App QR code"} src={QR} />
        </div>

        <ButtonSimple
          text={"Print"}
          btnType={"button"}
          btnStyle={"primary"}
          classes={"mt-4"}
          onClick={() => PrintQRCode()}
        />
      </div>

      <div
        id={"printable-div"}
        className={"invisible flex flex-col items-center"}>
        <div className={"w-full flex justify-center "}>
          <img alt={"App QR code"} className={"w-64 h-64 border"} src={QR} />
        </div>

        <p className={"py-4 text-center"}>
          Your building is using FabricApp for resident communications.
          <br />
          Scan and download FabricApp here.
        </p>
      </div>
    </>
  );
};
