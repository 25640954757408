import React from "react";
import { upperCaseFirst } from "upper-case-first";

type FormLabelProps = {
  text: string;
  htmlForId?: string;
  extraClassNames?: string;
};

export const FormLabel: React.FC<FormLabelProps> = (props) => {
  const { text, htmlForId, extraClassNames } = props;

  return (
    <label
      htmlFor={htmlForId ?? text.toLowerCase()}
      className={`block text-sm font-medium text-gray-700 ${extraClassNames}`}>
      {upperCaseFirst(text)}
    </label>
  );
};
