import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import Congrats from "../../assets/images/Congrats.png";
import SignupLayout from "./SignupLayout";
import {trackEvent} from "../../utils/Amplitude";

export const FinishScreen = (props: any) => {
  const { basePath } = props;

  const history = useHistory();
  const location: any = useLocation();
  console.log(location, "location");

  return (
    <SignupLayout>
      <div className={"mt-8"}>
        <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Congrats,{" "}
          <span className={"font-medium text-indigo-600 hover:text-indigo-500"}>
            {location?.state?.username}
          </span>
          !
        </h2>

        <div className={"flex justify-center"}>
          <img
            alt={"User Congrats"}
            className={"px-2 py-2 h-40 lg:max-w-md md:max-w-md"}
            src={Congrats}
          />
        </div>

        <h2 className={"mt-8 text-center text-lg font-medium text-emph-high"}>
          Your account and unique building profile has been setup.
        </h2>

        <h2 className={"mt-8 text-center text-lg font-medium text-emph-high"}>
          A FabricApp Team Member will get in touch with you to the email you
          provided within 1 business day to help you launch your first resident
          app.
        </h2>
      </div>

      <div className={"flex justify-center mt-5"}>
        <ButtonSimple
          text={"Go to Dashboard"}
          btnType={"submit"}
          btnStyle={"primary"}
          onClick={() => {
            trackEvent("Go to Dashboard Clicked!");
            history.push("/dashboard");
          }}
        />
      </div>
    </SignupLayout>
  );
};
