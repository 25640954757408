import "firebase/auth";
import "firebase/storage";

import firebase from "firebase/app";

const prodConfig = {
  apiKey: "AIzaSyDCukPYSpiLSQbJPQ7O07RqWH_TC6ol0t8",
  authDomain: "eagleridge-prod-3848e.firebaseapp.com",
  projectId: "eagleridge-prod-3848e",
  storageBucket: "eagleridge-prod-3848e.appspot.com",
  messagingSenderId: "1035066312742",
  appId: "1:1035066312742:web:09e82e622b447e14207ee7",
  measurementId: "G-QQ65XF0D3C",
};

const devConfig = {
  apiKey: "AIzaSyDzY9bn9pWo_0h961IspFO_oU690O_pRys",
  authDomain: "eagleridge-dev.firebaseapp.com",
  projectId: "eagleridge-dev",
  storageBucket: "eagleridge-dev.appspot.com",
  messagingSenderId: "508404134587",
  appId: "1:508404134587:web:41f2ce822b29226e606f9e",
  measurementId: "G-EYK19HSRH3",
};

// eslint-disable-next-line no-underscore-dangle
export const APP_ENV = window.__RUNTIME_CONFIG__.REACT_APP_FABRIC_ENV || "dev";

const envConfig = APP_ENV === "prod" ? prodConfig : devConfig;

console.log("Fabric App Env: ", APP_ENV);

const fbApp = firebase.initializeApp(envConfig);

// export const fbStorage = fbApp.storage();
export const fbAuth = fbApp.auth();

export type CurrentUser = firebase.User | undefined;
export type CurrentUserToken = string | undefined;
export type CurrentUserInfo = firebase.UserInfo | undefined;
