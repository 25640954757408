import dayjs from "dayjs";

export type MinutesStartEnd = {
  start: number; // max: 24*60 = 1440
  end: number;
};

const MINUTES_RESOLUTION = 30; // 1 ticker = 30 minutes

export function minutesToHrs(minutes: number): number {
  return Math.floor(minutes / MINUTES_RESOLUTION);
}

export function hrsToMinutes(hrs: number): number {
  return Math.floor(hrs * MINUTES_RESOLUTION);
}

export function tickerToDuration(ticker: number): string {
  return dayjs("1970-01-01")
    .minute(ticker * 30)
    .format("h:mm A");
}

export function minutesToDuration(minutes: number): string {
  if (minutes == null) {
    return "";
  }
  return dayjs("1970-01-01").minute(minutes).format("h:mm A");
}

export function minutesToTime(minutes: number): string {
  if (minutes == null) {
    return "";
  }
  return dayjs("1970-01-01").minute(minutes).format("HH:mm");
}

export function timeToMinutes(time: string): number {
  if (time == null) {
    return 0;
  }
  const hours = parseInt(time.split(":")[0], 10);
  const minutes = parseInt(time.split(":")[1], 10);
  return hours * 60 + minutes;
}
