// import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
// import { joiResolver } from "@hookform/resolvers/joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SharedStyles } from "../../themes/FabTheme";
import { AddAmenitySteps } from "./AddAmenitySteps";
import { AddAmenityWizardLayout } from "./AddAmenityWizardLayout";
import {
  AddAmenityStep4,
  addAmenityStep4Submit,
  resetAddAmenityStatus,
} from "./AmenitiesSlice";

type Step4Props = {
  basePath: string;
};

const schema = yup.object({
  rulesRegulations: yup.string().required().label("Rules and regulations"),
  opensAt: yup.string().optional().label("Opens At").nullable(),
  maxBookableMinutes: yup
    .number()
    .label("Booking buffer in minutes")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(),
  maxCapacity: yup
    .number()
    .label("Max capacity")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(),
  maxResidentsPerUnit: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required()
    .label("Max residents per unit")
    .min(0),
  bookingBuffer: yup
    .number()
    .label("Booking buffer")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  advancedBookingDays: yup
    .number()
    .label("Advanced booking in days")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(),
  price: yup
    .number()
    .label("Charges")
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable(),
  securityDeposit: yup
    .number()
    .label("Security Deposit")
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .min(0)
    .max(10000)
    .nullable(),
  cleaningFees: yup
    .number()
    .label("Cleaning Fees")
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable(),
  isChargedHourly: yup
    .boolean()
    .label("Charged Hourly")
    .transform((currentValue, originalValue) => {
      return originalValue == null ? false : currentValue;
    })
    .nullable(),
});

export const AddAmenityWizardStep4: FunctionComponent<Step4Props> = (props) => {
  const { basePath } = props;
  const history = useHistory();

  const { step4 } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddAmenityStep4>({
    // defaultValues: { ...step4 },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { status } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(resetAddAmenityStatus());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "STEP4_LOADING":
        break;
      case "STEP4_SUCCESS":
        history.push(`${basePath}/done`);
        break;
      default:
        break;
    }
  }, [basePath, history, status]);

  const onSubmit = (data: AddAmenityStep4) => {
    console.log("AddAmenityWizard3: data", data);
    dispatch(addAmenityStep4Submit(data));
  };

  console.log(errors);

  const wizardStepper = wizardStepperWrapper(AddAmenitySteps)(2);
  return (
    <AddAmenityWizardLayout>
      {wizardStepper}

      <div className={"flex flex-col items-center"}>
        <div
          className={"text-2xl font-medium text-heading text-center py-4 mb-4"}>
          What are the amenity rules and restrictions?
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col items-center max-w-2xl w-full"}>
          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"rulesRegulations"}
              className={"block text-sm font-medium text-gray-700"}>
              Rules and regulations for this amenity
            </label>
            <textarea
              rows={4}
              {...register("rulesRegulations")}
              id={"rulesRegulations"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Any special rules or restrictions for this amenity that residents
              should follow.
            </p>
            {errors.rulesRegulations && (
              <div className={"text-error"}>
                {errors.rulesRegulations.message}
              </div>
            )}
          </div>

          {/*max capacity*/}
          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"maxCapacity"}
              className={"block text-sm font-medium text-gray-700"}>
              What is the maximum capacity?
            </label>
            <input
              type={"number"}
              id={"maxCapacity"}
              {...register("maxCapacity")}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              The maximum number of people that can book a time slot.
            </p>
            {errors.maxCapacity && (
              <div className={"text-error"}>{errors.maxCapacity.message}</div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"maxBookableMinutes"}
              className={"block text-sm font-medium text-gray-700"}>
              How long in minutes can a person book?
            </label>
            <input
              type={"number"}
              {...register("maxBookableMinutes")}
              id={"maxDuration"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Max size of a time-slot.
            </p>
            {errors.maxBookableMinutes && (
              <div className={"text-error"}>
                {errors.maxBookableMinutes.message}
              </div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"maxResidentsPerUnit"}
              className={"block text-sm font-medium text-gray-700"}>
              How many residents can book per unit?
            </label>
            <input
              type={"number"}
              {...register("maxResidentsPerUnit")}
              id={"maxResidentsPerUnit"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              This will restrict how many residents per unit can be booked for
              the same time-slot.
            </p>
            {errors.maxResidentsPerUnit && (
              <div className={"text-error"}>
                {errors.maxResidentsPerUnit.message}
              </div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"bookingBuffer"}
              className={"block text-sm font-medium text-gray-700"}>
              Booking Buffer
            </label>
            <input
              type={"number"}
              {...register("bookingBuffer")}
              id={"bookingBuffer"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Minutes of buffer between the end of a booking and the start of
              the next booking.
            </p>
            {errors.bookingBuffer && (
              <div className={"text-error"}>{errors.bookingBuffer.message}</div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"advancedBookingDays"}
              className={"block text-sm font-medium text-gray-700"}>
              Advanced Booking
            </label>
            <input
              type={"number"}
              {...register("advancedBookingDays")}
              id={"advancedBookingDays"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Days in advance that a resident can book.
            </p>
            {errors.advancedBookingDays && (
              <div className={"text-error"}>
                {errors.advancedBookingDays.message}
              </div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"price"}
              className={"block text-sm font-medium text-gray-700"}>
              Charges
            </label>
            <input
              type={"number"}
              {...register("price")}
              id={"price"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Charges in C$ for this amenity. Enter only the $ amount.
            </p>
            {errors.price && (
              <div className={"text-error"}>{errors.price.message}</div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"isChargedHourly"}
              className={"block text-sm font-medium text-gray-700"}>
              Is the charge hourly?
            </label>
            <input
              type={"checkbox"}
              {...register("isChargedHourly", { required: false })}
              id={"isChargedHourly"}
              // className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              The rate in charges field will be applied hourly if this is set.
              Otherwise, it will be applied per whole booking.
            </p>
            {errors.isChargedHourly && (
              <div className={"text-error"}>
                {errors.isChargedHourly.message}
              </div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"securityDeposit"}
              className={"block text-sm font-medium text-gray-700"}>
              Security Deposit
            </label>
            <input
              type={"number"}
              {...register("securityDeposit")}
              id={"securityDeposit"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              This amount is put on hold on the credit card and released when
              the amenity booking is over.
            </p>
            {errors.securityDeposit && (
              <div className={"text-error"}>
                {errors.securityDeposit.message}
              </div>
            )}
          </div>

          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"cleaningFees"}
              className={"block text-sm font-medium text-gray-700"}>
              Cleaning Fees
            </label>
            <input
              type={"number"}
              {...register("cleaningFees")}
              id={"cleaningFees"}
              className={SharedStyles.inputs.input()}
            />
            <p className={"mt-2 text-sm text-gray-500"}>
              Cleaning fees are charged once at the end of the booking.
            </p>
            {errors.cleaningFees && (
              <div className={"text-error"}>{errors.cleaningFees.message}</div>
            )}
          </div>

          <div className={"py-6"}>
            <ButtonSimple
              text={"Next"}
              btnType={"submit"}
              btnStyle={"primary"}
            />
          </div>
        </form>
      </div>
    </AddAmenityWizardLayout>
  );
};
