import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EmptyStateImage } from "../../DefaultImages/EmptyStateImage";
import { loadResidentDirectoryListing } from "./ResidentDirectoryListingSlice";
import { ResidentUnitCard } from "./ResidentUnitCard";

type TowerProps = {
  name: string;
  units: Array<ManagersClient.ManagerGetUnitResDto>;
};

export const Towers: FunctionComponent<TowerProps> = (props) => {
  const { units } = props;

  const unitCards = units.map((unit) => {
    return <ResidentUnitCard key={unit.id} unit={unit} />;
  });

  return <>{unitCards}</>;
};

export const ResidentDirectoryListing: React.FC<unknown> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { units, status, error } = useAppSelector(
    (state) => state.residentDirectory.directoryListing,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  useEffect(() => {
    console.log("inside resident directory");
    dispatch(loadResidentDirectoryListing());
  }, [dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, path, status]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  if (!units || units.length < 1) {
    return (
      <EmptyStateImage
        text={"No units yet!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  return (
    <div className={"grid grid-cols-1 gap-6 pt-10 px-6"}>
      <Towers name={"Main"} units={units} />
    </div>
  );
};
