import React, { FunctionComponent } from "react";

import { useAppDispatch } from "../../app/hooks";
import { AddAmenityWizard } from "./AddAmenityWizard";

interface Props {
  basePath: string;
}

export const AddAmenityEditor: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(true);
  const { basePath } = props;

  return <AddAmenityWizard basePath={basePath} />;
};
