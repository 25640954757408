import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../../api/managersApi";
import type { AppThunk } from "../../../app/store";
import { getSelectionIds } from "../../../utils/Helpers";

export interface ResidentDirectoryListingSliceState {
  selectedTower?: string;
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  units?: Array<ManagersClient.ManagerGetUnitResDto>;
  error?: string;
  deletingResident?: string;
}

const initialState: ResidentDirectoryListingSliceState = {
  status: "IDLE",
};

export const ResidentDirectoryListingSlice = createSlice({
  name: "residentDirectoryListing",
  initialState,
  reducers: {
    getResidentDirectoryStart: (state) => {
      state.status = "LOADING";
    },
    getResidentDirectorySuccess: (
      state,
      action: PayloadAction<{
        units: Array<ManagersClient.ManagerGetUnitResDto>;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.units = action.payload.units;
    },
    getResidentDirectoryFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
    deleteResidentStart: (state, action: PayloadAction<string>) => {
      state.deletingResident = action.payload;
    },
    deleteResidentEnd: (state) => {
      state.deletingResident = undefined;
    },
  },
});

export const {
  getResidentDirectoryStart,
  getResidentDirectorySuccess,
  getResidentDirectoryFailure,
  deleteResidentEnd,
  deleteResidentStart,
} = ResidentDirectoryListingSlice.actions;

export const residentDirectoryListingReducer =
  ResidentDirectoryListingSlice.reducer;

export const loadResidentDirectoryListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getResidentDirectoryStart());
      const { orgId, propertyId, buildingId } = getSelectionIds(getState());

      const units = (
        await managersApi.getUnitsByBuilding({
          orgId,
          propertyId,
          buildingId,
        })
      ).results;

      dispatch(getResidentDirectorySuccess({ units }));
    } catch (err: any) {
      dispatch(getResidentDirectoryFailure(err.toString()));
    }
  };

export const deleteUnitResident =
  (residentId: string, unitId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { orgId, propertyId, buildingId } = getSelectionIds(getState());

      dispatch(deleteResidentStart(residentId));
      await managersApi.deleteResident({
        orgId,
        propertyId,
        buildingId,
        unitId,
        residentId,
      });

      dispatch(loadResidentDirectoryListing());
      dispatch(deleteResidentEnd());
      // eslint-disable-next-line no-empty
    } catch (err: any) {
      dispatch(deleteResidentEnd());
    }
  };
