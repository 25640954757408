import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";
import { HiOutlineDocumentText } from "react-icons/hi";

import { LinkSimple } from "../../components/LinkSimple";

type DocumentGroupsSideNavProps = {
  url: string;
  documents: ManagersClient.SharedGetDocumentResDto[] | undefined;
};

export const DocumentGroupsSideNav: React.FC<DocumentGroupsSideNavProps> = (
  props,
) => {
  const { url, documents } = props;

  return (
    <div
      className={
        "flex flex-col w-64 border-r border-border-low space-y-8 py-4"
      }>
      {documents &&
        documents.map((d) => {
          return (
            <LinkSimple
              text={d.title}
              key={d.id}
              destination={`${url}/other/${d.id}`}
              SVGImageIcon={<HiOutlineDocumentText size={20} />}
            />
          );

          return null;
        })}
    </div>
  );
};
