import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";
import { sortDescByCreatedAt } from "../../utils/SortUtils";

export interface FabricAppPerksListingSliceState {
  getAllStatus: "IDLE" | "ALL_LOADING" | "ALL_SUCCESS" | "ALL_ERROR";
  getAllError?: string;
  getOneStatus: "IDLE" | "ONE_LOADING" | "ONE_SUCCESS" | "ONE_ERROR";
  getOneError?: string;
  fabPerksAdapter: EntityState<ManagersClient.ManagerGetPerkResDto>;
}

const fabPerksAdapter =
  createEntityAdapter<ManagersClient.ManagerGetPerkResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: FabricAppPerksListingSliceState = {
  getAllStatus: "IDLE",
  getOneStatus: "IDLE",
  fabPerksAdapter: fabPerksAdapter.getInitialState(),
};

export const FabricAppPerksListingSlice = createSlice({
  name: "fabricAppPerksListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
    },
    getAllPerksStart: (state) => {
      state.getAllStatus = "ALL_LOADING";
    },
    getAllPerksSuccess: (
      state,
      action: PayloadAction<{
        fabPerks: Array<ManagersClient.ManagerGetPerkResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_SUCCESS";
      state.getAllError = undefined;
      fabPerksAdapter.setAll(state.fabPerksAdapter, action.payload.fabPerks);
    },
    getAllPerksFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetGetOneStatus: (state) => {
      state.getOneStatus = "IDLE";
    },
    getOnePerksStart: (state) => {
      state.getOneStatus = "ONE_LOADING";
    },
    getOnePerksSuccess: (
      state,
      action: PayloadAction<{
        fabPerk: ManagersClient.ManagerGetPerkResDto;
      }>,
    ) => {
      state.getOneStatus = "ONE_SUCCESS";
      state.getOneError = undefined;
      fabPerksAdapter.setOne(state.fabPerksAdapter, action.payload.fabPerk);
    },
    getOnePerksFailure: (state, action: PayloadAction<string>) => {
      state.getOneStatus = "ONE_ERROR";
      state.getOneError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllPerksStart,
  getAllPerksSuccess,
  getAllPerksFailure,
  resetGetOneStatus,
  getOnePerksSuccess,
  getOnePerksStart,
  getOnePerksFailure,
} = FabricAppPerksListingSlice.actions;

export const fabricAppPerksListingReducer = FabricAppPerksListingSlice.reducer;

export const loadFabricAppPerksListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getAllPerksStart());
      const { orgId, propertyId } = getSelectionIds(getState());

      const fabPerks = (
        await managersApi.getPerks({
          orgId,
          propertyId,
          type: ManagersClient.GetPerksTypeEnum.Fabricapp,
        })
      ).results;
      dispatch(getAllPerksSuccess({ fabPerks }));
    } catch (err: any) {
      dispatch(getAllPerksFailure(prettyRawError(err)));
    }
  };

export const loadFabricAppPerkById =
  (perkId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getOnePerksStart());
      const { orgId, propertyId } = getSelectionIds(getState());

      const fabPerk = await managersApi.getPerkById({
        orgId,
        propertyId,
        type: ManagersClient.GetPerkByIdTypeEnum.Fabricapp,
        perkId,
      });
      dispatch(getOnePerksSuccess({ fabPerk }));
    } catch (err: any) {
      dispatch(getOnePerksFailure(prettyRawError(err)));
    }
  };

export const fabPerksSelector = fabPerksAdapter.getSelectors<RootState>(
  (state) => state.perks.fabricAppPerksListing.fabPerksAdapter,
);
