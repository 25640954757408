import { Prisma } from "@fabricapp-ca/fabricapp-api-shared";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  ManagerAmenityDto,
  SharedAmenityScheduleDto,
} from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { minutesToTime, timeToMinutes } from "../../utils/DateTimeUtils";
import { getSelectionIds } from "../../utils/Helpers";
import { YesNoEnum } from "./AddNewAmenityState";

export interface AmenitiesSliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  error?: string;
  amenities?: Array<ManagersClient.ManagerAmenityDto>;
  amenitiesAdapter: EntityState<ManagersClient.ManagerAmenityDto>;
  addAmenityState: AddAmenityState;
}

export interface AddAmenityStep1 {
  id?: string;
  amenityType: Prisma.AmenityType;
  amenityChargeType: Prisma.AmenityChargeType;
  buildingId: string;
}

export interface AddAmenityStep2 {
  name: string;
  description: string;
  location: string;
}

export interface Times {
  start: number;
  end: number;
}

export interface AmenitySchedule {
  day: Prisma.Day;
  openTimes: Times;
  cleaningTimes: Times;
  open: YesNoEnum;
}

export interface AddAmenityStep4 {
  rulesRegulations: string;
  opensAt?: Date;
  maxBookableMinutes?: number;
  maxCapacity?: number;
  maxResidentsPerUnit?: number;
  bookingBuffer?: number;
  advancedBookingDays?: number;
  price?: number;
  securityDeposit?: number;
  cleaningFees?: number;
  isChargedHourly?: boolean;
}

export interface AddAmenityState {
  status:
    | "IDLE"
    | "STEP1_LOADING"
    | "STEP1_SUCCESS"
    | "STEP2_LOADING"
    | "STEP2_SUCCESS"
    | "STEP3_LOADING"
    | "STEP3_SUCCESS"
    | "STEP4_LOADING"
    | "STEP4_SUCCESS"
    | "SUBMIT_SUCCESS"
    | "SUBMIT_LOADING"
    | "SUBMIT_ERROR";
  step1: AddAmenityStep1;
  step2: AddAmenityStep2;
  step3: Record<Prisma.Day, AmenitySchedule>;
  step4: AddAmenityStep4;
  error?: string;
}

const amenitiesAdapter = createEntityAdapter<ManagersClient.ManagerAmenityDto>(
  {},
);

const initialState: AmenitiesSliceState = {
  status: "IDLE",
  amenitiesAdapter: amenitiesAdapter.getInitialState(),
  addAmenityState: {
    status: "IDLE",
    step1: {} as AddAmenityStep1,
    step2: {} as AddAmenityStep2,
    step3: {
      MONDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.MONDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      TUESDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.TUESDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      WEDNESDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.WEDNESDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      THURSDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.THURSDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      FRIDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.FRIDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      SATURDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.SATURDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
      SUNDAY: {
        openTimes: {
          start: 9 * 60,
          end: 17 * 60,
        },
        open: YesNoEnum.No,
        day: Prisma.Day.SUNDAY,
        cleaningTimes: {
          start: 0,
          end: 0,
        },
      },
    },
    step4: {} as AddAmenityStep4,
  },
};

export const AmenitiesListingSlice = createSlice({
  name: "amenitiesListingSlice",
  initialState,
  reducers: {
    resetAddAmenityStatus: (state) => {
      state.addAmenityState.status = "IDLE";
    },
    getAmenitiesListingStart: (state) => {
      state.status = "LOADING";
    },
    getAmenitiesListingSuccess: (
      state,
      action: PayloadAction<{
        amenities: Array<ManagersClient.ManagerAmenityDto>;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.amenities = action.payload.amenities;
      amenitiesAdapter.setAll(state.amenitiesAdapter, action.payload.amenities);
    },
    getAmenitiesListingFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
    saveAmenityStart: (state) => {
      state.addAmenityState.status = "SUBMIT_LOADING";
    },
    saveAmenitySuccess: (state) => {
      state.addAmenityState.status = "SUBMIT_SUCCESS";
    },
    saveAmenityFailure: (state, action: PayloadAction<string>) => {
      state.addAmenityState.status = "SUBMIT_ERROR";
      state.addAmenityState.error = action.payload;
    },
    addAmenityStep1Submit: (state, action: PayloadAction<AddAmenityStep1>) => {
      state.addAmenityState.step1 = action.payload;
      console.log(
        "amenity state",
        state.addAmenityState.step1,
        state.addAmenityState.step2,
        state.addAmenityState.step3,
      );
      state.addAmenityState.status = "STEP1_SUCCESS";
    },
    addAmenityStep2Submit: (state, action: PayloadAction<AddAmenityStep2>) => {
      console.log(
        "amenity state",
        state.addAmenityState.step1,
        state.addAmenityState.step2,
        state.addAmenityState.step3,
      );
      state.addAmenityState.step2 = action.payload;
      state.addAmenityState.status = "STEP2_SUCCESS";
    },
    addAmenityStep3Submit: (
      state,
      action: PayloadAction<Record<Prisma.Day, AmenitySchedule>>,
    ) => {
      console.log(
        "amenity state",
        state.addAmenityState.step1,
        state.addAmenityState.step2,
        state.addAmenityState.step3,
      );
      state.addAmenityState.step3 = action.payload;
      state.addAmenityState.status = "STEP3_SUCCESS";
    },
    addAmenityStep4Submit: (state, action: PayloadAction<AddAmenityStep4>) => {
      state.addAmenityState.step4 = action.payload;
      state.addAmenityState.status = "STEP4_SUCCESS";
    },
    addAmenitySetExistingAmenityToEdit: (
      state,
      action: PayloadAction<ManagerAmenityDto>,
    ) => {
      state.addAmenityState.step1 = {
        amenityType: action.payload.amenityType as Prisma.AmenityType,
        amenityChargeType: action.payload
          .amenityChargeType as Prisma.AmenityChargeType,
        buildingId: action.payload.buildingId || "",
        id: action.payload.id,
      };
      state.addAmenityState.step2 = {
        name: action.payload.name,
        description: action.payload.description,
        location: action.payload.location,
      };
      state.addAmenityState.step3 = getSchedulesFromDto(
        action.payload.schedules,
      );
      state.addAmenityState.step4 = {
        rulesRegulations: action.payload.rulesRegulations,
        opensAt: action.payload.opensAt,
        maxBookableMinutes: action.payload.maxBookableMinutes,
        maxCapacity: action.payload.maxCapacity,
        maxResidentsPerUnit: action.payload.maxResidentsPerUnit,
        bookingBuffer: action.payload.bookingBuffer,
        advancedBookingDays: action.payload.advancedBookingDays,
        price: action.payload.price,
        securityDeposit: action.payload.securityDeposit,
        cleaningFees: action.payload.cleaningFees,
        isChargedHourly: action.payload.isChargedHourly,
      };
    },
    resetAddAmenityState: (state) => {
      state.addAmenityState = initialState.addAmenityState;
    },
  },
});

const getSchedulesFromDto = (schedules: Array<SharedAmenityScheduleDto>) => {
  const result: Record<Prisma.Day, AmenitySchedule> = {} as any;
  schedules.forEach((schedule) => {
    result[schedule.day as Prisma.Day] = {
      openTimes: {
        start: timeToMinutes(schedule.openTime),
        end: timeToMinutes(schedule.closeTime),
      },
      open: !schedule.closed ? YesNoEnum.Yes : YesNoEnum.No,
      day: schedule.day as Prisma.Day,
      cleaningTimes: {
        start: 0,
        end: 0,
      },
    };
  });
  return result;
};

export const {
  getAmenitiesListingStart,
  getAmenitiesListingSuccess,
  getAmenitiesListingFailure,
  addAmenitySetExistingAmenityToEdit,
  resetAddAmenityState,
  resetAddAmenityStatus,
  saveAmenityStart,
  saveAmenitySuccess,
  saveAmenityFailure,
  addAmenityStep1Submit,
  addAmenityStep2Submit,
  addAmenityStep3Submit,
  addAmenityStep4Submit,
} = AmenitiesListingSlice.actions;

export const amenitiesListingReducer = AmenitiesListingSlice.reducer;

export const loadAmenitiesListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getAmenitiesListingStart());

      const { orgId, propertyId } = getSelectionIds(getState());

      const amenities: ManagersClient.ManagerGetAmenitiesResDto =
        await managersApi.getAmenitiesForProperty({
          propertyId,
          orgId,
        });

      dispatch(
        getAmenitiesListingSuccess({
          amenities: amenities.amenities,
        }),
      );
    } catch (err: any) {
      dispatch(getAmenitiesListingFailure(err.toString()));
    }
  };

export const saveAmenity = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(saveAmenityStart());

    const { orgId, propertyId } = getSelectionIds(getState());
    const { addAmenityState: amenity } = getState().amenities.amenities;

    const schedules: Array<SharedAmenityScheduleDto> = [];
    Object.entries(amenity.step3).forEach(([day, schedule]) => {
      const scheduleDto: SharedAmenityScheduleDto =
        {} as SharedAmenityScheduleDto;
      scheduleDto.day = day.toUpperCase();
      scheduleDto.closed = schedule.open === YesNoEnum.No;
      scheduleDto.openTime = minutesToTime(schedule.openTimes.start);
      scheduleDto.closeTime = minutesToTime(schedule.openTimes.end);
      schedules.push(scheduleDto);
    });

    const dto: ManagerAmenityDto = {
      advancedBookingDays: amenity.step4.advancedBookingDays,
      buildingId: amenity.step1.buildingId,
      amenityType: amenity.step1.amenityType,
      amenityChargeType: amenity.step1.amenityChargeType,
      bookingBuffer: amenity.step4.bookingBuffer,
      cleaningFees: amenity.step4.cleaningFees,
      description: amenity.step2.description,
      isChargedHourly: amenity.step4.isChargedHourly,
      location: amenity.step2.location,
      maxBookableMinutes: amenity.step4.maxBookableMinutes,
      maxCapacity: amenity.step4.maxCapacity,
      maxResidentsPerUnit: amenity.step4.maxResidentsPerUnit,
      opensAt: amenity.step4.opensAt,
      price: amenity.step4.price,
      rulesRegulations: amenity.step4.rulesRegulations,
      schedules,
      securityDeposit: amenity.step4.securityDeposit,
      name: amenity.step2.name,
      id: amenity.step1.id,
    };

    await managersApi.createNewAmenityForProperty({
      propertyId,
      orgId,
      managerAmenityDto: dto,
    });

    dispatch(saveAmenitySuccess());
  } catch (err: any) {
    dispatch(saveAmenityFailure(err.toString()));
  }
};

export const amenitiesListingSelector =
  amenitiesAdapter.getSelectors<RootState>(
    (state) => state.amenities.amenities.amenitiesAdapter,
  );
