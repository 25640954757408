import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../../api/managersApi";
import type { RootState } from "../../../app/rootReducer";
import type { AppThunk } from "../../../app/store";
import { prettyRawError } from "../../../utils/ErrorParseDisplay";
import { MaybeT } from "../../../utils/SimpleTypes";
import { sortDescByCreatedAt } from "../../../utils/SortUtils";

export interface PendingResidentsListingSliceState {
  getAllStatus:
    | "IDLE"
    | "ALL_PENDING_LOADING"
    | "ALL_PENDING_SUCCESS"
    | "ALL_PENDING_ERROR";
  getAllError?: string;
  approvePendingStatus:
    | "IDLE"
    | "APPROVE_PENDING_LOADING"
    | "APPROVE_PENDING_SUCCESS"
    | "APPROVE_PENDING_ERROR";
  approvePendingError?: string;
  denyPendingStatus:
    | "IDLE"
    | "DENY_PENDING_LOADING"
    | "DENY_PENDING_SUCCESS"
    | "DENY_PENDING_ERROR";
  denyPendingError?: string;
  pendingResidentsAdapter: EntityState<ManagersClient.ManagerGetPendingResidentDto>;
}

const pendingResidentsAdapter =
  createEntityAdapter<ManagersClient.ManagerGetPendingResidentDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: PendingResidentsListingSliceState = {
  getAllStatus: "IDLE",
  approvePendingStatus: "IDLE",
  denyPendingStatus: "IDLE",
  pendingResidentsAdapter: pendingResidentsAdapter.getInitialState(),
};

export const PendingResidentsListingSlice = createSlice({
  name: "pendingResidentsListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.approvePendingError = undefined;
      state.getAllError = undefined;
    },
    getAllPendingStart: (state) => {
      state.getAllStatus = "ALL_PENDING_LOADING";
      state.getAllError = undefined;
    },
    getAllPendingSuccess: (
      state,
      action: PayloadAction<{
        pendingResidents: Array<ManagersClient.ManagerGetPendingResidentDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_PENDING_SUCCESS";
      pendingResidentsAdapter.setAll(
        state.pendingResidentsAdapter,
        action.payload.pendingResidents,
      );
      state.getAllError = undefined;
    },
    getAllPendingFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_PENDING_ERROR";
      state.getAllError = action.payload;
    },

    resetApprovePendingStatus: (state) => {
      state.approvePendingStatus = "IDLE";
      state.approvePendingError = undefined;
    },
    approvePendingStart: (state) => {
      state.approvePendingStatus = "APPROVE_PENDING_LOADING";
    },
    approvePendingSuccess: (state) => {
      state.approvePendingStatus = "APPROVE_PENDING_SUCCESS";
      state.approvePendingError = undefined;
    },
    approvePendingFailure: (state, action: PayloadAction<string>) => {
      state.approvePendingStatus = "APPROVE_PENDING_ERROR";
      state.approvePendingError = action.payload;
    },

    resetDenyPendingStatus: (state) => {
      state.denyPendingStatus = "IDLE";
      state.denyPendingError = undefined;
    },
    denyPendingStart: (state) => {
      state.denyPendingStatus = "DENY_PENDING_LOADING";
    },
    denyPendingSuccess: (state) => {
      state.denyPendingStatus = "DENY_PENDING_SUCCESS";
      state.denyPendingError = undefined;
    },
    denyPendingFailure: (state, action: PayloadAction<string>) => {
      state.denyPendingStatus = "DENY_PENDING_ERROR";
      state.denyPendingError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllPendingStart,
  getAllPendingSuccess,
  getAllPendingFailure,
  resetApprovePendingStatus,
  approvePendingSuccess,
  approvePendingStart,
  approvePendingFailure,
  resetDenyPendingStatus,
  denyPendingSuccess,
  denyPendingStart,
  denyPendingFailure,
} = PendingResidentsListingSlice.actions;

export const pendingResidentsListingReducer =
  PendingResidentsListingSlice.reducer;

export const loadPendingResidentsListing =
  (input: { orgId: string; propertyId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId } = input;
    try {
      dispatch(getAllPendingStart());
      const pendingResidents = (
        await managersApi.getPendingResidentsByProperty({
          orgId,
          propertyId,
        })
      ).results;
      dispatch(getAllPendingSuccess({ pendingResidents }));
    } catch (err: any) {
      dispatch(getAllPendingFailure(prettyRawError(err)));
    }
  };

export const approvePendingResident =
  (input: {
    orgId: string;
    propertyId: string;
    pendingResidentId: string;
    managerPutPendingResidentApproveDto: ManagersClient.ManagerPutPendingResidentApproveDto;
  }): AppThunk =>
  async (dispatch) => {
    const {
      orgId,
      propertyId,
      pendingResidentId,
      managerPutPendingResidentApproveDto,
    } = input;
    try {
      dispatch(approvePendingStart());
      await managersApi.putApprovePendingResident({
        orgId,
        propertyId,
        pendingResidentId,
        managerPutPendingResidentApproveDto,
      });
      dispatch(approvePendingSuccess());
    } catch (err: any) {
      dispatch(approvePendingFailure(prettyRawError(err)));
    }
  };

export const denyPendingResident =
  (input: {
    orgId: string;
    propertyId: string;
    pendingResidentId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, pendingResidentId } = input;
    try {
      dispatch(denyPendingStart());
      await managersApi.putDenyPendingResident({
        orgId,
        propertyId,
        pendingResidentId,
      });
      dispatch(denyPendingSuccess());
    } catch (err: any) {
      dispatch(denyPendingFailure(prettyRawError(err)));
    }
  };

export const pendingResidentsSelector =
  pendingResidentsAdapter.getSelectors<RootState>(
    (state) =>
      state.residentDirectory.pendingResidentsListing.pendingResidentsAdapter,
  );

export const pendingResidentCountSelector = (state: RootState): number => {
  return pendingResidentsSelector.selectTotal(state);
};

export const pendingResidentIsEmptySelector = (state: RootState): boolean => {
  return pendingResidentsSelector.selectTotal(state) === 0;
};

export const pendingResidentByIdSelector = (
  state: RootState,
  pendingResidentId: string,
): MaybeT<ManagersClient.ManagerGetPendingResidentDto> => {
  const allItems = pendingResidentsSelector.selectAll(state);
  return allItems.find((x) => x.id === pendingResidentId);
};
