import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectInspect from "object-inspect";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { parseErrorForDisplay } from "../../utils/ErrorParseDisplay";

export type Step1AgreementsGetData = {
  agreements: Array<ManagersClient.ManagerGetOnboardingAgreementResDto>;
};

export type Step2ProfileGetData = {
  profile: ManagersClient.ManagerGetOnboardingProfileResDto;
};

export type Step1AgreementsPostData = {
  agreements: Array<ManagersClient.ManagerPostOnboardingAgreementReqDto>;
};

export type Step2ProfilePostData = {
  profile: ManagersClient.ManagerPostOnboardingProfileReqDto;
  authentication: ManagersClient.ManagerPostOnboardingAuthReqDto;
};

export type Step3AuthenticationPostData = {
  authentication: ManagersClient.ManagerPostOnboardingAuthReqDto;
};

export type Step3FormData = Step3AuthenticationPostData & {
  verificationCode: number;
};

export interface OnboardingState {
  wizardStepStatus:
    | "IDLE"
    | "LOADING_GET_ENTRYPOINT"
    | "SUCCESS_GET_ENTRYPOINT"
    | "ERROR_GET_ENTRYPOINT"
    | "STEP1_LOADING"
    | "STEP1_SUCCESS"
    | "STEP2_LOADING"
    | "STEP2_SUCCESS"
    | "STEP3_LOADING_SENDING_OTP"
    | "STEP3_SUCCESS_SENDING_OTP"
    | "STEP3_ERROR_SENDING_OTP"
    | "STEP3_LOADING_VERIFYING_OTP"
    | "STEP3_SUCCESS_VERIFYING_OTP"
    | "STEP3_ERROR_VERIFYING_OTP"
    | "LOADING_POST_ENTRYPOINT"
    | "SUCCESS_POST_ENTRYPOINT"
    | "ERROR_POST_ENTRYPOINT";
  managerEntryPointState?: ManagersClient.ManagerGetOnboardingEntrypointResDto;
  step1AgreementData?: Step1AgreementsPostData;
  step2ProfileData?: Step2ProfilePostData;
  step3AuthenticationData?: Step3AuthenticationPostData;
  getEntrypointError?: string;
  postEntrypointError?: string;
}

const initialState: OnboardingState = {
  wizardStepStatus: "IDLE",
};

export const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    onboardingStep0Start: (state) => {
      state.wizardStepStatus = "LOADING_GET_ENTRYPOINT";
    },
    onboardingStep0Success: (
      state,
      action: PayloadAction<{
        managerEntryPointState: ManagersClient.ManagerGetOnboardingEntrypointResDto;
      }>,
    ) => {
      state.wizardStepStatus = "SUCCESS_GET_ENTRYPOINT";
      state.managerEntryPointState = action.payload.managerEntryPointState;
      state.getEntrypointError = undefined;
    },
    onboardingStep0Failure: (state, action: PayloadAction<string>) => {
      state.wizardStepStatus = "ERROR_GET_ENTRYPOINT";
      state.getEntrypointError = action.payload;
    },

    onboardingStep1Loading: (state) => {
      state.wizardStepStatus = "STEP1_LOADING";
    },
    onboardingStep1Success: (
      state,
      action: PayloadAction<Step1AgreementsPostData>,
    ) => {
      state.wizardStepStatus = "STEP1_SUCCESS";
      state.step1AgreementData = action.payload;
    },

    onboardingStep2Loading: (state) => {
      state.wizardStepStatus = "STEP2_LOADING";
    },
    onboardingStep2Success: (
      state,
      action: PayloadAction<Step2ProfilePostData>,
    ) => {
      state.wizardStepStatus = "STEP2_SUCCESS";
      state.step2ProfileData = action.payload;
      state.postEntrypointError = undefined;
    },

    sendOtpCodeOnboardingStep3Loading: (state) => {
      state.wizardStepStatus = "STEP3_LOADING_SENDING_OTP";
    },
    sendOtpCodeOnboardingStep3Success: (
      state,
      action: PayloadAction<Step3AuthenticationPostData>,
    ) => {
      state.wizardStepStatus = "STEP3_SUCCESS_SENDING_OTP";
      state.step3AuthenticationData = action.payload;
      state.postEntrypointError = undefined;
    },
    sendOtpCodeOnboardingStep3Failure: (
      state,
      action: PayloadAction<{ error: string }>,
    ) => {
      state.wizardStepStatus = "STEP3_ERROR_SENDING_OTP";
      state.postEntrypointError = action.payload.error;
    },

    verifyOtpCodeOnboardingStep3Loading: (state) => {
      state.wizardStepStatus = "STEP3_LOADING_VERIFYING_OTP";
    },
    verifyOtpCodeOnboardingStep3Success: (state) => {
      state.wizardStepStatus = "STEP3_SUCCESS_VERIFYING_OTP";
    },
    verifyOtpCodeOnboardingStep3Failure: (
      state,
      action: PayloadAction<{ error: string }>,
    ) => {
      state.wizardStepStatus = "STEP3_ERROR_VERIFYING_OTP";
      state.postEntrypointError = action.payload.error;
    },

    completeOnboardingLoading: (state) => {
      state.wizardStepStatus = "LOADING_POST_ENTRYPOINT";
    },
    completeOnboardingSuccess: (state) => {
      state.wizardStepStatus = "SUCCESS_POST_ENTRYPOINT";
    },
    completeOnboardingFailure: (
      state,
      action: PayloadAction<{ error: string }>,
    ) => {
      state.wizardStepStatus = "ERROR_POST_ENTRYPOINT";
      state.postEntrypointError = action.payload.error;
    },
  },
});

export const {
  onboardingStep0Start,
  onboardingStep0Success,
  onboardingStep0Failure,
  onboardingStep1Loading,
  onboardingStep1Success,
  onboardingStep2Loading,
  onboardingStep2Success,
  sendOtpCodeOnboardingStep3Loading,
  sendOtpCodeOnboardingStep3Success,
  sendOtpCodeOnboardingStep3Failure,
  verifyOtpCodeOnboardingStep3Loading,
  verifyOtpCodeOnboardingStep3Success,
  verifyOtpCodeOnboardingStep3Failure,
  completeOnboardingLoading,
  completeOnboardingSuccess,
  completeOnboardingFailure,
} = OnboardingSlice.actions;

export const onboardingReducer = OnboardingSlice.reducer;

export const loadOnboardingEntryPoint = (): AppThunk => async (dispatch) => {
  try {
    dispatch(onboardingStep0Start());
    const managerEntryPoint: ManagersClient.ManagerGetOnboardingEntrypointResDto =
      await managersApi.getManagerOnboardingEntrypoint();
    if (!managerEntryPoint) {
      dispatch(onboardingStep0Failure("Error fetching entrypoint data"));
      return;
    }
    dispatch(
      onboardingStep0Success({
        managerEntryPointState: managerEntryPoint,
      }),
    );
  } catch (err: any) {
    dispatch(onboardingStep0Failure(err.toString()));
  }
};

export const saveOnboardingStep1 =
  (step1Data: Step1AgreementsPostData): AppThunk =>
  async (dispatch) => {
    dispatch(onboardingStep1Loading());
    dispatch(onboardingStep1Success(step1Data));
  };

export const saveOnboardingStep2 =
  (step2Data: Step2ProfilePostData): AppThunk =>
  async (dispatch) => {
    dispatch(onboardingStep2Loading());
    dispatch(onboardingStep2Success(step2Data));
  };

export const sendOtpCodeOnboardingStep3 =
  (step3Data: Step3FormData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(sendOtpCodeOnboardingStep3Loading());
      // todo: call API
      dispatch(sendOtpCodeOnboardingStep3Success(step3Data));
    } catch (error: any) {
      dispatch(sendOtpCodeOnboardingStep3Failure({ error }));
    }
  };

export const verifyOtpCodeOnboardingStep3 =
  (step3Data: Step3FormData): AppThunk =>
  async (dispatch) => {
    try {
      console.log("OnboardingSlice | ", {
        otpCode: step3Data.verificationCode,
      });
      dispatch(verifyOtpCodeOnboardingStep3Loading());

      await managersApi.postManagerVerify2fa({
        managerVerify2faResDto: {
          verificationCode: step3Data.verificationCode,
          id: "some-id",
        },
      });
      dispatch(verifyOtpCodeOnboardingStep3Success());
    } catch (error) {
      console.log(
        "OnboardingSlice | verifyOtpCodeOnboardingStep3 error",
        objectInspect({ error }),
      );
      dispatch(
        verifyOtpCodeOnboardingStep3Failure({
          error: parseErrorForDisplay(error),
        }),
      );
    }
  };

export const completeOnboarding =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(completeOnboardingLoading());

      const { step1AgreementData, step2ProfileData } = getState().onboarding;
      if (!step1AgreementData || !step2ProfileData) {
        dispatch(
          completeOnboardingFailure({
            error: "Missing required step data",
          }),
        );
        return;
      }

      await managersApi.postManagerOnboardingEntrypoint({
        managerPostOnboardingEntrypointReqDto: {
          id: "some-id",
          agreements: step1AgreementData.agreements,
          profile: step2ProfileData.profile,
          authentication: step2ProfileData.authentication,
        },
      });
      dispatch(completeOnboardingSuccess());
    } catch (error: any) {
      const errorMessage = await error.json();
      console.log(
        "OnboardingSlice | completeOnboarding error",
        objectInspect({ error }),
      );
      dispatch(
        completeOnboardingFailure({
          error: errorMessage.message,
        }),
      );
    }
  };
