import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { ManagerTitleCheckBoxSelect } from "../../components/forms/ManagerTitleCheckBoxSelect";
import { SharedStyles } from "../../themes/FabTheme";
import { saveStep2, Step2ProfilePostData } from "./SetupStepsSlice";
import {trackEvent} from "../../utils/Amplitude";

export const SetupProfileComposer = (props: any) => {
  const { basePath } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { wizardStepStatus, step2ProfileData } = useAppSelector(
    (state) => state.setupSteps,
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const schema = Joi.object<Step2ProfilePostData>({
    profile: Joi.object({
      firstName: SchemaValidation.FieldRequiredString({
        label: "First Name",
        min: 1,
      }),
      lastName: SchemaValidation.FieldRequiredString({
        label: "Last Name",
        min: 1,
      }),
      title: SchemaValidation.FieldRequiredString({
        label: "Role",
      }),
      organizationName: SchemaValidation.FieldRequiredString({
        label: "Organization Name",
      }),
    }),
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<Step2ProfilePostData>({
    defaultValues: {
      profile: step2ProfileData?.profile,
    },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "STEP2_LOADING":
        setIsLoading(true);
        break;
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [basePath, history, wizardStepStatus]);

  const onSubmit = (step2Data: Step2ProfilePostData) => {
    trackEvent("Next Clicked!");
    console.log("step2Data", step2Data);
    dispatch(saveStep2({ ...step2Data }));
    setIsLoading(true);
    history.push("/signup/setup-property");
  };

  return (
    <div
      className={
        "max-w-lg mx-auto flex flex-col lg:my-2 lg:px-8 lg:py-5 lg:space-y-8"
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"First Name"} htmlForId={"firstName"} />
            <input
              type={"text"}
              {...register("profile.firstName")}
              className={SharedStyles.inputs.input()}
            />
            {errors?.profile?.firstName && (
              <div className={"text-error"}>
                {errors.profile.firstName.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Last Name"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("profile.lastName")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors?.profile?.lastName && (
              <div className={"text-error"}>
                {errors.profile.lastName.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel
              text={"Which title best describes your role at this property?"}
            />
            <ManagerTitleCheckBoxSelect
              roleValue={
                getValues().profile?.title ? getValues().profile?.title : ""
              }
              setValue={setValue}
              valueName={"profile.title"}
            />
            {errors?.profile?.title && (
              <div className={"text-error"}>{errors.profile.title.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel
              text={"Organization Name"}
              htmlForId={"organizationName"}
            />
            <input
              type={"text"}
              {...register("profile.organizationName")}
              className={SharedStyles.inputs.input()}
            />
            {errors?.profile?.organizationName && (
              <div className={"text-error"}>
                {errors.profile.organizationName.message}
              </div>
            )}
            <div className={"text-sm text-emph-high mt-2"}>
              The legal name of your strata / homeowner&apos;s association or
              the legal name of your property management company.
            </div>
          </div>
        </div>
        <div className={"flex justify-center mt-5"}>
          <ButtonSimple
            text={"Next"}
            btnType={"submit"}
            btnStyle={"primary"}
            isDisabled={!isValid}
            isSpinning={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
