import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FormLabel } from "../../../components/forms/FormLabel";
import { ManagerTitleCheckBoxSelect } from "../../../components/forms/ManagerTitleCheckBoxSelect";
import { SharedStyles } from "../../../themes/FabTheme";
import { prettyRawError } from "../../../utils/ErrorParseDisplay";
import {
  deleteInvitedManager,
  postInvitedManager,
  resetDeleteInvitedStatus,
  resetPostInvitedStatus,
} from "./InvitedManagersListingSlice";
import {trackEvent} from "../../../utils/Amplitude";

type Props = {
  basePath: string;
  existingDto?: ManagersClient.ManagerGetInvitedManagerResDto;
};

const schema = Joi.object<ManagersClient.ManagerPostInvitedManagerReqDto>({
  firstName: SchemaValidation.FieldRequiredString({ label: "Firstname" }),
  email: SchemaValidation.FieldRequiredString({ label: "Email" }),
  managerTitle: SchemaValidation.FieldRequiredString({
    label: "Title",
  }),
});

export const InvitedManagerComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath, existingDto } = props;
  const defaultValues = existingDto || {};

  // forms
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ManagersClient.ManagerGetInvitedManagerResDto>({
    defaultValues,
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  // rtk
  const dispatch = useAppDispatch();
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  const {
    postInvitedStatus,
    postInvitedError,
    deleteInvitedStatus,
    deleteInvitedError,
  } = useAppSelector((state) => state.managerDirectory.invitedManagersListing);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(
    "InvitedManagerComposer | InvitedManagerComposer - errors",
    prettyRawError(errors),
  );

  // effects
  React.useEffect(() => {
    return () => {
      dispatch(resetPostInvitedStatus());
      dispatch(resetDeleteInvitedStatus());
    };
  }, [dispatch]);

  React.useEffect(() => {
    switch (postInvitedStatus) {
      case "POST_INVITED_LOADING":
        setIsLoading(true);
        break;
      case "POST_INVITED_SUCCESS":
        setIsLoading(false);
        history.push(basePath);
        break;
      case "POST_INVITED_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [postInvitedStatus, basePath, history]);

  React.useEffect(() => {
    switch (deleteInvitedStatus) {
      case "DELETE_INVITED_LOADING":
        setIsLoading(true);
        break;
      case "DELETE_INVITED_SUCCESS":
        setIsLoading(false);
        history.push(basePath);
        break;
      case "DELETE_INVITED_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [deleteInvitedStatus, basePath, history]);

  async function onApprove(data: any) {
    trackEvent("Send Invite Button Clicked!");
    const dto: ManagersClient.ManagerPostInvitedManagerReqDto = {
      ...data,
      propertyIds: [],
    };
    console.log(
      "InvitedManagerComposer: data",
      prettyRawError(data),
      prettyRawError(dto),
    );
    if (selectedOrg && selectedProperty) {
      dispatch(
        postInvitedManager({
          orgId: selectedOrg.id,
          dto,
        }),
      );
    }
  }

  async function onDelete() {
    trackEvent("Delete Button Clicked!");
    if (selectedOrg && existingDto) {
      dispatch(
        deleteInvitedManager({
          orgId: selectedOrg.id,
          invitedManagerId: existingDto.id,
        }),
      );
    }
  }

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4"}>
        Invite Manager
      </div>

      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"First Name"} />
            <input
              type={"text"}
              {...register("firstName")}
              className={SharedStyles.inputs.input()}
              readOnly={!!existingDto}
            />
            {errors.firstName && (
              <div className={"text-error"}>{errors.firstName.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Email"} />
            <div className={"mt-1"}>
              <input
                type={"text"}
                {...register("email")}
                className={SharedStyles.inputs.input()}
                readOnly={!!existingDto}
              />
            </div>
            {errors.email && (
              <div className={"text-error"}>{errors.email.message}</div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel
              text={
                "Which title best describes this person's role at this property?"
              }
            />
            <ManagerTitleCheckBoxSelect
              roleValue={
                getValues().managerTitle ? getValues().managerTitle : ""
              }
              setValue={setValue}
              valueName={"managerTitle"}
            />
            {errors?.managerTitle && (
              <div className={"text-error"}>{errors.managerTitle.message}</div>
            )}
          </div>
        </div>

        <>
          <div className={"py-3 mt-2"}>
            <div className={"space-x-4"}>
              {!existingDto && (
                <ButtonSimple
                  text={"Send invite"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onApprove)}
                />
              )}
              {existingDto && (
                <ButtonSimple
                  text={"Delete invite"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  btnIntent={"error"}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onDelete)}
                />
              )}
            </div>
          </div>
          {postInvitedError && <ErrorView message={postInvitedError} />}
          {deleteInvitedError && <ErrorView message={deleteInvitedError} />}
        </>
      </form>
    </div>
  );
};
