import { track } from "@amplitude/analytics-browser";

export function trackScreen(screenName: string): void {
  try {
    track(screenName, { source: screenName, screenVisit: true });
  } catch (e) {
    console.error(e);
  }
}

export function trackEvent(eventName: string) {
  try {
    track(eventName, { source: window.location.pathname, event: true });
  } catch (e) {
    console.error(e);
  }
}
