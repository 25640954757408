import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import { createFirebaseAuthEnhancer } from "../middleware/FirebaseMiddleware";
import rootReducer, { RootState } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const firebaseAuthEnhancer = createFirebaseAuthEnhancer();
    return getDefaultMiddleware().concat(firebaseAuthEnhancer);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
