import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";

interface Props {
  btnText: string;
  onClick: () => void;
}

export const SignInButton: React.FC<Props> = (props) => {
  const { btnText, onClick } = props;
  return (
    <div className={"flex justify-center mt-6"}>
      <ButtonSimple
        text={btnText}
        btnType={"submit"}
        btnStyle={"secondary"}
        onClick={onClick}
      />
    </div>
  );
};
