import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SubRouteProps } from "../../components/SubRouteProps";
import { firebaseVerifyEmail, resetToInitial } from "./FirebaseSlice";
import LoginLayout from "./LoginLayout";
import { SignInButton } from "./SignInButton";

export const SignInStep3EmailVerifyView: React.FC<SubRouteProps> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUserInfo, error, authStatus } = useAppSelector(
    (state) => state.auth.firebaseAuth,
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  // If user is already logged-in, re-direct them to the dashboard
  React.useEffect(() => {
    if (currentUserInfo) {
      console.log("SignInStep3EmailVerifyView | history.push /dashboard");
      history.push("/dashboard");
    } else {
      // uses the window.location.href to extract verification params
      dispatch(firebaseVerifyEmail());
    }
  }, [currentUserInfo, dispatch, history]);

  React.useEffect(() => {
    switch (authStatus) {
      case "VERIFY_LOADING":
        setIsLoading(true);
        break;
      case "VERIFY_DONE":
        setIsLoading(false);
        history.push(`${basePath}/auth-status`);
        break;
      case "VERIFY_ERROR":
        setIsLoading(false);
        setHasError(true);
        break;
      default:
        break;
    }
  }, [authStatus, basePath, history]);

  if (isLoading) {
    return (
      <LoginLayout>
        <LoadingView />
      </LoginLayout>
    );
  }

  if (hasError && error) {
    return (
      <LoginLayout>
        <ErrorView message={error} />
        <SignInButton
          btnText={"Sign-in using another email"}
          onClick={() => {
            dispatch(resetToInitial());
            history.push(`${basePath}/enter-email`);
          }}
        />
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <div className={"flex flex-col items-center justify-center"}>
        <div className={"mt-3 text-2xl font-medium text-default"}>
          Email Link Verified
        </div>
        <div className={"w-24 h-24 my-4"}>
          <LoadingView />
        </div>
        <div className={"text-default text-center"}>
          Loading account information ...
        </div>
      </div>
    </LoginLayout>
  );
};
