import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../../api/managersApi";
import type { RootState } from "../../../app/rootReducer";
import type { AppThunk } from "../../../app/store";
import { MaybeT } from "../../../utils/SimpleTypes";
import { sortDescByCreatedAt } from "../../../utils/SortUtils";

export interface ApprovedManagersListingSliceState {
  getAllStatus:
    | "IDLE"
    | "ALL_APPROVED_LOADING"
    | "ALL_APPROVED_SUCCESS"
    | "ALL_APPROVED_ERROR";
  getAllError?: string;
  approvedManagersAdapter: EntityState<ManagersClient.ManagerGetApprovedManagerResDto>;
}

const approvedManagersAdapter =
  createEntityAdapter<ManagersClient.ManagerGetApprovedManagerResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: ApprovedManagersListingSliceState = {
  getAllStatus: "IDLE",
  approvedManagersAdapter: approvedManagersAdapter.getInitialState(),
};

export const ApprovedManagersListingSlice = createSlice({
  name: "approvedManagersListing",
  initialState,
  reducers: {
    getApprovedManagersStart: (state) => {
      state.getAllStatus = "ALL_APPROVED_LOADING";
      state.getAllError = undefined;
    },
    getApprovedManagersSuccess: (
      state,
      action: PayloadAction<{
        approvedManagers: Array<ManagersClient.ManagerGetApprovedManagerResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_APPROVED_SUCCESS";
      approvedManagersAdapter.setAll(
        state.approvedManagersAdapter,
        action.payload.approvedManagers,
      );
    },
    getApprovedManagersFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_APPROVED_ERROR";
      state.getAllError = action.payload;
    },
  },
});

export const {
  getApprovedManagersStart,
  getApprovedManagersSuccess,
  getApprovedManagersFailure,
} = ApprovedManagersListingSlice.actions;

export const approvedManagersListingReducer =
  ApprovedManagersListingSlice.reducer;

export const loadApprovedManagersListing =
  (input: { orgId: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getApprovedManagersStart());
      const approvedManagers = (
        await managersApi.getApprovedManagers({
          orgId: input.orgId,
        })
      ).results;
      dispatch(getApprovedManagersSuccess({ approvedManagers }));
    } catch (err: any) {
      dispatch(getApprovedManagersFailure(err.toString()));
    }
  };

export const approvedManagersSelector =
  approvedManagersAdapter.getSelectors<RootState>(
    (state) =>
      state.managerDirectory.approvedManagersListing.approvedManagersAdapter,
  );

export const approvedManagerCountSelector = (state: RootState): number => {
  return approvedManagersSelector.selectTotal(state);
};

export const approvedManagerIsEmptySelector = (state: RootState): boolean => {
  return approvedManagersSelector.selectTotal(state) === 0;
};

export const approvedManagerByIdSelector = (
  state: RootState,
  approvedManagerId: string,
): MaybeT<ManagersClient.ManagerGetApprovedManagerResDto> => {
  const allItems = approvedManagersSelector.selectAll(state);
  return allItems.find((x) => x.id === approvedManagerId);
};
