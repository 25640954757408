import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { PropertyContactComposer } from "./PropertyContactComposer";
import { PropertyContactsTabs } from "./PropertyContactsTabs";

export const PropertyContactsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Property Contacts Clicked!");

  const pathToAdd = `${path}/add-property-contact`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Property Contacts</div>
        </Link>
      </div>
      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Add phone number(s) and email(s) for contacts residents might need.
        Examples: property manager, council members, emergency lines. Contacts
        will be instantly viewable in the resident app after they are added.
      </h2>

      <Switch>
        <Route path={pathToAdd}>
          <PropertyContactComposer basePath={path} />
        </Route>
        <Route path={path}>
          <PropertyContactsTabs basePath={path} addNewPath={pathToAdd} />
        </Route>
      </Switch>
    </div>
  );
};
