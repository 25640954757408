import { Prisma } from "@fabricapp-ca/fabricapp-api-shared";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { sentenceCase } from "change-case";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { minutesToDuration } from "../../utils/DateTimeUtils";
import { AddAmenitySteps } from "./AddAmenitySteps";
import { AddAmenityWizardLayout } from "./AddAmenityWizardLayout";
import { DayOfTheWeekType } from "./AddAmenityWizardStep3";
import { saveAmenity } from "./AmenitiesSlice";
import {YesNoEnum} from "./AddNewAmenityState";

type DoneProps = {
  redirectPath: string;
};
export const AddAmenityWizardDone: FunctionComponent<DoneProps> = (props) => {
  const { redirectPath } = props;
  const history = useHistory();

  const { addAmenityState } = useAppSelector(
    (state) => state.amenities.amenities,
  );

  const { selectedBuildings } = useAppSelector(
    (state) => state.selectionContext,
  );

  const buildingSelected = selectedBuildings?.find(
    (b) => b.id === addAmenityState?.step1?.buildingId,
  );

  const { status } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    switch (status) {
      case "SUBMIT_LOADING":
        break;
      case "SUBMIT_SUCCESS":
        history.push(`${redirectPath}`);
        break;
      default:
        break;
    }
  }, [redirectPath, history, status]);

  const getOpenHoursText = (open: number, close: number) => {
    return `Open Hours: 
    ${minutesToDuration(open)} - ${minutesToDuration(close)}`;
  };

  const wizardStepper = wizardStepperWrapper(AddAmenitySteps)(3);
  return (
    <AddAmenityWizardLayout>
      {wizardStepper}
      <div className={"flex flex-col items-center"}>
        <div
          className={"container flex flex-col items-center mx-auto px-6 py-10"}>
          <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
            Are you ready to create this amenity?
          </div>

          <div className={"text-emph-low"}>
            This amenity will be visible to your residents right-away!
          </div>

          <div className={"flex flex-col space-y-4 my-4 text-center w-full"}>
            <div className={"flex flex-col"}>
              <div className={"text-lg text-heading font-semibold"}>
                Location
              </div>
              <div className={"flex flex-col space-y-2"}>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>
                    {buildingSelected?.name}
                  </div>
                </div>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-default"}>
                    {sentenceCase(addAmenityState.step1.amenityType)}
                  </div>
                </div>
              </div>
            </div>
            <div className={"flex flex-col"}>
              <div className={"text-lg text-heading font-semibold"}>
                Schedule
              </div>
              <div className={"flex flex-col space-y-2"}>
                {Object.keys(DayOfTheWeekType).map((day) => {
                  const schedule = addAmenityState.step3[day as Prisma.Day];
                  return (
                    <div
                      className={
                        "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                      }>
                      <div className={"text-emph-high"}>{day}</div>
                      <div
                        className={
                          "flex flex-col items-end justify-center space-y-2"
                        }>
                        <div className={"text-default"}>
                          {schedule.open === YesNoEnum.Yes
                            ? getOpenHoursText(
                                schedule.openTimes.start,
                                schedule.openTimes.end,
                              )
                            : "Closed"}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"flex flex-col"}>
              <div className={"text-lg text-heading font-semibold"}>Rules</div>
              <div className={"flex flex-col space-y-2"}>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>Max capacity</div>
                  <div className={"text-default"}>
                    {addAmenityState.step4.maxCapacity}
                  </div>
                </div>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>Booking duration</div>
                  <div className={"text-default"}>
                    {addAmenityState.step4.maxBookableMinutes} minutes
                  </div>
                </div>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>Advanced booking</div>
                  <div className={"text-default"}>
                    {addAmenityState.step4.advancedBookingDays} days
                  </div>
                </div>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>Buffer time</div>
                  <div className={"text-default"}>
                    {addAmenityState.step4.bookingBuffer} minutes
                  </div>
                </div>
                <div
                  className={
                    "flex flex-row w-2/4 mx-auto items-center justify-between py-4 border-b"
                  }>
                  <div className={"text-emph-high"}>Important Rules</div>
                  <div className={"text-default text-right w-2/3"}>
                    {addAmenityState.step4.rulesRegulations}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"py-6"}>
          <ButtonSimple
            text={"Create Now"}
            btnType={"button"}
            btnStyle={"primary"}
            onClick={() => dispatch(saveAmenity())}
          />
        </div>
      </div>
    </AddAmenityWizardLayout>
  );
};
