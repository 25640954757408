import React, { FunctionComponent } from "react";

import { SharedStyles } from "../../themes/FabTheme";

type UnitGroupRowProps = {
  index: number;
  name: string;
  floor: string;
};

export const UnitNumberRow: FunctionComponent<UnitGroupRowProps> = (props) => {
  const { index, name, floor } = props;

  return (
    <tr key={index as any}>
      <td className={"p-3"}>
        <span
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}>
          {name}
        </span>
      </td>
      <td className={"p-3"}>
        <span
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}>
          {floor}
        </span>
      </td>
    </tr>
  );
};
