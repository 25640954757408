import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { UnitNumbersTabs } from "./UnitNumbersTabs";

export const UnitNumbersNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();
  trackEvent("Unit Numbers Clicked!");

  const pathToAdd = `${path}/add-units`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-1"}>
        <Link to={path}>
          <div className={"text-primary"}>Unit Numbers</div>
        </Link>
      </div>
      <h2 className={"text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Add all Property Units before inviting residents to the mobile app.
        This will make it easy to keep track of who/has not signed up yet. Enter
        multiple units at once by clicking the + Unit button to drop another
        row.
      </h2>

      <Switch>
        <Route path={path}>
          <UnitNumbersTabs basePath={path} addNewPath={pathToAdd} />
        </Route>
      </Switch>
    </div>
  );
};
