import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { FabricAppPerkComposer } from "./FabricAppPerkComposer";
import { PerksTabs } from "./PerksTabs";

export const PerksNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  const addFabricAppPerkPath = `${path}/add-fabperk`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Perks</div>
        </Link>
      </div>

      <Switch>
        <Route path={addFabricAppPerkPath}>
          <FabricAppPerkComposer basePath={path} />
        </Route>
        <Route path={path}>
          <PerksTabs basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
