import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { DocumentsListing } from "./DocumentsListing";

export const DocumentsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Documents Clicked!");

  console.log("Documents | Documents");

  return (
    <Switch>
      <Route path={path}>
        <DocumentsListing />
      </Route>
    </Switch>
  );
};
