import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import {
  buildingsIsEmptySelector,
  getAllBuildingsListing,
} from "../Buildings/BuildingsListingSlice";
import { ResidentProfile } from "./DirectoryListing/ResidentProfile";
import { ResidentDirectoryTabs } from "./ResidentDirectoryTabs";
import {trackEvent} from "../../utils/Amplitude";

const ResidentDirectoryNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Resident Directory Clicked!");

  // preload buildings
  const dispatch = useAppDispatch();
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );
  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.buildings.buildingsListing,
  );
  const buildingsIsEmpty = buildingsIsEmptySelector(store.getState());
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (buildingsIsEmpty) {
      if (selectedOrg && selectedProperty) {
        dispatch(
          getAllBuildingsListing({
            orgId: selectedOrg.id,
            propertyId: selectedProperty.id,
          }),
        );
      }
    }
  }, [buildingsIsEmpty, dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "GET_ALL_LOADING":
        setIsLoading(true);
        break;
      case "GET_ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "GET_ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  return (
    <div className={"container mx-auto py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Resident Directory</div>
        </Link>
      </div>

      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 The Resident Directory is your source of truth for resident
        information. When someone signs up for the resident mobile app, their
        contact card will appear in the Resident Profile tab.
      </h2>

      <Switch>
        <Route path={`${path}/view-profile`}>
          <ResidentProfile basePath={path} />
        </Route>
        <Route path={path}>
          <ResidentDirectoryTabs basePath={path} />
        </Route>
        {/*<Route path={`${path}/add-new`}>*/}
        {/*  <AddAmenityWizardNew basePath={path} />*/}
        {/*</Route>*/}
      </Switch>
    </div>
  );
};

export default ResidentDirectoryNav;
