import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { ReminderComposer } from "./ReminderComposer";
import { RemindersListing } from "./RemindersListing";

export const RemindersNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Reminders Clicked!");

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Reminders</div>
        </Link>
      </div>
      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 By default, Reminders are broadcast to All Residents. Use them to
        remind residents of a building rule, an upcoming event, and more!
        Reminders will be instantly viewable by all residents after you hit
        send.
      </h2>

      <Switch>
        <Route exact path={`${path}/create`}>
          <ReminderComposer basePath={path} />
        </Route>
        <Route path={path}>
          <RemindersListing basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
