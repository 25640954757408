import React from "react";

import { StatsCard, StatsCardProps } from "./StatsCard";

export type StatsRowProps = { stats: Array<StatsCardProps> };

export const StatsRow: React.FC<StatsRowProps> = (props) => {
  const { stats } = props;

  const statsCards = stats.map((stat) => {
    return (
      <StatsCard
        key={stat.label}
        label={stat.label}
        value={stat.value}
        iconType={stat.iconType}
      />
    );
  });

  return (
    <div className={"w-full flex flex-col p-4 mt-10 border rounded-md"}>
      <div className={"flex items-center"}>
        <div className={"p-4 w-full"}>
          <div className={"grid grid-cols-12 gap-4"}>{statsCards}</div>
        </div>
      </div>
    </div>
  );
};
