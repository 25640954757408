import React from "react";

import { SetupPropertyComposer } from "./SetupPropertyComposer";
import SignupLayout from "./SignupLayout";

export const SetupProperty = (props: any) => {
  const { basePath } = props;

  return (
    <SignupLayout>
      <div className={"mt-16"}>
        <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Setup your Property
        </h2>
        <h2 className={"mt-2 text-center text-lg font-medium text-emph-high"}>
          This is so we can create a dashboard for your property and associate a
          resident app to your account.
        </h2>
        <SetupPropertyComposer />
      </div>
    </SignupLayout>
  );
};
