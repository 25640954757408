import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { BuildingComposer } from "./BuildingComposer";
import { BuildingListing } from "./BuildingListing";

export type Props = {
  basePath: string;
};

export const BuildingNav: React.FC<Props> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const addBuildingPath = `${path}/add`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Buildings</div>
        </Link>
      </div>

      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add New Building"}
          btnType={"button"}
          btnStyle={"secondary"}
          onClick={() => history.push(addBuildingPath)}
        />
      </div>

      <Switch>
        <Route path={addBuildingPath}>
          <BuildingComposer basePath={path} />
        </Route>
        <Route path={path}>
          <BuildingListing basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
