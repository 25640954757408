import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { SentAnnouncementsListing } from "./SentAnnouncementsListing";
import { SentAnnouncementViewer } from "./SentAnnouncementViewer";

export const SentAnnouncementsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full px-6"}>
        <Switch>
          <Route path={`${path}/:announcementId`}>
            <SentAnnouncementViewer basePath={path} />
          </Route>
          <Route path={`${path}`}>
            <SentAnnouncementsListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
