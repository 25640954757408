/**
 *
 * @export
 * @interface SignupDto
 */
import { exists } from "@fabricapp-ca/fabricapp-openapi/dist/generated/admin-ts-fetch";

export interface SignupDto {
  /**
   *
   * @type {string}
   * @memberof SignupDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SignupDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SignupDto
   */
  country: SignupDtoCountryEnum;
  /**
   *
   * @type {boolean}
   * @memberof SignupDto
   */
  agreement: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum SignupDtoCountryEnum {
  USA = "USA",
  CANADA = "CANADA",
}

export function SignupDtoFromJSON(
  json: any,
): SignupDto {
  return SignupDtoFromJSONTyped(json, false);
}

export function SignupDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SignupDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json.name,
    email: !exists(json, "email") ? undefined : json.email,
    country: json.country,
    agreement: json.agreement,
  };
}

export function SignupDtoToJSON(
  value?: SignupDto | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    country: value.country,
    agreement: value.agreement,
  };
}
