import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { capitalCase } from "change-case";
import React, { FunctionComponent } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { upperCaseFirst } from "upper-case-first";

import { getAvatarOrPhotoUrl } from "../../../utils/AvatarInitials";
import { ResidentUnitUserCard } from "./ResidentUnitUserCard";
import { TemporaryResidentUnitUserCard } from "./TemporaryResidentUnitUserCard";

type ResidentUnitCardProps = {
  unit: ManagersClient.ManagerGetUnitResDto;
};

export const ResidentUnitCard: FunctionComponent<ResidentUnitCardProps> = (
  props,
) => {
  const { unit } = props;

  const { url } = useRouteMatch();
  const [residents, setResidents] = React.useState<
    Array<ManagersClient.ManagerGetResidentProfileDto>
  >([...unit.residents]);

  const unitUserCards: Array<JSX.Element> = residents.map((resident) => {
    const avatarUrl = getAvatarOrPhotoUrl({
      firstName: resident.firstName,
      lastName: resident.lastName,
      photoPath: resident.photoPath,
    });

    const unitUser: JSX.Element = (
      <ResidentUnitUserCard
        key={resident.id}
        name={`${resident.firstName} ${resident.lastName}`}
        role={capitalCase(resident.role.toLowerCase())}
        avatar={avatarUrl}
        phoneNumber={resident.phoneNumber}
        email={resident.email}
        unitId={unit.id}
        residentId={resident.id}
      />
    );
    return unitUser;
  });

  const temporaryResidentCards: Array<JSX.Element> =
    unit.temporaryResidents.map((item: any) => {
      const temporaryResident: JSX.Element = (
        <TemporaryResidentUnitUserCard key={item.id} email={item.email} />
      );
      return temporaryResident;
    });

  const emptyStateView = (
    <div className={"w-full flex py-4 px-5 mt-5 text-emph-high text-center"}>
      {upperCaseFirst("No approved residents in this unit yet!")}
    </div>
  );

  const derivedView =
    !residents || residents.length < 1 ? emptyStateView : unitUserCards;

  return (
    <div className={"border rounded-md shadow-sm flex flex-col my-2"}>
      <div
        className={
          "flex flex-row align-center justify-between my-2 px-5 border-b-2"
        }>
        <div className={"flex flex-col my-2"}>
          <div className={"text-xl font-medium mt-2"}>Unit {unit.name}</div>
        </div>
        <Link
          to={`/dashboard/unit-directory/${unit.id}`}
          className={"underline text-primary self-center"}>
          View Profile
        </Link>
      </div>

      <div className={"grid grid-cols-2"}>
        {derivedView}
        {temporaryResidentCards}
      </div>
    </div>
  );
};
