import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import * as promiseUtils from "blend-promise-utils";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { getSelectionIds } from "../../utils/Helpers";

export interface SentAnnouncementsListingSliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  error?: string;
  sentAnnouncements?: Array<ManagersClient.ManagerGetAnnouncementResDto>;
  sentAnnouncementsAdapter: EntityState<ManagersClient.ManagerGetAnnouncementResDto>;
}

const sentAnnouncementsAdapter =
  createEntityAdapter<ManagersClient.ManagerGetAnnouncementResDto>({
    selectId: (sentAnnouncement) => sentAnnouncement.id,
    // sortComparer: (a, b) => a.content.t.title.localeCompare(b.title),
  });

const initialState: SentAnnouncementsListingSliceState = {
  status: "IDLE",
  sentAnnouncementsAdapter: sentAnnouncementsAdapter.getInitialState(),
};

export const SentAnnouncementsListingSlice = createSlice({
  name: "sentAnnouncementsListing",
  initialState,
  reducers: {
    getSentAnnouncementsListingStart: (state) => {
      state.status = "LOADING";
    },
    getSentAnnouncementsListingSuccess: (
      state,
      action: PayloadAction<{
        sentAnnouncements: Array<ManagersClient.ManagerGetAnnouncementResDto>;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.sentAnnouncements = action.payload.sentAnnouncements;
      sentAnnouncementsAdapter.setAll(
        state.sentAnnouncementsAdapter,
        action.payload.sentAnnouncements,
      );
    },
    getSentAnnouncementsListingFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
  },
});

export const {
  getSentAnnouncementsListingStart,
  getSentAnnouncementsListingSuccess,
  getSentAnnouncementsListingFailure,
} = SentAnnouncementsListingSlice.actions;

export const sentAnnouncementsListingReducer =
  SentAnnouncementsListingSlice.reducer;

export const loadSentAnnouncementsListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getSentAnnouncementsListingStart());

      const { orgId, propertyId } = getSelectionIds(getState());

      await promiseUtils.delay(10);
      const foo: ManagersClient.ManagerPageOfGetAnnouncementsResDto =
        await managersApi.getAnnouncements({
          status: ManagersClient.GetAnnouncementsStatusEnum.Sent,
          propertyId,
          orgId,
        });

      dispatch(
        getSentAnnouncementsListingSuccess({
          sentAnnouncements: foo.results,
        }),
      );
    } catch (err: any) {
      dispatch(getSentAnnouncementsListingFailure(err.toString()));
    }
  };

export const sentAnnouncementsSelector =
  sentAnnouncementsAdapter.getSelectors<RootState>(
    (state) => state.announcements.sentAnnouncements.sentAnnouncementsAdapter,
  );
