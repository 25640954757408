import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { getSelectionIds } from "../../utils/Helpers";

export interface UnitProfilesListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  postOneStatus:
    | "IDLE"
    | "POST_ONE_LOADING"
    | "POST_ONE_SUCCESS"
    | "POST_ONE_ERROR";
  postOneError?: string;
  updateDeleteOneStatus:
    | "IDLE"
    | "UPDATE_DELETE_ONE_LOADING"
    | "UPDATE_DELETE_ONE_SUCCESS"
    | "UPDATE_DELETE_ONE_ERROR";
  updateDeleteOneError?: string;
}

const initialState: UnitProfilesListingSliceState = {
  getAllStatus: "IDLE",
  postOneStatus: "IDLE",
  updateDeleteOneStatus: "IDLE",
};

export const UnitProfilesListingSlice = createSlice({
  name: "unitGroupsListing",
  initialState,
  reducers: {
    resetPostOneUnitProfileStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    resetUnitProfilesStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    resetUpdateDeleteOneUnitProfileStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    postOneUnitProfileStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOneUnitProfileStart: (state) => {
      state.postOneStatus = "POST_ONE_LOADING";
    },
    postOneUnitProfileSuccess: (state) => {
      state.postOneStatus = "POST_ONE_SUCCESS";
      state.postOneError = undefined;
    },
    postOneUnitProfileFailure: (state, action: PayloadAction<string>) => {
      state.postOneStatus = "POST_ONE_ERROR";
      state.postOneError = action.payload;
    },
  },
});

export const {
  resetUnitProfilesStatus,
  resetPostOneUnitProfileStatus,
  resetUpdateDeleteOneUnitProfileStatus,
  postOneUnitProfileStatus,
  postOneUnitProfileSuccess,
  postOneUnitProfileStart,
  postOneUnitProfileFailure,
} = UnitProfilesListingSlice.actions;

export const unitProfilesListingReducer = UnitProfilesListingSlice.reducer;

export const updateUnitProfile =
  (input: {
    unitId: string;
    dto: ManagersClient.ManagerGetUnitOnlyResDto;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { unitId, dto } = input;
    const { orgId, propertyId, buildingId } = getSelectionIds(getState());
    try {
      dispatch(postOneUnitProfileStart());
      await managersApi.updateUnitProfile({
        orgId,
        propertyId,
        buildingId,
        unitId,
        managerGetUnitOnlyResDto: dto,
      });
      dispatch(postOneUnitProfileSuccess());
    } catch (err: any) {
      console.error(err);
      const error = await err.json();
      dispatch(postOneUnitProfileFailure(prettyRawError(error.message)));
    }
  };
