import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { sortDescByCreatedAt } from "../../utils/SortUtils";
import { updateSelectedBuildings } from "../SelectionContext/SelectionContextSlice";
import {LocalStorageAPI} from "../../api/localStorageAPI";

export interface BuildingsListingSliceState {
  getAllStatus:
    | "IDLE"
    | "GET_ALL_LOADING"
    | "GET_ALL_SUCCESS"
    | "GET_ALL_ERROR";
  getAllError?: string;
  postOneStatus:
    | "IDLE"
    | "POST_ONE_LOADING"
    | "POST_ONE_SUCCESS"
    | "POST_ONE_ERROR";
  postOneError?: string;
  updateDeleteOneStatus:
    | "IDLE"
    | "UPDATE_DELETE_ONE_LOADING"
    | "UPDATE_DELETE_ONE_SUCCESS"
    | "UPDATE_DELETE_ONE_ERROR";
  updateDeleteOneError?: string;
  buildingsAdapter: EntityState<ManagersClient.ManagerGetBuildingResDto>;
}

const buildingsAdapter =
  createEntityAdapter<ManagersClient.ManagerGetBuildingResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: BuildingsListingSliceState = {
  getAllStatus: "IDLE",
  postOneStatus: "IDLE",
  updateDeleteOneStatus: "IDLE",
  buildingsAdapter: buildingsAdapter.getInitialState(),
};

export const BuildingsListingSlice = createSlice({
  name: "buildingsListing",
  initialState,
  reducers: {
    resetGetAllBuildingsStatus: (state) => {
      state.getAllStatus = "IDLE";
      state.getAllError = undefined;
    },
    getAllBuildingsStart: (state) => {
      state.getAllStatus = "GET_ALL_LOADING";
    },
    getAllBuildingsSuccess: (
      state,
      action: PayloadAction<{
        buildings: Array<ManagersClient.ManagerGetBuildingResDto>;
      }>,
    ) => {
      state.getAllStatus = "GET_ALL_SUCCESS";
      buildingsAdapter.setAll(state.buildingsAdapter, action.payload.buildings);
      state.getAllError = undefined;
    },
    getAllBuildingsFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "GET_ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetPostOneBuildingsStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOneBuildingStatus: (state) => {
      state.postOneStatus = "IDLE";
      state.postOneError = undefined;
    },
    postOneBuildingStart: (state) => {
      state.postOneStatus = "POST_ONE_LOADING";
    },
    postOneBuildingSuccess: (state) => {
      state.postOneStatus = "POST_ONE_SUCCESS";
      state.postOneError = undefined;
    },
    postOneBuildingFailure: (state, action: PayloadAction<string>) => {
      state.postOneStatus = "POST_ONE_ERROR";
      state.postOneError = action.payload;
    },

    resetUpdateDeleteOneBuildingsStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneBuildingStatus: (state) => {
      state.updateDeleteOneStatus = "IDLE";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneBuildingStart: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_LOADING";
    },
    updateDeleteOneBuildingSuccess: (state) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_SUCCESS";
      state.updateDeleteOneError = undefined;
    },
    updateDeleteOneBuildingFailure: (state, action: PayloadAction<string>) => {
      state.updateDeleteOneStatus = "UPDATE_DELETE_ONE_ERROR";
      state.updateDeleteOneError = action.payload;
    },
  },
});

export const {
  resetGetAllBuildingsStatus,
  getAllBuildingsStart,
  getAllBuildingsSuccess,
  getAllBuildingsFailure,
  resetPostOneBuildingsStatus,
  postOneBuildingStatus,
  postOneBuildingSuccess,
  postOneBuildingStart,
  postOneBuildingFailure,
  resetUpdateDeleteOneBuildingsStatus,
  updateDeleteOneBuildingSuccess,
  updateDeleteOneBuildingStart,
  updateDeleteOneBuildingFailure,
} = BuildingsListingSlice.actions;

export const buildingsListingReducer = BuildingsListingSlice.reducer;

export const getAllBuildingsListing =
  (input: { orgId: string; propertyId: string }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId } = input;
    try {
      dispatch(getAllBuildingsStart());
      const buildings = (
        await managersApi.getBuildingsByProperty({
          orgId,
          propertyId,
        })
      ).results;
      dispatch(
        getAllBuildingsSuccess({
          buildings,
        }),
      );
      const selectedBuildingsObj = { selectedBuildings: buildings };
      dispatch(updateSelectedBuildings(selectedBuildingsObj));
      await LocalStorageAPI.setJson("selectedBuildings", selectedBuildingsObj);
    } catch (err: any) {
      dispatch(getAllBuildingsFailure(prettyRawError(err)));
    }
  };

export const postOneBuilding =
  (input: {
    orgId: string;
    propertyId: string;
    dto: ManagersClient.ManagerPostBuildingReqDto;
  }): AppThunk =>
  async (dispatch) => {
    const { orgId, propertyId, dto } = input;
    try {
      dispatch(postOneBuildingStart());
      await managersApi.postBuilding({
        orgId,
        propertyId,
        managerPostBuildingReqDto: dto,
      });
      dispatch(postOneBuildingSuccess());
    } catch (err: any) {
      dispatch(postOneBuildingFailure(prettyRawError(err)));
    }
  };

// export const updateOneBuilding =
//   (input: {
//     orgId: string;
//     propertyId: string;
//     buildingId: string;
//     dto: ManagersClient.ManagerPostBuildingDto;
//   }): AppThunk =>
//   async (dispatch) => {
//     const { orgId, propertyId, buildingId, dto } = input;
//     try {
//       dispatch(updateDeleteOneBuildingStart());
//       await managersApi.putBuilding({
//         orgId,
//         propertyId,
//         buildingId,
//         managerPostBuildingDto: dto,
//       });
//       dispatch(updateDeleteOneBuildingSuccess());
//     } catch (err: any) {
//       dispatch(updateDeleteOneBuildingFailure(prettyRawError(err)));
//     }
//   };

// export const deleteOneBuilding =
//   (input: {
//     orgId: string;
//     propertyId: string;
//     buildingId: string;
//   }): AppThunk =>
//   async (dispatch) => {
//     const { orgId, propertyId, buildingId } = input;
//     try {
//       dispatch(updateDeleteOneBuildingStart());
//       await managersApi.deleteBuilding({
//         orgId,
//         propertyId,
//         buildingId,
//       });
//       dispatch(updateDeleteOneBuildingSuccess());
//     } catch (err: any) {
//       dispatch(updateDeleteOneBuildingFailure(prettyRawError(err)));
//     }
//   };

export const buildingsSelector = buildingsAdapter.getSelectors<RootState>(
  (state) => state.buildings.buildingsListing.buildingsAdapter,
);

export const buildingsCountSelector = (state: RootState): number => {
  return buildingsSelector.selectTotal(state);
};

export const buildingsIsEmptySelector = (state: RootState): boolean => {
  return buildingsSelector.selectTotal(state) === 0;
};

export const buildingsByIdSelector = (
  state: RootState,
  buildingId: string,
): ManagersClient.ManagerGetBuildingResDto | undefined => {
  const allItems = buildingsSelector.selectAll(state);
  return allItems.find((x) => x.id === buildingId);
};
