import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { FabPerkCard } from "./FabPerkCard";
import {
  fabPerksSelector,
  loadFabricAppPerksListing,
  resetGetAllStatus,
} from "./FabricAppPerksListingSlice";

type FabricAppPerksListingProps = {
  basePath: string;
};

export const FabricAppPerksListing: React.FC<FabricAppPerksListingProps> = (
  props,
) => {
  const { basePath } = props;
  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.perks.fabricAppPerksListing,
  );
  const fabPerks = fabPerksSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    dispatch(loadFabricAppPerksListing());
  }, [dispatch]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(resetGetAllStatus());
    }
  }, [dispatch, isLoading]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "ALL_LOADING":
        setIsLoading(true);
        break;
      case "ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    console.log("FabricAppPerksListing | FabricAppPerksListing", {
      getAllError,
    });
    return <ErrorView message={getAllError} />;
  }

  if (!fabPerks || fabPerks.length < 1) {
    // return <ErrorView message={"Show empty state"} />;
    return (
      <EmptyStateImage
        text={"To add your first perk now, please contact the FabricApp team!"}
        imageType={ManagersClient.SharedDefaultImageResDtoTypeEnum.FeaturePerks}
      />
    );
  }

  const fabPerkCards = fabPerks.map((item) => {
    return (
      <div
        key={item.id}
        className={"flex flex-row px-6 py-6 border rounded-md shadow-sm"}>
        <FabPerkCard basePath={basePath} fabPerk={item} showLink />
      </div>
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      {fabPerkCards}
    </div>
  );
};
