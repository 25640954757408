import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import {ButtonSimple, ErrorView} from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SharedStyles } from "../../themes/FabTheme";
import AddressDropdown from "./AddressDropdown";
import {
  completeSetupSteps,
  saveStep3,
  Step3PropertyPostData,
} from "./SetupStepsSlice";
import { SignupSteps } from "./SignupSteps";
import {trackEvent} from "../../utils/Amplitude";

export const SetupPropertyComposer = (props: any) => {
  const { basePath } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    wizardStepStatus,
    postEntrypointError,
    step3PropertyData,
    step2ProfileData,
  } = useAppSelector((state) => state.setupSteps);

  const [isLoading, setIsLoading] = React.useState(false);

  const schema = Joi.object<Step3PropertyPostData>({
    property: Joi.object({
      propertyName: SchemaValidation.FieldRequiredString({
        label: "Property Name",
        max: 300,
      }),
      address: SchemaValidation.FieldRequiredString({
        label: "Address",
        max: 300,
      }),
      numberOfUnits: SchemaValidation.FieldRequiredInteger({
        label: "Number Of Units",
      }),
      numberOfFloors: SchemaValidation.FieldRequiredInteger({
        label: "Number Of Floors",
      }),
    }),
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm<Step3PropertyPostData>({
    defaultValues: {
      property: step3PropertyData?.property,
    },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const currentStepIndex = 2;
  const wizardStepper = wizardStepperWrapper(SignupSteps)(currentStepIndex);

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "STEP3_LOADING":
        setIsLoading(true);
        break;
      case "STEP3_SUCCESS":
        setIsLoading(false);
        dispatch(completeSetupSteps());
        break;
      case "LOADING_POST_ENTRYPOINT":
        setIsLoading(true);
        break;
      case "SUCCESS_POST_ENTRYPOINT":
        setIsLoading(false);
        history.push({
          pathname: "/signup/finish",
          state: { username: step2ProfileData?.profile.firstName },
        });
        break;
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [basePath, history, wizardStepStatus]);

  const onSubmit = (step3Data: Step3PropertyPostData) => {
    trackEvent("Next Clicked!");
    dispatch(saveStep3({ ...step3Data }));
  };

  console.log("log", errors, isValid);

  return (
    <div
      className={
        "max-w-lg mx-auto flex flex-col lg:my-2 lg:px-8 lg:py-5 lg:space-y-8"
      }>
      {wizardStepper}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Property Name"} htmlForId={"propertyName"} />
            <input
              type={"text"}
              {...register("property.propertyName")}
              className={SharedStyles.inputs.input()}
            />
            {errors?.property?.propertyName && (
              <div className={"text-error"}>
                {errors.property.propertyName.message}
              </div>
            )}
            <div className={"text-sm text-emph-high mt-2"}>
              Does your property have a name? Don&apos;t worry - you can always
              change this later.
            </div>
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Address"} />
            <div className={"mt-1"}>
              <AddressDropdown
                placeValue={
                  getValues().property?.address
                    ? getValues().property?.address
                    : ""
                }
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            {errors?.property?.address && (
              <div className={"text-error"}>
                {errors.property.address.message}
              </div>
            )}
            <div className={"text-sm text-emph-high mt-2"}>
              Start typing to search and select your property address from the
              dropdown.
            </div>
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Number Of Units"} />
            <div className={"mt-1"}>
              <input
                type={"number"}
                {...register("property.numberOfUnits")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors?.property?.numberOfUnits && (
              <div className={"text-error"}>
                {errors.property.numberOfUnits.message}
              </div>
            )}
          </div>

          <div className={"col-span-full"}>
            <FormLabel text={"Number Of Floors"} />
            <div className={"mt-1"}>
              <input
                type={"number"}
                {...register("property.numberOfFloors")}
                className={SharedStyles.inputs.input()}
              />
            </div>
            {errors?.property?.numberOfFloors && (
              <div className={"text-error"}>
                {errors.property.numberOfFloors.message}
              </div>
            )}
          </div>
        </div>

        <div className={"col-span-full text-center mt-5"}>
          {postEntrypointError && <ErrorView message={postEntrypointError} />}
        </div>

        <div className={"flex justify-between mt-5"}>
          <div
            tabIndex={0}
            role={"button"}
            className={
              "inline-flex items-center text-indigo-700 cursor-pointer"
            }
            onClick={() => history.goBack()}
            onKeyDown={() => history.goBack()}>
            <FaLongArrowAltLeft />
            &nbsp;back
          </div>
          <ButtonSimple
            text={"Finish"}
            btnType={"submit"}
            btnStyle={"primary"}
            isDisabled={!isValid}
            isSpinning={isLoading}
          />
        </div>
      </form>
      {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}
    </div>
  );
};
