import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { trackEvent } from "../../utils/Amplitude";
import { DocumentGroups } from "./DocumentGroups";

export const DocumentsListing: React.FunctionComponent<unknown> = () => {
  const { path } = useRouteMatch();
  const addNewPath = `${path}/new`;
  const history = useHistory();

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <div className={"text-primary"}>Documents</div>
      </div>

      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Upload PDFs for any documents that residents would find useful.
        Examples: Building Rules, Strata Bylaws, FAQs, Forms. Documents will be
        instantly viewable on the resident app after being uploaded.
      </h2>
      <div className={"flex flex-row items-center justify-end py-4"}>
        <ButtonSimple
          text={"Add New Document"}
          btnType={"button"}
          btnStyle={"secondary"}
          onClick={() => {
            trackEvent("Add New Document Button Clicked!");
            history.push(addNewPath);
          }}
        />
      </div>
      <Tabs
        className={"flex flex-col items-start min-h-screen"}
        disabledTabClassName={"opacity-50 cursor-not-allowed"}
        selectedTabClassName={
          "text-primary border-b-2 border-primary text-center"
        }
        selectedTabPanelClassName={"w-11/12 py-4 mx-4"}>
        <TabList
          className={
            "flex flex-row border-b-2 border-border-low w-full text-emph-high"
          }>
          <Tab className={"w-50 px-4 py-4 text-center cursor-pointer"}>
            All Documents
          </Tab>
        </TabList>
        <TabPanel>
          <div className={"grid grid-cols-12 gap-6 mt-10 overflow-y-hidden"}>
            <div className={"col-span-12 lg:col-span-12"}>
              <DocumentGroups />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
