import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SharedStyles } from "../../themes/FabTheme";
import { AddAmenitySteps } from "./AddAmenitySteps";
import { AddAmenityWizardLayout } from "./AddAmenityWizardLayout";
import { AddNewAmenityAction, AddNewAmenityState } from "./AddNewAmenityState";
import {AddAmenityStep2, addAmenityStep2Submit, resetAddAmenityStatus} from "./AmenitiesSlice";

type Step2Props = {
  basePath: string;
  dispatcher: React.Dispatch<AddNewAmenityAction>;
  defaultValue: AddNewAmenityState["step2Info"];
};

const schema = Joi.object({
  name: SchemaValidation.FieldRequiredString({ label: "Amenity name" }),
  description: SchemaValidation.FieldOptionalString({
    label: "Amenity description",
  }),
  location: SchemaValidation.FieldOptionalString({ label: "Amenity location" }),
  // photoUrl: SchemaValidation.FieldRequiredUriString({ label: "Amenity photo" }),
});

export const AddAmenityWizardStep2: FunctionComponent<Step2Props> = (props) => {
  const { basePath } = props;
  const history = useHistory();

  const { step2 } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<AddAmenityStep2>({
    defaultValues: { ...step2 },
    mode: "onChange",
    resolver: joiResolver(schema),
  });

  const { status } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    switch (status) {
      case "STEP2_LOADING":
        break;
      case "STEP2_SUCCESS":
        history.push(`${basePath}/step3`);
        break;
      default:
        break;
    }
  }, [basePath, history, status]);

  React.useEffect(() => {
    dispatch(resetAddAmenityStatus());
  }, [dispatch]);

  const onSubmit = (data: AddAmenityStep2) => {
    console.log("AddAmenityWizard2: data", data);
    dispatch(addAmenityStep2Submit(data));
  };

  const wizardStepper = wizardStepperWrapper(AddAmenitySteps)(0);
  return (
    <AddAmenityWizardLayout>
      {wizardStepper}

      <div className={"flex flex-col items-center w-full"}>
        <div
          className={"text-2xl font-medium text-heading text-center py-4 mb-4"}>
          How would you like your residents to refer to this amenity?
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col items-center max-w-2xl w-full"}>
          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"name"}
              className={"block text-sm font-medium text-gray-700"}>
              Name of the Amenity
              <input
                type={"text"}
                id={"name"}
                {...register("name")}
                className={SharedStyles.inputs.input()}
              />
              {errors.name && (
                <div className={"text-error"}>{errors.name.message}</div>
              )}
            </label>
          </div>
          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"description"}
              className={"block text-sm font-medium text-gray-700"}>
              Amenity Description
              <div className={"mt-1"}>
                <textarea
                  rows={1}
                  {...register("description")}
                  className={SharedStyles.inputs.input()}
                />
              </div>
              {errors.description && (
                <div className={"text-error"}>{errors.description.message}</div>
              )}
            </label>
          </div>
          <div className={"mt-4 space-y-2 w-full"}>
            <label
              htmlFor={"location"}
              className={"block text-sm font-medium text-gray-700"}>
              Amenity Location (for e.g. Second floor)
              <div className={"mt-1"}>
                <textarea
                  rows={1}
                  {...register("location")}
                  className={SharedStyles.inputs.input()}
                />
              </div>
              {errors.location && (
                <div className={"text-error"}>{errors.location.message}</div>
              )}
            </label>
          </div>
          {/*<div className={"mt-4 space-y-2 w-full"}>*/}
          {/*  <label*/}
          {/*    htmlFor={"photo"}*/}
          {/*    className={"block text-sm font-medium text-gray-700"}>*/}
          {/*    Amenity Location*/}
          {/*    <div className={"mt-1"}>*/}
          {/*      <Controller*/}
          {/*        control={control}*/}
          {/*        name={"location"}*/}
          {/*        render={({ field }) => (*/}
          {/*          <ImageUploadSimple*/}
          {/*            onChange={field.onChange}*/}
          {/*            labelText={"Upload amenity photo"}*/}
          {/*            uploadDirName={"amenity"}*/}
          {/*            existingImagePath={field.value}*/}
          {/*            defaultImageType={*/}
          {/*              ManagersClient.SharedDefaultImageResDtoTypeEnum*/}
          {/*                .FeatureAmenityBooking*/}
          {/*            }*/}
          {/*            uploadType={*/}
          {/*              ManagersClient.UploadPhotoUploadTypeEnum.PerkPhoto*/}
          {/*            }*/}
          {/*          />*/}
          {/*        )}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    {errors.photoUrl && (*/}
          {/*      <div className={"text-error"}>{errors.photoUrl.message}</div>*/}
          {/*    )}*/}
          {/*  </label>*/}
          {/*</div>*/}

          <div className={"py-6"}>
            <ButtonSimple
              text={"Next"}
              btnType={"submit"}
              btnStyle={"primary"}
            />
          </div>
        </form>
      </div>
    </AddAmenityWizardLayout>
  );
};
