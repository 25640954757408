import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { EmptyStateImage } from "../../DefaultImages/EmptyStateImage";
import { PendingResidentRow } from "./PendingResidentRow";
import {
  loadPendingResidentsListing,
  pendingResidentsSelector,
} from "./PendingResidentsListingSlice";

type PendingResidentsProps = {
  basePath: string;
};

export const PendingResidentsListing: React.FC<PendingResidentsProps> = (
  props,
) => {
  const { basePath } = props;
  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.residentDirectory.pendingResidentsListing,
  );
  const pendingResidents = pendingResidentsSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(
        loadPendingResidentsListing({
          propertyId: selectedProperty.id,
          orgId: selectedOrg.id,
        }),
      );
    }
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "ALL_PENDING_LOADING":
        setIsLoading(true);
        break;
      case "ALL_PENDING_SUCCESS":
        setIsLoading(false);
        break;
      case "ALL_PENDING_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (!selectedOrg || !selectedProperty) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!pendingResidents || pendingResidents.length < 1) {
    return (
      <EmptyStateImage
        text={"No pending residents yet!"}
        imageType={
          ManagersClient.SharedDefaultImageResDtoTypeEnum.FeatureAnnouncements
        }
      />
    );
  }

  const pendingResidentRows = pendingResidents.map((item) => {
    return <PendingResidentRow basePath={basePath} key={item.id} dto={item} />;
  });

  return (
    <div className={"flex flex-col"}>
      <div className={"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"}>
        <div
          className={
            "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          }>
          <div
            className={
              "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            }>
            <table className={"min-w-full divide-y divide-gray-200"}>
              <thead className={"bg-gray-50"}>
                <tr>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Name
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Unit
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Role
                  </th>
                  <th
                    scope={"col"}
                    className={
                      "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    }>
                    Phone number
                  </th>
                  <th scope={"col"} className={"relative px-6 py-3"}>
                    <span className={"sr-only"}>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className={"bg-white divide-y divide-gray-200"}>
                {pendingResidentRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
