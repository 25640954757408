import { Disclosure } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { getDownloadUrl } from "../../api/managersApi";
import { useAppSelector } from "../../app/hooks";
import { FabLogo } from "../../components/FabLogo";
import DashboardTopNav from "./DashboardTopNav";
import { ProfileDropDown } from "./ProfileDropDown";

export default function DashboardHeaderNav() {
  const { selectedOrgRole, selectedOrg, isMultiOrgUser } = useAppSelector(
    (state) => state.selectionContext,
  );

  return (
    <Disclosure as={"nav"} className={"border-b"}>
      {({ open }) => (
        <>
          <div className={"max-w-full mx-auto px-4 sm:px-6 lg:px-8"}>
            <div className={"flex items-center justify-between h-16"}>
              <Link className={"flex flex-row items-center"} to={"/dashboard"}>
                {selectedOrg?.photoPath ? (
                  <>
                    <img
                      style={{
                        objectFit: "contain",
                        height: 60,
                        width: "auto",
                      }}
                      className={"mx-5"}
                      src={getDownloadUrl(selectedOrg.photoPath)}
                      alt={"FabricApp Logo"}
                    />
                    <FabLogo poweredBy styles={"h-12 w-auto"} />
                  </>
                ) : (
                  <FabLogo styles={"h-16 w-auto"} />
                )}
              </Link>

              <div className={"flex items-center justify-end"}>
                {/* Nav dropdown */}
                <div className={"visible lg:invisible mt-2"}>
                  <div className={"ml-4 flex items-center md:ml-6"}>
                    <DashboardTopNav isMultiOrgUser={isMultiOrgUser} />
                  </div>
                </div>

                <div>
                  {/*<button*/}
                  {/*  className={*/}
                  {/*    "bg-gray-50 p-1 rounded-full text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary focus:ring-white"*/}
                  {/*  }>*/}
                  {/*  <span className={"sr-only"}>View notifications</span>*/}
                  {/*  <HiBell className={"h-6 w-6"} aria-hidden={"true"} />*/}
                  {/*</button>*/}

                  {/* Profile dropdown */}
                  <ProfileDropDown selectedOrgRole={selectedOrgRole} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
