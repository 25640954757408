/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from "react";
import { Direction, Range } from "react-range";
import { ITrackBackground } from "react-range/lib/types";

import { hrsToMinutes, minutesToHrs } from "../../utils/DateTimeUtils";

function getTrackBackground({
  values,
  colors,
  min,
  max,
  direction = Direction.Right,
}: ITrackBackground) {
  const progress = values
    .slice(0)
    .sort((a, b) => a - b)
    .map((value) => ((value - min) / (max - min)) * 100);
  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    "",
  );
  return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`;
}

type SliderProps = {
  colors: { active: string; inactive: string };
  onChange(val: { start: number; end: number }): void;
  defaultValues: { start: number; end: number };
  gap?: { max: number };
};

const RangeSlider: FunctionComponent<SliderProps> = (props) => {
  const minValue = 0;
  const maxValue = 48;
  const [selectedValues, setSelectedValues] = React.useState([
    minValue,
    maxValue,
  ]);
  const { colors, onChange, defaultValues, gap } = props;
  const minGap = 2;
  const maxGap = gap?.max ?? maxValue;
  const rangeColors = [colors.inactive, colors.active, colors.inactive];

  React.useEffect(() => {
    const newValues = [
      minutesToHrs(defaultValues.start),
      minutesToHrs(defaultValues.end),
    ];
    setSelectedValues(newValues);
  }, [defaultValues]);

  return (
    <div className={"flex w-full"}>
      <Range
        draggableTrack
        step={1}
        min={minValue}
        max={maxValue}
        values={selectedValues}
        onChange={(values) => {
          const newStart = values[0];
          let newEnd = values[1];
          if (newEnd > maxValue) {
            newEnd = maxValue;
          }
          if (newEnd - newStart < minGap || newEnd - newStart > maxGap) {
            return;
          }
          setSelectedValues([newStart, newEnd]);
          const changeValue = {
            start: hrsToMinutes(newStart),
            end: hrsToMinutes(newEnd),
          };
          onChange(changeValue);
          console.log("onChange", [newStart, newEnd], changeValue);
        }}
        renderTrack={({ props: iTrackProps, children }) => (
          <div
            {...iTrackProps}
            className={
              "w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-move"
            }
            style={{
              background: getTrackBackground({
                values: selectedValues,
                colors: rangeColors,
                min: minValue,
                max: maxValue,
              }),
            }}>
            {children}
          </div>
        )}
        renderThumb={({ props: iThumbProps }) => {
          return (
            <div
              {...iThumbProps}
              className={
                "w-5 h-5 transform translate-x-10 bg-primary rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
            />
          );
        }}
      />
    </div>
  );
};

export default RangeSlider;
