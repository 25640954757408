import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { SharedStyles } from "../../themes/FabTheme";
import OnboardingLayout from "./OnboardingLayout";
import {
  completeOnboarding,
  sendOtpCodeOnboardingStep3,
  Step3FormData,
  verifyOtpCodeOnboardingStep3,
} from "./OnboardingSlice";
import { OnboardingSteps } from "./OnboardingSteps";
import { OnboardingText } from "./OnboardingText";

type Props = {
  basePath: string;
};

const schema = Joi.object<Step3FormData>({
  authentication: Joi.object({
    phoneNumber: SchemaValidation.FieldRequiredString({
      label: "Phone number",
    }),
  }),
  verificationCode: SchemaValidation.FieldOptionalInteger({
    label: "Verification code",
    min: 100000,
    max: 999999,
  }),
});

const COUNTRY_CODE: CountryCode = "CA";

const Step3Authentication2fa: FunctionComponent<Props> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { wizardStepStatus, managerEntryPointState, postEntrypointError } =
    useAppSelector((state) => state.onboarding);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
    control,
    setValue,
    getValues,
  } = useForm<Step3FormData>({
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const initialButtonText = "Send OTP Code";
  const [buttonText, setButtonText] = React.useState(initialButtonText);
  const [isVerifyCodeEnabled, setIsVerifyCodeEnabled] = React.useState(false);

  // phone number validation
  const phoneNumberInputRef = React.createRef<HTMLInputElement>();
  const [isPhoneValid, setIsPhoneValid] = React.useState(false);
  const [formattedPhone, setFormattedPhone] = React.useState("");
  const [intlFormattedPhone, setIntlFormattedPhone] =
    React.useState(formattedPhone);

  const onSubmit = (step3Data: Step3FormData) => {
    // setIsLoading(true);
    const vals = getValues();
    console.log("Step3Authentication | onSubmit", {
      step3Data,
      vals,
      isValid,
      isPhoneValid,
    });
    if (wizardStepStatus === "STEP2_SUCCESS") {
      dispatch(sendOtpCodeOnboardingStep3(step3Data));
    } else if (
      wizardStepStatus === "STEP3_SUCCESS_SENDING_OTP" ||
      wizardStepStatus === "STEP3_ERROR_VERIFYING_OTP" ||
      wizardStepStatus === "ERROR_POST_ENTRYPOINT"
    ) {
      dispatch(verifyOtpCodeOnboardingStep3(step3Data));
    } else {
      console.error(`step3: Invalid wizard status ${wizardStepStatus}`);
      history.push("/onboarding");
    }
  };

  const setPhoneNumber = () => {
    const phoneNumber = phoneNumberInputRef.current?.value ?? "";
    const asYouType = new AsYouType(COUNTRY_CODE);
    const asYouTypeFormatted = asYouType.input(phoneNumber);
    if (asYouType.isValid()) {
      const parsedPhoneNumber = parsePhoneNumber(
        asYouTypeFormatted,
        COUNTRY_CODE,
      );
      const formattedNational = parsedPhoneNumber.formatNational();
      setFormattedPhone(formattedNational);
      setIntlFormattedPhone(parsedPhoneNumber.formatInternational());
      setIsPhoneValid(true);
      setValue("authentication.phoneNumber", formattedNational, {
        shouldValidate: true,
      });
    } else {
      setFormattedPhone(asYouTypeFormatted);
      setIntlFormattedPhone("");
      setIsPhoneValid(false);
    }
    console.log("Step3Authentication | setPhoneNumber", {
      isValid,
      isPhoneValid,
      errors,
    });
  };

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "STEP3_LOADING_SENDING_OTP":
        setIsLoading(true);
        break;
      case "STEP3_ERROR_SENDING_OTP":
        setIsLoading(false);
        break;
      case "STEP3_SUCCESS_SENDING_OTP":
        setButtonText("Verify OTP Code");
        setIsVerifyCodeEnabled(true);
        setIsLoading(false);
        break;
      case "STEP3_LOADING_VERIFYING_OTP":
        setIsLoading(true);
        break;
      case "STEP3_ERROR_VERIFYING_OTP":
        setIsLoading(false);
        break;
      case "STEP3_SUCCESS_VERIFYING_OTP":
        dispatch(completeOnboarding());
        setIsLoading(false);
        break;
      case "LOADING_POST_ENTRYPOINT":
        setIsLoading(true);
        break;
      case "ERROR_POST_ENTRYPOINT":
        setIsLoading(false);
        break;
      case "SUCCESS_POST_ENTRYPOINT":
        setIsLoading(false);
        history.push("/select-building");
        break;
      default:
        break;
    }
  }, [dispatch, history, wizardStepStatus]);

  const enteredPhoneNumber = watch("authentication.phoneNumber");

  const currentStepIndex = 2;
  const wizardStepper = wizardStepperWrapper(OnboardingSteps)(currentStepIndex);

  return (
    <OnboardingLayout>
      {wizardStepper}
      <OnboardingText title={OnboardingSteps[currentStepIndex].name}>
        FabricApp is built from the ground-up with strong security. To keep your
        account safe, we require Two-factor authentication (2FA). We will only
        use your phone number for verifying your login.
        <br /> <br />
        Once you enter a phone number, we will send a one-time verification code
        to your phone number. Please enter it below to activate your account.
      </OnboardingText>

      <div
        className={"max-w-lg mx-auto flex flex-col my-2 px-6 py-5 space-y-6"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"mb-5"}>
            <FormLabel text={"email"} />
            <div className={"w-full py-3 rounded-lg text-default"}>
              {managerEntryPointState?.authentication.email ?? "Missing email"}
            </div>
          </div>

          <div className={"mb-5"}>
            <FormLabel text={"Mobile phone number"} htmlForId={"phoneNumber"} />
            <Controller
              control={control}
              name={"authentication.phoneNumber"}
              render={() => (
                <input
                  type={"tel"}
                  ref={phoneNumberInputRef}
                  onChange={() => {
                    setPhoneNumber();
                  }}
                  className={SharedStyles.inputs.input()}
                />
              )}
            />
            {isPhoneValid && (
              <div className={"text-green-500"}>
                A text message will be sent to {intlFormattedPhone}
              </div>
            )}
            {!isPhoneValid && !!formattedPhone && (
              <div className={"text-error"}>Invalid phone number</div>
            )}
            {/*{errors.authentication?.phoneNumber && (*/}
            {/*  <div className={"text-error"}>*/}
            {/*    {errors.authentication.phoneNumber.message}*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>

          <div className={"mb-5"}>
            <FormLabel
              text={"Verification code"}
              htmlForId={"verificationCode"}
            />
            <input
              type={"number"}
              {...register("verificationCode", {
                valueAsNumber: true,
              })}
              className={SharedStyles.inputs.input()}
              disabled={!isVerifyCodeEnabled}
            />
            {errors?.verificationCode && (
              <div className={"text-error"}>
                {errors.verificationCode.message}
              </div>
            )}
          </div>

          {isVerifyCodeEnabled && (
            <div className={"text-sm text-emph-high text-center"}>
              A verification code has been sent to {enteredPhoneNumber}
            </div>
          )}

          <div className={"flex flex-col justify-center text-center mt-5"}>
            {postEntrypointError && (
              <div className={"text-error my-5"}>{postEntrypointError}</div>
            )}
            <ButtonSimple
              text={buttonText}
              btnType={"submit"}
              btnStyle={"primary"}
              isSpinning={isLoading}
              isDisabled={!isPhoneValid}
            />
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Step3Authentication2fa;
