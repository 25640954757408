import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useState } from "react";

import { getDownloadUrl, managersApi } from "../../api/managersApi";
import { DefaultImage } from "../../features/DefaultImages/DefaultImage";
import { ImageSimple } from "./ImageSimple";

type ImageUploadSimpleProps = {
  onChange(val: string | null): void;
  existingImagePath?: string;
  defaultImageType: ManagersClient.SharedDefaultImageResDtoTypeEnum;
  uploadType: ManagersClient.UploadPhotoUploadTypeEnum;
  labelText: string;
  uploadDirName: string;
  styleType?: "square" | "circle-sm";
};

export const ImageUploadSimple: React.FC<ImageUploadSimpleProps> = (props) => {
  const {
    onChange,
    uploadDirName,
    existingImagePath,
    defaultImageType,
    uploadType,
    styleType = "square",
  } = props;
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (existingImagePath) {
      const photoPathUrl = getDownloadUrl(existingImagePath);
      console.log("ImageUploadSimple | useEffect", {
        existingImagePath,
        photoPathUrl,
      });
      setImageUrl(photoPathUrl);
    }
  }, [existingImagePath]);

  const handleUpload = async () => {
    setIsUploading(true);
    setProgress(0);

    try {
      const maybeFiles: FileList | undefined =
        fileInputRef.current?.files ?? undefined;

      if (!maybeFiles || maybeFiles.length !== 1) {
        setErrorMsg("Oops something went wrong! Missing file");
        return;
      }

      const firstFile: File = maybeFiles[0];
      console.log("ImageUploadSimple | firstFile", { firstFile });

      const uploadedPhoto = await managersApi.uploadPhoto({
        uploadType,
        photos: [firstFile],
      });
      console.log("ImageUploadSimple | handleUpload", { uploadedPhoto });
      onChange(uploadedPhoto.photoPath);
    } catch (err: any) {
      setErrorMsg(`Oops something went wrong! ${err}`);
    } finally {
      setErrorMsg(undefined);
      setProgress(100);
      setIsUploading(false);
    }
  };

  const baseImageStyle = "flex items-center justify-center mx-auto";
  const squareStyles = " bg-gray-100 h-80 w-80 border";
  const circleStyles = " bg-gray-100 h-24 w-24 border rounded-full";
  let derivedStyle = baseImageStyle;
  switch (styleType) {
    case "square":
      derivedStyle += squareStyles;
      break;
    case "circle-sm":
      derivedStyle += circleStyles;
      break;
    default:
      derivedStyle += squareStyles;
      break;
  }

  return (
    <div className={"flex flex-col"}>
      <div className={derivedStyle}>
        {imageUrl ? (
          <ImageSimple imageUrl={imageUrl} altName={uploadDirName} />
        ) : (
          <DefaultImage imageType={defaultImageType} />
        )}
      </div>

      <label
        className={
          "cursor-pointer inline-flex justify-between items-center focus:outline-none border-none rounded-md py-2 px-4 text-primaryAlt bg-white hover:bg-gray-100 font-medium"
        }>
        {"Upload photo"}
        <input
          className={"hidden"}
          type={"file"}
          multiple={false}
          accept={".jpg,.jpeg,.png"}
          ref={fileInputRef}
          onChange={handleUpload}
        />
      </label>
      {isUploading && <p>Upload progress: {progress}</p>}
      {errorMsg && <ErrorView message={errorMsg} />}
    </div>
  );
};
