import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { CheckEmailPage } from "./CheckEmailPage";
import { EmailVerified } from "./EmailVerified";
import { FinishScreen } from "./FinishScreen";
import { SetupProfile } from "./SetupProfile";
import { SetupProperty } from "./SetupProperty";
import { SignupPage } from "./SignupPage";

export const SignupNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${path}`}>
          <SignupPage basePath={path} />
        </Route>
        <Route exact path={`${path}/email-sent`}>
          <GoogleReCaptchaProvider
            reCaptchaKey={"6LfB79keAAAAAJDOPaBvi6BC6q32SBnzxCXYYIOY"}>
            <CheckEmailPage />
          </GoogleReCaptchaProvider>
        </Route>

        <Route exact path={`${path}/verify-email`}>
          <EmailVerified basePath={path} />
        </Route>

        <Route exact path={`${path}/setup-profile`}>
          <SetupProfile basePath={path} />
        </Route>

        <Route exact path={`${path}/setup-property`}>
          <SetupProperty basePath={path} />
        </Route>

        <Route exact path={`${path}/finish`}>
          <FinishScreen basePath={path} />
        </Route>
      </Switch>
    </div>
  );
};
