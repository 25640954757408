import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import React from "react";

import { getDownloadUrl } from "../../api/managersApi";
import { ImageSimple } from "../../components/image/ImageSimple";

type PropertyCardProps = {
  property: ManagersClient.ManagerGetPropertyResDto;
  onClick: (id: string) => void;
};

export const PropertyCard: React.FC<PropertyCardProps> = (props) => {
  const { property, onClick } = props;
  const { id, name, identifier, photoPath } = property;

  return (
    <div>
      <button
        type={"button"}
        className={"mx-4 w-64"}
        onClick={() => onClick(id)}>
        <div
          className={
            "flex flex-col justify-center items-center border border-border-low rounded-lg py-8 focus:outline-none focus:ring-1 focus:bg-indigo-700 group hover:bg-primary hover:shadow-lg hover:border-transparent"
          }>
          <div className={"w-full h-40 mb-4"}>
            <ImageSimple
              imageUrl={getDownloadUrl(photoPath)}
              altName={"property image"}
            />
          </div>
          <div className={"text-lg text-default group-hover:text-white"}>
            {name}
          </div>
          <div className={"text-sm text-emph-low group-hover:text-gray-100"}>
            {identifier}
          </div>
        </div>
      </button>
    </div>
  );
};
