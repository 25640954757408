import {
  ConnectionInfo,
  typeValidations,
} from "@fabricapp-ca/fabricapp-data-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as promiseUtils from "blend-promise-utils";

import * as firebaseAPI from "../../api/firebaseAPI";
import { LocalStorageAPI } from "../../api/localStorageAPI";
import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { CurrentUserInfo } from "../../configs/FirebaseConfig";

export interface FirebaseAuthState {
  authStatus:
    | "IDLE"
    | "SIGN_IN_LOADING"
    | "SIGN_IN_DONE"
    | "SIGN_IN_ERROR"
    | "VERIFY_LOADING"
    | "VERIFY_DONE"
    | "VERIFY_ERROR"
    | "SIGN_OUT_LOADING"
    | "SIGN_OUT_DONE"
    | "SIGN_OUT_ERROR";
  currentUserInfo?: CurrentUserInfo;
  currentUserToken?: string;
  error: string | undefined;
}

const initialState: FirebaseAuthState = {
  authStatus: "IDLE",
  error: undefined,
};

export const FirebaseAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetToInitial: (state) => {
      state.authStatus = initialState.authStatus;
      state.error = initialState.error;
      state.currentUserToken = initialState.currentUserToken;
      state.currentUserInfo = initialState.currentUserInfo;
    },
    signInStart: (state) => {
      state.authStatus = "SIGN_IN_LOADING";
    },
    signInSuccess: (state) => {
      state.authStatus = "SIGN_IN_DONE";
    },
    signInFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.authStatus = "SIGN_IN_ERROR";
    },

    verifyEmailStart: (state) => {
      state.authStatus = "VERIFY_LOADING";
    },
    verifyEmailSuccess: (
      state,
      action: PayloadAction<{
        userInfo: CurrentUserInfo;
      }>,
    ) => {
      state.currentUserInfo = action.payload.userInfo;
      state.authStatus = "VERIFY_DONE";
    },
    verifyEmailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.authStatus = "VERIFY_ERROR";
    },

    signOutStart: (state) => {
      state.authStatus = "SIGN_OUT_LOADING";
    },
    signOutSuccess: (state) => {
      state.currentUserInfo = undefined;
      state.currentUserToken = undefined;
      state.authStatus = "SIGN_OUT_DONE";
    },
    signOutFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.authStatus = "SIGN_OUT_ERROR";
    },
  },
});

export const {
  resetToInitial,
  signInStart,
  signInSuccess,
  signInFailure,
  verifyEmailStart,
  verifyEmailSuccess,
  verifyEmailFailure,
  signOutStart,
  signOutSuccess,
  signOutFailure,
} = FirebaseAuthSlice.actions;

export const firebaseAuthReducer = FirebaseAuthSlice.reducer;

export const selectCurrentUserToken = (
  state: RootState,
): string | undefined => {
  const { currentUserToken } = state.auth.firebaseAuth;
  return currentUserToken;
};

export const selectCurrentUserInfo = (state: RootState): CurrentUserInfo => {
  const { currentUserInfo } = state.auth.firebaseAuth;
  return currentUserInfo;
};

export const firebaseEmailSignIn =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(signInStart());
      await LocalStorageAPI.setKv("signInEmail", email);
      await managersApi.signInWithEmail({
        managerSignInWithEmailReqDto: { email, recaptchaCode: "123" },
      });
      dispatch(signInSuccess());
    } catch (err: any) {
      dispatch(signInFailure(err.message));
    }
  };

export const firebaseVerifyEmail = (): AppThunk => async (dispatch) => {
  try {
    dispatch(verifyEmailStart());
    await firebaseAPI.firebaseSignInWithEmailLink();
    await promiseUtils.delay(2000);
    // Note verifyEmailSuccess is called by the middleware
  } catch (err: any) {
    dispatch(verifyEmailFailure(err.toString()));
  }
};

export const firebaseEmailSignOut = (): AppThunk => async (dispatch) => {
  try {
    dispatch(signOutStart());
    await LocalStorageAPI.clearStorage();
    await firebaseAPI.firebaseSignOut();
    dispatch(signOutSuccess());
  } catch (err: any) {
    dispatch(signOutFailure(err.toString()));
  }
};

export function getAuthFromEnv(userToken?: string): ConnectionInfo {
  const endpoint = process.env.REACT_APP_HASURA_GRAPHQL_RESIDENT_ENDPOINT;
  typeValidations.assertIsString(endpoint);
  return {
    endpoint,
    userToken,
  };
}
