import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { RootState } from "../../app/rootReducer";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import { sortDescByCreatedAt } from "../../utils/SortUtils";

export interface OrganizationListingSliceState {
  getAllStatus: "IDLE" | "ALL_LOADING" | "ALL_SUCCESS" | "ALL_ERROR";
  getAllError?: string;
  getOneStatus: "IDLE" | "ONE_LOADING" | "ONE_SUCCESS" | "ONE_ERROR";
  getOneError?: string;
  organizationsAdapter: EntityState<ManagersClient.SharedGetOrgResDto>;
}

const organizationsAdapter =
  createEntityAdapter<ManagersClient.SharedGetOrgResDto>({
    selectId: (item) => item.id,
    sortComparer: sortDescByCreatedAt,
  });

const initialState: OrganizationListingSliceState = {
  getAllStatus: "IDLE",
  getOneStatus: "IDLE",
  organizationsAdapter: organizationsAdapter.getInitialState(),
};

export const OrganizationListingSlice = createSlice({
  name: "organizationListing",
  initialState,
  reducers: {
    resetGetAllStatus: (state) => {
      state.getAllStatus = "IDLE";
    },
    getAllOrganizationsStart: (state) => {
      state.getAllStatus = "ALL_LOADING";
    },
    getAllOrganizationsSuccess: (
      state,
      action: PayloadAction<{
        organizations: Array<ManagersClient.SharedGetOrgResDto>;
      }>,
    ) => {
      state.getAllStatus = "ALL_SUCCESS";
      state.getAllError = undefined;
      organizationsAdapter.setAll(
        state.organizationsAdapter,
        action.payload.organizations,
      );
    },
    getAllOrganizationsFailure: (state, action: PayloadAction<string>) => {
      state.getAllStatus = "ALL_ERROR";
      state.getAllError = action.payload;
    },

    resetGetOneStatus: (state) => {
      state.getOneStatus = "IDLE";
    },
    getOneOrganizationStart: (state) => {
      state.getOneStatus = "ONE_LOADING";
    },
    getOneOrganizationSuccess: (
      state,
      action: PayloadAction<{
        organization: ManagersClient.SharedGetOrgResDto;
      }>,
    ) => {
      state.getOneStatus = "ONE_SUCCESS";
      state.getOneError = undefined;
      organizationsAdapter.setOne(
        state.organizationsAdapter,
        action.payload.organization,
      );
    },
    getOneOrganizationFailure: (state, action: PayloadAction<string>) => {
      state.getOneStatus = "ONE_ERROR";
      state.getOneError = action.payload;
    },
  },
});

export const {
  resetGetAllStatus,
  getAllOrganizationsStart,
  getAllOrganizationsSuccess,
  getAllOrganizationsFailure,
  resetGetOneStatus,
  getOneOrganizationSuccess,
  getOneOrganizationStart,
  getOneOrganizationFailure,
} = OrganizationListingSlice.actions;

export const organizationListingReducer = OrganizationListingSlice.reducer;

export const loadOrganizationListing = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getAllOrganizationsStart());
    const organizations = (await managersApi.getOrgsByManagerId()).results;
    dispatch(getAllOrganizationsSuccess({ organizations }));
  } catch (err: any) {
    dispatch(getAllOrganizationsFailure(prettyRawError(err)));
  }
};

export const loadOrganizationById =
  (organizationId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOneOrganizationStart());
      // const organization = await managersApi.getOrganization({
      //   orgId: mockApiConstants.orgId,
      //   organizationId: mockApiConstants.organizationId,
      //   type: ManagersClient.GetOrganizationByIdTypeEnum.Fabricapp,
      //   organizationId,
      // });
      // dispatch(getOneOrganizationSuccess({ organization }));
      dispatch(
        getOneOrganizationFailure(`Not implemented yet ${organizationId}`),
      );
    } catch (err: any) {
      dispatch(getOneOrganizationFailure(prettyRawError(err)));
    }
  };

export const organizationsSelector =
  organizationsAdapter.getSelectors<RootState>(
    (state) => state.organizations.organizationListing.organizationsAdapter,
  );
