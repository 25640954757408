import { useState } from "react";
import { BsInfoCircle } from "react-icons/all";

import { FabTheme } from "../../../themes/FabTheme";

export const ImportEmailInfoBox = () => {
  const [tooltipStatus, setTooltipStatus] = useState(0);
  return (
    <>
      <div
        className={"relative ml-28"}
        onMouseEnter={() => setTooltipStatus(2)}
        onMouseLeave={() => setTooltipStatus(0)}>
        <div className={"cursor-pointer"}>
          <BsInfoCircle size={20} color={FabTheme.colors.primary} />
        </div>
        {tooltipStatus === 2 && (
          <div
            role={"tooltip"}
            className={
              "z-20 -mt-20 w-96 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-primaryLight p-4 rounded"
            }>
            <p className={"flex text-sm font-bold pb-1"}>
              {" "}
              <BsInfoCircle size={20} color={FabTheme.colors.primary} />
              <span className={"ml-2 text-primary"}>Import Emails</span>
            </p>
            <p className={"text-sm text-default pb-3"}>
              By entering residents’ email addresses here, even residents who
              haven’t signed up for the resident app will get an email
              notification when any communication is sent through FabricApp. In
              order to see the entire message, they will be directed to download
              the resident mobile app.
            </p>
          </div>
        )}
      </div>
    </>
  );
};
