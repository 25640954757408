import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  destination: string;
  SVGImageIcon?: JSX.Element;
  iconPosition?: "left" | "right";
};

export const LinkSimple: React.FC<Props> = (props) => {
  const defaultIcon = <HiOutlineArrowRight size={20} />;
  const {
    text,
    destination,
    SVGImageIcon = defaultIcon,
    iconPosition = "left",
  } = props;

  return (
    <Link to={destination} className={"flex mt-1 text-primary items-center"}>
      {iconPosition === "left" && SVGImageIcon}
      <span className={"mx-2"}>{text}</span>
      {iconPosition === "right" && SVGImageIcon}
    </Link>
  );
};
