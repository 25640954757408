import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { FunctionComponent, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import OnboardingIntro from "./OnboardingIntro";
import OnboardingLayout from "./OnboardingLayout";
import { loadOnboardingEntryPoint } from "./OnboardingSlice";
import Step1Agreements from "./Step1Agreements";
import Step2Profile from "./Step2Profile";
import Step3Authentication2fa from "./Step3Authentication2fa";

const OnboardingNav: FunctionComponent<unknown> = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { wizardStepStatus, getEntrypointError } = useAppSelector(
    (state) => state.onboarding,
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    console.log("inside onboarding");
    dispatch(loadOnboardingEntryPoint());
  }, [dispatch]);

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "LOADING_GET_ENTRYPOINT":
        setIsLoading(true);
        break;
      case "SUCCESS_GET_ENTRYPOINT":
        setIsLoading(false);
        history.push(`${path}/intro`);
        break;
      case "ERROR_GET_ENTRYPOINT":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [history, path, wizardStepStatus]);

  if (isLoading) {
    return (
      <OnboardingLayout>
        <LoadingView />
      </OnboardingLayout>
    );
  }

  if (getEntrypointError) {
    return (
      <OnboardingLayout>
        <ErrorView message={getEntrypointError} />
      </OnboardingLayout>
    );
  }

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/intro`}>
          <OnboardingIntro basePath={path} />
        </Route>
        <Route exact path={`${path}/step1-agreements`}>
          <Step1Agreements basePath={path} />
        </Route>
        <Route exact path={`${path}/step2-profile`}>
          <Step2Profile basePath={path} />
        </Route>
        <Route exact path={`${path}/step3-authentication`}>
          <Step3Authentication2fa basePath={path} />
        </Route>
        <Route path={path}>
          <OnboardingLayout>
            <LoadingView />
          </OnboardingLayout>
        </Route>
      </Switch>
    </div>
  );
};

export default OnboardingNav;
