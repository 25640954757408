import { FunctionComponent } from "react";
import { AiOutlineContacts, AiOutlineGroup } from "react-icons/ai";
import {FiMonitor, MdOutlinePersonAdd} from "react-icons/all";
import { FiHash, FiUsers } from "react-icons/fi";
import { GiSpeaker } from "react-icons/gi";
import { GrUserAdmin } from "react-icons/gr";
import {
  HiOutlineClock,
  HiOutlineDocumentText,
  HiOutlineSwitchHorizontal,
} from "react-icons/hi";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { FabTheme } from "../../themes/FabTheme";

const DashboardSideNav: FunctionComponent<unknown> = () => {
  // const match = useRouteMatch();
  //
  // useEffect(() => {
  //   console.log("DashboardSideNav: ", match);
  // }, [match]);

  const { selectedOrg, isMultiOrgUser, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  return (
    <div
      className={
        "z-30 inset-y-0 left-0 w-64 hidden lg:block transition duration-300 transform bg-white relative lg:translate-x-0 lg:static lg:inset-0 border-r border-gray-200"
      }>
      <nav className={"mt-10 divide-y-4 divide-dashed"}>
        <div>
          {isMultiOrgUser && (
            <Link
              to={"/dashboard/organizations"}
              className={"flex mt-1 py-4 px-6 text-default"}>
              <HiOutlineSwitchHorizontal size={25} />
              <div className={"flex flex-col"}>
                <span className={"mx-3"}>Switch Organizations</span>
                <span
                  className={"mx-3 my-1 text-sm"}
                  style={{ color: FabTheme.colors.primary }}>
                  {selectedOrg?.name}
                </span>
              </div>
            </Link>
          )}
          <Link
            to={"/dashboard/properties"}
            className={"flex mt-1 py-4 px-6 text-default"}>
            <HiOutlineSwitchHorizontal size={25} />
            <div className={"flex flex-col"}>
              <span className={"mx-3"}>Switch Properties</span>
              <span
                className={"mx-3 my-1 text-sm"}
                style={{ color: FabTheme.colors.primary }}>
                {selectedProperty?.name}
              </span>
            </div>
          </Link>
          {/*<Link*/}
          {/*  to={"/dashboard/buildings"}*/}
          {/*  className={"flex items-center mt-1 py-4 px-6 text-default"}>*/}
          {/*  <HiOutlineSwitchHorizontal size={25} />*/}
          {/*  <span className={"mx-3"}>Buildings</span>*/}
          {/*</Link>*/}
        </div>

        <div>
          <Link
            to={"/dashboard"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <FiMonitor size={25} />
            <span className={"mx-3"}>Dashboard</span>
          </Link>
          <Link
            to={"/dashboard/manager-directory"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <GrUserAdmin size={25} />
            <span className={"mx-3"}>Manager Directory</span>
          </Link>
          <Link
            to={"/dashboard/resident-directory"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <FiUsers size={25} />
            <span className={"mx-3"}>Resident Directory</span>
          </Link>
          <Link
            to={"/dashboard/reminders"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <HiOutlineClock size={25} />
            <span className={"mx-3"}>Reminders</span>
          </Link>
          <Link
            to={"/dashboard/announcements"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <GiSpeaker size={25} />
            <span className={"mx-3"}>Announcements</span>
          </Link>
          {/*<Link*/}
          {/*  to={"/dashboard/amenities"}*/}
          {/*  className={"flex items-center mt-1 py-4 px-6 text-default"}>*/}
          {/*  <ImageSvg size={25} />*/}
          {/*  <span className={"mx-3"}>Amenities</span>*/}
          {/*</Link>*/}
          <Link
            to={"/dashboard/documents"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <HiOutlineDocumentText size={25} />
            <span className={"mx-3"}>Documents</span>
          </Link>
          {/*<Link*/}
          {/*  to={"/dashboard/perks"}*/}
          {/*  className={"flex items-center mt-1 py-4 px-6 text-default"}>*/}
          {/*  <AiFillTags size={25} />*/}
          {/*  <span className={"mx-3"}>Perks</span>*/}
          {/*</Link>*/}
          <Link
            to={"/dashboard/contacts"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <AiOutlineContacts size={25} />
            <span className={"mx-3"}>Property Contacts</span>
          </Link>
          <Link
            to={"/dashboard/unit-groups"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <AiOutlineGroup size={25} />
            <span className={"mx-3"}>Unit Groups</span>
          </Link>
          <Link
            to={"/dashboard/unit-numbers"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <FiHash size={25} />
            <span className={"mx-3"}>Unit Numbers</span>
          </Link>
          <Link
            to={"/dashboard/invite-residents"}
            className={"flex items-center mt-1 py-4 px-6 text-default"}>
            <MdOutlinePersonAdd size={25} />
            <span className={"mx-3"}>Invite Residents</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default DashboardSideNav;
