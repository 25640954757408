import { Prisma } from "@fabricapp-ca/fabricapp-api-shared";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { useRadioGroupState } from "@react-stately/radio";
import { sentenceCase } from "change-case";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RangeSlider from "../../components/forms/RangeSlider";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import { FabTheme } from "../../themes/FabTheme";
import { minutesToDuration } from "../../utils/DateTimeUtils";
import { AddAmenitySteps } from "./AddAmenitySteps";
import { AddAmenityWizardLayout } from "./AddAmenityWizardLayout";
import { YesNoEnum } from "./AddNewAmenityState";
import {
  addAmenityStep3Submit,
  AmenitySchedule,
  resetAddAmenityStatus,
} from "./AmenitiesSlice";

type Step3Props = {
  basePath: string;
};

export enum DayOfTheWeekType {
  MONDAY = "1",
  TUESDAY = "2",
  WEDNESDAY = "3",
  THURSDAY = "4",
  FRIDAY = "5",
  SATURDAY = "6",
  SUNDAY = "7",
}

export const AddAmenityWizardStep3: FunctionComponent<Step3Props> = (props) => {
  const { basePath } = props;

  const { status, step3 } = useAppSelector(
    (state) => state.amenities.amenities.addAmenityState,
  );


  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
  } = useForm<Record<Prisma.Day, AmenitySchedule>>({
    defaultValues: { ...step3 },
    mode: "onChange",
  });
  const [radioValue, setRadioValue] = React.useState<string>("MONDAY");
  const radioGroupState = useRadioGroupState({
    defaultValue: radioValue,
    onChange: (newValue) => {
      setRadioValue(newValue);
    },
  });

  const dispatch = useAppDispatch();
  const watchMonday = watch("MONDAY");
  const watchTuesday = watch("TUESDAY");
  const watchWednesday = watch("WEDNESDAY");
  const watchThursday = watch("THURSDAY");
  const watchFriday = watch("FRIDAY");
  const watchSaturday = watch("SATURDAY");
  const watchSunday = watch("SUNDAY");
  const watches = {
    MONDAY: watchMonday,
    TUESDAY: watchTuesday,
    WEDNESDAY: watchWednesday,
    THURSDAY: watchThursday,
    FRIDAY: watchFriday,
    SATURDAY: watchSaturday,
    SUNDAY: watchSunday,
  };
  const history = useHistory();

  const onSubmit = (data: Record<Prisma.Day, AmenitySchedule>) => {
    dispatch(addAmenityStep3Submit(data));
  };

  React.useEffect(() => {
    switch (status) {
      case "STEP3_LOADING":
        break;
      case "STEP3_SUCCESS":
        history.push(`${basePath}/step4`);
        break;
      default:
        break;
    }
  }, [basePath, history, status]);

  React.useEffect(() => {
    dispatch(resetAddAmenityStatus());
  }, [dispatch]);

  const getDayFormView = (
    day:
      | "MONDAY"
      | "TUESDAY"
      | "WEDNESDAY"
      | "THURSDAY"
      | "FRIDAY"
      | "SATURDAY"
      | "SUNDAY",
  ) => {
    const daySentenceCase = sentenceCase(day.toLowerCase());
    const dayStyles = radioValue === day ? "flex" : "hidden";

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${dayStyles} flex-col items-center w-full`}
        key={`form-${day}`}>
        <div
          className={
            "mt-4 space-y-2 max-w-2xl w-full flex flex-col py-12 px-10 border border-gray-300 rounded-lg"
          }>
          <div className={"text-heading font-bold text-xl text-center"}>
            {daySentenceCase}
          </div>
          <div className={"pb-4"}>
            <div className={"text-default"}>
              Is it open on {daySentenceCase}?
            </div>
            <div className={"mt-4 space-x-4 inline-flex"}>
              <div className={"flex items-center"}>
                <input
                  id={`${day}_yes`}
                  type={"radio"}
                  value={"Yes"}
                  className={
                    "focus:ring-primaryAlt h-4 w-4 text-primary border-gray-300"
                  }
                  {...register(`${day}.open` as any, {
                    required: "Open is required",
                  })}
                />
                <label
                  htmlFor={`${day}_yes`}
                  className={"ml-3 block text-sm font-medium text-gray-700"}>
                  Yes
                </label>
              </div>
              <div className={"flex items-center"}>
                <input
                  id={`${day}_no`}
                  type={"radio"}
                  value={"No"}
                  className={
                    "focus:ring-primaryAlt h-4 w-4 text-primary border-gray-300"
                  }
                  {...register(`${day}.open` as any, {
                    required: "Open or open is required",
                  })}
                />
                <label
                  htmlFor={`${day}_no`}
                  className={"ml-3 block text-sm font-medium text-gray-700"}>
                  No
                </label>
              </div>
            </div>
            {errors[day]?.open && (
              <div className={"text-error pt-2"}>
                {errors[day]?.open?.message}
              </div>
            )}
          </div>
          <div
            className={
              "border-t-2 py-4" +
              `${watches[day]?.open === YesNoEnum.No ? " hidden" : ""}`
            }>
            <div className={"text-default"}>
              What are the open hours for {daySentenceCase}?{" "}
              <span className={"font-medium"}>
                {minutesToDuration(watches[day]?.openTimes?.start)} -{" "}
                {minutesToDuration(watches[day]?.openTimes?.end)}
              </span>
            </div>
            <Controller
              control={control}
              name={`${day}.openTimes`}
              render={({ field }) => {
                return (
                  <RangeSlider
                    colors={{
                      active: FabTheme.colors.primary,
                      inactive: FabTheme.textColor.emph.low,
                    }}
                    onChange={field.onChange}
                    defaultValues={field.value}
                  />
                );
              }}
            />
          </div>
          {/*<div*/}
          {/*  className={*/}
          {/*    "border-t-2 py-4" +*/}
          {/*    `${watches[day]?.open ? " hidden" : ""}`*/}
          {/*  }>*/}
          {/*  <div className={"text-default"}>*/}
          {/*    Does it close for cleaning on {day} in-between the open hours?*/}
          {/*  </div>*/}
          {/*  <div className={"mt-4 space-x-4 inline-flex"}>*/}
          {/*    <div className={"flex items-center"}>*/}
          {/*      <input*/}
          {/*        id={`${day}_cleaning_yes`}*/}
          {/*        type={"radio"}*/}
          {/*        value={"Yes"}*/}
          {/*        className={*/}
          {/*          "focus:ring-primaryAlt h-4 w-4 text-primary border-gray-300"*/}
          {/*        }*/}
          {/*        {...register(`${day}.isClosedForCleaning`, {*/}
          {/*          required: "Closed for cleaning is required",*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      <label*/}
          {/*        htmlFor={`${day}_cleaning_yes`}*/}
          {/*        className={"ml-3 block text-sm font-medium text-gray-700"}>*/}
          {/*        Yes*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*    <div className={"flex items-center"}>*/}
          {/*      <input*/}
          {/*        id={`${day}_cleaning_no`}*/}
          {/*        type={"radio"}*/}
          {/*        value={"No"}*/}
          {/*        className={*/}
          {/*          "focus:ring-primaryAlt h-4 w-4 text-primary border-gray-300"*/}
          {/*        }*/}
          {/*        {...register(`${day}.isClosedForCleaning`, {*/}
          {/*          required: "Closed for cleaning is required",*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      <label*/}
          {/*        htmlFor={`${day}_cleaning_no`}*/}
          {/*        className={"ml-3 block text-sm font-medium text-gray-700"}>*/}
          {/*        No*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  {errors[day]?.isClosedForCleaning && (*/}
          {/*    <div className={"text-error pt-2"}>*/}
          {/*      {errors[day].isClosedForCleaning.message}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className={*/}
          {/*    "border-t-2 py-4" +*/}
          {/*    `${*/}
          {/*      watches[day]?.isClosedForCleaning === YesNoEnum.No*/}
          {/*        ? " hidden"*/}
          {/*        : ""*/}
          {/*    }`*/}
          {/*  }>*/}
          {/*  <div className={"text-default"}>*/}
          {/*    What are the cleaning hours for {day}?{" "}*/}
          {/*    <span className={"font-medium"}>*/}
          {/*      {minutesToDuration(watches[day]?.cleaningHours.start)}*/}
          {/*      {" - "}*/}
          {/*      {minutesToDuration(watches[day]?.cleaningHours.end)}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*  <Controller*/}
          {/*    control={control}*/}
          {/*    name={`${day}.cleaningHours`}*/}
          {/*    render={({ field }) => {*/}
          {/*      return (*/}
          {/*        <RangeSlider*/}
          {/*          colors={{*/}
          {/*            active: FabTheme.textColor.default,*/}
          {/*            inactive: FabTheme.textColor.emph.low,*/}
          {/*          }}*/}
          {/*          onChange={field.onChange}*/}
          {/*          defaultValues={field.value}*/}
          {/*          gap={{ max: 4 }}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

        <div className={"py-6"}>
          <ButtonSimple text={"Next"} btnType={"submit"} btnStyle={"primary"} />
        </div>
      </form>
    );
  };

  const wizardStepper = wizardStepperWrapper(AddAmenitySteps)(1);
  const btnInactiveStyle =
    "py-12 px-8 rounded-full border border-gray-200 text-primary hover:text-white hover:bg-primary focus:outline-none focus:ring-0";
  const btnActiveStyle =
    "py-12 px-8 rounded-full border border-gray-200 text-white bg-primary focus:outline-none focus:ring-0";

  return (
    <AddAmenityWizardLayout>
      {wizardStepper}

      <div className={"flex flex-col items-center"}>
        <div
          className={"text-2xl font-medium text-heading text-center py-4 mb-4"}>
          What are the hours of operation?
        </div>

        <div
          className={
            "flex flex-row items-center justify-center space-x-4 py-4"
          }>
          {Object.keys(DayOfTheWeekType).map((day) => {
            return (
              <button
                className={
                  radioValue === day ? btnActiveStyle : btnInactiveStyle
                }
                onClick={() => radioGroupState.setSelectedValue(day)}
                key={`button-${day}`}>
                {day}
              </button>
            );
          })}
        </div>
        {Object.keys(DayOfTheWeekType).map((day) => {
          return getDayFormView(day as any);
        })}
      </div>
    </AddAmenityWizardLayout>
  );
};
