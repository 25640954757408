import {
  ButtonSimple,
  ErrorView,
  LoadingView,
} from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { trackEvent } from "../../utils/Amplitude";
import LaunchAppProgressBar from "./LaunchAppProgressBar";
import {
  getAccountSetupStepsInfo,
  resetSetupAccountStatus,
} from "./SetupAccountSlice";

export const LaunchApp = (props: any) => {
  const history = useHistory();

  const { selectedOrg, selectedProperty } = useAppSelector(
    (state) => state.selectionContext,
  );

  const { setupAccount, setupAccountStatus, setupAccountError } =
    useAppSelector((state) => state.setupAccountReducer.setupAccount);

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  // console.log("setup account", setupAccount);

  const [stepsStatus, setStepsStatus] = useState<any>(setupAccount || []);

  useEffect(() => {
    if (selectedOrg && selectedProperty) {
      dispatch(getAccountSetupStepsInfo());
    }
    return () => {
      dispatch(resetSetupAccountStatus());
    };
  }, [dispatch, selectedOrg, selectedProperty]);

  React.useEffect(() => {
    switch (setupAccountStatus) {
      case "SETUP_ACCOUNT_LOADING":
        setIsLoading(true);
        break;
      case "SETUP_ACCOUNT_SUCCESS":
        // console.log(setupAccount);
        setStepsStatus(setupAccount || []);
        setIsLoading(false);
        break;
      case "SETUP_ACCOUNT_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [setupAccountStatus]);

  if (!selectedOrg || !selectedProperty) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (setupAccountError) {
    return <ErrorView message={setupAccountError} />;
  }

  function checkForIncompleteStep() {
    if (stepsStatus && !isLoading) {
      const result = stepsStatus.filter((item: any) => item.complete === false);

      if (result.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {!checkForIncompleteStep() ? (
        <></>
      ) : (
        <>
          <h2 className={"text-left text-xl font-sans text-emph-high mb-4"}>
            Complete the steps below to configure your property and launch your
            resident mobile app.
          </h2>
          <div className={"w-3/5 pt-8"}>
            <LaunchAppProgressBar stepsStatus={stepsStatus} />

            <div
              className={"flex flex-row items-center justify-start py-6 mt-4"}>
              <ButtonSimple
                text={
                  stepsStatus[0].steps[0].complete
                    ? "Continue Launching My App"
                    : "Launch My App"
                }
                btnType={"button"}
                btnStyle={"primary"}
                onClick={() => {
                  trackEvent("Clicked Launch My App Button!");
                  history.push("/setup-account");
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
