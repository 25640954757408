import React, { FunctionComponent } from "react";

import { FabLogo } from "../../components/FabLogo";

type Props = unknown;

const OnboardingLayout: FunctionComponent<Props> = (props) => {
  const { children } = props;
  return (
    <div>
      <div className={"w-full min-h-screen bg-default top-0 pb-20"}>
        <nav className={"flex py-4 px-6 bg-transparent"}>
          <FabLogo styles={"h-14 w-auto"} />
        </nav>
        <div className={"mx-auto w-2/3 bg-white rounded-sm px-20 py-20"}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
