import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { SignInStep1EnterEmailView } from "./SignInStep1EnterEmailView";
import { SignInStep2EmailSentView } from "./SignInStep2EmailSentView";
import { SignInStep3EmailVerifyView } from "./SignInStep3EmailVerifyView";
import { SignInStep5AuthStatusView } from "./SignInStep5AuthStatusView";

export const SignInNav: React.FC<unknown> = () => {
  const { path, url } = useRouteMatch();

  console.log("SignInView | SignInView", { path, url });

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/enter-email`}>
          <SignInStep1EnterEmailView basePath={path} />
        </Route>
        <Route exact path={`${path}/email-sent`}>
          <SignInStep2EmailSentView basePath={path} />
        </Route>
        <Route exact path={`${path}/verify-email`}>
          <SignInStep3EmailVerifyView basePath={path} />
        </Route>
        {/*<Route exact path={`${path}/auth-verify`}>*/}
        {/*  <SignInStep4AuthVerifyView basePath={path} />*/}
        {/*</Route>*/}
        <Route exact path={`${path}/auth-status`}>
          <SignInStep5AuthStatusView basePath={path} />
        </Route>
        <Route path={path}>
          <Redirect to={`${url}/enter-email`} />
        </Route>
      </Switch>
    </div>
  );
};
