import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AllUnitNumbersNav } from "./AllUnitNumbersNav";

type UnitGroupsTabsProps = {
  basePath: string;
  addNewPath: string;
};

export const UnitNumbersTabs: React.FC<UnitGroupsTabsProps> = () => {
  const { path } = useRouteMatch();

  const pathToAllUnitGroups = `${path}/all-unit-groups`;

  return (
    <div className={"py-4"}>
      <div className={"min-h-screen"}>
        <Switch>
          <Route path={pathToAllUnitGroups} component={AllUnitNumbersNav} />
          <Route path={path} component={AllUnitNumbersNav} />
        </Switch>
      </div>
    </div>
  );
};
