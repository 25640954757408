import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AllContactsListing } from "./AllContactsListing";
import { PropertyContactEditor } from "./PropertyContactEditor";

export const AllContactsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  return (
    <div className={"flex flex-row"}>
      <div className={"flex flex-col w-full"}>
        <Switch>
          <Route path={`${path}/:contactId`}>
            <PropertyContactEditor basePath={path} />
          </Route>
          <Route path={`${path}`}>
            <AllContactsListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
