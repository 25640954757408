import React from "react";

import { LabelPill, LabelPillProps } from "./LabelPill";

type LabelPillContainerProps = {
  title: string;
  labels: LabelPillProps[];
};

export const LabelPillContainer: React.FC<LabelPillContainerProps> = (props) => {
  const { title, labels } = props;

  return (
    <div className={"flex align-center flex-row py-2"}>
      <span className={"text-lg self-center"}>{title}:</span>
      {labels.map((u) => (
        <LabelPill key={u.label} label={u.label} />
      ))}
    </div>
  );
};
