import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { upperCaseFirst } from "upper-case-first";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wizardStepperWrapper } from "../../components/wizard/WizardStepper";
import OnboardingLayout from "./OnboardingLayout";
import {
  saveOnboardingStep1,
  Step1AgreementsGetData,
  Step1AgreementsPostData,
} from "./OnboardingSlice";
import { OnboardingSteps } from "./OnboardingSteps";
import { OnboardingText } from "./OnboardingText";

type Props = {
  basePath: string;
};

const schema = Joi.object<Step1AgreementsGetData>({
  agreements: SchemaValidation.FieldRequiredArray({
    label: "Agreement",
    items: Joi.object({
      hasAccepted: SchemaValidation.FieldRequiredBoolean({
        label: "Agreement",
        option: "mustBeTrue",
      }),
    }),
  }),
});

const Step1Agreements: FunctionComponent<Props> = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { wizardStepStatus, managerEntryPointState } = useAppSelector(
    (state) => state.onboarding,
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm<Step1AgreementsGetData>({
    defaultValues: { agreements: managerEntryPointState?.agreements },
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });
  const { fields } = useFieldArray({ control, name: "agreements" });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    switch (wizardStepStatus) {
      case "STEP1_LOADING":
        setIsLoading(true);
        break;
      case "STEP1_SUCCESS":
        setIsLoading(false);
        history.push(`${basePath}/step2-profile`);
        break;
      default:
        break;
    }
  }, [basePath, history, wizardStepStatus]);

  const onSubmit = (step1Data: Step1AgreementsPostData) => {
    dispatch(saveOnboardingStep1(step1Data));
  };

  const currentStepIndex = 0;
  const wizardStepper = wizardStepperWrapper(OnboardingSteps)(currentStepIndex);

  const agreementsView = fields.map((item, index) => {
    return (
      <div className={"flex items-start"} key={item.id}>
        <div className={"flex items-center h-5"}>
          <input hidden {...register(`agreements.${index}.title` as const)} />
          <input hidden {...register(`agreements.${index}.url` as const)} />
          <input
            type={"checkbox"}
            {...register(`agreements.${index}.hasAccepted` as const)}
            className={
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            }
          />
        </div>
        <div className={"flex flex-col ml-3"}>
          <label className={"font-medium text-gray-700"}>
            I accept the{" "}
            <a
              href={item.url}
              target={"_blank"}
              className={"text-primary"}
              rel={"noreferrer"}>
              {upperCaseFirst(item.title)}
            </a>
          </label>
          {errors.agreements?.[index]?.hasAccepted && (
            <div className={"text-error"}>
              {errors.agreements?.[index]?.hasAccepted?.message}
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <OnboardingLayout>
      {wizardStepper}
      <OnboardingText title={OnboardingSteps[currentStepIndex].name}>
        To continue using FabricApp, please review and accept the privacy policy
        and the terms of service shown below. We will notify you of any changes
        to these documents ahead of time.
      </OnboardingText>

      <div
        className={
          "max-w-lg mx-auto flex flex-col items-center my-2 px-6 py-5 space-y-6"
        }>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <div className={"mt-4 space-y-4"}>{agreementsView}</div>
          </fieldset>
          <div className={"flex justify-center mt-5"}>
            <ButtonSimple
              text={"Next"}
              btnType={"submit"}
              btnStyle={"primary"}
              isDisabled={!isValid}
              isSpinning={isLoading}
            />
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Step1Agreements;
