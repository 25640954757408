import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";

type PaginationProps = {
  nPages: number;
  currentPage: number;
  setCurrentPage(currentPage: number): void;
};

const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
}: PaginationProps) => {
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div
      className={"isolate inline-flex rounded-md mt-5 w-full justify-center"}>
      <button
        type={"button"}
        className={
          "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white lg:px-16 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        }
        onClick={prevPage}>
        <span className={"sr-only"}>Previous</span>
        <ChevronLeftIcon className={"h-5 w-5"} aria-hidden={"true"} />
      </button>
      <button
        type={"button"}
        className={
          "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white lg:px-16 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        }
        onClick={nextPage}>
        <span className={"sr-only"}>Next</span>
        <ChevronRightIcon className={"h-5 w-5"} aria-hidden={"true"} />
      </button>
    </div>
  );
};

export default Pagination;
