import { getDownloadUrl } from "../api/managersApi";
import { MaybeT } from "./SimpleTypes";

export type AvatarInitialsInput = {
  firstName: string;
  lastName: string;
};

export function getAvatarInitialsUrl(input: AvatarInitialsInput): string {
  const firstNameChar = input.firstName.slice(0, 2) ?? "";
  const lastNameChar = input.lastName.slice(0, 2) ?? "";
  const basePath =
    "https://ui-avatars.com/api/?background=6b76ff&color=fff&size=128&";
  return `${basePath}name=${firstNameChar}+${lastNameChar}`;
}

export type AvatarOrPhotoUrlInput = AvatarInitialsInput & {
  photoPath: MaybeT<string>;
};

export function getAvatarOrPhotoUrl(input: AvatarOrPhotoUrlInput) {
  const { firstName, lastName, photoPath } = input;
  if (photoPath) {
    return getDownloadUrl(photoPath);
  }

  return getAvatarInitialsUrl({ firstName, lastName });
}
