export const FabTheme = {
  colors: {
    primary: "#6b76ff",
    primaryAlt: "#a5aeff",
    primaryLight: "#e2e4ff",
    secondary: "#d1a039",
    border: {
      high: "#707070",
      low: "#e8e9ec",
    },
  },
  backgroundColor: {
    default: "#f5f6fa",
  },
  textColor: {
    default: "#4d4f5c",
    heading: "#43425d",
    emph: {
      high: "#9a9ba2",
      low: "#b8b9c4",
    },
    error: "#EF4444",
  },
};

export const SharedStyles = {
  inputs: {
    input: () =>
      `
      bg-white 
      block 
      border 
      border-gray-300 
      focus:border-indigo-500 
      focus:outline-none 
      focus:ring-indigo-500 
      mt-1 
      rounded-md
      shadow-sm 
      sm:text-sm 
      w-full 
      `,
    inputDesc: () => "text-sm mt-1",
    disabled: () =>
      `${SharedStyles.inputs.input} opacity-50 cursor-not-allowed`,
  },
  tabs: {
    tabListClassName: () =>
      "flex flex-row mb-4 border-b-2 border-border-low w-full text-emph-high",
    selectedTabClassName: () =>
      "text-primary border-b-2 px-4 py-4 border-primary text-center",
    unselectedTabClassName: () => "px-4 py-4 border-primary text-center",
  },
};
