import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { trackEvent } from "../../utils/Amplitude";
import { UnitGroupComposer } from "./UnitGroupComposer";
import { UnitGroupsTabs } from "./UnitGroupsTabs";

export const UnitGroupsNav: React.FC<unknown> = () => {
  const { path } = useRouteMatch();

  trackEvent("Unit Groups Clicked!");

  const pathToAdd = `${path}/add-unit-group`;

  return (
    <div className={"container mx-auto px-6 py-4"}>
      <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
        <Link to={path}>
          <div className={"text-primary"}>Unit Groups</div>
        </Link>
      </div>
      <h2 className={"w-3/4 text-left text-xl font-sans text-emph-high mb-4"}>
        💡 Create Unit Groups for specific units that might receive similar
        announcements. Examples: “Units with a balcony”, “South facing units”,
        “Units with pets”. Only the units in a specific Unit Group will receive
        the announcement.
      </h2>

      <Switch>
        <Route path={pathToAdd}>
          <UnitGroupComposer basePath={path} />
        </Route>
        <Route path={path}>
          <UnitGroupsTabs basePath={path} addNewPath={pathToAdd} />
        </Route>
      </Switch>
    </div>
  );
};
