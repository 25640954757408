import {
  ButtonSimple,
  ErrorView,
  LockSvg,
} from "@fabricapp-ca/fabricapp-shared-ui";
import React, { SyntheticEvent, useRef } from "react";
import { useHistory } from "react-router-dom";

import { LocalStorageAPI } from "../../api/localStorageAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SubRouteProps } from "../../components/SubRouteProps";
import { trackEvent } from "../../utils/Amplitude";
import { firebaseEmailSignIn, resetToInitial } from "./FirebaseSlice";
import LoginLayout from "./LoginLayout";
import { SignInButton } from "./SignInButton";

export const SignInStep1EnterEmailView: React.FC<SubRouteProps> = (props) => {
  const { basePath } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { authStatus, currentUserInfo, error } = useAppSelector(
    (state) => state.auth.firebaseAuth,
  );
  console.log("SignInStep1EnterEmailView | SignInStep1EnterEmailView", {
    basePath,
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const emailRef = useRef<HTMLInputElement>(null);

  // If user is already logged-in, re-direct them to the dashboard
  React.useEffect(() => {
    if (currentUserInfo) {
      console.log("SignInStep1EnterEmailView | history.push /dashboard");
      history.push("/dashboard");
    } else {
      LocalStorageAPI.clearStorage();
    }
  }, [currentUserInfo, history]);

  React.useEffect(() => {
    switch (authStatus) {
      case "SIGN_IN_LOADING":
        setIsLoading(true);
        break;
      case "SIGN_IN_DONE":
        setIsLoading(false);
        console.log("SignInStep1EnterEmailView | history.push /email-sent");
        history.push(`${basePath}/email-sent`);
        break;
      case "SIGN_IN_ERROR":
        setIsLoading(false);
        setHasError(true);
        break;
      default:
        break;
    }
  }, [authStatus, basePath, history]);

  function handleSubmit(e: SyntheticEvent) {
    trackEvent("Sign in Clicked!");
    e.preventDefault();

    const email = emailRef.current?.value.toLowerCase().trim();

    if (!email) {
      return;
    }

    dispatch(firebaseEmailSignIn(email));
  }

  if (hasError && error) {
    return (
      <LoginLayout>
        <ErrorView message={error} />
        <SignInButton
          btnText={"Back to sign-in"}
          onClick={() => {
            dispatch(resetToInitial());
            console.log(
              "SignInStep1EnterEmailView | hasError history.push /enter-email",
            );
            history.push(`${basePath}/enter-email`);
          }}
        />
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
        Sign in
      </h2>
      <h2 className={"mt-2 text-center text-lg font-medium text-emph-high"}>
        Continue to FabricApp Manager
      </h2>

      <form className={"mt-8"} onSubmit={handleSubmit}>
        <input type={"hidden"} name={"remember"} value={"true"} />
        <div>
          <label
            htmlFor={"email"}
            className={
              "block text-xs font-semibold text-gray-600 uppercase mb-1"
            }>
            Email Address
          </label>
          <input
            id={"email"}
            type={"text"}
            name={"email"}
            autoComplete={"email"}
            ref={emailRef}
            className={
              "block w-full p-3 mb-4 border border-gray-300 rounded text-gray-700 bg-gray-50 appearance-none focus:outline-none focus:bg-white focus:shadow-inner focus:ring-indigo-500 focus:border-indigo-500"
            }
            required
          />
        </div>

        <div className={"flex mt-8 w-full"}>
          <ButtonSimple
            text={"Sign in with email"}
            SVGImage={LockSvg}
            btnType={"submit"}
            btnStyle={"primary"}
            isSpinning={isLoading}
            classes={"w-full"}
          />
        </div>
        <div className={"flex my-4 w-full"}>
          <ButtonSimple
            text={"Sign up to FabricApp"}
            btnType={"button"}
            btnStyle={"secondary"}
            isSpinning={isLoading}
            onClick={() => {
              trackEvent("Sign up Clicked!");
              history.push("/signup");
            }}
            classes={"w-full"}
          />
        </div>
      </form>
    </LoginLayout>
  );
};
