import React, { FunctionComponent } from "react";

import { FabLogo } from "../../components/FabLogo";

type Props = unknown;

const SignupLayout: FunctionComponent<Props> = (props) => {
  const { children } = props;

  return (
    <div
      className={
        "min-h-screen flex flex-col items-center justify-center bg-indigo-50"
      }>
      <div
        className={
          "max-w-5xl w-full bg-white rounded-md border px-16 py-8 shadow-lg"
        }>
        <FabLogo styles={"mx-auto h-24 w-auto"} />

        {children}
      </div>

    </div>
  );
};

export default SignupLayout;
