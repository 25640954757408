import { ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import { useParams } from "react-router-dom";

import { store } from "../../../app/store";
import { InvitedManagerComposer } from "./InvitedManagerComposer";
import { invitedManagerByIdSelector } from "./InvitedManagersListingSlice";

type PropertyContactEditorProps = {
  basePath: string;
};

export const InvitedManagerEditor: React.FC<PropertyContactEditorProps> = (
  props,
) => {
  const { invitedManagerId } = useParams<{ invitedManagerId: string }>();
  const { basePath } = props;

  const existingDto = invitedManagerByIdSelector(
    store.getState(),
    invitedManagerId,
  );

  if (!existingDto) {
    return <ErrorView message={"Missing invited manager in store"} />;
  }

  return (
    <InvitedManagerComposer basePath={basePath} existingDto={existingDto} />
  );
};
