import React from "react";
import SignupLayout from "./SignupLayout";

import { SetupProfileComposer } from "./SetupProfileComposer";
import {wizardStepperWrapper} from "../../components/wizard/WizardStepper";
import {SignupSteps} from "./SignupSteps";

export const SetupProfile = (props: any) => {
  const { basePath } = props;
  const currentStepIndex = 1;
  const wizardStepper = wizardStepperWrapper(SignupSteps)(currentStepIndex);

  // @ts-ignore
  return (
    <SignupLayout>
      {wizardStepper}
      <div className={"mt-16"}>
        <h2 className={"mt-3 text-2xl font-medium text-default text-center"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Setup your Profile
        </h2>
        <h2 className={"mt-2 text-center text-lg font-medium text-emph-high"}>
          FabricApp does not share your personal information without your
          consent. Please refer to our detailed privacy policy for more
          information.
        </h2>

        <SetupProfileComposer />
      </div>
    </SignupLayout>
  );
};
