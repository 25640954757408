import { ManagerPostUnitReqDto } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import React from "react";

import { ImportEmailsRow } from "./ImportEmailsRow";

type ImportEmailsTableProps = {
  clickAddEmail: boolean;
  currentRecords: Array<ManagerPostUnitReqDto>;
  deleteEmailFromRow(rowIndex: number, temporaryResidentEmailId: string): void;
  handleChange(index: number, evnt: any): void;
};

export const ImportEmailsTable = (props: ImportEmailsTableProps) => {
  const { clickAddEmail, handleChange, currentRecords, deleteEmailFromRow } =
    props;

  const unitNumberDisplayRows = currentRecords.map(
    (data: any, index: number) => {
      const { id, name, floor, temporaryResidents } = data;
      return (
        <ImportEmailsRow
          key={index as any}
          index={index}
          name={name}
          floor={floor}
          residentEmails={temporaryResidents}
          clickAddEmail={clickAddEmail}
          handleChange={handleChange}
          deleteEmailFromRow={deleteEmailFromRow}
        />
      );
    },
  );

  return (
    <div
      className={
        "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      }>
      <table className={"min-w-full divide-y divide-gray-200"}>
        <thead className={"bg-gray-50"}>
          <tr>
            <th
              scope={"col"}
              className={
                "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              }>
              UNIT NUMBER
            </th>
            <th
              scope={"col"}
              className={
                "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              }>
              FLOOR NUMBER
            </th>
            <th
              scope={"col"}
              className={
                "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              }>
              EMAIL(S)
            </th>
          </tr>
        </thead>
        <tbody className={"bg-white divide-y divide-gray-200"}>
          {unitNumberDisplayRows}
        </tbody>
      </table>
    </div>
  );
};
