import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { prettyRawError } from "../../utils/ErrorParseDisplay";

export interface ManagerAuthStatusSliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  error?: string;
  managerStatus: ManagersClient.ManagerGetStatusResDtoStatusEnum;
}

const initialState: ManagerAuthStatusSliceState = {
  status: "IDLE",
  managerStatus: ManagersClient.ManagerGetStatusResDtoStatusEnum.Invalid,
};

export const SignInStep5AuthStatusSlice = createSlice({
  name: "authStatus",
  initialState,
  reducers: {
    resetToIdle: (state) => {
      state.status = "IDLE";
      state.error = undefined;
      state.managerStatus =
        ManagersClient.ManagerGetStatusResDtoStatusEnum.Invalid;
    },
    getManagerAuthStatusStart: (state) => {
      state.status = "LOADING";
    },
    getManagerAuthStatusSuccess: (
      state,
      action: PayloadAction<{
        managerStatus: ManagersClient.ManagerGetStatusResDtoStatusEnum;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.error = undefined;
      state.managerStatus = action.payload.managerStatus;
    },
    getManagerAuthStatusFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
  },
});

export const {
  resetToIdle,
  getManagerAuthStatusStart,
  getManagerAuthStatusSuccess,
  getManagerAuthStatusFailure,
} = SignInStep5AuthStatusSlice.actions;

export const managerAuthStatusReducer = SignInStep5AuthStatusSlice.reducer;

export const loadManagerAuthStatus = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getManagerAuthStatusStart());
    const managerStatus = await managersApi.getManagerAuthStatus();
    dispatch(
      getManagerAuthStatusSuccess({
        managerStatus: managerStatus.status,
      }),
    );
  } catch (err: any) {
    dispatch(getManagerAuthStatusFailure(prettyRawError(err)));
  }
};
