export const LS_SIGNIN_EMAIL_KEY_NAME = "emailForSignIn";
export const LS_AUTH_TOKEN_KEY_NAME = "authToken";
export const LS_CURRENT_USER_INFO_KEY_NAME = "currentUserInfo";
export const LS_LAST_SIGNED_IN = "lastSignedIn";
export const LS_SELECTED_ORG = "selectedOrg";
export const LS_SELECTED_PROPERTY = "selectedProperty";
export const LS_SELECTED_BUILDINGS = "selectedBuildings";

const ALL_KEYS = [
  "signInEmail",
  "authToken",
  "currentUserInfo",
  "selectedOrg",
  "selectedBuildings",
  "selectedProperty",
  "lastSignedIn",
] as const;

type LSKeyType = typeof ALL_KEYS[number];

export class LocalStorageAPI {
  // eslint-disable-next-line consistent-return
  static getKeyName = (key: LSKeyType): string => {
    switch (key) {
      case "signInEmail":
        return LS_SIGNIN_EMAIL_KEY_NAME;
      case "authToken":
        return LS_AUTH_TOKEN_KEY_NAME;
      case "currentUserInfo":
        return LS_CURRENT_USER_INFO_KEY_NAME;
      case "lastSignedIn":
        return LS_LAST_SIGNED_IN;
      case "selectedOrg":
        return LS_SELECTED_ORG;
      case "selectedProperty":
        return LS_SELECTED_PROPERTY;
      case "selectedBuildings":
        return LS_SELECTED_BUILDINGS;
    }
  };

  static setKv = async (key: LSKeyType, value: string): Promise<void> => {
    const keyName = LocalStorageAPI.getKeyName(key);
    window.localStorage.setItem(keyName, value);
  };

  static getKv = async (key: LSKeyType): Promise<string | undefined> => {
    const keyName = LocalStorageAPI.getKeyName(key);
    return window.localStorage.getItem(keyName) ?? undefined;
  };

  static removeKv = async (key: LSKeyType): Promise<void> => {
    const keyName = LocalStorageAPI.getKeyName(key);
    return window.localStorage.removeItem(keyName);
  };

  static setJson = async <T>(
    key: LSKeyType,
    value: T | undefined,
  ): Promise<void> => {
    if (!value) {
      return;
    }
    const keyName = LocalStorageAPI.getKeyName(key);
    window.localStorage.setItem(keyName, JSON.stringify(value));
  };

  static getJson = async <T>(key: LSKeyType): Promise<T | undefined> => {
    const keyName = LocalStorageAPI.getKeyName(key);
    const strItem = window.localStorage.getItem(keyName);
    return strItem ? JSON.parse(strItem) : undefined;
  };

  static removeJson = async (key: LSKeyType): Promise<void> => {
    return LocalStorageAPI.removeKv(key);
  };

  static clearStorage = async (): Promise<void> => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of ALL_KEYS) {
      // eslint-disable-next-line no-await-in-loop
      await LocalStorageAPI.removeKv(key);
    }
  };
}
