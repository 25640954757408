import React from "react";
import { IconBaseProps } from "react-icons";
import { HiOutlineArrowRight } from "react-icons/hi";

export type StatsCardProps = {
  label: string;
  value: string;
  iconType?: React.ComponentType<IconBaseProps>;
};

export const StatsCard: React.FC<StatsCardProps> = (props) => {
  const DefaultIconComponentType: React.ComponentType<IconBaseProps> =
    HiOutlineArrowRight;
  const {
    label,
    value,
    iconType: IconTypeComponent = DefaultIconComponentType,
  } = props;
  const derivedIconSvg = <IconTypeComponent size={20} />;

  return (
    <div className={"col-span-12 sm:col-span-6 md:col-span-4"}>
      <div className={"flex flex-row bg-white rounded p-4"}>
        <div
          className={
            "flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-primaryLight text-primary"
          }>
          {derivedIconSvg}
        </div>
        <div className={"flex flex-col flex-grow ml-4"}>
          <div className={"text-sm text-emph-high"}>{label}</div>
          <div className={"font-bold text-lg"}>{value}</div>
        </div>
      </div>
    </div>
  );
};
