import { combineReducers } from "@reduxjs/toolkit";

import { firebaseAuthReducer } from "./FirebaseSlice";
import { managerAuthVerifyReducer } from "./SignInStep4AuthVerifySlice";
import { managerAuthStatusReducer } from "./SignInStep5AuthStatusSlice";

export const managerAuthReducer = combineReducers({
  firebaseAuth: firebaseAuthReducer,
  managerAuthVerify: managerAuthVerifyReducer,
  managerAuthStatus: managerAuthStatusReducer,
});
