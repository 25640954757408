import React from "react";
import { useParams } from "react-router-dom";

import { store } from "../../../app/store";
import { PendingResidentComposer } from "./PendingResidentComposer";
import { pendingResidentByIdSelector } from "./PendingResidentsListingSlice";

type PropertyContactEditorProps = {
  basePath: string;
};

export const PendingResidentEditor: React.FC<PropertyContactEditorProps> = (
  props,
) => {
  const { pendingResidentId } = useParams<{ pendingResidentId: string }>();
  const { basePath } = props;

  const existingDto = pendingResidentByIdSelector(
    store.getState(),
    pendingResidentId,
  );

  return (
    <PendingResidentComposer basePath={basePath} existingDto={existingDto} />
  );
};
