import React from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import DashboardHeaderNav from "../Dashboard/DashboardHeaderNav";
import { BuildingListing } from "./BuildingListing";

export type Props = {
  basePath: string;
};

export const BuildingSignInNav: React.FC<Props> = () => {
  const { path } = useRouteMatch();
  const { selectedOrg, selectedProperty, selectedBuildings } = useAppSelector(
    (state) => state.selectionContext,
  );

  if (selectedOrg && selectedProperty && selectedBuildings) {
    console.log("history.redirect /dashboard");
    return <Redirect to={"/dashboard"} />;
  }

  return (
    <div className={"flex flex-col min-h-screen bg-white"}>
      <DashboardHeaderNav />

      <div className={"container mx-auto px-6 py-4"}>
        <div className={"flex flex-row text-2xl font-medium pb-4 mb-4"}>
          <Link to={path}>
            <div className={"text-primary"}>Select A Building</div>
          </Link>
        </div>

        <Switch>
          <Route path={path}>
            <BuildingListing basePath={path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
