import React, { FunctionComponent } from "react";
import { WithContext as ReactTags } from "react-tag-input";

import { SharedStyles } from "../../../themes/FabTheme";

type UnitGroupRowProps = {
  index: number;
  residentEmails: Array<any>;
  handleChange(index: number, evnt: any): void;
};

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};

export const ImportEmailsInputRow: FunctionComponent<UnitGroupRowProps> = (
  props,
) => {
  const { index, residentEmails, handleChange } = props;

  const [tags, setTags] = React.useState<any>([]);
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [dirtyString, setDirtyString] = React.useState<string>("");

  const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const emailExistsIn = (email: any) => {
    const result = residentEmails.find((item: any) => item.email === email);

    return !!result;
  };

  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: any, ind: number) => ind !== i));
  };

  const clearAllTags = () => {
    setTags([]);
  };

  const handleAddition = (tag: any) => {
    const { text } = tag;

    if (!validateEmail(text)) {
      setErrorMsg("Email is incorrect");
    } else if (emailExistsIn(text)) {
      setErrorMsg("Email already exists! Please try another");
    } else {
      setTags([...tags, tag]);
      handleChange(index, tag);
    }
  };

  return (
    <td className={"p-3"}>
      <div
        className={
          "w-auto flex justify-end items-center border border-gray-400"
        }>
        <ReactTags
          tags={tags}
          placeholder={""}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleInputChange={(i) => {
            setDirtyString(i);
            setErrorMsg("");
          }}
          // onClearAll={() => {}}
          classNames={
            tags.length > 0
              ? {
                  tags: "p-3 w-full",
                  tagInputField: "mt-3 border",
                  tag: "inline-block bg-indigo-200 p-2 rounded-md text-xs lg:text-sm md:text-sm ml-2 mt-1",
                  remove: "ml-3",
                }
              : {
                  tags: "w-full",
                  tagInputField: "w-full border border-white",
                  tag: " inline-block bg-indigo-200 p-2 rounded-md",
                  remove: "ml-3",
                }
          }
        />

        <button className={"relative w-10"} onClick={clearAllTags}>
          <svg
            xmlns={"http://www.w3.org/2000/svg"}
            fill={"none"}
            viewBox={"0 0 24 24"}
            strokeWidth={1.5}
            stroke={"currentColor"}
            className={"w-6 h-6"}>
            <path
              strokeLinecap={"round"}
              strokeLinejoin={"round"}
              d={"M6 18L18 6M6 6l12 12"}
            />
          </svg>
        </button>
      </div>
      <div className={"text-red-400"}>{errorMsg}</div>
      {dirtyString !== "" && (
        <span className={`${SharedStyles.inputs.inputDesc()} border-gray-300`}>
          Press enter/return after entering an email.
          <p>You can enter multiple emails.</p>
        </span>
      )}
    </td>
  );
};
