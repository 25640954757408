import { ButtonSimple } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";

import OnboardingLayout from "../Onboarding/OnboardingLayout";

type Props = {
  basePath: string;
};

// const schema = Joi.object<DomainModels.ManagerOnboardingStep3>({
//   data: Joi.object({
//     buildingName: SchemaValidation.FieldRequiredString({
//       label: "Property name",
//       min: 5,
//       max: 25,
//     }),
//     buildingIdentifier: SchemaValidation.FieldRequiredString({
//       label: "Property identifier",
//       min: 3,
//       max: 25,
//     }),
//     buildingImageUrl: SchemaValidation.FieldOptionalUriString({
//       label: "Property image url",
//     }),
//     towers: SchemaValidation.FieldRequiredArray({
//       label: "Building",
//       items: Joi.object({
//         name: SchemaValidation.FieldRequiredString({ label: "Property name" }),
//         address: SchemaValidation.FieldRequiredString({
//           label: "Building address",
//           min: 10,
//           max: 100,
//         }),
//         numberOfFloors: SchemaValidation.FieldRequiredInteger({
//           label: "Number of floors",
//           min: 1,
//           max: 201,
//         }),
//         numberOfUnits: SchemaValidation.FieldRequiredInteger({
//           label: "Number of units",
//           min: 1,
//           max: 1001,
//         }),
//       }),
//     }),
//   }),
// });

export const OldPropertyComposer: React.FC<Props> = () => {
  console.log("AddProperty | AddProperty");

  // const {
  //   handleSubmit,
  //   register,
  //   control,
  //   formState: { errors, isValid },
  // } = useForm<DomainModels.ManagerOnboardingStep3>({
  //   defaultValues: step3DefaultValue,
  //   mode: "onChange",
  //   resolver: joiResolver(schema, { allowUnknown: true }),
  // });
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "data.towers",
  // });

  // const towersView = [].map((item, index) => {
  //   const towerNumber = index + 1;
  //   return (
  //     <div
  //       key={"foobar"}
  //       className={
  //         "flex flex-col p-4 w-11/12 mx-auto border border-border-low shadow shadow-sm rounded-lg"
  //       }>
  //       <div className={"mb-5 text-center tracking-wider"}>
  //         Building {towerNumber}
  //       </div>
  //       <div className={"mb-5"}>
  //         <label
  //           className={"text-emph-high font-medium mb-1 text-default block"}>
  //           Building name
  //           <input
  //             type={"text"}
  //             //{...register(`data.towers.${index}.name` as const)}
  //             className={
  //               "w-full px-4 py-3 rounded-lg focus:outline-none focus:shadow-outline text-gray-600 font-medium"
  //             }
  //           />
  //           {/*{errors.data?.towers?.[index]?.name && (*/}
  //           {/*  <div className={"text-error"}>*/}
  //           {/*    {errors.data?.towers?.[index]?.name?.message}*/}
  //           {/*  </div>*/}
  //           {/*)}*/}
  //         </label>
  //       </div>
  //       <div className={"mb-5"}>
  //         <label
  //           className={"text-emph-high font-medium mb-1 text-default block"}>
  //           Building address
  //           <input
  //             type={"text"}
  //             //{...register(`data.towers.${index}.address` as const)}
  //             className={
  //               "w-full px-4 py-3 rounded-lg focus:outline-none focus:shadow-outline text-default"
  //             }
  //           />
  //           {/*{errors.data?.towers?.[index]?.address && (*/}
  //           {/*  <div className={"text-error"}>*/}
  //           {/*    {errors.data?.towers?.[index]?.address?.message}*/}
  //           {/*  </div>*/}
  //           {/*)}*/}
  //         </label>
  //       </div>
  //       <div className={"mb-5"}>
  //         <label
  //           className={"text-emph-high font-medium mb-1 text-default block"}>
  //           Number of floors
  //           <input
  //             type={"text"}
  //             //{...register(`data.towers.${index}.numberOfFloors` as const)}
  //             className={
  //               "w-full px-4 py-3 rounded-lg focus:outline-none focus:shadow-outline text-default"
  //             }
  //           />
  //           {/*{errors.data?.towers?.[index]?.numberOfFloors && (*/}
  //           {/*  <div className={"text-error"}>*/}
  //           {/*    {errors.data?.towers?.[index]?.numberOfFloors?.message}*/}
  //           {/*  </div>*/}
  //           {/*)}*/}
  //         </label>
  //       </div>
  //       <div className={"mb-5"}>
  //         <label
  //           className={"text-emph-high font-medium mb-1 text-default block"}>
  //           Number of floors
  //           <input
  //             type={"text"}
  //             //{...register(`data.towers.${index}.numberOfUnits` as const)}
  //             className={
  //               "w-full px-4 py-3 rounded-lg focus:outline-none focus:shadow-outline text-default"
  //             }
  //           />
  //           {/*{errors.data?.towers?.[index]?.numberOfUnits && (*/}
  //           {/*  <div className={"text-error"}>*/}
  //           {/*    {errors.data?.towers?.[index]?.numberOfUnits?.message}*/}
  //           {/*  </div>*/}
  //           {/*)}*/}
  //         </label>
  //       </div>
  //
  //       <ButtonSimple
  //         text={`Remove Building ${towerNumber}`}
  //         btnType={"button"}
  //         btnStyle={"secondary"}
  //         btnIntent={"error"}
  //         onClick={() => {
  //           // remove(index)
  //         }}
  //       />
  //     </div>
  //   );
  // });

  return (
    <OnboardingLayout>
      <div
        className={"max-w-lg mx-auto flex flex-col my-2 px-6 py-5 space-y-6"}>
        <form
          onSubmit={() => {
            // handleSubmit(onSubmit)
          }}>
          {/*<Controller*/}
          {/*  // control={control}*/}
          {/*  name={"data.buildingPhotoUrl"}*/}
          {/*  render={({ field }) => (*/}
          {/*    <ImageUploadSimple*/}
          {/*      onChange={field.onChange}*/}
          {/*      labelText={"Upload Property image"}*/}
          {/*      uploadDirName={"Property"}*/}
          {/*      defaultImageType={*/}
          {/*        ManagersClient.SharedDefaultImageResDtoTypeEnum.Property*/}
          {/*      }*/}
          {/*      uploadType={ManagersClient.UploadPhotoUploadTypeEnum.PerkPhoto}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}

          <div className={"mb-5"}>
            <label
              htmlFor={"buildingName"}
              className={"text-emph-high font-medium mb-1 text-default block"}>
              Property Name
              <input
                type={"text"}
                //{...register("data.buildingName")}
                className={
                  "w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                }
              />
              <div className={"text-emph-high text-sm mt-1"}>
                This is the name of the entire property. If you have multiple
                buildings with different names, you can enter them below.
              </div>
              {/*{errors.data?.buildingName && (*/}
              {/*  <div className={"text-error"}>*/}
              {/*    {errors.data?.buildingName?.message}*/}
              {/*  </div>*/}
              {/*)}*/}
            </label>
          </div>

          <div className={"mb-5"}>
            <label
              className={"text-emph-high font-medium mb-1 text-default block"}>
              Property Identifier
              <input
                type={"text"}
                //{/*{...register("data.buildingIdentifier", {*/}
                //{/*  required: "property identifier is required",*/}
                //{/*})}*/}
                className={
                  "w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                }
              />
              <div className={"text-emph-high text-sm mt-1"}>
                This could be a LTSA number, LMS number or an internal building
                identifier.
              </div>
              {/*{errors.data?.buildingIdentifier && (*/}
              {/*  <div className={"text-error"}>*/}
              {/*    {errors.data?.buildingIdentifier?.message}*/}
              {/*  </div>*/}
              {/*)}*/}
            </label>
          </div>

          <div className={"p-6 flex flex-col items-center space-y-4"}>
            {/*{towersView}*/}
            <div className={"p-4 text-center"}>
              <ButtonSimple
                text={"Add a Building"}
                btnType={"button"}
                btnStyle={"secondary"}
                onClick={() => {
                  // append({
                  //   name: "",
                  //   address: "",
                  //   numberOfFloors: 0,
                  //   numberOfUnits: 0,
                  // });
                }}
              />
            </div>
          </div>

          <div className={"flex justify-center mt-5"}>
            <ButtonSimple
              text={"Create"}
              btnType={"submit"}
              btnStyle={"primary"}
              isDisabled={false}
              isSpinning={false}
            />
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};
