import React, { FunctionComponent } from "react";

type OnboardingTextProps = {
  title: string;
};

export const OnboardingText: FunctionComponent<OnboardingTextProps> = (
  props,
) => {
  const { title, children } = props;
  return (
    <div className={"my-8"}>
      <div className={"font-medium text-xl text-heading text-center mb-8"}>
        {title}
      </div>
      <div className={"max-w-lg mx-auto font-light text-default text-center"}>
        {children}
      </div>
    </div>
  );
};
