import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import { useRouteMatch } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AnnouncementsNav } from "../Announcements/AnnouncementsNav";
import { DocumentsNav } from "../Documents/DocumentsNav";
import { DownloadAppPage } from "../DownloadApp/DownloadAppPage";
import { PropertyContactsNav } from "../PropertyContacts/PropertyContactsNav";
import { ImportEmailsNav } from "../ResidentDirectory/ImportEmails/ImportEmailsNav";
import { ApproveYourselfPage } from "../ResidentDirectory/PendingResidents/ApproveYourselfPage";
import { UnitNumbersNav } from "../UnitNumbers/UnitNumbersNav";
import { FinishSetup } from "./FinishSetup";
import { LaunchTab } from "./LaunchTab";
import { ProgressBreadcrumbs } from "./ProgressBreadcrumbs";
import { SetupAccountProgressSidebar } from "./SetupAccountProgressSidebar";
import { getAccountSetupStepsInfo } from "./SetupAccountSlice";
import { StepAlert } from "./StepAlert";

export const SetupAccountPage = (props: any) => {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();

  const { setupAccount, setupAccountStatus, setupAccountError } =
    useAppSelector((state) => state.setupAccountReducer.setupAccount);

  const [isLoading, setIsLoading] = React.useState(true);
  const [warningMsg, setWarningMsg] = useState<string>("");

  const [isOpen, setIsOpen] = useState(true);
  const [breadcrumbStep, setBreadcrumbStep] = useState<any>([]);

  React.useEffect(() => {
    switch (setupAccountStatus) {
      case "SETUP_ACCOUNT_LOADING":
        setIsLoading(true);
        break;
      case "SETUP_ACCOUNT_SUCCESS":
        setIsLoading(false);
        break;
      case "SETUP_ACCOUNT_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [setupAccountStatus]);

  React.useEffect(() => {
    if (
      setupAccount == null ||
      (setupAccount[0] == null &&
        setupAccountStatus !== "SETUP_ACCOUNT_LOADING")
    ) {
      console.log("getting account");
      dispatch(getAccountSetupStepsInfo());
    }
  }, [setupAccount]);

  function setBreadcrumbsArray(
    mainCategoryName: string,
    subCategoryName: string,
  ) {
    setBreadcrumbStep([
      { name: mainCategoryName, current: false },
      { name: subCategoryName, current: true },
    ]);
  }

  useEffect(() => {
    if (setupAccount == null || setupAccount.length === 0) {
      return;
    }
    if (!setupAccount[0]?.steps[0].complete) {
      setBreadcrumbsArray("Configure your property", "Add Unit Numbers");
      setWarningMsg(
        "Enter at least one Unit Number to complete this step. To add more units later, go to Unit Numbers on the dashboard.",
      );
      return;
    }

    if (!setupAccount[0]?.steps[1].complete) {
      setBreadcrumbsArray("Configure your property", "Add Property Contacts");
      setWarningMsg(
        "Enter at least one Property Contact to complete this step. To add more later, go to Property Contacts on the dashboard.",
      );
      return;
    }

    if (!setupAccount[0]?.steps[2].complete) {
      setBreadcrumbsArray("Configure your property", "Add Documents");
      setWarningMsg(
        "Upload at least one Document to complete this step. To add more later, go to Documents on the dashboard.",
      );
      return;
    }

    if (!setupAccount[1]?.steps[0].complete) {
      setBreadcrumbsArray("Test Drive", "Download App");
      setWarningMsg("");
      return;
    }

    if (!setupAccount[1]?.steps[1].complete) {
      setBreadcrumbsArray("Test Drive", "Approve Yourself");
      setWarningMsg("");
      return;
    }

    if (!setupAccount[1]?.steps[2].complete) {
      setBreadcrumbsArray("Test Drive", "Test Announcement");
      setWarningMsg("Send at least one Announcement to complete this step.");
      return;
    }

    if (!setupAccount[2]?.steps[0].complete) {
      setBreadcrumbsArray("Invite Residents", "Add Emails");
      setWarningMsg(
        "Enter at least one email address to complete this step. To add more emails later, go to Resident Directory on the dashboard.",
      );
      return;
    }

    if (!setupAccount[2]?.steps[1].complete) {
      setBreadcrumbsArray("Invite Residents", "Invite QR");
      setWarningMsg("");
    }
  }, [setupAccount]);

  const renderIncomplete = () => {
    if (!setupAccount[0]?.complete) {
      if (!setupAccount[0]?.steps[0].complete) {
        return <UnitNumbersNav />;
      }

      if (!setupAccount[0]?.steps[1].complete) {
        return <PropertyContactsNav />;
      }

      if (!setupAccount[0]?.steps[2].complete) {
        return <DocumentsNav />;
      }
    } else if (!setupAccount[1]?.complete) {
      if (!setupAccount[1]?.steps[0].complete) {
        return <DownloadAppPage />;
      }

      if (!setupAccount[1]?.steps[1].complete) {
        return <ApproveYourselfPage />;
      }

      if (!setupAccount[1]?.steps[2].complete) {
        return <AnnouncementsNav />;
      }
    } else if (!setupAccount[2]?.complete) {
      if (!setupAccount[2]?.steps[0].complete) {
        return <ImportEmailsNav />;
      }

      if (!setupAccount[2]?.steps[1].complete) {
        setWarningMsg("");

        return <FinishSetup />;
      }
    }

    return <FinishSetup />;
  };

  if (isLoading || setupAccount == null) {
    return <LoadingView />;
  }

  if (setupAccountError) {
    return <ErrorView message={setupAccountError} />;
  }

  return (
    <div className={`flex ${isOpen ? "flex-row" : "flex-col"}`}>
      {isOpen ? (
        <div>
          <SetupAccountProgressSidebar
            setIsOpen={setIsOpen}
            stepsStatus={setupAccount}
          />
        </div>
      ) : (
        <HiMenu
          className={"block h-10 w-8"}
          aria-hidden={"true"}
          onClick={() => setIsOpen(true)}
        />
      )}

      <div className={"w-full py-6 px-2 border-l min-h-screen overflow-scroll"}>
        <LaunchTab />
        <div className={"visible lg:invisible mt-2"}>
          <ProgressBreadcrumbs breadcrumbStep={breadcrumbStep} />
        </div>
        {warningMsg && <StepAlert warningMsg={warningMsg} />}

        {renderIncomplete()}
      </div>
    </div>
  );
};
