import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { managersApi } from "../../api/managersApi";
import type { AppThunk } from "../../app/store";
import { getSelectionIds } from "../../utils/Helpers";

type AllDocuments = Array<ManagersClient.SharedGetDocumentResDto>;

export interface DocumentsListingSliceState {
  status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
  error?: string;
  allDocuments?: AllDocuments;
}

const initialState: DocumentsListingSliceState = {
  status: "IDLE",
};

export const DocumentsListingSlice = createSlice({
  name: "documentsListing",
  initialState,
  reducers: {
    getDocumentsStart: (state) => {
      state.status = "LOADING";
    },
    getDocumentsSuccess: (
      state,
      action: PayloadAction<{
        allDocuments: AllDocuments;
      }>,
    ) => {
      state.status = "SUCCESS";
      state.allDocuments = action.payload.allDocuments;
    },
    getDocumentsFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
  },
});

export const { getDocumentsStart, getDocumentsSuccess, getDocumentsFailure } =
  DocumentsListingSlice.actions;

export const documentsListingReducer = DocumentsListingSlice.reducer;

export const loadDocumentsListing =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(getDocumentsStart());
      const { orgId, propertyId } = getSelectionIds(getState());
      const allDocuments = await managersApi.getDocuments({
        orgId,
        propertyId,
      });
      dispatch(getDocumentsSuccess({ allDocuments }));
    } catch (err: any) {
      dispatch(getDocumentsFailure(err.toString()));
    }
  };
export const deleteDocumentById =
  (documentId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { orgId, propertyId } = getSelectionIds(getState());
      await managersApi.deleteDocumentById({
        orgId,
        propertyId,
        documentId,
      });
      dispatch(loadDocumentsListing());
    } catch (err: any) {
      dispatch(getDocumentsFailure(err.toString()));
    }
  };
