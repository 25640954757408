import {ManagersClient} from "@fabricapp-ca/fabricapp-openapi";
import React, {FunctionComponent} from "react";
import {MdClose} from "react-icons/all";

import {SharedStyles} from "../../../themes/FabTheme";
import {ImportEmailsInputRow} from "./ImportEmailsInputRow";

type UnitGroupRowProps = {
  index: number;
  name: string;
  floor: string;
  residentEmails: ManagersClient.ManagerPutTemporaryUnitResidentEmail[];
  clickAddEmail: boolean;
  deleteEmailFromRow: (rowIndex: number, id: string) => void;
  handleChange(index: number, evnt: any): void;
};

export const ImportEmailsRow: FunctionComponent<UnitGroupRowProps> = (
  props,
) => {
  const {
    index,
    name,
    floor,
    residentEmails,
    clickAddEmail,
    deleteEmailFromRow,
    handleChange,
  } = props;

  return (
    <tr key={index as any}>
      <td className={"p-3"}>
        <span
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}>
          {name}
        </span>
      </td>
      <td className={"p-3"}>
        <span
          className={`${SharedStyles.inputs.inputDesc()} border-gray-300 justify-self-center ml-3`}>
          {floor}
        </span>
      </td>

      {clickAddEmail && (
        <ImportEmailsInputRow
          index={index}
          residentEmails={residentEmails}
          handleChange={handleChange}
        />
      )}

      <td className={"p-3"}>
        {residentEmails.map(function (item) {
          return (
            <span
              key={item.id}
              className={
                "inline-block bg-green-100 rounded p-1 m-1 text-xs lg:text-sm md:text-sm "
              }>
              {item.email}

              <button
                onClick={() => {
                  deleteEmailFromRow(index, item.id as string);
                }}>
                <MdClose size={12} />
              </button>
            </span>
          );
        })}
      </td>
    </tr>
  );
};
