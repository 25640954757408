import { SchemaValidation } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ButtonSimple, ErrorView } from "@fabricapp-ca/fabricapp-shared-ui";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { FunctionComponent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormLabel } from "../../components/forms/FormLabel";
import { MultiSelect } from "../../components/forms/MultiSelect";
import { SharedStyles } from "../../themes/FabTheme";
import { prettyRawError } from "../../utils/ErrorParseDisplay";
import {
  resetPostOneUnitProfileStatus,
  resetUpdateDeleteOneUnitProfileStatus,
  updateUnitProfile,
} from "./unitProfilesSlice";

type Props = {
  basePath: string;
  unitProfile: ManagersClient.ManagerGetUnitOnlyResDto;
  existingStorageUnits: ManagersClient.StorageUnitDto[];
  existingParkingStalls: ManagersClient.ParkingDto[];
};

const schema = Joi.object<ManagersClient.ManagerGetUnitResDto>({
  name: SchemaValidation.FieldRequiredString({ label: "Name" }),
  floor: SchemaValidation.FieldOptionalString({ label: "Floor", min: 1 }),
  id: SchemaValidation.FieldRequiredString({ label: "Unit ID" }),
  storageUnits: SchemaValidation.FieldOptionalArray({
    label: "Storage",
    min: 0,
    items: Joi.object({
      name: SchemaValidation.FieldRequiredString({
        label: "Name",
        min: 1,
      }),
      id: SchemaValidation.FieldOptionalString({
        label: "ID",
        min: 1,
      }),
    }),
  }),
  parkingStalls: SchemaValidation.FieldOptionalArray({
    label: "Parking",
    min: 0,
    items: Joi.object({
      name: SchemaValidation.FieldRequiredString({
        label: "Name",
        min: 1,
      }),
      id: SchemaValidation.FieldOptionalString({
        label: "ID",
        min: 1,
      }),
    }),
  }),
});

export const UnitProfileComposer: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const { basePath, unitProfile, existingStorageUnits, existingParkingStalls } =
    props;

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm<ManagersClient.ManagerGetUnitResDto>({
    defaultValues: unitProfile || {},
    mode: "onChange",
    resolver: joiResolver(schema, { allowUnknown: true }),
  });
  const dispatch = useAppDispatch();

  const { postOneStatus, updateDeleteOneStatus, updateDeleteOneError } =
    useAppSelector((state) => state.unitProfiles.unitProfiles);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(
    "UnitProfileComposer | UnitProfileComposer - errors",
    errors,
    prettyRawError(errors),
  );

  React.useEffect(() => {
    return () => {
      dispatch(resetPostOneUnitProfileStatus());
      dispatch(resetUpdateDeleteOneUnitProfileStatus());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (
      postOneStatus === "POST_ONE_LOADING" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_LOADING"
    ) {
      setIsLoading(true);
      return;
    }

    if (
      postOneStatus === "POST_ONE_SUCCESS" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_SUCCESS"
    ) {
      setIsLoading(false);
      history.goBack();
      return;
    }

    if (
      postOneStatus === "POST_ONE_ERROR" ||
      updateDeleteOneStatus === "UPDATE_DELETE_ONE_ERROR"
    ) {
      setIsLoading(false);
    }
  }, [basePath, history, postOneStatus, updateDeleteOneStatus]);

  async function onUpdate(data: ManagersClient.ManagerGetUnitResDto) {
    console.log(
      "UnitProfileComposer: data",
      data,
      // prettyRawError(dto),
    );
    if (unitProfile) {
      dispatch(
        updateUnitProfile({
          unitId: unitProfile.id,
          dto: data,
        }),
      );
    }
  }

  const getLabelsForAttachments = (
    dtos:
      | ManagersClient.ParkingDto[]
      | ManagersClient.StorageUnitDto[]
      | undefined,
  ) => {
    if (!dtos) {
      return [];
    }
    return dtos.map((attachment) => {
      return {
        label:
          attachment.unitName && attachment.unitName !== unitProfile.name
            ? `${attachment.name} - Unit ${attachment.unitName}`
            : `${attachment.name}`,
        value: attachment.id,
      };
    });
  };

  const isFloorFilled =
    unitProfile?.floor != null && unitProfile?.floor.trim().length > 0;

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"text-2xl font-medium text-heading py-4 mb-4"}>
        {"Edit Unit Profile"}
      </div>
      <form>
        <div className={"grid grid-cols-12 gap-6 pt-10"}>
          <div className={"col-span-full"}>
            <FormLabel text={"Unit Number"} htmlForId={"name"} />
            <input
              type={"text"}
              {...register("name")}
              className={SharedStyles.inputs.disabled()}
              disabled
            />
            {errors.name && (
              <div className={"text-error"}>{errors.name.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"Floor"} htmlForId={"floor"} />
            <input
              type={"text"}
              {...register("floor")}
              className={
                isFloorFilled
                  ? SharedStyles.inputs.disabled()
                  : SharedStyles.inputs.input()
              }
              disabled={isFloorFilled}
            />
            {errors.floor && (
              <div className={"text-error"}>{errors.floor.message}</div>
            )}
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"Storage Units"} />
            <Controller
              control={control}
              name={"storageUnits"}
              render={({ field }) => (
                <MultiSelect
                  creatable
                  name={"Storage Units"}
                  options={getLabelsForAttachments(existingStorageUnits)}
                  defaultValues={getLabelsForAttachments(
                    unitProfile?.storageUnits,
                  )}
                  onChange={(allSelectedOptions) => {
                    console.log("multiselect | allSelectedOptions", {
                      allSelectedOptions,
                    });
                    const selectedStorageUnits = allSelectedOptions.map(
                      (val) => {
                        if (val.__isNew__) {
                          return {
                            name: val.label,
                          };
                        }
                        return {
                          id: val.value,
                          name: val.label,
                        };
                      },
                    );
                    field.onChange(selectedStorageUnits);
                  }}
                />
              )}
            />
            <div className={"text-sm text-emph-high mt-2"}>
              Enter the storage units allocated to this unit.
            </div>
          </div>
          <div className={"col-span-full"}>
            <FormLabel text={"Parking Stalls"} />
            <Controller
              control={control}
              name={"parkingStalls"}
              render={({ field }) => (
                <MultiSelect
                  name={"Parking Stalls"}
                  creatable
                  options={getLabelsForAttachments(existingParkingStalls)}
                  defaultValues={getLabelsForAttachments(
                    unitProfile?.parkingStalls,
                  )}
                  onChange={(allSelectedOptions) => {
                    console.log("multiselect | allSelectedOptions", {
                      allSelectedOptions,
                    });
                    const selectedParkingStalls = allSelectedOptions.map(
                      (val) => {
                        if (val.__isNew__) {
                          return {
                            name: val.label,
                          };
                        }
                        return {
                          id: val.value,
                          name: val.label,
                        };
                      },
                    );
                    field.onChange(selectedParkingStalls);
                  }}
                />
              )}
            />
            <div className={"text-sm text-emph-high mt-2"}>
              Enter the parking stalls allocated to this unit.
            </div>
          </div>
          <>
            <div className={"py-3 mt-2"}>
              <div className={"space-x-4"}>
                <ButtonSimple
                  text={"Update"}
                  btnType={"submit"}
                  btnStyle={"primary"}
                  isDisabled={!isValid}
                  isSpinning={isLoading}
                  onClick={handleSubmit(onUpdate)}
                />
              </div>
            </div>
            {updateDeleteOneError && (
              <ErrorView message={updateDeleteOneError} />
            )}
          </>
        </div>
      </form>
    </div>
  );
};
