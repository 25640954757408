import { dayjsUtils } from "@fabricapp-ca/fabricapp-data-models";
import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { capitalCase } from "change-case";
import React from "react";
import { upperCaseFirst } from "upper-case-first";

import { getAvatarOrPhotoUrl } from "../../../utils/AvatarInitials";

type ApprovedManagerRowProps = {
  basePath: string;
  dto: ManagersClient.ManagerGetApprovedManagerResDto;
};

export const ApprovedManagerRow: React.FC<ApprovedManagerRowProps> = (
  props,
) => {
  const { dto } = props;
  const {
    firstName,
    lastName,
    email,
    createdAt,
    photoPath,
    managerRole,
    managerTitle,
  } = dto;

  const avatarUrl = getAvatarOrPhotoUrl({
    firstName,
    lastName: lastName ?? "",
    photoPath,
  });

  return (
    <tr>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <div className={"flex items-center"}>
          <div className={"w-12 h-12 flex-none image-fit"}>
            <img
              alt={"View Profile"}
              className={"rounded-full"}
              src={avatarUrl}
            />
          </div>
          <div className={"flex flex-col ml-4"}>
            <div className={"text-sm font-medium text-gray-900"}>
              {firstName} {lastName}
            </div>
          </div>
        </div>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        {upperCaseFirst(dayjsUtils.formatDate(createdAt))}
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <span
          className={
            "inline-flex text-xs leading-5"
          }>
          {managerTitle ? capitalCase(managerTitle) : "n/a"}
        </span>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"}>
        <span
          className={
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          }>
          {managerRole ? capitalCase(managerRole) : "n/a"}
        </span>
      </td>
      <td className={"px-6 py-4 whitespace-nowrap text-sm text-gray-500"}>
        {email}
      </td>
      <td
        className={
          "px-6 py-4 space-x-4 whitespace-nowrap text-right text-sm font-medium"
        }>
        <div className={"flex justify-end"}>
          {/*<LinkSimple*/}
          {/*  text={"View"}*/}
          {/*  destination={`${basePath}/${id}`}*/}
          {/*  iconPosition={"right"}*/}
          {/*/>*/}
        </div>
      </td>
    </tr>
  );
};
