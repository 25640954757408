import { CurrentUser, fbAuth } from "../configs/FirebaseConfig";
import { LocalStorageAPI } from "./localStorageAPI";

export async function firebaseSignInWithEmailLink(): Promise<CurrentUser> {
  const windowLocationHref = window.location.href;
  if (!fbAuth.isSignInWithEmailLink(windowLocationHref)) {
    throw Error("Invalid incoming URL");
  }

  const signInEmail = await LocalStorageAPI.getKv("signInEmail");
  if (!signInEmail) {
    throw Error("Missing sign-in email. Please sign-in again on this device.");
  }

  const userCredential = await fbAuth.signInWithEmailLink(
    signInEmail,
    windowLocationHref,
  );
  // console.debug(
  //   "app: signInWithEmailLink | setCurrentUser",
  //   userCredential.user,
  // );
  LocalStorageAPI.removeKv("signInEmail");
  return userCredential.user ?? undefined;
}

export function firebaseSignOut(): Promise<void> {
  return fbAuth.signOut();
}
