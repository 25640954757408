import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { getAllBuildingsListing } from "../Buildings/BuildingsListingSlice";
import { EmptyStateImage } from "../DefaultImages/EmptyStateImage";
import { updateSelectedProperty } from "../SelectionContext/SelectionContextSlice";
import { PropertyCard } from "./PropertyCard";
import {
  loadPropertyListing,
  propertiesSelector,
  resetGetAllStatus,
} from "./PropertyListingSlice";

export type Props = {
  basePath: string;
};

export const PropertyListing: React.FC<Props> = (props) => {
  const { basePath } = props;
  const { selectedOrg } = useAppSelector((state) => state.selectionContext);
  const { getAllStatus, getAllError } = useAppSelector(
    (state) => state.properties.propertyListing,
  );
  const properties = propertiesSelector.selectAll(store.getState());
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    dispatch(loadPropertyListing());
  }, [dispatch]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(resetGetAllStatus());
    }
  }, [dispatch, isLoading]);

  React.useEffect(() => {
    switch (getAllStatus) {
      case "ALL_LOADING":
        setIsLoading(true);
        break;
      case "ALL_SUCCESS":
        setIsLoading(false);
        break;
      case "ALL_ERROR":
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getAllStatus]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (getAllError) {
    return <ErrorView message={getAllError} />;
  }

  if (!properties || properties.length < 1) {
    return (
      <EmptyStateImage
        text={
          "No properties yet! If you are an admin, you can create a new property."
        }
        imageType={ManagersClient.SharedDefaultImageResDtoTypeEnum.Property}
      />
    );
  }

  const propertyCards = properties.map((item) => {
    return (
      <PropertyCard
        key={item.id}
        property={item}
        onClick={() => {
          console.log("PropertyListing | selected property", {
            basePath,
            id: item.id,
          });
          dispatch(
            getAllBuildingsListing({
              propertyId: item.id,
              orgId: selectedOrg?.id || "",
            }),
          );
          dispatch(updateSelectedProperty({ selectedProperty: item }));
        }}
      />
    );
  });

  return (
    <div className={"flex flex-col space-y-4 w-full mx-auto px-0 py-4"}>
      <div className={"flex flex-row"}>{propertyCards}</div>
    </div>
  );
};
