import { ErrorView, LoadingView } from "@fabricapp-ca/fabricapp-shared-ui";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import { loadLsFbAuthToken, resetToIdle } from "./AppSlice";
import { ScrollToTopOnMount } from "./components/navigation/ScrollToTopOnMount";
import { SignInNav } from "./features/Auth/SignInNav";
import { BuildingSignInNav } from "./features/Buildings/BuildingSignInNav";
import DashboardNav from "./features/Dashboard/DashboardNav";
import OnboardingNav from "./features/Onboarding/OnboardingNav";
import { OrganizationSignInNav } from "./features/Organizations/OrganizationSignInNav";
import { loadProfileInfoListing } from "./features/Profile/ProfileInfoSlice";
import { OldPropertyComposer } from "./features/Property/OldPropertyComposer";
import { PropertyListing } from "./features/Property/PropertyListing";
import { PropertySignInNav } from "./features/Property/PropertySignInNav";
import { SetupAccountNav } from "./features/SetupAccount/SetupAccountNav";
import { SignupNav } from "./features/Signup/SignupNav";
import { trackScreen } from "./utils/Amplitude";

const App: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { status, error } = useAppSelector((state) => state.appLaunch);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userSignedOut, setUserSignedOut] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const { getProfileStatus } = useAppSelector((state) => state.profileInfo);

  React.useEffect(() => {
    if (!isLoading && status === "SIGN_IN_SUCCESS") {
      dispatch(loadProfileInfoListing());
    }
  }, [isLoading, dispatch, status]);

  React.useEffect(() => {
    dispatch(loadLsFbAuthToken());
  }, [dispatch]);

  React.useEffect(() => {
    const currentLocation = location.pathname;
    if (userSignedOut && currentLocation.indexOf("/signup") === -1) {
      if (currentLocation.indexOf("/signin") === -1) {
        history.push("/signin");
      }
    }
  }, [userSignedOut, dispatch]);

  React.useEffect(() => {
    switch (status) {
      case "LOADING":
        setIsLoading(true);
        break;
      case "SUCCESS":
        setIsLoading(false);
        break;
      case "ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      case "SIGNING_IN":
        setIsLoading(true);
        break;
      case "SIGN_IN_SUCCESS":
        setIsLoading(false);
        break;
      case "SIGN_OUT":
        setUserSignedOut(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [status]);

  React.useEffect(() => {
    switch (getProfileStatus) {
      case "PROFILE_LOADING":
        setIsLoading(true);
        break;
      case "PROFILE_SUCCESS":
        setIsLoading(false);
        break;
      case "PROFILE_ERROR":
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [getProfileStatus]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(resetToIdle());
    }
  }, [dispatch, isLoading]);

  React.useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);

  function trackPageView() {
    trackScreen(window.location.pathname);
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (hasError && error) {
    return <ErrorView message={error} />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <div className={"flex flex-col min-h-screen overflow-hidden"}>
        <main className={"min-w-full mx-auto"}>
          <Switch>
            <Route path={"/onboarding"} component={OnboardingNav} />
            <Route path={"/dashboard"} component={DashboardNav} />
            <Route path={"/setup-account"} component={SetupAccountNav} />
            <Route
              path={"/select-organization"}
              component={OrganizationSignInNav}
            />
            <Route path={"/select-property"} component={PropertySignInNav} />
            <Route path={"/select-building"} component={BuildingSignInNav} />
            <Route
              exact
              path={"/select-building"}
              component={PropertyListing}
            />
            <Route
              exact
              path={"/select-building/add"}
              component={OldPropertyComposer}
            />
            <Route path={"/signup"}>
              <SignupNav />
            </Route>
            <Route path={"/signin"}>
              <SignInNav />
            </Route>
            <Route path={"/"}>
              <Redirect to={"/signin"} />
            </Route>
          </Switch>
        </main>
        <footer
          className={
            "bg-white border border-t-0 shadow-inner text-center text-emph-low font-light py-10 space-x-8"
          }>
          <a target={"_blank"} href={"/privacy-policy.pdf"} rel={"noreferrer"}>
            Privacy
          </a>
          <a target={"_blank"} href={"/terms.pdf"} rel={"noreferrer"}>
            Terms of service
          </a>
        </footer>
      </div>
    </>
  );
};

export default App;
