import { ManagersClient } from "@fabricapp-ca/fabricapp-openapi";
import { SharedDefaultImageResDtoTypeEnum } from "@fabricapp-ca/fabricapp-openapi/dist/generated/managers-ts-fetch";
import React from "react";

import { DefaultImage } from "../DefaultImages/DefaultImage";

type BuildingCardProps = {
  building: ManagersClient.ManagerGetBuildingResDto;
  onClick: (building: any) => void;
};

export const BuildingCard: React.FC<BuildingCardProps> = (props) => {
  const { building, onClick } = props;
  const { name, address } = building;

  return (
    <div>
      <button
        type={"button"}
        className={"mx-4 w-64"}
        onClick={() => onClick(building)}>
        <div
          className={
            "flex flex-col justify-center items-center border border-border-low rounded-lg py-8 focus:outline-none focus:ring-1 focus:bg-indigo-700 group hover:bg-primary hover:shadow-lg hover:border-transparent"
          }>
          <div className={"w-full h-40 mb-4"}>
            <DefaultImage
              imageType={SharedDefaultImageResDtoTypeEnum.Property}
            />
          </div>
          <div className={"text-lg text-default group-hover:text-white"}>
            {name}
          </div>
          <div className={"text-sm text-emph-low group-hover:text-gray-100"}>
            {address}
          </div>
        </div>
      </button>
    </div>
  );
};
