import { SharedStyles } from "../themes/FabTheme";

export function getCurrentTabClassName(input: {
  pathname: string;
  currentPath: string;
  pathToDefault: string;
  isDefault: boolean;
}): string {
  const { pathname, pathToDefault, isDefault, currentPath } = input;
  const isMyPath = pathname.endsWith(currentPath);
  const isCurrentPath = pathname.endsWith(pathToDefault) && isDefault;
  const isActive = isMyPath || isCurrentPath;
  return isActive
    ? SharedStyles.tabs.selectedTabClassName()
    : SharedStyles.tabs.unselectedTabClassName();
}
