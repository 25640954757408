import { parsePhoneNumber } from "libphonenumber-js";
import React, { FunctionComponent, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ResidentDeleteModal } from "./ResidentDeleteModal";
import { deleteUnitResident } from "./ResidentDirectoryListingSlice";

type ResidentUnitUserCardInput = {
  avatar: string;
  name: string;
  role: string;
  phoneNumber: string;
  email: string | undefined;
  unitId: string;
  residentId: string;
};

export const ResidentUnitUserCard: FunctionComponent<
  ResidentUnitUserCardInput
> = (props) => {
  const { avatar, name, role, phoneNumber, email, unitId, residentId } = props;
  const phoneNumberParsed = parsePhoneNumber(phoneNumber);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { deletingResident } = useAppSelector(
    (state) => state.residentDirectory.directoryListing,
  );

  const deleteResident = () => {
    if (deletingResident !== residentId) {
      dispatch(deleteUnitResident(residentId, unitId));
    }
  };

  return (
    <div
      className={
        "flex flex-1 flex-col lg:flex-row items-center max-w-xs w-full py-2 my-2 px-5"
      }>
      <div className={"w-12 h-12 lg:w-12 lg:h-12 image-fit lg:mr-1"}>
        <img alt={"User Avatar"} className={"rounded-full"} src={avatar} />
      </div>
      <div className={"lg:ml-2 text-center lg:text-left mt-3 lg:mt-0"}>
        <div className={"font-medium"}>
          {name}
          <span
            className={
              "inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 ml-2"
            }>
            {role}
          </span>
        </div>

        <div className={"text-gray-600 text-sm mt-0.5"}>
          {phoneNumberParsed.formatNational()}
        </div>

        <div className={"text-gray-600 text-sm mt-0.5"}>{email}</div>
        <div
          tabIndex={0}
          onKeyDown={() => setOpenModal(true)}
          role={"button"}
          className={"text-red-600 cursor-pointer text-sm underline mt-0.5"}
          onClick={() => setOpenModal(true)}>
          {deletingResident === residentId ? "deleting" : "delete resident"}
        </div>
      </div>
      <div className={"flex mt-4 lg:mt-0"}>
        {/*<Link className={"underline text-primary"} to={`${url}/view-profile`}>*/}
        {/*  View Profile*/}
        {/*</Link>*/}
      </div>

      <ResidentDeleteModal
        showModal={openModal}
        setShowModal={setOpenModal}
        deleteFn={deleteResident}
        residentId={residentId}
      />
    </div>
  );
};
