import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocalStorageAPI } from "./api/localStorageAPI";
import type { AppThunk } from "./app/store";
import { CurrentUserInfo } from "./configs/FirebaseConfig";
import { verifyEmailSuccess } from "./features/Auth/FirebaseSlice";

export interface AppLaunchSliceState {
  status:
    | "IDLE"
    | "LOADING"
    | "SUCCESS"
    | "ERROR"
    | "SIGNING_IN"
    | "SIGN_OUT"
    | "SIGN_IN_SUCCESS";
  error?: string;
}

const initialState: AppLaunchSliceState = {
  status: "IDLE",
};

export const AppLaunchSlice = createSlice({
  name: "appLaunch",
  initialState,
  reducers: {
    resetToIdle: (state) => {
      state.status = "IDLE";
    },
    getLsFbAuthTokenStart: (state) => {
      state.status = "LOADING";
    },
    getLsFbAuthTokenSuccess: (state) => {
      state.status = "SUCCESS";
    },
    getLsFbAuthTokenFailure: (state, action: PayloadAction<string>) => {
      state.status = "ERROR";
      state.error = action.payload;
    },
    signingInWithFirebase: (state) => {
      state.status = "SIGNING_IN";
    },
    signedInWithFirebase: (state) => {
      state.status = "SIGN_IN_SUCCESS";
    },
    getLsFbAuthTokenSignOut: (state) => {
      state.status = "SIGN_OUT";
    },
  },
});

export const {
  resetToIdle,
  getLsFbAuthTokenStart,
  getLsFbAuthTokenSuccess,
  getLsFbAuthTokenFailure,
  getLsFbAuthTokenSignOut,
  signingInWithFirebase,
  signedInWithFirebase,
} = AppLaunchSlice.actions;

export const appLaunchReducer = AppLaunchSlice.reducer;

export const loadLsFbAuthToken = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getLsFbAuthTokenStart());

    const userInfo =
      (await LocalStorageAPI.getJson<CurrentUserInfo>("currentUserInfo")) ??
      undefined;
    if (userInfo) {
      // console.log("sign-in with restored LS token success", {
      //   userInfo,
      // });
      dispatch(verifyEmailSuccess({ userInfo }));
    } else {
      dispatch(getLsFbAuthTokenSignOut());
    }
  } catch (err: any) {
    dispatch(getLsFbAuthTokenFailure(err.toString()));
  }
};
